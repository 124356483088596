import React, { FC, useState, useCallback, useEffect, Fragment } from 'react';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { HeaderContainer } from '../header/HeaderContainer';
// eslint-disable-next-line no-restricted-imports
import {
	Container,
	Row,
	Col,
	Input,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Media,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { RemoteData } from '@devexperts/remote-data-ts';
import { some } from 'fp-ts/lib/Option';
import { Team, TeamMember } from 'volley-common/dist/services/teams.service';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import { formatInitials } from 'volley-common/dist/utils/string.utils';
import cn from 'classnames';
import { constVoid } from 'fp-ts/lib/function';
import { isDisplayedError } from 'volley-common/dist/utils/error.utils';

export type TeamMembersProps = {
	canEdit: boolean;
	canAddMembers: boolean;
	teamId: number;
	team: RemoteData<Error, Team>;
	onInviteMembers: (emails: string[]) => void;
	inviteResult: RemoteData<Error, unknown>;
	onRemoveMember: (type: 'member' | 'prospect', id: number) => void;
	removeMemberResult: RemoteData<Error, unknown>;
	onResendInvite: (id: number) => void;
	resendInviteResult: RemoteData<Error, unknown>;
	userId: RemoteData<Error, number>;
};

export const TeamMembersContent: FC<Omit<TeamMembersProps, 'team'> & { team: Team }> = ({
	team,
	canEdit,
	onRemoveMember,
	onResendInvite,
	userId,
}) => {
	return (
		<Fragment>
			<Row className="justify-content-center">
				<Col xl={10}>
					<div className="list-default margin-top">
						<MembersListRow
							canEdit={false}
							member={team.owner}
							key={team.owner.id}
							status={'Owner'}
							onRemoveMember={constVoid}
							onResendInvite={constVoid}
						/>
						{team.members.map(member => (
							<MembersListRow
								canEdit={canEdit}
								member={member}
								key={member.id}
								status={'Member'}
								onRemoveMember={onRemoveMember}
								onResendInvite={onResendInvite}
								isSelf={userId.exists(selfId => selfId === member.id)}
							/>
						))}
						{team.prospects.map(member => (
							<MembersListRow
								canEdit={canEdit}
								member={member}
								key={member.id}
								status={'Invited'}
								onRemoveMember={onRemoveMember}
								onResendInvite={onResendInvite}
							/>
						))}
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

type MembersListRowProps = {
	member: TeamMember | { id: number; email: string; name: undefined; profile: undefined };
	status: 'Member' | 'Owner' | 'Invited';
	onRemoveMember: (type: 'prospect' | 'member', id: number) => void;
	onResendInvite: (id: number) => void;
	canEdit: boolean;
	isSelf?: boolean;
};

function MembersListRow({
	member,
	status,
	onRemoveMember,
	onResendInvite,
	canEdit,
	isSelf = false,
}: MembersListRowProps) {
	const { id } = member;
	const handleRemove = useCallback(
		() => onRemoveMember(status === 'Invited' ? 'prospect' : 'member', id),
		[id, status, onRemoveMember],
	);
	const handleResend = useCallback(() => onResendInvite(id), [id, onResendInvite]);
	const name = member.name || member.email.split('@')[0];
	const canResend = canEdit && status === 'Invited';
	const canRemove = canEdit || isSelf;

	return (
		<Row className="align-items-center">
			<Col sm={6}>
				<Media className="align-items-center">
					<div className="avatar avatar-xs mr-3">{formatInitials(name)}</div>
					<Media body>
						<h4 className="no-margin medium-font">{name}</h4>
						<h5 className="no-margin">{member.email}</h5>
					</Media>
				</Media>
			</Col>
			<Col>
				<h4
					className={cn('no-margin', {
						'medium-font': status === 'Owner',
						'extra-light-text-color': status === 'Invited',
					})}>
					{status}
				</h4>
			</Col>
			<Col>
				{(canRemove || canResend) && status !== 'Owner' && (
					<div className="justify-content-end d-flex">
						<UncontrolledDropdown>
							<DropdownToggle className="btn btn-flat btn-icon menu-button">
								<i className="material-icons">more_horiz</i>
							</DropdownToggle>
							<DropdownMenu right>
								{canRemove && (
									<DropdownItem onClick={handleRemove}>
										{isSelf ? 'Leave team' : 'Remove from team'}
									</DropdownItem>
								)}
								{canResend && <DropdownItem onClick={handleResend}>Resend invite</DropdownItem>}
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				)}
			</Col>
		</Row>
	);
}

export const TeamMembers = combineReader(
	HeaderContainer,
	(HeaderContainer): FC<TeamMembersProps> =>
		props => {
			const { inviteResult, onInviteMembers, team, canAddMembers } = props;
			const [inviteModal, toggleInviteModal] = useState(false);
			const showInviteModal = useCallback(() => toggleInviteModal(true), []);
			const hideInviteModal = useCallback(() => toggleInviteModal(false), []);

			useEffect(() => {
				if (inviteResult.isSuccess()) {
					hideInviteModal();
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [inviteResult]);

			return (
				<div id="dashboard-container">
					<HeaderContainer teamId={some(props.teamId)} />
					{team
						.map(team => (
							<AddMembersModal
								isOpen={inviteModal}
								result={inviteResult}
								onCancel={hideInviteModal}
								onConfirm={onInviteMembers}
								team={team}
							/>
						))
						.toNullable()}
					<div className="main">
						<section>
							<Container>
								<Row className="justify-content-center">
									<Col xl={10}>
										<Row className="align-items-center page-header-for-title">
											<Col>
												<h2 className="medium-font title">Members</h2>
											</Col>
											<Col>
												{canAddMembers && (
													<div className="justify-content-end d-flex">
														<Button
															color="primary"
															disabled={!team.isSuccess()}
															onClick={showInviteModal}>
															+ Add members
														</Button>
													</div>
												)}
											</Col>
										</Row>
									</Col>
								</Row>
								<RenderRemoteData
									data={props.team}
									success={data => <TeamMembersContent {...props} team={data} />}
								/>
							</Container>
						</section>
					</div>
				</div>
			);
		},
);

type AddMembersModalProps = {
	isOpen: boolean;
	result: RemoteData<Error, unknown>;
	onCancel: () => void;
	onConfirm: (emails: string[]) => void;
	team: Team;
};

function AddMembersModal({ isOpen, result, onCancel, onConfirm, team }: AddMembersModalProps) {
	const [formKey, setFormKey] = useState(0);
	useEffect(() => {
		if (isOpen) {
			setFormKey(i => i + 1);
		}
	}, [isOpen]);
	const handleSubmit = useCallback(
		(values: any) => {
			onConfirm(
				((values.emails || '') as string)
					.trim()
					.split(/\s*,\s*/)
					.filter(s => s.length > 0),
			);
		},
		[onConfirm],
	);
	const inlineError = result.isFailure() && isDisplayedError(result.error) ? result.error.message : null;

	return (
		<Modal isOpen={isOpen} toggle={onCancel}>
			<ModalHeader toggle={onCancel} className="no-border-bottom no-padding-bottom" />
			<ModalBody>
				<div className="padded no-padding-top no-padding-bottom">
					<h2 className="text-center">Invite people to your team</h2>
					<p className="light-text-color text-center">
						Team members can view, comment, or also create and edit all projects. Typically used for
						employees.
					</p>
					<Form
						key={formKey}
						onSubmit={handleSubmit}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<div className="padding-top">
									<div className="position-relative">
										<Field
											name="emails"
											render={({ input }) => (
												<Input
													style={{ height: 'auto' }}
													bsSize="lg"
													placeholder="Enter emails separated by commas"
													invalid={!!inlineError}
													rows="4"
													{...input}
													type="textarea"
												/>
											)}
										/>
										{inlineError && <div className="invalid-feedback d-block">{inlineError}</div>}
									</div>
								</div>
								<div className="double-margin-bottom margin-top">
									<h5 className="light-text-color">
										Enter the email addresses (comma separated) of the people you want to invite to
										view the <strong>{team.name}</strong> team.
									</h5>
								</div>
								<div className="text-center">
									<LoadingButton pending={result.isPending()} color="primary" size="lg" block>
										Invite people
									</LoadingButton>
								</div>
							</form>
						)}
					/>
				</div>
			</ModalBody>
			<ModalFooter className="no-border-top" />
		</Modal>
	);
}
