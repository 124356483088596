import * as React from 'react';
import { FC, ReactNode, useMemo, Fragment } from 'react';
import { Modal } from './Modal';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export type TConfirmationProps = {
	isOpen: boolean;
	onClose: () => void;
	title: ReactNode;
	actions: ReactNode;
};

export const Confirmation: FC<TConfirmationProps> = React.memo(({ isOpen, onClose, title, actions, children }) => (
	<Modal isOpen={isOpen}>
		<ModalHeader tag="div" toggle={onClose}>
			<h2 className="padding-left">{title}</h2>
		</ModalHeader>
		<ModalBody>
			<div className="padded no-padding-top no-padding-bottom">{children}</div>
		</ModalBody>
		<ModalFooter className="no-border-top">{actions}</ModalFooter>
	</Modal>
));

export type TYesNoConfirmationProps = {
	isOpen: boolean;
	onClose: () => void;
	title: ReactNode;
	onCancel?: () => void;
	cancelLabel?: ReactNode;
	onConfirm?: () => void;
	confirmLabel?: ReactNode;
};

export const YesNoConfirmation: FC<TYesNoConfirmationProps> = React.memo(props => {
	const { onCancel, cancelLabel = 'Cancel', onConfirm, confirmLabel = 'Confirm' } = props;
	const actions = useMemo(
		() => (
			<Fragment>
				{onCancel && (
					<Button color="link" onClick={onCancel}>
						{cancelLabel}
					</Button>
				)}
				{onConfirm && (
					<Button color="primary" onClick={onConfirm}>
						{confirmLabel}
					</Button>
				)}
			</Fragment>
		),
		[onCancel, cancelLabel, onConfirm, confirmLabel],
	);

	return (
		<Confirmation isOpen={props.isOpen} onClose={props.onClose} title={props.title} actions={actions}>
			{props.children}
		</Confirmation>
	);
});
