import React, { Component, SFC } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import logoSvg from '../../assets/images/logo.svg';
import { Input } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { WelcomeLayout } from '../welcome/WelcomeLayout';
import { Link } from 'react-router-dom';
import { FormField } from '../welcome/FormField';
import { routes } from 'volley-common/dist/utils/routes';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { withReturnUrl } from '../../utils/route';
import qs from 'qs';
import { getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { pipe } from 'fp-ts/lib/function';

export type TLoginData = {
	email: string;
	password: string;
};

type TFormType = Partial<TLoginData>;

export type TLoginProps = {
	onTryLogin: (data: TLoginData) => void;
	result: RemoteData<Error, boolean>;
	returnUrl?: string;
	isLoggedIn: boolean;
};

const Login = combineReader(WelcomeLayout, WelcomeLayout =>
	withReturnUrl(
		class extends Component<TLoginProps> {
			render() {
				const { returnUrl } = this.props;
				const signupUrl = routes.signUp + qs.stringify({ returnUrl }, { addQueryPrefix: true });
				return (
					<WelcomeLayout>
						<div className="form-content">
							<img className="double-margin-top double-margin-bottom" src={logoSvg} width="120" />
							<h2>Welcome back to Volley</h2>
							<p className="light-text-color">The world’s first design QA platform</p>
							<Form onSubmit={this.handleSubmit} render={this.renderForm} />

							<div className="double-margin-top">
								<p>
									No account? <Link to={signupUrl}>Sign up</Link>
								</p>
							</div>
						</div>
					</WelcomeLayout>
				);
			}

			private renderForm: SFC<FormRenderProps> = ({ handleSubmit }) => {
				const { result } = this.props;
				return (
					<form onSubmit={handleSubmit}>
						<Field
							name="email"
							render={({ input }) => (
								<FormField name="Email">
									<Input
										bsSize="lg"
										placeholder="john@doe.com"
										disabled={result.isPending()}
										invalid={result.isFailure()}
										{...input}
										type="email"
									/>
								</FormField>
							)}
						/>
						<Field
							name="password"
							render={({ input }) => (
								<FormField name="Password">
									<Input
										bsSize="lg"
										placeholder="Min 6 chars."
										disabled={result.isPending()}
										invalid={result.isFailure()}
										{...input}
										type="password"
									/>
									{result.isFailure() && (
										<div className="invalid-feedback">
											{pipe(
												(arr: string[]) =>
													arr.length
														? arr
														: ['No active account found with the given credentials.'],
												arr => arr.join(' '),
											)(getNonFieldErrors(result.error))}
										</div>
									)}
								</FormField>
							)}
						/>

						<div className="margin-bottom">
							<p className="no-margin-bpttpm">
								<Link className="light-text-color" to={routes.forgotPassword}>
									Forgot password?
								</Link>
							</p>
						</div>

						<div className="double-margin-top">
							<LoadingButton color="primary" size="lg" block pending={result.isPending()}>
								Log in
							</LoadingButton>
						</div>
					</form>
				);
			};

			handleSubmit = (values: TFormType) => {
				this.props.onTryLogin &&
					this.props.onTryLogin({
						email: values.email || '',
						password: values.password || '',
					});
			};
		},
	),
);

export default Login;
