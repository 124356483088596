import React, {
	Component,
	FocusEventHandler,
	createRef,
	ComponentType,
	Fragment,
	MutableRefObject,
	ClipboardEvent,
} from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import style from './CommentForm.module.scss';
import mentionStyle from 'volley-common/dist/components/Mentions.module.scss';
import { AttachmentPreview } from 'volley-common/dist/components/AttachmentPreview';
import cn from 'classnames';
import { Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { MentionsInput, Mention } from 'react-mentions';
import { TMember } from 'volley-common/dist/services/products.service';
import { TComment } from 'volley-common/dist/services/notes.service';
import { TFileData, TFileOrFileData } from 'volley-common/dist/utils/axios';

export type TEditedComment = Pick<TComment, 'text' | 'attachment'>;

export type TNewComment = {
	text: string;
	attachments: TFileOrFileData[];
	mentions: number[];
};

export type TCommentFormProps = {
	onSave: (comment: TNewComment) => void;
	onCancel?: () => unknown;
	saveStatus: RemoteData<Error, unknown>;
	teamMembers: TMember[];
	isEditMode?: boolean;
	editedComment?: TEditedComment;
	className?: string;
	canUseMentions?: boolean;
	isNoteComment?: boolean;
};

type TCommentFormState = {
	isExpanded: boolean;
	text: string;
	attachments: TFileOrFileData[];
	showError: boolean;
	mentions: Array<{ id: string }>;
	editedComment?: TEditedComment;
	textareaHeight?: number;
};

const acceptedFiles = ['image/png', 'image/jpeg', 'image/bmp', 'image/gif'];

const MentionsInputComponent: ComponentType<any> = MentionsInput;

export class CommentForm extends Component<TCommentFormProps, TCommentFormState> {
	lastFocused?: HTMLElement;
	formRef = createRef<HTMLDivElement>();
	inputRef: MutableRefObject<HTMLElement | null> = { current: null };
	ignoreEvent: number = 0;
	state: TCommentFormState = {
		text: '',
		isExpanded: false,
		attachments: [],
		mentions: [],
		showError: false,
	};

	static getDerivedStateFromProps(
		nextProps: TCommentFormProps,
		prevState: TCommentFormState,
	): Partial<TCommentFormState> | null {
		if (prevState.editedComment !== nextProps.editedComment) {
			let newState: Partial<TCommentFormState> = {
				editedComment: nextProps.editedComment,
			};
			if (nextProps.editedComment) {
				newState = {
					...newState,
					text: nextProps.editedComment.text,
					attachments: nextProps.editedComment.attachment
						? [{ fileData: inferAttahcmentFromUrl(nextProps.editedComment.attachment) }]
						: [],
				};
			}
			return newState;
		} else {
			return null;
		}
	}

	componentDidMount() {
		document.addEventListener('click', this.handleOutsideClick);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick);
	}

	handleOutsideClick = (e: Event) => {
		if (Date.now() - this.ignoreEvent < 300) {
			// TODO: ugly
			return;
		}
		if (this.state.attachments.length === 0 && this.state.text.trim() === '') {
			this.setState({
				isExpanded: false,
				showError: false,
			});
		}
	};

	handleInsideClick = (e: Event) => {
		this.ignoreEvent = Date.now();
		this.setState({ isExpanded: true });
	};

	handleContainerRef = (div: HTMLDivElement | null) => {
		this.inputRef.current = div;
		if (div) {
			div.addEventListener('click', this.handleInsideClick);
		}
	};

	handlePaste = (e: ClipboardEvent) => {
		if (e.clipboardData.files.length) {
			this.handleAttach([e.clipboardData.files[0]]);
		}
	};

	/*static getDerivedStateFromProps(props: TCommentFormProps, state?: TCommentFormState) {
        if (props.saveStatus.isSuccess()) {
            return {
                text: ''
            };
        }
	}*/

	private isExpanded = () => this.props.isEditMode || this.state.isExpanded;

	componentDidUpdate(prevProps: TCommentFormProps, prevState: TCommentFormState) {
		if (this.props.saveStatus.isSuccess() && !prevProps.saveStatus.isSuccess()) {
			this.setState({
				text: '',
				attachments: [],
				mentions: [],
				showError: false,
			});
		}
		if (this.state.text !== prevState.text && this.inputRef.current) {
			const textarea = this.inputRef.current.querySelector(
				'[class*="CommentForm_commentForm__text__highlighter__"]',
			);
			if (textarea) {
				this.setState({
					textareaHeight: textarea.scrollHeight,
				});
			}
		}
	}

	render() {
		const { canUseMentions = true, isNoteComment = false } = this.props;
		const { text, attachments } = this.state;
		const isExpanded = this.isExpanded();
		return (
			<div
				ref={this.handleContainerRef}
				className={cn(
					style.newComment,
					style.newComment__button,
					isExpanded && style.newComment_expanded,
					isNoteComment && style.newComment_note,
					this.props.isEditMode && style.newComment_edit,
					this.props.className,
				)}
				// onClick={this.handleInsideClick}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}>
				<div onPaste={this.handlePaste}>
					<MentionsInputComponent
						placeholder="Write a reply"
						value={text}
						data-gramm="false"
						style={{
							input: {
								overflow: 'auto',
							},
							height:
								isExpanded && this.state.textareaHeight ? `${this.state.textareaHeight}px` : undefined,
						}}
						classNames={{
							mentions: cn(style.commentForm__text, 'quarter-margin-bottom'),
							mentions__control: style.commentForm__text__control,
							mentions__highlighter: style.commentForm__text__highlighter,
							mentions__input: cn('form-control full-width'),
							mentions__suggestions__list: 'dropdown-menu show',
							mentions__suggestions__item: 'dropdown-item',
							'mentions__suggestions__item--focused': 'dropdown-item-hover',
							// 'mentions__mention': style.commentForm__text__mention,
						}}
						disabled={this.props.saveStatus.isPending()}
						onChange={this.handleTextChange}>
						<Mention
							trigger="@"
							className={mentionStyle.mention}
							appendSpaceOnAdd={true}
							//  renderSuggestion={this.renderSuggestion}
							data={canUseMentions ? formatMemberMentions(this.props.teamMembers) : []}
						/>
					</MentionsInputComponent>
				</div>

				{this.state.showError && this.state.text.trim() === '' && (
					<div className={cn(style.error, 'invalid-feedback')}>Please add some text.</div>
				)}

				{isExpanded && (
					<Fragment>
						{attachments.length > 0 && this.renderAttachments(attachments)}
						<div className={style.newComment__buttons}>
							{!this.props.isEditMode && attachments.length === 0 ? (
								<Dropzone
									noClick={true}
									noKeyboard={true}
									disabled={!!attachments.length}
									onDropAccepted={this.handleAttach}
									multiple={false}
									accept={acceptedFiles}>
									{({ getRootProps, getInputProps, open }) => (
										<div {...getRootProps({ className: style.newComment__dropZone })}>
											<input {...getInputProps()} />
											<Button
												color="flat"
												className={cn('quarter-margin-right', style.newComment__attachButton)}
												onClick={open}
												disabled={!!attachments.length}>
												<i className="material-icons">attachment</i>
											</Button>
											{this.renderCancelButton()}
										</div>
									)}
								</Dropzone>
							) : (
								<div className={style.newComment__dropZone}>{this.renderCancelButton()}</div>
							)}
							<Button
								color="primary"
								size="sm"
								className={cn(style.newComment__button)}
								disabled={this.props.saveStatus.isPending()}
								onClick={this.handleSubmit}>
								{this.props.isEditMode ? 'Save' : 'Comment'}
							</Button>
						</div>
					</Fragment>
				)}
			</div>
		);
	}

	renderCancelButton = () => {
		if (this.props.isEditMode) {
			return (
				<Button
					color="link"
					size="sm"
					className={cn(style.newComment__cancelButton)}
					disabled={this.props.saveStatus.isPending()}
					onClick={this.props.onCancel}>
					Cancel
				</Button>
			);
		}
	};

	handleSubmit = () => {
		if (this.state.text.trim() === '') {
			this.setState({ showError: true });
		} else {
			this.props.onSave({
				text: this.state.text,
				attachments: this.state.attachments,
				mentions: this.state.mentions.map(m => Number(m.id)),
			});
		}
	};

	renderAttachments = (attachments: TFileOrFileData[]) => {
		return (
			<div className={style.newComment__attachments}>
				{attachments.map((file, index) => (
					<AttachmentPreview
						{...file}
						key={index}
						onRemove={this.props.isEditMode ? undefined : () => this.removeAttach(file)}
					/>
				))}
			</div>
		);
	};

	handleAttach = (files: File[]) => {
		if (this.state.attachments.length === 0) {
			this.setState({
				attachments: [...this.state.attachments, ...files.map(file => ({ file }))].slice(0, 1),
			});
		}
	};

	removeAttach = (file: TFileOrFileData) => {
		this.setState({
			attachments: this.state.attachments.filter(a => a !== file),
		});
	};

	handleFocus: FocusEventHandler<HTMLElement> = e => {
		// this.lastFocused = e.target;
		// this.setState({ isExpanded: true });
	};
	handleBlur: FocusEventHandler = e => {
		/*this.lastFocused = undefined;
		setTimeout(() => {
			if (!this.lastFocused && !this.state.text.trim().length) {
				this.setState({ isExpanded: false });
			}
		}, 300);*/
	};

	handleTextChange = (event: any, newValue: string, newPlainTextValue: any, mentions: any) => {
		this.setState({
			text: newValue,
			mentions,
		});
	};
}

function formatMemberMentions(members: TMember[]): Array<{ id: number; display: string }> {
	return members.map(m => ({
		id: m.id,
		display: m.name,
	}));
}

function inferAttahcmentFromUrl(url: string): TFileData {
	const file = {
		data: url,
		name: 'Attachment',
	};
	try {
		const parts = new URL(url);
		const path = parts.pathname.split('/');
		file.name = path[path.length - 1];
	} catch (e) {}
	return file;
}
