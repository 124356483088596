import { Subject, BehaviorSubject, of } from 'rxjs';
import { Option, none, fromNullable, some } from 'fp-ts/lib/Option';
import { map, shareReplay } from 'rxjs/operators';
import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { combineReader, deferReader } from '@devexperts/utils/dist/adt/reader.utils';
import { AxiosReader } from 'volley-common/dist/utils/axios';
import { JWT_COOKIE_KEY, REFRESH_COOKIE_KEY, isExpired } from 'volley-common/dist/services/token.service';
import { AxiosInstance } from 'axios';
import { ask } from 'fp-ts/lib/Reader';
import { getJwtExpiration } from 'volley-common/dist/services/auth.service';

export type TToken = {
	user_id: number;
	email: number;
	exp?: number;
};

type TRawToken = {
	access: Option<string>;
	refresh: Option<string>;
};

interface ITokenStore {
	get(): Promise<TRawToken>;
	set(token: TRawToken): Promise<void>;
	setAccessToken(token: Option<string>): Promise<void>;
}

type TSessionServiceContext = {
	tokenStore: ITokenStore;
};
const REFRESH_WINDOW = 3 * 60 * 1000;
const REFRESH_WINDOW_SKEW = 2 * 60 * 1000;

export class SessionServiceClass {
	private refreshTimer: number | undefined;
	private readonly _token$ = new Subject<Option<string>>();
	readonly token$ = this._token$.pipe(
		// tap(t => console.log('Token: ', t)),
		shareReplay(1),
	);
	readonly decoded$ = this.token$.pipe(
		map(token => token.map(token => decode<TToken>(token))),
		// tap(t => console.log('Decoded Token: ', t)),
	);

	private readonly refresh$ = new BehaviorSubject<Option<string>>(none);

	constructor(private readonly axios: AxiosInstance, private readonly tokenStore: ITokenStore) {
		this.tokenStore.get().then(tokens => this.acceptTokens(tokens.access, tokens.refresh));
	}

	acceptTokens = (maybeAccess: Option<string>, maybeRefresh?: Option<string>) => {
		clearTimeout(this.refreshTimer);
		const access = maybeAccess.filter(token => !isExpired(decode<TToken>(token)));
		const refresh = maybeRefresh ? maybeRefresh.filter(token => !isExpired(decode<TToken>(token))) : undefined;

		this._token$.next(access);

		if (refresh) {
			this.refresh$.next(refresh);
			this.tokenStore.set({
				access,
				refresh,
			});
		} else {
			this.tokenStore.setAccessToken(access);
		}

		if (access.isSome()) {
			const refreshTimeout = REFRESH_WINDOW + REFRESH_WINDOW_SKEW * (Math.random() - 0.5);
			this.refreshTimer = setTimeout(() => {
				this.doRefresh();
			}, refreshTimeout) as any;
		} else {
			refresh && this.doRefresh();
		}
	};

	private doRefresh() {
		return this.refresh$.value
			.fold<Promise<Option<{ access: string; refresh?: string }>>>(Promise.resolve(none), refresh =>
				this.axios
					.post('/api/account/refresh', 'refresh=' + refresh, {
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
						},
					})
					.then(
						response => some(response.data),
						error => none,
					),
			)
			.then(result => {
				result.fold(undefined, token =>
					this.acceptTokens(some(token.access), token.refresh ? some(token.refresh) : undefined),
				);
			});
	}
}

export const SessionService = combineReader(
	deferReader(AxiosReader, 'token$'),
	ask<TSessionServiceContext>(),
	(axios, ctx) => new SessionServiceClass(axios.run({ token$: of() }), ctx.tokenStore),
);

export class TokenStore implements ITokenStore {
	get(): Promise<TRawToken> {
		return Promise.resolve({
			access: fromNullable(Cookies.get(JWT_COOKIE_KEY)),
			refresh: fromNullable(Cookies.get(REFRESH_COOKIE_KEY)),
		});
	}

	set(token: TRawToken): Promise<void> {
		token.refresh.foldL<unknown>(
			() => Cookies.remove(REFRESH_COOKIE_KEY),
			token => {
				const secure = window.location.protocol.includes('https');
				return Cookies.set(REFRESH_COOKIE_KEY, token, {
					path: '/',
					expires: getJwtExpiration(token),
					sameSite: secure ? 'None' : undefined,
					secure,
				});
			},
		);
		return this.setAccessToken(token.access);
	}

	setAccessToken(token: Option<string>): Promise<void> {
		token.foldL<unknown>(
			() => Cookies.remove(JWT_COOKIE_KEY),
			token => {
				const secure = window.location.protocol.includes('https');
				return Cookies.set(JWT_COOKIE_KEY, token, {
					path: '/',
					expires: getJwtExpiration(token),
					sameSite: secure ? 'None' : undefined,
					secure,
				});
			},
		);
		return Promise.resolve();
	}
}
