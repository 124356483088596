import { initial } from '@devexperts/remote-data-ts/dist/remote-data';
import { of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { history } from '../../utils/history';
import { identity } from 'fp-ts/lib/function';
import Header from './Header';
import { THeaderProps } from './Header';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { ask } from 'fp-ts/lib/Reader';
import { none } from 'fp-ts/lib/Option';
import { isDefined } from 'volley-common/dist/utils/object.utils';
import { ProfileModelType } from '../../models/profile.model';
import { routes } from 'volley-common/dist/utils/routes';
import { TeamsService } from 'volley-common/dist/services/teams.service';
import { useObservable } from 'volley-common/dist/utils/react.utils';
import { useMemo } from 'react';

export type THeaderContainerContext = {
	authService: AuthService;
	profileModel: ProfileModelType;
	teamsService: TeamsService;
};

export const HeaderContainer = combineReader(Header, ask<THeaderContainerContext>(), (Header, ctx) => {
	function onSignOut() {
		ctx.authService.signOut();
		history.push(routes.login);
	}

	const userInfo$ = combineLatest([ctx.profileModel.profile$, ctx.profileModel.currentPlan$]).pipe(
		map(([userRD, plan]) =>
			userRD.map(user =>
				user.fold(
					{
						fullName: '',
						email: '',
						avatar: undefined,
						isSuperUser: false,
						plan,
					},
					user => ({
						fullName: user.name,
						email: user.email,
						avatar: user.profile.avatar,
						isSuperUser: false, // !!user.is_superuser,	// TODO: cleanup this eventually
						plan,
					}),
				),
			),
		),
	);

	const canCreateTeams$ = ctx.profileModel.currentPlan$.pipe(
		map(plan => !plan.features.maxTeamMembers.exists(limit => limit === 0)),
	);

	return (props: Partial<Pick<THeaderProps, 'teamId'>>) => {
		const { teamId: teamIdProp } = props;
		const teamId$ = useMemo(
			() =>
				isDefined(teamIdProp)
					? of(teamIdProp)
					: ctx.teamsService.selectedTeam$.pipe(
							map(team =>
								team
									.toOption()
									.chain(identity)
									.map(team => team.id),
							),
					  ),
			[teamIdProp],
		);

		const userInfo = useObservable(userInfo$, initial);
		const teamId = useObservable(teamId$, none);
		const canCreateTeams = useObservable(canCreateTeams$, false);
		const teams = useObservable(ctx.teamsService.teams$, initial);
		return (
			<Header
				userInfo={userInfo}
				onSignOut={onSignOut}
				canCreateTeams={canCreateTeams}
				teams={teams}
				teamId={teamId}
			/>
		);
	};
});
