import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { ExportPage, State, ExportData } from './ExportPage';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { EMPTY, Subject } from 'rxjs';
import { TProductImage, ProductService } from 'volley-common/dist/services/products.service';
import { exporters, IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { switchMap, shareReplay, map, withLatestFrom, tap } from 'rxjs/operators';
import { initial } from '@devexperts/remote-data-ts';
import { ask } from 'fp-ts/lib/Reader';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';
import { history } from '../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import { isEnum } from 'volley-common/dist/utils/object.utils';

type ExportPageContainerContext = {
	toastService: ToastService;
	productService: ProductService;
};

export const ExportPageContainer = combineReader(
	exporters,
	ExportPage,
	ask<ExportPageContainerContext>(),
	(exporters, ExportPage, ctx) =>
		withRX(ExportPage)(props$ => {
			const state$ = new Subject<State>();

			const onContinueToIntegration = (notes: TProductImage[]) => {
				state$.next({
					step: 'integration',
					notes,
				});
			};

			const onBackToNotes = () => {
				state$.next({
					step: 'notes',
				});
			};

			const productId$ = props$.pipe(map(props => props.productId));
			const integrationId$ = props$.pipe(map(props => props.integrationId));
			const integrationType$ = props$.pipe(map(props => props.integrationType));

			const exportRequest$ = new Subject<ExportData>();
			const exportResult$ = exportRequest$.pipe(
				withLatestFrom(productId$, integrationId$, integrationType$),
				switchMap(([request, productId, integrationId, type]) =>
					isEnum(IntegrationType)(type)
						? exporters.startExport(productId, integrationId, type, request)
						: EMPTY,
				),
				shareReplay(1),
			);

			const resultNotificationEffect$ = exportResult$.pipe(
				withLatestFrom(props$.pipe(map(props => props.productId))),
				tap(([result, productId]) =>
					result.fold(
						null,
						null,
						() => ctx.toastService.push({ text: genericErrorMessage }),
						() => {
							ctx.toastService.push({ text: 'Notes have been successfully exported' });
							history.push(routes.product(productId));
						},
					),
				),
			);

			return {
				defaultProps: {
					state: { step: 'notes' },
					onContinueToIntegration,
					onBackToNotes,
					exportResult: initial,
					integrationId: 0,
					onStartExport: (req: ExportData) => exportRequest$.next(req),
				},
				props: {
					state: state$,
					exportResult: exportResult$,
					integrationId: integrationId$,
				},
				effects$: resultNotificationEffect$,
			};
		}),
);
