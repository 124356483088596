import { combineReader, deferReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ProductView } from './Review';
import { createReviewViewModel, ReviewViewModel, ReviewViewModelProps } from './review.view-model';
import { ReviewContainer } from './ReviewContainer';
import { BoardViewContainer } from './BoardViewContainer';
import React, { ReactElement, useMemo, useState } from 'react';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { NoteStatus } from 'volley-common/dist/services/products.service';
import { RemoteData } from '@devexperts/remote-data-ts';
import { TProjectType } from './NoteView';

interface ReviewOuterProps extends ReviewViewModelProps {
	viewModel: ReviewViewModel;
	view: ProductView;
	onShare: () => unknown;
	onOpenSettings: () => unknown;
	focusedCommentId?: number;
	noteId?: number;
	imageId?: number;
	onSaveStatuses: (columns: NoteStatus[]) => void;
	saveStatusesResult: RemoteData<Error, unknown>;
	canDeleteStatus?: (id: number) => Promise<boolean | string>;
	type: RemoteData<Error, TProjectType>;
}

export const ReviewOuter = combineReader(
	deferReader(ReviewContainer, 'viewModel'),
	deferReader(BoardViewContainer, 'viewModel'),
	(ReviewContainerFactory, BoardViewContainerFactory) =>
		({ viewModel, ...props }: ReviewOuterProps) => {
			const ReviewContainer = useMemo(() => ReviewContainerFactory.run({ viewModel }), [viewModel]);
			const BoardViewContainer = useMemo(() => BoardViewContainerFactory.run({ viewModel }), [viewModel]);
			const [subscriptionOverlay, setSubscriptionOverlay] = useState<ReactElement>();

			const isUploaded = props.type.exists(t => t === 'uploaded');
			if (!isUploaded && props.view === 'board') {
				return (
					<BoardViewContainer
						productId={props.productId}
						subscriptionOverlay={subscriptionOverlay}
						setSubscriptionOverlay={setSubscriptionOverlay}
						onSaveStatuses={props.onSaveStatuses}
						saveStatusesResult={props.saveStatusesResult}
						canDeleteStatus={props.canDeleteStatus}
					/>
				);
			} else {
				return (
					<ReviewContainer
						type={props.type}
						product={props.product}
						onShare={props.onShare}
						onOpenSettings={props.onOpenSettings}
						focusedCommentId={props.focusedCommentId}
						noteId={props.noteId}
						imageId={props.imageId}
						subscriptionOverlay={subscriptionOverlay}
						setSubscriptionOverlay={setSubscriptionOverlay}
					/>
				);
			}
		},
);

export const ReviewOuterContainer = combineReader(
	ReviewOuter,
	createReviewViewModel,
	(ReviewOuter, createReviewViewModel) =>
		withRX(ReviewOuter)(props$ => {
			const viewModel = createReviewViewModel(props$);
			return { defaultProps: { viewModel } };
		}),
);
