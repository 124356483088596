import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { ProfileContainer } from './ProfileContainer';
import { HeaderContainer } from '../header/HeaderContainer';
import { Container, Row, Col, Nav } from 'reactstrap';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { Link } from 'react-router-dom';
import { PasswordContainer } from './PasswordContainer';
import cn from 'classnames';
import { BillingContainer } from './billing/BillingContainer';
import { Alerts } from '../alerts/Alerts';
import { routes } from 'volley-common/dist/utils/routes';
import { AppSumoContainer } from '../appsumo/AppSumoContainer';
import { NotificationsContainer } from './NotificationsContainer';

type TSettingsPageProps = {
	userName?: string;
	location: {
		pathname: string;
	};
};

export const Settings = combineReader(
	ProfileContainer,
	PasswordContainer,
	HeaderContainer,
	BillingContainer,
	NotificationsContainer,
	Alerts,
	AppSumoContainer,
	(
		ProfileContainer,
		PasswordContainer,
		HeaderContainer,
		BillingContainer,
		NotificationsContainer,
		Alerts,
		AppSumoContainer,
	) =>
		class Settings extends Component<TSettingsPageProps> {
			render() {
				return (
					<div id="dashboard-container">
						<HeaderContainer />
						<div className="main">
							<section>
								<Container>
									<Row className="justify-content-center">
										<Col xl={10}>
											<Alerts />
										</Col>
									</Row>
								</Container>
								<Container>
									<Row className="justify-content-center">
										<Col xl={10}>
											<div className="page-header-for-title">
												<h2 className="medium-font title">
													{renderGreeting(this.props.userName)}
												</h2>
											</div>
											<Nav tag="nav" className="double-margin-bottom primary-tabs">
												<Link
													to={routes.profile}
													className={this.formatLinkClassName(routes.profile)}>
													Profile
												</Link>
												<Link
													to={routes.changePassword}
													className={this.formatLinkClassName(routes.changePassword)}>
													Password
												</Link>
												<Link
													to={routes.notifications}
													className={this.formatLinkClassName(routes.notifications)}>
													Notifications
												</Link>
												<Link
													to={routes.billing}
													className={this.formatLinkClassName(routes.billing)}>
													Subscription
												</Link>
											</Nav>
											<Switch>
												<Route path={routes.profile} component={ProfileContainer} />
												<Route path={routes.changePassword} component={PasswordContainer} />
												<Route exact path={routes.billing} component={BillingContainer} />
												<Route
													exact
													path={routes.notifications}
													component={NotificationsContainer}
												/>
												<Route path={routes.appSumo} component={AppSumoContainer} />
												<Redirect to={routes.profile} />
											</Switch>
										</Col>
									</Row>
								</Container>
							</section>
						</div>
					</div>
				);
			}

			private formatLinkClassName = (route: string) =>
				cn(this.props.location.pathname.startsWith(route) && 'active', 'nav-link');
		},
);

function renderGreeting(userName?: string) {
	const time = new Date();
	const hrs = time.getHours();
	const nameSuffix = userName ? `, ${userName}` : '';
	if (hrs < 12) {
		return `Good morning${nameSuffix}!`;
	} else if (hrs < 18) {
		return `Good afternoon${nameSuffix}!`;
	} else {
		return `Good evening${nameSuffix}!`;
	}
}

export default Settings;
