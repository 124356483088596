import { Subject, Observable } from 'rxjs';
import { Function1 } from 'fp-ts/lib/function';
import { scan, shareReplay, startWith } from 'rxjs/operators';

type SetState<T> = Partial<T> | Function1<T, Partial<T>>;
export type AppState<T> = {
	state$: Observable<T>;
	setState: Function1<SetState<T>, void>;
};

export function appState<T extends object>(initialState: T): AppState<T> {
	type SetState = Partial<T> | Function1<T, Partial<T>>;
	const state$ = new Subject<SetState>();

	return {
		state$: state$.pipe(
			scan<SetState, T>((prevState, update) => {
				const patch = typeof update === 'function' ? update(prevState) : update;
				return { ...prevState, ...patch };
			}, initialState),
			startWith(initialState),
			shareReplay(1),
		),
		setState: (update: SetState) => state$.next(update),
	};
}
