import React, { FC } from 'react';
import { asks } from 'fp-ts/lib/Reader';

interface WelcomeLayoutContext {
	websiteUrl: string;
}

export const WelcomeLayout = asks(
	(ctx: WelcomeLayoutContext): FC => ({ children }) => (
		<div className="main">
			<div className="welcome-container">
				<div className="form-container">
					<div className="form-header">
						<a className="close" href={ctx.websiteUrl}>
							<i className="material-icons">close</i>
						</a>
					</div>
					<div className="form-content-wrapper">{children}</div>
				</div>
			</div>
		</div>
	),
);
