import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { TeamSettings, TUpdateTeamData } from './TeamSettings';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { initial } from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/lib/function';
import { TeamsService } from 'volley-common/dist/services/teams.service';
import { map, tap, withLatestFrom, switchMap, delay, startWith, shareReplay } from 'rxjs/operators';
import { ask } from 'fp-ts/lib/Reader';
import { history } from '../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import { Subject, of } from 'rxjs';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';
import { requireTeam } from './team.utils';
import { AuthService } from 'volley-common/dist/services/auth.service';

type TeamSettingsContainerContext = {
	teamsService: TeamsService;
	toastService: ToastService;
	authService: AuthService;
};

export const TeamSettingsContainer = combineReader(
	TeamSettings,
	ask<TeamSettingsContainerContext>(),
	(TeamSettings, ctx) =>
		withRX(TeamSettings)(props$ => {
			const teamId$ = props$.pipe(map(props => props.teamId));
			const { realTeamId$, effects$, team$, isOwner$ } = requireTeam(teamId$, ctx.teamsService, ctx.authService);

			const deleteRequest$ = new Subject<void>();
			const deleteResult$ = deleteRequest$.pipe(
				withLatestFrom(realTeamId$),
				switchMap(([, id]) => ctx.teamsService.deleteTeam(id)),
				tap(result =>
					result.foldL(
						constVoid,
						constVoid,
						() => ctx.toastService.push({ text: genericErrorMessage }),
						() => {
							history.push(routes.dashboard);
						},
					),
				),
			);

			const saveRequest$ = new Subject<TUpdateTeamData>();
			const saveResult$ = saveRequest$.pipe(
				withLatestFrom(realTeamId$),
				switchMap(([data, id]) => ctx.teamsService.updateTeam(id, data)),
				shareReplay(1),
			);

			const resultShown$ = saveResult$.pipe(
				switchMap(result => {
					if (result.isFailure() || result.isSuccess()) {
						return of(false).pipe(
							delay(3000),
							startWith(true),
						);
					} else {
						return of(false);
					}
				}),
			);

			return {
				defaultProps: {
					deleteResult: initial,
					saveResult: initial,
					onDelete: () => deleteRequest$.next(),
					onSave: (data: TUpdateTeamData) => saveRequest$.next(data),
					team: initial,
					canEdit: false,
					resultShown: false,
				},
				props: {
					deleteResult: deleteResult$,
					saveResult: saveResult$,
					team: team$,
					canEdit: isOwner$,
					resultShown: resultShown$,
				},
				effects$,
			};
		}),
);
