import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { map } from 'rxjs/operators';
import { ask } from 'fp-ts/lib/Reader';
import { ProfileModelType } from '../../models/profile.model';
import Settings from './Settings';
import { identity } from 'fp-ts/lib/function';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';

export type TSettingsContainerContext = {
	profileModel: ProfileModelType;
};

export const SettingsContainer = combineReader(Settings, ask<TSettingsContainerContext>(), (Settings, ctx) =>
	withRX(Settings)(() => {
		const userName$ = ctx.profileModel.profile$.pipe(
			map(user =>
				user
					.toOption()
					.chain(identity)
					.map(u => u.name)
					.toUndefined(),
			),
		);

		return {
			props: {
				userName: userName$,
			},
		};
	}),
);
