import React, { FC, Fragment, ReactNode, useCallback, useState } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import {
	TPlanType,
	TPlan,
	CardInfo,
	isAppSumoPlan,
	getEffectiveRegularPriceForUser,
	TBillingInfo,
} from 'volley-common/dist/services/auth.service';
import { DisplayPlanFeatures } from '../../subscribe/PlanInfoCard';
import { RemoteData, remoteData } from '@devexperts/remote-data-ts';
import { TextLoading, RemoteDataText } from 'volley-common/dist/components/TextLoading';
import { makeBy } from 'fp-ts/lib/Array';
import cn from 'classnames';
import css from './Billing.module.scss';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { useSubscriptionView } from '../../subscribe/SubscriptionViewContainer';
import { PaymentMethodPopupContainer } from './PaymentMethodPopupContainer';
import { Option, isSome } from 'fp-ts/lib/Option';
import { capitalWords, formatPrice } from 'volley-common/dist/utils/string.utils';
import { sequenceT } from 'fp-ts/lib/Apply';

type BillingProps = {
	billingInfo: RemoteData<unknown, TBillingInfo>;
	plan: RemoteData<unknown, TPlan>;
	paymentMethod: RemoteData<unknown, Option<CardInfo>>;
	isLoading: boolean;
};

export const Billing = combineReader(
	useSubscriptionView,
	PaymentMethodPopupContainer,
	(useSubscriptionView, PaymentMethodPopupContainer): FC<BillingProps> =>
		({ plan, billingInfo, paymentMethod }) => {
			const { showSubscriptionView, subscriptionView } = useSubscriptionView();
			const handleShowSubscriptionView = useCallback(() => showSubscriptionView(), [showSubscriptionView]);
			const [isEditingPaymentMethod, setEditPaymentMethod] = useState(false);
			const toggleEditPaymentMethod = useCallback(() => setEditPaymentMethod(value => !value), []);
			const isFreePlan = plan.exists(p => p.type === 'starter');
			const planPrice = sequenceT(remoteData)(billingInfo, plan).map(([billing, plan]) => {
				const price = getEffectiveRegularPriceForUser(plan.type, billing);
				return `${formatPrice(price)}/${billing.interval === 'year' ? 'year' : 'month'}`;
			});

			return (
				<Fragment>
					{subscriptionView}
					<PaymentMethodPopupContainer isOpen={isEditingPaymentMethod} onClose={toggleEditPaymentMethod} />
					<Card className="margin-bottom">
						<CardBody>
							<Row className="align-items-center">
								<Col>
									<p className="medium-font no-margin-bottom">
										{plan
											.map<ReactNode>(plan => formatPlanName(plan.type))
											.getOrElseL(() => (
												<Fragment>
													Current plan: <TextLoading inline>Volley plan</TextLoading>
												</Fragment>
											))}
									</p>
									<h4 className="light-text-color no-margin-bottom">
										{plan.exists(p => isAppSumoPlan(p.type)) ? (
											'Free for life!'
										) : (
											<Fragment>
												$
												<RemoteDataText inline text={planPrice} alt="10.00/month" />
											</Fragment>
										)}
									</h4>
									<ul
										className={cn(
											'list-unstyled margin-top icon-list no-margin-bottom',
											css.featuresList,
										)}>
										{plan
											.map(plan => <DisplayPlanFeatures plan={plan.type} withTeamMembersCount />)
											.getOrElseL(() => (
												<Fragment>
													{makeBy(3, i => (
														<li key={i}>
															{' '}
															<TextLoading inline>Unlimited cool features</TextLoading>
														</li>
													))}
												</Fragment>
											))}
									</ul>
								</Col>
								<Col className="col-4 text-center">
									<div className="float-right">
										<Button
											color="primary"
											className="margin-bottom"
											onClick={handleShowSubscriptionView}
											disabled={!plan.isSuccess()}>
											{isFreePlan ? 'Upgrade plan' : 'Change plan'}
										</Button>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Card className="margin-bottom">
						<CardBody>
							<Row className="align-items-center">
								<Col>
									<p className="medium-font no-margin-bottom">Payment method</p>
									<h4 className="light-text-color no-margin-bottom">
										{isFreePlan && !paymentMethod.exists(isSome)
											? 'You are currently on a Starter plan.'
											: 'Enter your payment details.'}
									</h4>
								</Col>
								<Col>
									<p className="no-margin-bottom text-right">
										{paymentMethod.exists(isSome) && (
											<Fragment>
												<RemoteDataText
													inline
													text={paymentMethod.map(card =>
														card.map(formatCardDetails).getOrElse(''),
													)}
													alt="Visa ending in 4444, exp: 09/2020"
												/>
												<Button
													color="link"
													onClick={toggleEditPaymentMethod}
													className="margin-left">
													Update
												</Button>
											</Fragment>
										)}
									</p>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Fragment>
			);
		},
);

function formatCardDetails(card: CardInfo): string {
	return `${capitalWords(card.brand)} ending in ${card.last4}, exp: ${card.expMonth}/${card.expYear}`;
}

function formatPlanName(type: TPlanType): string {
	switch (type) {
		case 'starter':
			return 'Starter plan';
		case 'freelance':
			return 'Freelance plan';
		case 'freelance_20211031':
			return 'Freelance plan (10-31-2021)';
		case 'pro':
			return 'Pro plan';
		case 'pro_20211031':
			return 'Professional plan (10-31-2021)';
		case 'unlimited':
		case 'unlimited_early':
			return 'Unlimited plan';
		case 'unlimited_20211031':
			return 'Unlimited plan (10-31-2021)';
		case 'team':
			return 'Team plan';
		case 'team_plus':
			return 'Team Plus plan';
		case 'enterprise':
			return 'Enterprise plan';
		case 'appsumo_1':
			return 'AppSumo 1 code plan';
		case 'appsumo_2':
			return 'AppSumo 2 code plan';
		case 'appsumo_3':
			return 'AppSumo 3 code plan';
		case 'appsumo_4':
			return 'AppSumo 4 code plan';
		case 'appsumo_5':
			return 'AppSumo 5 code plan';
	}
}
