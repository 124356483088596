import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoImg from 'volley-common/dist/assets/images/logo.svg';
import { routes } from 'volley-common/dist/utils/routes';

export type TProductUnavailableProps = {};

export class ProductUnavailable extends Component<TProductUnavailableProps> {
	render() {
		return (
			<div className="review-container unavailable">
				<div className="note-container">
					<div className="note-body">
						<div className="note-info text-center">
							<div className="note-zero">
								<Link className="margin-auto double-margin-bottom" to={routes.dashboard}>
									<img src={logoImg} width={150} />
								</Link>
								<div className="note-zero-text">
									<h2 className="text-center">This project is unavailable</h2>
									<p>
										Looks like this project is no longer available, or you do not have permission to
										access it
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
