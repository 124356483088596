import * as React from 'react';
import { Redirect } from 'react-router';
import qs from 'qs';
import { getOrigin } from '../utils/history';
import { routes } from 'volley-common/dist/utils/routes';

export const RedirectToLogin = () => <Redirect to={getLoginUrl()} />;

export function getLoginUrl() {
	const returnUrl = window.location.href.substring(getOrigin().length);
	return `${routes.login}?` + qs.stringify({ returnUrl });
}
