import { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';

function getLocationInfo(location: Location) {
	return {
		pathname: location.pathname,
		hash: location.hash,
	};
}

function ScrollToTopRaw(props: RouteComponentProps<{}>) {
	const info = getLocationInfo(props.location);
	const [, setPrevInfo] = useState(info);

	useEffect(() => {
		if (!info.hash) {
			window.scroll({
				behavior: 'smooth',
				top: 0,
			});
		}
		setPrevInfo(info);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [info.pathname, info.hash]);

	return null;
}

export const ScrollToTop = withRouter(ScrollToTopRaw);
