import * as React from 'react';
import { FC, Fragment, useCallback } from 'react';
import { Container, Row, Col, Media, Button } from 'reactstrap';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { renderIntegrationIcon } from '../export.model';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { success } from '@devexperts/remote-data-ts';
import { IndefiniteProgress } from 'volley-common/dist/components/IndefiniteProgress';
import { TrelloBoard } from './TrelloBoardSelector';
import css from './TrelloListSelector.module.scss';
import { useSingleSelect, ListSelect } from '../ListSelect';

export type TrelloList = {
	id: string;
	name: string;
};

type TrelloListSelectorProps = {
	onContinue: (listId: string) => void;
	onBack: () => void;
	board: TrelloBoard;
	notes: TProductImage[];
	isPending: boolean;
};

const renderList = (list: TrelloList) => (
	<Row className="align-items-center double-margin-left">
		<Col>
			<h4 className="quarter-margin-bottom medium-font">{list.name}</h4>
		</Col>
	</Row>
);

const pk = (list: TrelloList) => list.id;

export const TrelloListSelector: FC<TrelloListSelectorProps> = ({ board, onContinue, notes, onBack, isPending }) => {
	const { selected: selectedIds, handleToggleItem, getSelectedItem } = useSingleSelect();
	const selected = getSelectedItem().toUndefined();
	const handleContinue = useCallback(() => selected && onContinue(selected), [selected, onContinue]);

	return (
		<Fragment>
			<section className="export-header">
				<Container>
					<Row className="align-items-center">
						<Col md={6}>
							<Media>
								{renderIntegrationIcon(IntegrationType.Trello)}
								<Media body>
									<p className="no-margin-bottom">Export notes to Trello</p>
									<h2 className="medium-font margin-bottom">Choose a list from {board.name}</h2>
								</Media>
							</Media>
						</Col>
						<Col md={6}>
							<div className="text-right actions margin-top">
								<Button
									color="secondary"
									size="lg"
									className="half-margin-right"
									onClick={onBack}
									disabled={isPending}>
									Back
								</Button>{' '}
								<div className={css.submitBtn}>
									<Button
										color="primary"
										size="lg"
										disabled={selected === undefined || isPending}
										onClick={handleContinue}>
										{isPending
											? 'Exporting...'
											: `Export ${notes.length} ${notes.length > 1 ? 'notes' : 'note'}`}
									</Button>
									{isPending && (
										<IndefiniteProgress className={css.progress} barClassName="bg-primary" />
									)}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<ListSelect
				items={success(board.lists)}
				renderItem={renderList}
				onToggleSelected={handleToggleItem}
				identifier={pk}
				selected={selectedIds}
			/>
		</Fragment>
	);
};
