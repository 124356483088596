import * as React from 'react';
import { FC, useState, useCallback } from 'react';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { TrelloBoardSelectorContainer } from './TrelloBoardSelectorContainer';
import { TrelloBoard } from './TrelloBoardSelector';
import { TrelloListSelectorContainer } from './TrelloListSelectorContainer';
import { ExportData } from '../ExportPage';
import { RemoteData } from '@devexperts/remote-data-ts';

type TrelloExportProps = {
	onContinue: (data: ExportData) => void;
	exportResult: RemoteData<Error, unknown>;
	onBack: () => void;
	notes: TProductImage[];
	integrationId: number;
	productId: number;
};

type State = { step: 'board' } | { step: 'list'; board: TrelloBoard };

export const TrelloExport = combineReader(
	TrelloBoardSelectorContainer,
	TrelloListSelectorContainer,
	(TrelloBoardSelectorContainer, TrelloListSelectorContainer): FC<TrelloExportProps> =>
		({ notes, onContinue, onBack, exportResult, integrationId, productId }) => {
			const [state, setState] = useState<State>({ step: 'board' });
			const handleContinue = useCallback(
				(listId: string) => {
					if (state.step === 'list') {
						onContinue({
							note_ids: notes.map(note => note.id),
							trello_list_id: listId,
						} as any);
					}
				},
				[state, onContinue, notes],
			);
			const handleSelectBoard = useCallback(
				(board: TrelloBoard) => {
					setState({ step: 'list', board });
				},
				[setState],
			);
			const handleBackToBoardSelector = useCallback(() => {
				setState({ step: 'board' });
			}, [setState]);

			switch (state.step) {
				case 'board':
					return (
						<TrelloBoardSelectorContainer
							integrationId={integrationId}
							productId={productId}
							onContinue={handleSelectBoard}
							onBack={onBack}
						/>
					);
				case 'list':
					return (
						<TrelloListSelectorContainer
							notes={notes}
							board={state.board}
							onContinue={handleContinue}
							onBack={handleBackToBoardSelector}
							isPending={exportResult.isPending()}
						/>
					);
			}
		},
);
