import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial } from '@devexperts/remote-data-ts/dist/remote-data';
import { of, Subject } from 'rxjs';
import { switchMap, filter, delay, startWith, share, map } from 'rxjs/operators';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { ask } from 'fp-ts/lib/Reader';
import { ForgotPassword } from './ForgotPassword';
import { fixNonFieldErrors } from 'volley-common/dist/models/api.model';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';

export type TForgotPasswordContainerContext = {
	authService: AuthService;
};

export const ForgotPasswordContainer = combineReader(
	ForgotPassword,
	ask<TForgotPasswordContainerContext>(),
	(ForgotPassword, ctx) => {
		return withRX(ForgotPassword)(() => {
			const resetRequest$ = new Subject<string>();
			const resetResult$ = resetRequest$.pipe(
				switchMap(email => ctx.authService.sendResetToken(email)),
				map(result => result.mapLeft(fixNonFieldErrors)),
				share(),
			);

			const showResult$ = resetResult$.pipe(
				filter(rd => rd.isFailure() || rd.isSuccess()),
				switchMap(() =>
					of(false).pipe(
						delay(5000),
						startWith(true),
					),
				),
			);

			return {
				defaultProps: {
					result: initial,
					showResult: false,
				},
				props: {
					onSendRequest: of((email: string) => resetRequest$.next(email)),
					result: resetResult$,
					showResult: showResult$,
				},
			};
		});
	},
);
