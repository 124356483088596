import React, { useState, memo } from 'react';
import { Overlay } from 'volley-common/dist/components/Overlay';
import { Container, Col, Row, Nav, NavLink, TabContent, TabPane, Spinner } from 'reactstrap';
import cn from 'classnames';
import { NoteStatus, TProduct, TProductWidgetUpdate } from 'volley-common/dist/services/products.service';
import { RemoteData } from '@devexperts/remote-data-ts';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import css from './ProductSettings.module.scss';
import { WidgetSettingsTab } from './WidgetSettingsTab';
import { RenderRemoteData } from '../../../components/RenderRemoteData';
import { IntegrationsTabContainer } from './Integrations';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { Option } from 'fp-ts/lib/Option';
import { Workflow } from './Workflow';

type SettingsTab = 'product' | 'widget' | 'integrations' | 'workflow';
const allTabs = ['product', 'widget', 'integrations', 'workflow'];

export type ProductSettingsState = { tab: 'widget' } | { tab: 'integrations'; integration: Option<IntegrationType> };

interface ProductSettingsProps {
	product: TProduct;
	loadingStatus: RemoteData<unknown, unknown>;
	saveResult: RemoteData<Error, unknown>;
	onSaveWidget: (val: TProductWidgetUpdate) => void;
	onClose: () => void;
	onReloadProduct?: () => void;
	userId?: number;
	isProjectOwner: boolean;
	canUseExport: boolean;
	initialState?: ProductSettingsState;
	workflowSaveResult: RemoteData<Error, unknown>;
	onSaveWorkflow: (val: NoteStatus[]) => void;
	canDelete?: (id: number) => Promise<boolean | string>;
	/** show all tabs if not specified */
	tabs?: SettingsTab[];
}

const overlayTheme = {
	container: css.container,
	close: cn(css.closePanel, 'border-bottom'),
	close__link: css.closeIcon,
};

export const ProductSettings = combineReader(
	WidgetSettingsTab,
	IntegrationsTabContainer,
	(WidgetSettingsTab, IntegrationsTabContainer) => {
		return memo<ProductSettingsProps>(
			({
				product,
				onSaveWidget,
				onClose,
				saveResult,
				loadingStatus,
				userId,
				isProjectOwner,
				canUseExport,
				initialState,
				workflowSaveResult,
				onSaveWorkflow,
				canDelete,
				tabs = allTabs,
			}) => {
				const [_activeTab, setActiveTab] = useState<SettingsTab>(initialState?.tab ?? 'widget');
				const activeTab = tabs.includes(_activeTab) ? _activeTab : tabs[0];

				return (
					<Overlay onClose={onClose} theme={overlayTheme}>
						<section>
							<Container>
								<Row className="align-items-center justify-content-center">
									<Col md={9}>
										<h2 className="double-margin-bottom">Project settings</h2>

										<Nav tabs className="primary-tabs">
											{/*<NavLink
										className={cn('cursor-pointer', activeTab === 'product' && 'active')}
										onClick={() => setActiveTab('product')}>
										General
									</NavLink>*/}
											{tabs.includes('widget') && (
												<NavLink
													className={cn('cursor-pointer', activeTab === 'widget' && 'active')}
													onClick={() => setActiveTab('widget')}>
													Widget
												</NavLink>
											)}
											{tabs.includes('integrations') && isProjectOwner && (
												<NavLink
													className={cn(
														'cursor-pointer',
														activeTab === 'integrations' && 'active',
													)}
													onClick={() => setActiveTab('integrations')}>
													Integrations
												</NavLink>
											)}
											{tabs.includes('workflow') && (
												<NavLink
													className={cn(
														'cursor-pointer',
														activeTab === 'workflow' && 'active',
													)}
													onClick={() => setActiveTab('workflow')}>
													Workflow
												</NavLink>
											)}
										</Nav>
										<div
											className={cn(
												!loadingStatus.isSuccess() && 'double-margin-top',
												loadingStatus.isPending() && 'text-center',
											)}>
											<RenderRemoteData
												data={loadingStatus}
												DataStatePending={() => (
													<Spinner color="primary" style={{ width: 48, height: 48 }} />
												)}
												success={() => (
													<TabContent activeTab={activeTab}>
														<TabPane tabId="general" />
														{tabs.includes('widget') && (
															<TabPane tabId="widget">
																<WidgetSettingsTab
																	onSaveWidget={onSaveWidget}
																	product={product}
																	saveResult={saveResult}
																	userId={userId}
																/>
															</TabPane>
														)}
														{tabs.includes('integrations') && isProjectOwner && (
															<TabPane tabId="integrations">
																<IntegrationsTabContainer
																	productId={product.id}
																	canUseExport={canUseExport}
																	initialEditing={
																		initialState?.tab === 'integrations'
																			? initialState.integration.toUndefined()
																			: undefined
																	}
																/>
															</TabPane>
														)}
														{tabs.includes('workflow') && (
															<TabPane tabId="workflow">
																<Workflow
																	statuses={product.statuses ?? []}
																	workflowSaveResult={workflowSaveResult}
																	onSaveWorkflow={onSaveWorkflow}
																	onClose={onClose}
																	canDelete={canDelete}
																/>
															</TabPane>
														)}
													</TabContent>
												)}
											/>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					</Overlay>
				);
			},
		);
	},
);
