import { ask } from 'fp-ts/lib/Reader';
import { AppSumoSignUp } from './AppSumoSignUp';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { Subject, of } from 'rxjs';
import { AuthService, TRegistrationData, APPSUMO_TIERS } from 'volley-common/dist/services/auth.service';
import { switchMap, map, shareReplay, scan, withLatestFrom, startWith } from 'rxjs/operators';
import { tapRD } from 'volley-common/dist/utils/object.utils';
import { snoc, uniq } from 'fp-ts/lib/Array';
import { initial, failure } from '@devexperts/remote-data-ts';
import { setoidString } from 'fp-ts/lib/Setoid';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { loadReferralDetails } from '../../referral/utils';

type AppSumoSignUpContainerContext = {
	authService: AuthService;
};

export const AppSumoSignUpContainer = combineReader(
	AppSumoSignUp,
	ask<AppSumoSignUpContainerContext>(),
	(AppSumoSignUp, ctx) =>
		withRX(AppSumoSignUp)(() => {
			const appliedCode$ = new Subject<string>();
			const appliedCodes$ = appliedCode$.pipe(
				scan(snoc, [] as string[]),
				startWith([]),
				map(uniq(setoidString)),
				shareReplay(1),
			);

			const applyCode$ = new Subject<string>();
			const applyCodeResult$ = applyCode$.pipe(
				withLatestFrom(appliedCodes$),
				switchMap(([code, codes]) => {
					if (codes.length < APPSUMO_TIERS) {
						return ctx.authService.verifyAppSumoCode(code).pipe(
							map(result =>
								result.bimap(
									err => {
										if (err.data && Array.isArray(err.data)) {
											return new Error(err.data.join(' '));
										} else {
											return new Error('Failed to verify the code.');
										}
									},
									() => code,
								),
							),
						);
					} else {
						return of(
							failure<Error, string>(
								new Error(`You have entered the maximum (${APPSUMO_TIERS}) number of codes`),
							),
						);
					}
				}),
				tapRD(code => appliedCode$.next(code)),
				shareReplay(1),
			);

			const registrationRequest$ = new Subject<TRegistrationData>();
			const registrationResult$ = registrationRequest$.pipe(
				withLatestFrom(appliedCodes$),
				switchMap(([data, codes]) =>
					ctx.authService.register({
						...data,
						appSumoCodes: codes,
						referral_details: loadReferralDetails(),
					}),
				),
				map(result => result.map(_ => true)),
			);

			return {
				defaultProps: {
					appliedCodes: [],
					onApplyCode: (code: string) => applyCode$.next(code),
					onRegister: (data: TRegistrationData) => registrationRequest$.next(data),
					registrationResult: initial,
					applyCodeResult: initial,
				},
				props: {
					appliedCodes: appliedCodes$,
					registrationResult: registrationResult$,
					applyCodeResult: applyCodeResult$,
				},
			};
		}),
);
