import React, { useState, useCallback, ReactNode } from 'react';
import { asks } from 'fp-ts/lib/Reader';
import { ProfileModelType } from '../../models/profile.model';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { SubscriptionView, TSubscriptionViewProps } from './SubscriptionView';
import { none, some, isSome } from 'fp-ts/lib/Option';
import { map, switchMap, withLatestFrom, filter, tap, shareReplay, share } from 'rxjs/operators';
import {
	TPlanType,
	AuthService,
	isPaymentAuthorizationRequired,
	isAppSumoPlan,
	BillingPeriod,
} from 'volley-common/dist/services/auth.service';
import { history } from '../../utils/history';
import { ProductService, filterLimitedProjects } from 'volley-common/dist/services/products.service';
import { filterSuccess, switchMapRD } from 'volley-common/dist/utils/object.utils';
import { Subject, combineLatest, of } from 'rxjs';
import { routes } from 'volley-common/dist/utils/routes';
import { TApiError } from 'volley-common/dist/models/api.model';
import { failure, RemoteFailure } from '@devexperts/remote-data-ts';
import { TeamsService } from 'volley-common/dist/services/teams.service';
import { head } from 'fp-ts/lib/Array';
import { identity } from 'fp-ts/lib/function';
import { AppState } from '../../models/app-state.model';
import { YouRockAppState } from './YouRockContainer';

type TSubscriptionViewContainerContext = {
	profileModel: ProfileModelType;
	productService: ProductService;
	authService: AuthService;
	teamsService: TeamsService;
	appState: AppState<YouRockAppState>;
};

class NeedPaymentError extends Error {
	constructor(readonly prospective: TPlanType, readonly billingPeriod: BillingPeriod) {
		super();
	}
}

export const SubscriptionViewContainer = asks((ctx: TSubscriptionViewContainerContext) => {
	return withRX(SubscriptionView)(props$ => {
		const currentPlan$ = ctx.profileModel.currentPlan$.pipe(map(plan => plan.type));
		const currentBillingInfo$ = ctx.profileModel.profile$.pipe(
			map(rd =>
				rd
					.toOption()
					.chain(identity)
					.mapNullable(profile => profile.billing),
			),
		);
		const choosePlan$ = new Subject<[TPlanType, BillingPeriod]>();
		const planChange$ = choosePlan$.pipe(
			withLatestFrom(
				currentPlan$,
				currentBillingInfo$.pipe(
					filter(isSome),
					map(opt => opt.value),
				),
			),
			map(([[prospective, billingPeriod], current, currentBillingInfo]) => ({
				prospective,
				billingPeriod,
				current,
				currentBillingInfo,
			})),
		);

		const planChangeResult$ = planChange$.pipe(
			switchMap(({ prospective, current, currentBillingInfo, billingPeriod }) => {
				if (prospective === 'starter') {
					return isAppSumoPlan(current)
						? ctx.authService.unstackAppSumoPlan()
						: ctx.authService.switchToFreePlan();
				} else {
					return isPaymentAuthorizationRequired(current, prospective)
						? of(failure<TApiError, unknown>(new NeedPaymentError(prospective, billingPeriod)))
						: ctx.authService.switchToAnotherPaidPlan({ planType: prospective }, billingPeriod);
				}
			}),
			switchMapRD(() => ctx.profileModel.refresh()),
			withLatestFrom(props$),
			tap(([result, props]) => {
				if (result.isSuccess()) {
					props.onClose();
					ctx.appState.setState({
						showYouRock: true,
					});
				}
			}),
			map(([result]) => result),
			shareReplay(1),
		);

		const redirectToPaymentEffect$ = planChangeResult$.pipe(
			filter((result): result is RemoteFailure<Error, any> => result.isFailure()),
			map(result => result.error),
			filter((err): err is NeedPaymentError => err instanceof NeedPaymentError),
			tap(err =>
				history.push(routes.billingPay, {
					prospectivePlan: err.prospective,
					billingPeriod: err.billingPeriod,
				}),
			),
		);

		const allProjects$ = ctx.productService.getAll(false).pipe(
			filterSuccess,
			share(),
		);

		const personalProjects$ = combineLatest(allProjects$, ctx.authService.userId$).pipe(
			map(([products, userId]) => filterLimitedProjects(products, userId.getOrElse(0))),
		);

		const numNotes$ = personalProjects$.pipe(
			switchMap(products =>
				// NOTE: this assumes that plans with the note count limit also are limited to 1 project
				// If the user currently has more than one project, the note count is NOT checked for performance reasons
				some(products.filter(p => !p.archived_at))
					.filter(arr => arr.length === 1)
					.chain(head)
					.fold(of(0), firstProduct =>
						ctx.productService.getNotes(firstProduct.id).pipe(
							filterSuccess,
							map(notes => notes.length),
						),
					),
			),
		);

		const numProjects$ = personalProjects$.pipe(map(projects => projects.length));

		const numTeamMembers$ = ctx.teamsService.ownTeamMembers$.pipe(map(num => num.getOrElse(0)));
		const numTeams$ = ctx.teamsService.ownTeams$.pipe(map(num => num.getOrElse([]).length));

		return {
			defaultProps: {
				numProjects: 0,
				numTeamMembers: 0,
				numTeams: 0,
				numNotes: 0,
				currentPlan: none,
				onChoosePlan: (plan: TPlanType, billingPeriod: BillingPeriod) =>
					choosePlan$.next([plan, billingPeriod]),
				isSuperUser: false,
				currentBillingInfo: none,
			},
			props: {
				currentBillingInfo: currentBillingInfo$,
				numProjects: numProjects$,
				numTeamMembers: numTeamMembers$,
				numTeams: numTeams$,
				numNotes: numNotes$,
				currentPlan: currentPlan$.pipe(map(some)),
				downgradeResult: planChangeResult$,
				isSuperUser: ctx.profileModel.isSuperUser$,
			},
			effects$: redirectToPaymentEffect$,
		};
	});
});

type SubscriptionViewContainerProps = Omit<
	TSubscriptionViewProps,
	| 'currentPlan'
	| 'onChoosePlan'
	| 'onClose'
	| 'numProjects'
	| 'numTeamMembers'
	| 'numTeams'
	| 'isSuperUser'
	| 'currentBillingInfo'
	| 'downgradeResult'
>;

export const useSubscriptionView = SubscriptionViewContainer.map(Container => () => {
	const [subscriptionView, setSubscriptionView] = useState<ReactNode>();
	const hideSubscriptionView = useCallback(() => setSubscriptionView(null), []);
	const showSubscriptionView = useCallback(
		(props?: SubscriptionViewContainerProps) => {
			setSubscriptionView(<Container onClose={hideSubscriptionView} {...props} />);
		},
		[hideSubscriptionView],
	);

	return {
		subscriptionView,
		hideSubscriptionView,
		showSubscriptionView,
	};
});
