import React, { Fragment, useState } from 'react';
import cn from 'classnames';
import { CommentForm } from './CommentForm';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ask } from 'fp-ts/lib/Reader';
import { ReviewViewModel } from './review.view-model';
import style from './NoteDetailsPanel.module.scss';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { Comment, EditedComment } from './Comment';
import { NoteAttributesContainer } from './NoteAttributes';
import { RenderRemoteData } from '../../../components/RenderRemoteData';
import { useObservable } from 'volley-common/dist/utils/react.utils';
import { CommentLoading } from './CommentLoading';
import { initial } from '@devexperts/remote-data-ts';
import { NoteDetails, TReviewState } from './Review';
import { TComment } from 'volley-common/dist/services/notes.service';
import { Button } from 'reactstrap';
import { none, some } from 'fp-ts/lib/Option';
import { ResolveBar } from './ResolveBar';

interface NoteDetailsPanelContext {
	viewModel: ReviewViewModel;
}

interface NoteDetailsPanelProps {
	commentListOpened: boolean;
	onToggleCommentListOpened: (val?: boolean) => void;
	note: TProductImage;
	naturalSize?: TReviewState['naturalSize'];
	onClickAttachment: (url: string) => void;
	commentEffect: TReviewState['commentEffect'];
	handleCommentRef?: (e: HTMLElement | null) => void;
	onToggleNotesBar: () => void;
	onDeleteNote: (note: TProductImage) => void;
	className?: string;
}

export const NoteDetailsPanel = combineReader(
	NoteAttributesContainer,
	ask<NoteDetailsPanelContext>(),
	(NoteAttributesContainer, { viewModel }) => {
		function LoadingComments() {
			const loadingCommentsCount = useObservable(viewModel.loadingCommentsCount$, 1);
			const actualCount = loadingCommentsCount;
			const children = [];
			for (let index = 0; index < actualCount; index++) {
				children.push(<CommentLoading key={`loading-${index}`} />);
			}
			return <Fragment>{children}</Fragment>;
		}

		return function NoteDetailsPanel({
			commentListOpened,
			onToggleCommentListOpened,
			note,
			naturalSize,
			onClickAttachment,
			commentEffect,
			handleCommentRef,
			onToggleNotesBar,
			onDeleteNote,
			className,
		}: NoteDetailsPanelProps) {
			const comments = useObservable(viewModel.comments$, initial);
			const teamMembers = useObservable(viewModel.teamMembers$, []);
			const editedCommentId = useObservable(viewModel.editedCommentId$);
			const updateCommentResult = useObservable(viewModel.updateCommentResult$, initial);
			const canUseMentions = useObservable(viewModel.canUseMentions$, false);
			const userId = useObservable(viewModel.userId$, none);
			const saveCommentResult = useObservable(viewModel.saveCommentResult$, initial);
			const setPriorityResult = useObservable(viewModel.setPriorityResult$, initial);
			const setAssigneeResult = useObservable(viewModel.setAssigneeResult$, initial);
			const statuses = useObservable(viewModel.statuses$, []);

			const [commentDeleteConfirmation, setCommentDeleteConfirmation] = useState<TComment>();

			const isOwnNote = userId.exists(userId => !!note.author && note.author.id === userId);
			const isUploaded = !!note.product_upload_image_id;

			return (
				<div className={cn(style.commentsContainer, commentListOpened && style.commentsBarOpen, className)}>
					<ResolveBar
						statuses={statuses}
						note={some(note)}
						onToggleNotesBar={onToggleNotesBar}
						onDeleteNote={onDeleteNote}
						onSetNoteStatus={viewModel.onSetNoteStatus}
					/>
					{commentDeleteConfirmation && (
						<div className="comment-list-confirm-overlay text-center delete-this-comment visible">
							<div className="confirm-items">
								<h5>
									Delete <span className="bold-font">this</span> comment?
								</h5>
								<div className="inline-block half-margin-top">
									<Button
										color="primary"
										onClick={() => {
											if (commentDeleteConfirmation) {
												viewModel.onDeleteComment(commentDeleteConfirmation);
												setCommentDeleteConfirmation(undefined);
											}
										}}>
										Yes, delete
									</Button>
									<Button color="link" onClick={() => setCommentDeleteConfirmation(undefined)}>
										Cancel
									</Button>
								</div>
							</div>
						</div>
					)}
					<div className={style.commentsWrapper}>
						<div className="padded text-right no-padding-bottom show-comment-toggle d-sm-block d-md-none">
							<a
								href="#"
								onClick={e => {
									e.preventDefault();
									onToggleCommentListOpened();
								}}>
								<i className="material-icons">close</i>
							</a>
						</div>
						<div className={style.commentsList}>
							<div className={'note-comment-container'} style={isUploaded ? {paddingBottom: 6} : undefined}>
								{editedCommentId === 'note' ? (
									<div key={note.id} className="note-comment">
										<EditedComment
											comment={note}
											key={note.id}
											isNoteComment={true}
											teamMembers={teamMembers}
											updateCommentResult={updateCommentResult}
											onCancelEdit={() => viewModel.onChangeEditedCommentId(undefined)}
											canUseMentions={canUseMentions}
											onUpdateComment={viewModel.onUpdateComment}
											onUpdateNote={viewModel.onUpdateNote}
											ref={commentEffect?.id === note.id ? handleCommentRef : undefined}
										/>
									</div>
								) : (
									<Comment
										comment={{ ...note, note_id: note.id }}
										key={'note'}
										isNoteComment
										onAttachmentClick={onClickAttachment}
										onStartEdit={
											isOwnNote ? () => viewModel.onChangeEditedCommentId('note') : undefined
										}
									/>
								)}
								{!isUploaded && <div className="note-details-container">
									<NoteDetails note={note} naturalSize={naturalSize} />
								</div>}
							</div>

							<NoteAttributesContainer
								members={teamMembers}
								note={note}
								onPreviewAttachment={onClickAttachment}
								onSetPriority={priority => viewModel.onSetNotePriority(note.id, priority)}
								setPriorityResult={setPriorityResult}
								onSetAssignee={assignee => viewModel.onSetNoteAssignee(note.id, assignee)}
								setAssigneeResult={setAssigneeResult}
								onAttachmentDeleted={viewModel.onAttachmentDeleted}
								onAttachmentsUpdated={atts => viewModel.onAttachmentsUpdated(note.id, atts)}
							/>

							<CommentForm
								canUseMentions={canUseMentions}
								className={style.newComment}
								teamMembers={teamMembers}
								onSave={viewModel.onSaveComment}
								saveStatus={saveCommentResult}
							/>
							<RenderRemoteData
								data={comments}
								DataStatePending={LoadingComments}
								success={comments => (
									<Fragment>
										{comments.map(comment => {
											const isOwnComment = userId.exists(
												userId => !!comment.author && comment.author.id === userId,
											);
											const hasCommentEffect = commentEffect && commentEffect.id === comment.id;
											return editedCommentId === comment.id ? (
												<EditedComment
													comment={comment}
													key={comment.id}
													isNoteComment={false}
													teamMembers={teamMembers}
													updateCommentResult={updateCommentResult}
													onCancelEdit={() => viewModel.onChangeEditedCommentId(undefined)}
													canUseMentions={canUseMentions}
													onUpdateComment={viewModel.onUpdateComment}
													onUpdateNote={viewModel.onUpdateNote}
													ref={
														commentEffect?.id === comment.id ? handleCommentRef : undefined
													}
												/>
											) : (
												<Comment
													comment={comment}
													key={comment.id}
													onAttachmentClick={onClickAttachment}
													highlighted={
														hasCommentEffect && commentEffect!.effect === 'highlight'
													}
													onDelete={
														isOwnComment
															? () => setCommentDeleteConfirmation(comment)
															: undefined
													}
													onStartEdit={
														isOwnComment
															? () => viewModel.onChangeEditedCommentId(comment.id)
															: undefined
													}
													containerRef={hasCommentEffect ? handleCommentRef : undefined}
												/>
											);
										})}
									</Fragment>
								)}
							/>
						</div>
					</div>
				</div>
			);
		};
	},
);
