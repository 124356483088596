import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { ProductSettings } from './ProductSettings';
import { ProductService, TProductWidgetUpdate } from 'volley-common/dist/services/products.service';
import { ask } from 'fp-ts/lib/Reader';
import { Subject, of, combineLatest } from 'rxjs';
import {
	tap,
	switchMap,
	map,
	distinctUntilKeyChanged,
	withLatestFrom,
	shareReplay,
	pluck,
	distinctUntilChanged,
} from 'rxjs/operators';
import { initial, pending, success } from '@devexperts/remote-data-ts';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';
import { constVoid } from 'fp-ts/lib/function';
import { initialWidgetData } from './WidgetSettingsTab';
import { AuthService, getPlan, planNameToType, PLAN_STARTER } from 'volley-common/dist/services/auth.service';
import { fromNullable } from 'fp-ts/lib/Option';

interface ProductSettingsContainerContext {
	productService: ProductService;
	toastService: ToastService;
	authService: AuthService;
}

export const ProductSettingsContainer = combineReader(
	ProductSettings,
	ask<ProductSettingsContainerContext>(),
	(ProductSettings, ctx) =>
		withRX(ProductSettings)(props$ => {
			const product$ = props$.pipe(
				distinctUntilKeyChanged('product'),
				switchMap(({ product, onReloadProduct = constVoid }) => {
					if (!product.widget) {
						return ctx.productService.createWidget(product.id, initialWidgetData).pipe(
							map(result => {
								if (result.isFailure()) {
									return success(undefined);
								} else if (result.isSuccess()) {
									onReloadProduct && onReloadProduct();
								}
								return result;
							}),
						);
					} else {
						return of(success(undefined));
					}
				}),
			);

			const saveWidget$ = new Subject<TProductWidgetUpdate>();

			const saveWidgetEffect$ = saveWidget$.pipe(
				withLatestFrom(props$),
				switchMap(([data, { product, onClose, onReloadProduct = constVoid }]) => {
					const result$ = product.widget
						? ctx.productService.updateWidget(product.widget.id, data)
						: ctx.productService.createWidget(product.id, data);
					return result$.pipe(
						tap(result =>
							result.foldL(
								constVoid,
								constVoid,
								() => ctx.toastService.push({ text: genericErrorMessage }),
								() => {
									ctx.toastService.push({ text: 'Widget settings saved' });
									onReloadProduct();
									onClose();
								},
							),
						),
					);
				}),
				shareReplay(1),
			);

			const userId$ = ctx.authService.userId$.pipe(map(userId => userId.toUndefined()));
			const isProjectOwner$ = combineLatest([props$.pipe(pluck('product')), userId$]).pipe(
				map(([product, userId]) => userId === (product.team?.owner.id ?? product.owner?.id)),
				distinctUntilChanged(),
			);
			const canUseExport$ = props$.pipe(
				pluck('product'),
				map(product =>
					fromNullable(product.team?.owner?.billing?.plan_name || product.owner?.billing?.plan_name)
						.chain(planNameToType)
						.map(getPlan)
						.getOrElse(PLAN_STARTER),
				),
				map(plan => plan.features.useExport),
				distinctUntilChanged(),
			);

			return {
				defaultProps: {
					loadingStatus: pending,
					saveResult: initial,
					onSaveWidget: (val: TProductWidgetUpdate) => {
						saveWidget$.next(val);
					},
					isProjectOwner: false,
					canUseExport: false,
				},
				props: {
					loadingStatus: product$,
					saveResult: saveWidgetEffect$,
					userId: userId$,
					isProjectOwner: isProjectOwner$,
					canUseExport: canUseExport$,
				},
			};
		}),
);
