import * as React from 'react';
import { SuspendedContainer } from './suspended/SuspendedContainer';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { maintenanceInfo } from '../../env';
import { MaintenanceAlert } from './MaintenanceAlert';
import { constNull } from 'fp-ts/lib/function';

export const Alerts = combineReader(
	SuspendedContainer,
	SuspendedContainer => () => {
		return (
			<div>
				<SuspendedContainer />
				{maintenanceInfo.fold(constNull, info => (
					<MaintenanceAlert info={info} />
				))}
			</div>
		);
	},
);
