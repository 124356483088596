import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { pending, initial, success, RemoteData, fromEither } from '@devexperts/remote-data-ts';
import { of, Subject, Observable } from 'rxjs';
import { map, switchMap, distinctUntilChanged, share } from 'rxjs/operators';
import { ask } from 'fp-ts/lib/Reader';
import { AuthService, TRegistrationData, decodeProspectHash } from 'volley-common/dist/services/auth.service';
import { TProspectViewProps, ProspectView } from './ProspectView';
import { switchMapRD } from 'volley-common/dist/utils/object.utils';
import { SessionServiceClass } from '../../services/token.service';
import { isSome } from 'fp-ts/lib/Option';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { loadReferralDetails } from '../../referral/utils';

export type TProspectContainerContext = {
	authService: AuthService;
	sessionService: SessionServiceClass;
};

export const selector = combineReader(
	decodeProspectHash,
	ask<TProspectContainerContext>(),
	(decodeProspectHash, ctx) => (props$: Observable<TProspectViewProps>) => {
		const { authService } = ctx;
		const prospectHash$ = props$.pipe(
			map(props => props.prospectHash),
			distinctUntilChanged(),
			map(decodeProspectHash),
			map(hash => fromEither(hash)),
		);

		const prospect$ = prospectHash$.pipe(
			switchMapRD(hash => (hash.prospectId > 0 ? authService.getProspect(hash.prospectId) : of(pending))),
		);

		const registerRequest$ = new Subject<TRegistrationData>();
		const registerResult$ = registerRequest$.pipe(
			switchMap(data => authService.register({ ...data, referral_details: loadReferralDetails() })),
			share(),
		);

		const isLoggedIn$ = ctx.sessionService.token$.pipe(map(isSome));

		return {
			defaultProps: {
				prospect: initial,
				decodedHash: initial,
				result: initial,
				onRegister: (data: TRegistrationData) => registerRequest$.next(data),
				isLoggedIn: initial,
			},
			props: {
				prospect: prospect$,
				decodedHash: prospectHash$,
				result: registerResult$,
				isLoggedIn: isLoggedIn$.pipe(map(success)) as Observable<RemoteData<Error, boolean>>,
			},
		};
	},
);

export const ProspectViewContainer = combineReader(selector, ProspectView, (selector, ProspectView) =>
	withRX(ProspectView)(selector),
);
