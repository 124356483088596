import React, { FC, useCallback, CSSProperties, useState, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import {
	Card,
	CardBody,
	Row,
	Form as BootstrapForm,
	FormGroup,
	Input,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import appSumoLogoSvg from 'volley-common/dist/assets/images/appsumo-logo.svg';
import volleyAppSumoLogoSvg from 'volley-common/dist/assets/images/volley-appsumo.svg';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { RemoteData } from '@devexperts/remote-data-ts';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { Option, none, some } from 'fp-ts/lib/Option';
import { TPlanType, getAppSumoTier } from 'volley-common/dist/services/auth.service';
import { Link } from 'react-router-dom';
import { routes } from 'volley-common/dist/utils/routes';

export type AppSumoProps = {
	currentPlan: TPlanType;
	ownAppSumoCodes: number;
	onRestack: () => void;
	onSubmit: (code: string) => void;
	submitResult: RemoteData<Error, TPlanType>;
};

const submitStyle: CSSProperties = { padding: '8px 20px' };

export const AppSumo: FC<AppSumoProps> = ({ currentPlan, ownAppSumoCodes, onRestack, onSubmit, submitResult }) => {
	const [formId, setFormId] = useState(0);
	const [infoForModal, setInfoForModal] = useState<Option<TPlanType>>(none);
	const handleCloseModal = useCallback(() => setInfoForModal(none), []);
	const [restackConfirmation, setRestackConfirmation] = useState<boolean>(false);
	const handleCancelRestack = useCallback(() => setRestackConfirmation(false), []);

	useEffect(() => {
		if (submitResult.isSuccess()) {
			setInfoForModal(some(submitResult.value));
			setFormId(i => i + 1);
		}
	}, [submitResult]);

	const handleSubmit = useCallback(
		values => {
			onSubmit(values.code);
		},
		[onSubmit],
	);

	const renderForm = useCallback(
		(props: FormRenderProps) => {
			return (
				<BootstrapForm inline className="margin-top margin-bottom" onSubmit={props.handleSubmit}>
					<FormGroup className="half-margin-right">
						<Field
							name="code"
							render={({ input }) => (
								<Input
									invalid={submitResult.isFailure()}
									placeholder="AppSumo code"
									{...input}
									type="text"
								/>
							)}
						/>
					</FormGroup>
					<FormGroup>
						<LoadingButton
							pending={submitResult.isPending()}
							color="primary"
							type="submit"
							style={submitStyle}>
							Redeem
						</LoadingButton>
					</FormGroup>
					{submitResult.isFailure() && (
						<div className="invalid-feedback d-block">{submitResult.error.message}</div>
					)}
				</BootstrapForm>
			);
		},
		[submitResult],
	);

	return (
		<Card className="margin-bottom">
			<Modal isOpen={restackConfirmation} toggle={handleCancelRestack}>
				<ModalHeader tag="div" toggle={handleCancelRestack}>
					<h2 className="padding-left">Restack codes</h2>
				</ModalHeader>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<p>
							Are you sure you want to continue? Your plan will be changed from{' '}
							<strong>{formatPlanName(currentPlan)}</strong> to{' '}
							<strong>AppSumo {ownAppSumoCodes} code plan</strong>. This is based on {ownAppSumoCodes}{' '}
							previously applied codes.
						</p>
					</div>
				</ModalBody>
				<ModalFooter className="no-border-top">
					<Button color="link" onClick={handleCancelRestack}>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={() => {
							handleCancelRestack();
							onRestack();
						}}>
						Yes, change plan
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={infoForModal.isSome()} toggle={handleCloseModal}>
				<ModalHeader className="no-border-bottom" tag="div" toggle={handleCloseModal} />
				<ModalBody>
					<div className="padded">
						<div className="text-center">
							<img className="double-margin-bottom max-width" src={volleyAppSumoLogoSvg} width={300} />
							<h3>Congratulations! You have successfully upgraded to:</h3>
							<Card className="double-margin-top">
								<CardBody>
									<h3 className="cold-font">{infoForModal.fold('', renderPlanName)}</h3>
								</CardBody>
							</Card>
						</div>
						<div className="text-center double-margin-top">
							<Link className="btn btn-lg btn-primary margin-bottom" to={routes.dashboard}>
								Go to dashboard
							</Link>
							<br />
							<a
								href="#"
								onClick={e => {
									e.preventDefault();
									handleCloseModal();
								}}>
								Enter another code
							</a>
						</div>
					</div>
				</ModalBody>
			</Modal>
			<CardBody>
				<Row className="align-items-center">
					<div className="col-12">
						<img src={appSumoLogoSvg} width={140} />
						<p className="medium-font no-margin-bottom">Redeem a new AppSumo code</p>
						<Form onSubmit={handleSubmit} render={renderForm} key={formId} />
						<h4>
							Need more AppSumo codes? <a href="#">Click here</a>
						</h4>
						{ownAppSumoCodes > 0 && (
							<h4 className="no-margin-bottom">
								Need to re-apply your codes that have been previously entered?{' '}
								<a
									href="#"
									onClick={e => {
										e.preventDefault();
										setRestackConfirmation(true);
									}}>
									Restack now
								</a>
							</h4>
						)}
					</div>
				</Row>
			</CardBody>
		</Card>
	);
};

function renderPlanName(plan: TPlanType) {
	const tier = getAppSumoTier(plan);
	return tier !== null ? `Volley AppSumo ${tier} code plan` : formatPlanName(plan);
}

function formatPlanName(type: TPlanType): string {
	switch (type) {
		case 'starter':
			return 'Volley Starter';
		case 'freelance':
			return 'Volley Freelance';
		case 'freelance_20211031':
			return 'Volley Freelance (10-31-2021)';
		case 'pro':
			return 'Volley Pro';
		case 'pro_20211031':
			return 'Volley Pro (10-31-2021)';
		case 'team':
			return 'Volley Team';
		case 'team_plus':
			return 'Volley Team Plus';
		case 'enterprise':
			return 'Volley Enterprise';
		case 'unlimited':
		case 'unlimited_early':
			return 'Volley Unlimited';
		case 'unlimited_20211031':
			return 'Volley Unlimited (10-31-2021)';
		case 'appsumo_1':
			return 'AppSumo 1 code plan';
		case 'appsumo_2':
			return 'AppSumo 2 code plan';
		case 'appsumo_3':
			return 'AppSumo 3 code plan';
		case 'appsumo_4':
			return 'AppSumo 4 code plan';
		case 'appsumo_5':
			return 'AppSumo 5 code plan';
	}
}
