import * as React from 'react';
import { FC, useCallback, useEffect, useState, useContext } from 'react';
import { Row, ModalHeader, ModalBody, FormGroup, Input, Label, Button } from 'reactstrap';
import jiraLogoSvg from 'volley-common/dist/assets/images/jira-software-logo.svg';
import { RemoteData, initial } from '@devexperts/remote-data-ts';
import { FormRenderProps, Field, Form } from 'react-final-form';
import { JiraConnectionDetails, JiraValidationError } from 'volley-common/dist/services/export/jira.service';
import { Either, left, right } from 'fp-ts/lib/Either';
import { constUndefined, identity, constVoid } from 'fp-ts/lib/function';
import { Modal } from '../../../components/Modal';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';
import { validateRequired } from 'volley-common/dist/utils/object.utils';

export type JiraConnectionModalProps = {
	onVerifyAndComplete: (data: JiraConnectionDetails) => void;
	onBack: () => void;
	verifyAndSaveResult: RemoteData<Error, unknown>;
	visible?: boolean;
	initialValue?: FormValue;
};

const FormContext = React.createContext<Pick<JiraConnectionModalProps, 'verifyAndSaveResult'>>({
	verifyAndSaveResult: initial,
});

type FormValue = {
	api_token: string;
	host: string;
	username: string;
};

export const JiraConnectionModal: FC<JiraConnectionModalProps> = ({
	onBack,
	verifyAndSaveResult,
	onVerifyAndComplete,
	visible: visibleProp,
	initialValue,
}) => {
	const [visible, setVisible] = useState(false);
	useEffect(() => {
		setVisible(true);
	}, []);
	const handleSubmit = useCallback(
		(data: FormValue) => {
			const { api_token, host, username } = data;
			cleanJiraUrl(host).fold(constVoid, host => onVerifyAndComplete({ username, host, api_token }));
		},
		[onVerifyAndComplete],
	);

	return (
		<Modal isOpen={visibleProp ?? visible} toggle={onBack}>
			<ModalHeader className="no-border-bottom no-padding-bottom" toggle={onBack} />
			<ModalBody>
				<FormContext.Provider value={{ verifyAndSaveResult }}>
					<Form
						initialValues={initialValue}
						component={JiraConnectionModalForm}
						onSubmit={handleSubmit as (val: object) => void}
					/>
				</FormContext.Provider>
			</ModalBody>
		</Modal>
	);
};

function cleanJiraUrl(val: string): Either<string, string> {
	if (!/^https?:\/\//i.test(val)) {
		val = 'https://' + val;
	}

	try {
		const { hostname } = new URL(val);
		return right(hostname);
	} catch (e) {
		return left('Please make sure the URL is correct');
	}
}

const validateJiraUrl = (val: string) => {
	if (val && val.length) {
		const parsed = cleanJiraUrl(val);
		return parsed.fold(identity, constUndefined);
	} else {
		return 'Required';
	}
};

const JiraConnectionModalForm: FC<FormRenderProps> = ({ handleSubmit }) => {
	const { verifyAndSaveResult } = useContext(FormContext);
	return (
		<form onSubmit={handleSubmit}>
			<div className="padded no-padding-top text-center margin-bottom">
				<img className="margin-bottom" src={jiraLogoSvg} width={300} />
				<h1 className="no-margin-bottom bold-font">Connect to Jira</h1>
			</div>
			<div className="margin-bottom">
				<Row className="justify-content-center">
					<div className="col-12">
						<Field
							name="host"
							validate={validateJiraUrl}
							render={({ input, meta }) => (
								<FormGroup>
									<Label className="medium-font">Jira Host</Label>
									<Input
										invalid={meta.error && meta.touched}
										bsSize="lg"
										placeholder="Your Jira url (e.g. https://your-company.atlassian.net)"
										{...input}
										type="text"
									/>
									{meta.error && meta.touched && <div className="invalid-feedback">{meta.error}</div>}
								</FormGroup>
							)}
						/>
						<Field
							name="username"
							validate={validateRequired}
							render={({ input, meta }) => (
								<FormGroup>
									<Label className="medium-font">Jira Email or username</Label>
									<Input
										invalid={meta.error && meta.touched}
										bsSize="lg"
										placeholder="eg: john@doe.com"
										{...input}
										type="text"
									/>
									{meta.error && meta.touched && <div className="invalid-feedback">{meta.error}</div>}
								</FormGroup>
							)}
						/>
						<Field
							name="api_token"
							validate={validateRequired}
							render={({ input, meta }) => (
								<FormGroup>
									<Label className="full-width medium-font">
										<div className="inline-block">API token</div>
										<div className="inline-block float-right">
											<a
												href="https://id.atlassian.com/manage-profile/security/api-tokens"
												rel="noopener noreferrer"
												target="_blank">
												Get your API token
												<i className="material-icons helper-icon">launch</i>
											</a>
										</div>
									</Label>
									<Input
										invalid={meta.error && meta.touched}
										bsSize="lg"
										placeholder="Enter API token"
										{...input}
										type="text"
									/>
									{meta.error && meta.touched && <div className="invalid-feedback">{meta.error}</div>}
									{renderValidationError(verifyAndSaveResult)}
								</FormGroup>
							)}
						/>
					</div>
				</Row>
			</div>
			<div className="margin-bottom">
				<Button color="primary" size="lg" block disabled={verifyAndSaveResult.isPending()} type="submit">
					Connect Jira account
				</Button>
			</div>
		</form>
	);
};

function renderValidationError(result: RemoteData<Error, unknown>) {
	return (
		result.isFailure() && (
			<div className="invalid-feedback" style={{ display: 'block' }}>
				{result.error instanceof JiraValidationError
					? 'Connection failed. Please make sure the credentials are correct'
					: genericErrorMessage}
			</div>
		)
	);
}
