import React, { useState, useCallback, memo, MouseEvent, useEffect, CSSProperties } from 'react';
import { CommentNumber } from 'volley-common/dist/components/CommentNumber';
import { ClickHint } from 'volley-common/dist/components/ClickHint';
import { TReviewState } from './Review';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { ImageView as ImageViewFactory } from 'volley-common/dist/components/ImageView';
import { Option, none, some } from 'fp-ts/lib/Option';
import { identity } from 'fp-ts/lib/function';
import { initial } from '@devexperts/remote-data-ts';
import { v4 as uuid } from 'uuid';
import { TNoteResult } from 'volley-common/dist/components/Note';
import css from './ImagePanelV2.module.scss';
import cn from 'classnames';

const ImageView = ImageViewFactory.run({ resolveResource: identity });

export interface ImagePanelV2Props {
	maxHeight?: boolean;
	image: string;
	overlaySrc?: string;
	notes: TProductImage[];
	onReceivedNaturalSize?: (val: TReviewState['naturalSize']) => void;
	onClickNote?: (note: TProductImage) => void;
	onPostNote?: (note: Omit<CreateNoteForUploadedImage, 'productId' | 'uploadedImageId'>) => void;
	currentNoteId?: number;
	isUploaded?: boolean;
}

export type CreateNoteForUploadedImage = {
	productId: number;
	uploadedImageId: number;
	x: number;
	y: number;
} & TNoteResult;

export type TImagePanelState = {
	naturalWidth: number;
	naturalHeight: number;
	src: string;
};

export const ImagePanelV2 = memo<ImagePanelV2Props>(props => {
	const pixelRatio = props.notes[0]?.pixel_ratio ?? none;
	const noteId = props.notes[0]?.id ?? -1;
	const { onReceivedNaturalSize, maxHeight = false, onClickNote, currentNoteId, isUploaded } = props; // STOPSHIP: maxHeight unused
	const [state, setState] = useState<TImagePanelState | null>(null);
	const onLoad = useCallback(
		(e: React.UIEvent<HTMLImageElement>) => {
			const img = e.currentTarget;
			setState({
				naturalHeight: img.naturalHeight,
				naturalWidth: img.naturalWidth,
				src: img.src,
			});
			onReceivedNaturalSize?.({
				width: img.naturalWidth,
				height: img.naturalHeight,
				ratio: pixelRatio.getOrElse(1),
				noteId,
			});
		},
		[onReceivedNaturalSize, noteId, pixelRatio],
	);

	const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
	const [imageRect, setImageRect] = useState<DOMRect | undefined>();
	useEffect(() => {
		if (imageRef) {
			const observer = new ResizeObserver(e => {
				const event = e[e.length - 1];
				if (event) {
					console.log('image rect', event.target.getBoundingClientRect());
					setImageRect(event.target.getBoundingClientRect());
				}
			});
			observer.observe(imageRef);
			return () => observer.disconnect();
		}
		return undefined;
	}, [imageRef]);

	const [createdNote, setCreatedNote] = useState<Option<{ left: number; top: number }>>(none);

	const imgStyle: CSSProperties = state
		? {
				width: state.naturalWidth,
				maxWidth: '100%',
				maxHeight: maxHeight ? 'calc(100vh - 85px)' : undefined,
				objectFit: maxHeight ? 'contain' : undefined,
				border: maxHeight ? 'none' : undefined,
		  }
		: {};

	const [clickHint, setClickHint] = useState(null as { x: number; y: number } | null);
	const onMouseOver = useCallback((e: MouseEvent<HTMLImageElement>) => {
		setClickHint({ x: e.clientX, y: e.clientY });
	}, []);

	const onImgClick = useCallback((e: MouseEvent<HTMLImageElement>) => {
		setCreatedNote(
			some({
				left: e.clientX,
				top: e.clientY,
			}),
		);
	}, []);

	const [id] = useState(() => 'img-' + uuid());

	const handleSaveNote = (note: TNoteResult, position: { left: number; top: number }) => {
		const imageRect = imageRef?.getBoundingClientRect();
		if (imageRect) {
			const { naturalWidth, naturalHeight } = imageRef!;
			props.onPostNote?.({
				...note,
				x: ((position.left - imageRect.left) / imageRect.width) * naturalWidth,
				y: ((position.top - imageRect.top) / imageRect.height) * naturalHeight,
			});
			setCreatedNote(none);
		}
	};

	return (
		<div className={cn(css.container, isUploaded && css.container_uploaded)} id={id}>
			<img
				ref={setImageRef}
				src={props.image}
				style={imgStyle}
				onLoad={onLoad}
				onMouseMove={isUploaded ? onMouseOver : undefined}
				onMouseOut={() => setClickHint(null)}
				onClick={isUploaded ? onImgClick : undefined}
			/>
			{props.overlaySrc && <img src={props.overlaySrc} style={{ ...imgStyle, position: 'absolute', top: 0 }} />}
			{isUploaded &&
				state &&
				props.notes.map(note => (
					<CommentNumber
						isActive={note.id === currentNoteId}
						isSmall
						isPositioned
						style={{
							left: `${(note.x / state.naturalWidth) * 100}%`,
							top: `${(note.y / state.naturalHeight) * 100}%`,
						}}
						number={note.number}
						key={note.id}
						className={cn(note.id !== currentNoteId && css.comment_inactive)}
						onClick={() => onClickNote?.(note)}
					/>
				))}
			<ClickHint coords={clickHint ?? undefined} visible={!!clickHint && !!isUploaded} />
			{createdNote
				.map(position => (
					<ImageView
						// onPositionChange={props.onChangeNotePosition}
						// teamMembers={teamMembers}
						rect={imageRect}
						teamMembers={[]} // STOPSHIP
						onPositionChange={(x, y) => setCreatedNote(some({ left: x, top: y }))}
						pos={{ screenX: position.left, screenY: position.top }}
						onCancel={() => setCreatedNote(none)}
						onSave={note => handleSaveNote(note, position)}
						saveResult={initial}
						// onCancel={props.onCancelNote}
						// onSave={data =>
						// props.onSaveNote({
						// deferredData: position.deferredData,
						// ...data,
						// note: data.note || 'No comment',
						// viewport: rect ?? null,
						// })
						// }
						// noteNumber={props.projectNoteCounter}
						// saveResult={props.saveNoteResult}
					/>
				))
				.toNullable()}
		</div>
	);
});
