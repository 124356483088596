import React, { memo, useCallback, FC } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import style from './ShareForm.module.scss';
import cn from 'classnames';
import { Input, Button, ModalHeader, ModalBody, ModalFooter, Media, Row, Col } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { TApiError, getNonFieldErrors, getErrors } from 'volley-common/dist/models/api.model';
import { Avatar } from 'volley-common/dist/components/Avatar';
import { TMember, TProduct } from 'volley-common/dist/services/products.service';
import { Modal } from '../../../components/Modal';
import { Tooltip } from 'volley-common/dist/components/Tooltip';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { RemoveButtonContainer } from './RemoveButtonContainer';
import { LeaveButtonContainer } from './LeaveButtonContainer';
import { fromNullable } from 'fp-ts/lib/Option';
import { ProspectActionsContainer } from './ProspectActionsContainer';

export type TShareRequest = {
	emails: string[];
	productId: number;
};

export type TSharingMember = TMember & { status: 'owner' | 'member' | 'collaborator' | 'invited' };

export type TShareFormProps = {
	result: RemoteData<Error, void>;
	onSendRequest: (data: TShareRequest) => void;
	onClose: () => void;
	product: TProduct;
	members?: TSharingMember[];
	currentUserId?: number;
	onReloadProduct?: () => void;
	onProjectLeft?: () => void;
};

export const ShareForm = combineReader(
	RemoveButtonContainer,
	LeaveButtonContainer,
	ProspectActionsContainer,
	(RemoveButtonContainer, LeaveButtonContainer, ProspectActionsContainer) => {
		type ShareFormMemberProps = Pick<
			TShareFormProps,
			'currentUserId' | 'onClose' | 'product' | 'onReloadProduct' | 'onProjectLeft'
		> & {
			canManageOthers: boolean;
			member: TSharingMember;
		};
		const ShareFormMember = memo<ShareFormMemberProps>(
			({ canManageOthers, currentUserId, onReloadProduct, product, onClose, member, onProjectLeft }) => {
				const shouldDisplayButton = canManageOthers || member.id === currentUserId;
				const teamId = fromNullable(product.team)
					.filter(team => team.members.some(m => m.id === currentUserId) || team.owner.id === currentUserId)
					.map(team => team.id);
				return (
					<Media
						key={member.id}
						className={cn('half-margin-top half-padding-top half-padding-bottom', style.member)}>
						<Avatar user={member} size="xs" className="half-margin-right" />
						<Media body>
							<Row>
								<Col md={8}>
									<h4
										className={cn(
											'bold-font no-margin-bottom',
											member.status === 'invited' && 'extra-light-text-color',
										)}>
										{member.name || member.email}
										{member.status === 'invited' && ' (invited)'}
									</h4>
									<h5 className="light-font-name">
										{formatMemberStatus(member.status)} • {member.email}
									</h5>
								</Col>
								{shouldDisplayButton && (
									<Col md={4}>
										{member.status === 'owner' && (
											<Tooltip
												boundariesElement={'body'}
												target={''}
												trigger={
													<div>
														<Button block color="secondary" className="btn-label disabled">
															owner
														</Button>
													</div>
												}>
												Owner can't be removed
											</Tooltip>
										)}
										{member.status === 'member' && (
											<Tooltip
												target={''}
												boundariesElement={'body'}
												trigger={
													<div>
														<Button block color="secondary" className="btn-label disabled">
															Team member
														</Button>
													</div>
												}>
												Team members can't be removed
											</Tooltip>
										)}
										{member.status === 'collaborator' && member.id === currentUserId && (
											<LeaveButtonContainer
												projectId={product.id}
												teamId={teamId}
												onSuccess={() => {
													onClose();
													onProjectLeft && onProjectLeft();
												}}
											/>
										)}
										{member.status === 'collaborator' && member.id !== currentUserId && (
											<RemoveButtonContainer
												projectId={product.id}
												userId={member.id}
												onReloadProduct={onReloadProduct}
											/>
										)}
										{member.status === 'invited' && (
											<ProspectActionsContainer
												projectId={product.id}
												prospectId={member.id}
												onReloadProduct={onReloadProduct}
											/>
										)}
									</Col>
								)}
							</Row>
						</Media>
					</Media>
				);
			},
		);

		type TShareFormInnerProps = FormRenderProps & { result: RemoteData<Error, void> };

		const ShareFormInner: FC<TShareFormInnerProps> = ({ result, form, handleSubmit }) => {
			const emails = form.getFieldState('emails');
			return (
				<form onSubmit={handleSubmit}>
					<Field
						name="emails"
						render={({ input }) => (
							<div className="double-margin-bottom">
								<Input
									bsSize="lg"
									placeholder="Enter emails separated by commas"
									{...input}
									type="text"
								/>
								{getSharingErrors(result).map((error, index) => (
									<div className="invalid-feedback" style={{ display: 'block' }} key={index}>
										{error}
									</div>
								))}
							</div>
						)}
					/>
					<div className="text-center">
						<Button
							color="primary"
							size="lg"
							disabled={!emails || !emails.value || !emails.value.length || result.isPending()}>
							Send invite
						</Button>
					</div>
				</form>
			);
		};

		return memo<TShareFormProps>(
			({ members, result, currentUserId, onClose, onSendRequest, product, onReloadProduct, onProjectLeft }) => {
				const productId = product.id;
				const handleSubmit = useCallback(
					(values: object) => {
						onSendRequest &&
							onSendRequest({
								productId,
								emails: ((values as any).emails as string).split(/\s*,\s*/).filter(e => !!e.length),
							});
					},
					[productId, onSendRequest],
				);

				const FormInner = useCallback(
					(props: FormRenderProps) => <ShareFormInner {...props} result={result} />,
					[result],
				);

				const canManageOthers =
					!!members &&
					members.some(m => m.id === currentUserId && (m.status === 'owner' || m.status === 'member'));

				return (
					<Modal isOpen={true}>
						<ModalHeader toggle={onClose} className="no-padding-bottom no-border-bottom" />
						<ModalBody>
							<div className="padded no-padding-top no-padding-bottom">
								<h2 className="text-center">Invite people to this project</h2>
								<p className="light-text-color text-center">
									Invite collaborators to work in this project
								</p>

								<Form onSubmit={handleSubmit} render={FormInner} />

								{members && members.length > 0 && (
									<div className="double-margin-top">
										<h5 className="light-text-color margin-bottom">Who has access</h5>
										<div className={style.membersList}>
											{members.map(m => (
												<ShareFormMember
													canManageOthers={canManageOthers}
													member={m}
													key={m.id}
													onClose={onClose}
													currentUserId={currentUserId}
													product={product}
													onReloadProduct={onReloadProduct}
													onProjectLeft={onProjectLeft}
												/>
											))}
										</div>
									</div>
								)}
							</div>
						</ModalBody>
						<ModalFooter className="no-border-top" />
					</Modal>
				);
			},
		);
	},
);

function getSharingErrors(e: RemoteData<TApiError, any>): Array<string> {
	if (e.isFailure()) {
		let errors = getNonFieldErrors(e.error);
		const emailErrors = getErrors(e.error, 'emails');
		if (emailErrors.length) {
			errors = [...errors, ...emailErrors];
		}
		return errors;
	}
	return [];
}

function formatMemberStatus(status: TSharingMember['status']): string {
	switch (status) {
		case 'collaborator':
			return 'Collaborator';
		case 'invited':
			return 'Invited';
		case 'owner':
			return 'Owner';
		case 'member':
			return 'Member';
	}
}
