import * as React from 'react';
import { Fragment } from 'react';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import trelloIcon from 'volley-common/dist/assets/images/trello-icon.png';
import jiraIcon from 'volley-common/dist/assets/images/jira-icon.svg';
import pdfSvg from 'volley-common/dist/assets/images/pdf-icon-square.svg';
// import asanaIcon from 'volley-common/dist/assets/images/asana-icon.png';

export type ExportType = IntegrationType | 'pdf';

export function renderIntegrationIcon(type: ExportType): React.ReactNode {
	switch (type) {
		case IntegrationType.Trello:
			return <img className="mr-3" src={trelloIcon} width="55px" />;
		case IntegrationType.Asana:
			return <Fragment />;
		// return <img className="mr-3" src={asanaIcon} width="55px" />;
		case IntegrationType.Jira:
			return <img className="mr-3" src={jiraIcon} width="55px" />;
		case 'pdf':
			return <img className="mr-3" src={pdfSvg} width="55px" />;
	}
}

export function renderIntegrationName(type: ExportType): React.ReactNode {
	switch (type) {
		case IntegrationType.Trello:
			return 'Trello';
		case IntegrationType.Asana:
			return 'Asana';
		case IntegrationType.Jira:
			return 'JIRA';
		case 'pdf':
			return 'PDF';
	}
}
