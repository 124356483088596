import React, { Component, Fragment, ReactNode, ComponentType } from 'react';
import { Route } from 'react-router-dom';
import { history } from '../../utils/history';
import { Redirect, Router, Switch, RouteComponentProps } from 'react-router';
import { RemoteData } from '@devexperts/remote-data-ts';
import { ProductContainer } from '../product/ProductContainer';
import { LoginContainer } from '../login/LoginContainer';
import { SignUpContainer } from '../signup/SignUpContainer';
import { ProductsContainer } from '../products/ProductsContainer';
import { ProspectViewContainer } from '../prospect/ProspectViewContainer';
import { withProps } from 'recompose';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { PageNotFoundContainer } from '../errors/PageNotFoundContainer';
import { ToastsContainer } from 'volley-common/dist/components/ToastsContainer';
import { ProfileModelType } from '../../models/profile.model';
import { ask } from 'fp-ts/lib/Reader';
import { ForgotPasswordContainer } from '../forgot-password/ForgotPasswordContainer';
import qs from 'qs';
import { ResetPasswordContainer } from '../forgot-password/ResetPasswordContainer';
import { PaymentPageContainer } from '../subscribe/payment/PaymentPageContainer';
import { SubscriptionViewContainer } from '../subscribe/SubscriptionViewContainer';
import { YouRockContainer } from '../subscribe/YouRockContainer';
import { RedirectToLogin } from '../../components/RedirectToLogin';
import { ScrollToTop } from '../../components/ScrollToTop';
import { ExportPageContainer } from '../export/ExportPageContainer';
import { ConnectCompletionContainer } from '../export/ConnectCompletionContainer';
import { withRouteClass } from '../../utils/route';
import { routes } from 'volley-common/dist/utils/routes';
import { TeamMembersContainer } from '../team-members/TeamMembersContainer';
import { TeamSettingsContainer } from '../team-settings/TeamSettingsContainer';
import { SettingsContainer } from '../settings/SettingsContainer';
import { AppSumoSignUpContainer } from '../appsumo/AppSumoSignUpContainer';
import { maintenanceInfo } from '../../env';
import { MaintenanceInProgress } from '../errors/MaintenanceInProgress';
import { isMaintenanceInProgress } from 'volley-common/dist/models/maintenance.model';
import { isPlanType } from 'volley-common/dist/services/auth.service';
import { identity } from 'fp-ts/lib/function';
import { PdfDemoPage } from '../export/pdf/PdfDemoPage';
import { SurveyContainer } from '../settings/SurveyContainer';
import { PdfUploader } from '../pdf-import/pdfjs-loader';

export type TAppProps = {
	isLoggedIn: RemoteData<Error, boolean>;
};

const BrowserRouter = withProps({ history })(Router);

type TCtx = {
	profileModel: ProfileModelType;
};

export const Dependencies1 = combineReader(
	SignUpContainer,
	LoginContainer,
	ForgotPasswordContainer,
	ResetPasswordContainer,
	ProductsContainer,
	ProductContainer,
	ProspectViewContainer,
	PageNotFoundContainer,
	SettingsContainer,
	ToastsContainer,
	(
		SignUpContainer,
		LoginContainer,
		ForgotPasswordContainer,
		ResetPasswordContainer,
		ProductsContainer,
		ProductContainer,
		ProspectViewContainer,
		PageNotFoundContainer,
		SettingsContainer,
		ToastsContainer,
	) => ({
		SignUpContainer,
		LoginContainer,
		ForgotPasswordContainer,
		ResetPasswordContainer,
		ProductsContainer,
		ProductContainer,
		ProspectViewContainer,
		PageNotFoundContainer,
		SettingsContainer,
		ToastsContainer,
	}),
);

export const App = combineReader(
	Dependencies1,
	PaymentPageContainer,
	SubscriptionViewContainer,
	YouRockContainer,
	ConnectCompletionContainer,
	ExportPageContainer,
	AppSumoSignUpContainer,
	TeamSettingsContainer,
	TeamMembersContainer,
	SurveyContainer,
	ask<TCtx>(),
	(
		{
			SignUpContainer,
			LoginContainer,
			ForgotPasswordContainer,
			ResetPasswordContainer,
			ProductsContainer,
			ProductContainer,
			ProspectViewContainer,
			PageNotFoundContainer,
			SettingsContainer,
			ToastsContainer,
		},
		PaymentPageContainer,
		SubscriptionViewContainer,
		YouRockContainer,
		ConnectCompletionContainer,
		ExportPageContainer,
		AppSumoSignUpContainer,
		TeamSettingsContainer,
		TeamMembersContainer,
		SurveyContainer,
		{ profileModel },
	) => {
		const Pages = {
			Login: withRouteClass('login')(LoginContainer),
			SignUp: withRouteClass('signup')(SignUpContainer),
			AppSumoSignUp: withRouteClass('appsumo-signup')(AppSumoSignUpContainer),
			ForgotPassword: withRouteClass('forgot-password')(ForgotPasswordContainer),
			Products: withRouteClass('products')(ProductsContainer),
			Product: withRouteClass('product')(ProductContainer),
			TeamMembers: withRouteClass('team-members')(TeamMembersContainer),
			TeamSettings: withRouteClass('team-settings')(TeamSettingsContainer),
		};
		return class App extends Component<TAppProps> {
			render() {
				return maintenanceInfo.filterOrElse(isMaintenanceInProgress, []).fold(
					() => (
						<Fragment>
							<BrowserRouter>
								<Fragment>
									<ScrollToTop />
									{this.props.isLoggedIn.fold<ReactNode>(
										null,
										'loading',
										err => err.message,
										isLoggedIn => this.renderApp(isLoggedIn),
									)}
								</Fragment>
							</BrowserRouter>
							<ToastsContainer />
							<YouRockContainer />
							<SurveyContainer />
						</Fragment>
					),
					info => <MaintenanceInProgress info={info} />,
				);
			}

			private renderApp = (isLoggedIn: boolean) => {
				const authenticated = function <P>(Component: ComponentType<P>) {
					return (props: P) => (isLoggedIn ? <Component {...props} /> : <RedirectToLogin />);
				};
				const anonymous = function <P>(Component: ComponentType<P>) {
					return (props: P) => (!isLoggedIn ? <Component {...props} /> : <Redirect to={routes.dashboard} />);
				};

				return (
					<Switch>
						<Route exact={true} path={routes.root}>
							<Redirect to={routes.dashboard} />
						</Route>
						<Route path="/pdf" component={PdfDemoPage} />
						<Route
							path={routes.login}
							render={({ location }) => {
								const query = qs.parse(location.search, { ignoreQueryPrefix: true });
								return <Pages.Login returnUrl={query.returnUrl} />;
							}}
						/>
						<Route
							path={routes.signUp}
							render={({ location }) => {
								const query = qs.parse(location.search, { ignoreQueryPrefix: true });
								return <Pages.SignUp returnUrl={query.returnUrl} />;
							}}
						/>
						<Route path={routes.appSumoSignUp} component={anonymous(Pages.AppSumoSignUp)} />
						<Route path={routes.forgotPassword} component={Pages.ForgotPassword} />
						<Route
							path="/account/pwforgot/reset/:userId/:token"
							render={({ match }) => (
								<ResetPasswordContainer
									userId={Number(match.params.userId)}
									token={match.params.token}
								/>
							)}
						/>
						<Route
							path="/share/:hash"
							render={({ match }) => <ProspectViewContainer prospectHash={match.params.hash} />}
						/>
						<Route
							path={routes.productTeam.pattern}
							render={({ match }) => {
								const Page = authenticated(Pages.TeamMembers);
								return <Page teamId={Number(match.params.id)} />;
							}}
						/>
						<Route
							path={routes.productTeamSettings.pattern}
							render={({ match }) => {
								const Page = authenticated(Pages.TeamSettings);
								return <Page teamId={Number(match.params.id)} />;
							}}
						/>
						<Route path={routes.productNote.pattern} render={this.redirectFromDeprecatedProductNoteRoute} />
						{/* TODO: use one array path when react-router is upgraded */}
						<Route path={routes.productNotes.pattern} render={this.renderProductRoute} />
						<Route path={routes.productImages.pattern} render={this.renderProductRoute} />
						<Route path={routes.product.pattern} render={this.renderProductRoute} />
						<Route path={routes.products}>
							<Redirect to={routes.dashboard} />
						</Route>
						<Route path={routes.dashboardWelcome} component={authenticated(Pages.Products)} />
						<Route path={routes.dashboard} component={authenticated(Pages.Products)} />
						<Route path={routes.teamDashboard.pattern} component={authenticated(Pages.Products)} />
						<Route path={routes.billingPay} render={this.renderPaymentPageRoute} />
						<Route path={routes.export} render={this.renderExportRoute} />
						<Route path={routes.settings} component={authenticated(SettingsContainer)} />
						<Route path="/account/activate/:id/:token" component={this.renderActivationRoute(isLoggedIn)} />
						<Route component={PageNotFoundContainer} />
						{/* <Route render={() => <PdfUploader />} /> */}
					</Switch>
				);
			};

			private renderActivationRoute =
				(isLoggedIn: boolean) =>
				({ match }: RouteComponentProps<any>) => {
					if (isLoggedIn) {
						profileModel.activate(match.params.token);
						return <Redirect to={routes.dashboard} />;
					} else {
						return <LoginContainer />;
					}
				};

			private renderProductRoute = ({ match, location }: RouteComponentProps<any>) => {
				const query = qs.parse(location.search, { ignoreQueryPrefix: true });
				return this.props.isLoggedIn.exists(identity) ? (
					<Pages.Product
						productId={Number(match.params.id)}
						noteId={match.params.noteId && Number(match.params.noteId)}
						imageId={match.params.imgId && Number(match.params.imgId)}
						commentId={query.comment && Number(query.comment)}
						state={location.state}
					/>
				) : (
					<RedirectToLogin />
				);
			};

			private redirectFromDeprecatedProductNoteRoute = ({ match, location }: RouteComponentProps<any>) => {
				return <Redirect to={routes.productNotes(match.params.id, match.params.noteId)} />;
			};

			private renderExportRoute = ({ location: { state } }: RouteComponentProps<any>) => {
				if (this.props.isLoggedIn.exists(identity)) {
					if (
						typeof state === 'object' &&
						'product' in state &&
						typeof state.product === 'number' &&
						'type' in state &&
						typeof state.type === 'string' &&
						'integrationId' in state &&
						typeof state.integrationId === 'number'
					) {
						return (
							<ExportPageContainer
								productId={state.product}
								integrationType={state.type}
								integrationId={state.integrationId}
							/>
						);
					} else {
						return <ConnectCompletionContainer />;
					}
				} else {
					return <RedirectToLogin />;
				}
			};

			private renderPaymentPageRoute = ({ location: { state, search } }: RouteComponentProps<any>) => {
				const searchParams = qs.parse(search, { ignoreQueryPrefix: true });
				if (this.props.isLoggedIn.exists(identity)) {
					const stateObj = typeof state === 'object' ? state : {};
					const prospectivePlan =
						searchParams.plan || ('prospectivePlan' in stateObj ? stateObj.prospectivePlan : undefined);
					if (typeof prospectivePlan === 'string' && isPlanType(prospectivePlan)) {
						const coupon = searchParams.coupon || ('coupon' in stateObj ? stateObj.coupon : undefined);
						const billingPeriod =
							searchParams.billingPeriod ||
							('billingPeriod' in stateObj ? stateObj.billingPeriod : undefined);
						return (
							<PaymentPageContainer
								prospectivePlan={prospectivePlan}
								coupon={coupon}
								initialBillingPeriod={billingPeriod}
							/>
						);
					} else {
						return <SubscriptionViewContainer onClose={() => history.push(routes.dashboard)} />;
					}
				} else {
					return <RedirectToLogin />;
				}
			};
		};
	},
);

export default App;
