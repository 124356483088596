import { TeamsService } from 'volley-common/dist/services/teams.service';
import { TeamSelector } from './TeamSelector';
import { asks } from 'fp-ts/lib/Reader';
import { initial } from '@devexperts/remote-data-ts';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { ProfileModelType } from '../../models/profile.model';
import { switchMap, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Option } from 'fp-ts/lib/Option';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';
import { constVoid } from 'fp-ts/lib/function';
import { history } from '../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';

type TeamSelectorContainerContext = {
	teamsService: TeamsService;
	toastService: ToastService;
	profileModel: ProfileModelType;
};

export const TeamSelectorContainer = asks(
	({ teamsService, toastService, profileModel }: TeamSelectorContainerContext) =>
		withRX(TeamSelector)(() => {
			const createRequest$ = new Subject<string>();
			const createResult$ = createRequest$.pipe(
				switchMap(name => teamsService.createTeam(name)),
				tap(result =>
					result.foldL(
						constVoid,
						constVoid,
						() => toastService.push({ text: genericErrorMessage }),
						team => {
							history.push(routes.teamDashboard(team.id));
						},
					),
				),
			);

			return {
				defaultProps: {
					teams: initial,
					selectedTeam: initial,
					profile: initial,
					onSelect: (val: Option<number>) =>
						history.push(val.fold(routes.dashboard, id => routes.teamDashboard(id))),
					onCreate: (name: string) => createRequest$.next(name),
					createResult: initial,
				},
				props: {
					teams: teamsService.teams$,
					selectedTeam: teamsService.selectedTeam$,
					profile: profileModel.profile$,
					createResult: createResult$,
				},
			};
		}),
);
