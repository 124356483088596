import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { DropdownItem } from 'reactstrap';
import { IntegrationCard } from './Integrations';
import trelloPng from 'volley-common/dist/assets/images/trello-logo.png';
import { IntegrationsMap, IntegrationStore } from 'volley-common/dist/services/export/integration.store';
import { distinctUntilChanged, map, mapTo, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ask } from 'fp-ts/lib/Reader';
import { Subject, EMPTY, merge, of } from 'rxjs';
import { exporters, IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { RemoteData, initial, success, failure } from '@devexperts/remote-data-ts';
import { tuple } from 'fp-ts/lib/function';
import { PaidOption } from 'volley-common/dist/components/PaidOption';
import { routes } from 'volley-common/dist/utils/routes';
import { TrelloSettings, TrelloSettingsFormModel } from '../integrations/TrelloSettings';
import { trello, TrelloBoard } from 'volley-common/dist/services/export/trello.service';

interface TrelloCardProps {
	onConnect: () => void;
	onSaveSettings: (data: {}) => void;
	onDisconnect: () => void;
	hasCredentials: boolean;
	integrations: IntegrationsMap;
	productId: number;
	saveResult: RemoteData<Error, unknown>;
	onSubscriptionNeeded?: () => void;
	boards: RemoteData<Error, TrelloBoard[]>;
}

const TrelloCard = memo<TrelloCardProps>(
	({
		onConnect,
		onSaveSettings,
		onDisconnect,
		hasCredentials,
		integrations,
		saveResult,
		onSubscriptionNeeded,
		boards,
	}) => {
		const [isEditing, setIsEditing] = useState(false);
		const onStartEditing = () => setIsEditing(true);
		const integration = integrations.trello;

		const menu = hasCredentials ? (
			<Fragment>
				<PaidOption
					isUnlocked={!onSubscriptionNeeded}
					onAllowed={onStartEditing}
					onLocked={onSubscriptionNeeded}>
					Settings
				</PaidOption>
				<DropdownItem divider />
				<DropdownItem onClick={onDisconnect}>Disconnect</DropdownItem>
			</Fragment>
		) : (
			<PaidOption isUnlocked={!onSubscriptionNeeded} onAllowed={onConnect} onLocked={onSubscriptionNeeded}>
				Connect
			</PaidOption>
		);

		const handleSave = useCallback(
			(val: TrelloSettingsFormModel) => {
				const board = val.board ? boards.getOrElse([]).find(b => b.id === val.board) : null;
				const list = val.list ? board?.lists.find(l => l.id === val.list) : null;
				onSaveSettings({
					auto_export: val.auto_export,
					organization_id: board?.organizationId,
					organization_name: board?.group,
					board_id: board?.id,
					board_name: board?.name,
					list_id: list?.id,
					list_name: list?.name,
				});
			},
			[onSaveSettings, boards],
		);

		const initialValue = useMemo<TrelloSettingsFormModel>(() => {
			const config: any = integration?.config_details;
			return {
				auto_export: config?.auto_export,
				board: config?.board_id,
				list: config?.list_id,
			};
		}, [integration]);

		return (
			<Fragment>
				<TrelloSettings
					onClose={() => setIsEditing(false)}
					visible={hasCredentials && isEditing}
					onSave={handleSave}
					boards={boards}
					saveResult={saveResult}
					value={initialValue}
				/>
				<IntegrationCard active={hasCredentials} logo={trelloPng} name={'Trello'} menu={menu} />
			</Fragment>
		);
	},
);

type TrelloCardContainerContext = {
	integrationStore: IntegrationStore;
};

export const TrelloCardContainer = combineReader(
	exporters,
	trello,
	ask<TrelloCardContainerContext>(),
	(exporters, trello, ctx) =>
		withRX(TrelloCard)(props$ => {
			const hasCredentials$ = props$.pipe(
				map(p => !!p.integrations.trello),
				distinctUntilChanged(),
			);

			const productId$ = props$.pipe(pluck('productId'), distinctUntilChanged());
			const connect$ = new Subject<void>();
			const connectEffect$ = connect$.pipe(
				withLatestFrom(productId$),
				tap(([, product]) =>
					exporters.connect(IntegrationType.Trello, product, routes.product(product), {
						product,
						type: IntegrationType.Trello,
					}),
				),
				mapTo(success<Error, unknown>(undefined)),
			);

			const saveSettings$ = new Subject<TrelloSettingsFormModel>();
			const saveSettingsEffect$ = saveSettings$.pipe(
				withLatestFrom(props$),
				switchMap(([settings, { productId, integrations }]) =>
					integrations.trello
						? ctx.integrationStore.update(productId, integrations.trello.id, undefined, settings, {})
						: EMPTY,
				),
			);

			const disconnect$ = new Subject<void>();
			const disconnectEffect$ = disconnect$.pipe(
				withLatestFrom(props$),
				switchMap(([, props]) =>
					props.integrations.trello
						? ctx.integrationStore.remove(props.productId, props.integrations.trello.id)
						: EMPTY,
				),
			);

			const boards$ = props$.pipe(
				map(p => tuple(p.productId, p.integrations.trello?.id)),
				distinctUntilChanged((a, b) => a.every((val, i) => val === b[i])),
				switchMap(([productId, configId]) =>
					configId
						? trello.fold(
								of(failure<Error, TrelloBoard[]>(new Error('Trello is not available now'))),
								trello => trello.getBoards(productId, configId),
						  )
						: of(initial),
				),
			);

			return {
				defaultProps: {
					onConnect: () => connect$.next(),
					onSaveSettings: val => saveSettings$.next(val),
					onDisconnect: () => disconnect$.next(),
					hasCredentials: false,
					saveResult: initial,
					boards: initial,
				},
				props: {
					hasCredentials: hasCredentials$,
					saveResult: merge(saveSettingsEffect$, connectEffect$),
					boards: boards$,
				},
				effects$: disconnectEffect$,
			};
		}),
);
