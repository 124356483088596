import 'volley-common/dist/assets/stylesheets/style.css';
import './index.css';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { AppContainer } from './modules/app/AppContainer';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { AxiosReader } from 'volley-common/dist/utils/axios';
import { SessionService, TokenStore } from './services/token.service';
import { ProductService } from 'volley-common/dist/services/products.service';
import { NotesService } from 'volley-common/dist/services/notes.service';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import Hashids from 'hashids';
import { ProfileModel } from './models/profile.model';
import { appState } from './models/app-state.model';
import { integrationStore } from 'volley-common/dist/services/export/integration.store';
import { exportDomContext } from './services/export.service';
import { teamsService as teamsServiceFactory } from 'volley-common/dist/services/teams.service';
import { productsViewModel as productsViewModelFactory } from 'volley-common/dist/services/products.view-model';
import { env } from './env';
import { YouRockAppState } from './modules/subscribe/YouRockContainer';
import { fromNullable } from 'fp-ts/lib/Option';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

import './referral/index';

const commonContext = {
	baseUrl: env.baseUrl,
};

const sessionService = SessionService.run({
	...commonContext,
	tokenStore: new TokenStore(),
});

const axios = AxiosReader.run({
	...commonContext,
	token$: sessionService.token$,
});
const authService = new AuthService(
	{
		acceptTokens: sessionService.acceptTokens,
		token$: sessionService.token$,
		decoded$: sessionService.decoded$,
	},
	axios,
);
const stripeApiKey = env.stripeApiKey || 'pk_test_UbFEcetjrfZtSqC68yGwyOUb00epVCLVau';
const teamsService = teamsServiceFactory.run({ axios, authService });
const productsViewModel = productsViewModelFactory.run({ teamsService });
const App = AppContainer.run({
	isProduction: env.baseUrl.toLowerCase().includes('//api.meetvolley.com/'),
	axios,
	sessionService,
	authService,
	notesService: new NotesService(axios),
	productService: new ProductService(axios),
	toastService: new ToastService(),
	hashids: new Hashids(env.hashidsSalt, 13),
	profileModel: ProfileModel.run({ authService }),
	integrationStore: integrationStore.run({ axios }),
	stripeApiKey,
	appState: appState<YouRockAppState>({ showYouRock: false }),
	teamsService,
	productsViewModel,
	trelloOptions: env.trelloOptions,
	slackClientId: fromNullable(env.slackClientId),
	websiteUrl: env.websiteUrl,
	domContext: exportDomContext,
});

ReactDOM.render(
	<DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
		<App />
	</DndProvider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
