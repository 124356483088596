import React, { Fragment } from 'react';
import cn from 'classnames';
import style from './ResolveBar.module.scss';
import {
	Button,
	Col,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	UncontrolledDropdown,
} from 'reactstrap';
import { StatusSelector } from './StatusSelector';
import { NoteStatus, TProductImage } from 'volley-common/dist/services/products.service';
import { PaidOption } from 'volley-common/dist/components/PaidOption';
import { Option } from 'fp-ts/lib/Option';

interface ResolveBarProps {
	tablet?: boolean;
	note: Option<TProductImage>;
	onSetNoteStatus?: (noteId: number, val: number) => void;
	/** Should either show the delete confirmation or propose an upgrade */
	onDeleteNote: (note: TProductImage) => void;
	onToggleNotesBar: () => void;
	statuses: NoteStatus[];
}

export const ResolveBar = ({
	tablet,
	note,
	onDeleteNote,
	onSetNoteStatus,
	onToggleNotesBar,
	statuses,
}: ResolveBarProps) => {
	const resolveButton = (note: TProductImage) => (
		<StatusSelector
			options={statuses}
			value={note.status_id}
			onChange={(val: number) => onSetNoteStatus?.(note.id, val)}
		/>
	);
	const canDelete = !!onDeleteNote;
	const noteActions = (note: TProductImage) => (
		<UncontrolledDropdown className="float-right">
			<DropdownToggle color="secondary" className={'btn-icon'}>
				<i className="material-icons">more_horiz</i>
			</DropdownToggle>
			<DropdownMenu right>
				{note.source?.length > 0 && (
					<Fragment>
						<DropdownItem tag="a" href={note.source} rel="noopener noreferrer" target="_blank">
							<i className="material-icons-outlined icon-left">launch</i> Go to page
						</DropdownItem>
						<DropdownItem divider />
					</Fragment>
				)}
				<PaidOption
					className="text-danger"
					isUnlocked={canDelete}
					onAllowed={() => onDeleteNote(note)}
					onLocked={() => onDeleteNote(note)}>
					<i className="material-icons-outlined icon-left">delete</i> Delete note
				</PaidOption>
			</DropdownMenu>
		</UncontrolledDropdown>
	);

	if (tablet) {
		return (
			<div className={cn(style.noteNav, 'note-nav border-bottom')}>
				<Container fluid>
					<Row>
						<Col xs={8}>
							<div className={cn('inline-block align-middle menu-button', style.showNotesToggle)}>
								<Button color="secondary" tag="a" onClick={onToggleNotesBar}>
									<i className="material-icons">menu</i>
								</Button>
							</div>{' '}
							<div className="inline-block">{note.fold(null, resolveButton)}</div>
						</Col>
						<Col xs={4}>
							<div className="float-right">{note.fold(null, noteActions)}</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	} else {
		return (
			<div className={cn('resolved-header', style.commentsContainer__resolvedHeader)}>
				<div className="padded">
					<div className="row align-items-center">
						<div className="col-7">{note.fold(null, resolveButton)}</div>
						<div className="col">{note.fold(null, noteActions)}</div>
					</div>
				</div>
			</div>
		);
	}
};
