import React, { FC } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import logoImg from 'volley-common/dist/assets/images/logo.svg';
import { routes } from 'volley-common/dist/utils/routes';

export type TPageNotFoundProps = {
	isLoggedIn?: boolean;
};

export const PageNotFound: FC<TPageNotFoundProps> = ({ isLoggedIn }) => (
	<div className="review-container unavailable">
		<div className="note-container">
			<div className="note-body">
				<div className="note-nav border-bottom">
					<Container fluid>
						<Row>
							<Col cols={8}>
								<div className="inline-block align-middle show-notes-toggle menu-button">
									<a className="btn btn-secondary" href="#">
										<i className="material-icons">menu</i>
									</a>
								</div>
							</Col>
							<Col cols={4} />
						</Row>
					</Container>
				</div>
				<div className="note-info">
					<div className="note-zero">
						<Link className="margin-auto double-margin-bottom" to={routes.dashboard}>
							<img src={logoImg} width={150} />
						</Link>
						<div className="note-zero-text text-center">
							<h2>Page not found</h2>
							<p>
								<Link to={isLoggedIn ? routes.dashboard : routes.root}>Go to homepage</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);
