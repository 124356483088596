import { useVideoJS } from 'react-hook-videojs';
import React, { memo, useCallback, useEffect } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import css from './VideoPlayer.module.scss';
import playIconSvg from 'volley-common/dist/assets/images/play-icon.svg';

interface VideoPlayerProps {
	src: string;
	duration: number;
}

const OriginalBigPlayButton = videojs.getComponent('BigPlayButton');
videojs.registerComponent(
	'BigPlayButton',
	class VolleyBigPlayButton extends OriginalBigPlayButton {
		createEl(tag: any, props: any, attributes: any) {
			const el = super.createEl(tag, props, attributes);
			el.className += ' ' + css.playButton;
			const icon = document.createElement('img');
			icon.src = playIconSvg;
			el.querySelector('.vjs-icon-placeholder')?.appendChild(icon);

			el.addEventListener('mouseenter', e => {
				this.player().trigger('VolleyBigPlayButton.mouseenter');
			});
			el.addEventListener('mouseleave', e => {
				this.player().trigger('VolleyBigPlayButton.mouseleave');
			});

			return el;
		}
	},
);

const Component = videojs.getComponent('Component');
videojs.registerComponent(
	'BigPlayButtonBackdrop',
	class BigPlayButtonBackdrop extends Component {
		createEl(tag: any, props: any, attributes: any) {
			const el = super.createEl(tag, props, attributes);
			el.className = css.playButtonBackdrop;
			this.handleEnter = this.handleEnter.bind(this);
			this.handleLeave = this.handleLeave.bind(this);
			this.player().on('VolleyBigPlayButton.mouseenter', this.handleEnter);
			this.player().on('VolleyBigPlayButton.mouseleave', this.handleLeave);
			return el;
		}

		dispose() {
			this.player().off('VolleyBigPlayButton.mouseenter', this.handleEnter);
			this.player().off('VolleyBigPlayButton.mouseleave', this.handleLeave);
			super.dispose();
		}

		private handleEnter() {
			this.el().classList.add(css.playButtonBackdrop_hover);
		}

		private handleLeave() {
			this.el().classList.remove(css.playButtonBackdrop_hover);
		}
	},
);

export const VideoPlayer = memo<VideoPlayerProps>(({ src, duration }) => {
	const { Video, ready, player } = useVideoJS(
		{
			fluid: true,
			responsive: true,
			preload: 'metadata',
			controls: true,
			sources: [{ src }],
			// copied from the original source code, plus bigPlayButtonBackdrop
			children: [
				'mediaLoader',
				'posterImage',
				'textTrackDisplay',
				'loadingSpinner',
				'bigPlayButton',
				'liveTracker',
				'controlBar',
				'errorDisplay',
				'textTrackSettings',
				'resizeManager',
				'bigPlayButtonBackdrop',
			],
		},
		'vjs-theme-fantasy',
	);

	const fixDuration = useCallback(
		function (this: VideoJsPlayer, e: Event) {
			if (duration) {
				console.log('[VideoPlayer] got duration change event: ', this.duration());
				if (!Number.isFinite(this.duration())) {
					this.duration(duration);
				}
			}
		},
		[duration],
	);

	useEffect(() => {
		if (ready && player) {
			const handler = fixDuration;
			player.on('durationchange', handler);
			return () => {
				player.off('durationchange', handler);
			};
		}
	}, [ready, player, fixDuration]);

	/*useEffect(() => {
		if (ready && player) {
			const handler = function (this: VideoJsPlayer, e: any) {
				console.log('Video Error', this, arguments, this.error());
			};
			player.on('error', handler);
			return () => {
				player.off('error', handler);
			};
		}
	}, [ready, player]);*/

	return (
		<div className={css.container}>
			<Video />
		</div>
	);
});
