import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial } from '@devexperts/remote-data-ts/dist/remote-data';
import { Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'volley-common/dist/services/auth.service';
import Login, { TLoginData } from './Login';
import { ask } from 'fp-ts/lib/Reader';
import { SessionServiceClass } from '../../services/token.service';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { isSome } from 'fp-ts/lib/Option';

export type TLoginContainerContext = {
	authService: AuthService;
	sessionService: SessionServiceClass;
};

export const LoginContainer = combineReader(Login, ask<TLoginContainerContext>(), (Login, ctx) => {
	return withRX(Login)(() => {
		const loginRequest$ = new Subject<TLoginData>();
		const loginResult$ = loginRequest$.pipe(
			switchMap(data => ctx.authService.login(data.email, data.password)),
			map(result => result.map(_ => true)),
		);

		return {
			defaultProps: {
				onTryLogin: (data: TLoginData) => loginRequest$.next(data),
				result: initial,
				isLoggedIn: false,
			},
			props: {
				result: loginResult$,
				isLoggedIn: ctx.sessionService.token$.pipe(map(isSome)),
			},
		};
	});
});
