import { RemoteData } from '@devexperts/remote-data-ts';
import React, { memo, useEffect, useState } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { Modal } from '../../../components/Modal';
import { Column } from '../review/BoardView';
import { StatusEditor } from './StatusEditor';

export interface StatusEditorModalProps {
	value: Column[];
	onSave: (state: Column[]) => void;
	result: RemoteData<Error, void>;
	onClose: () => void;
	isOpen: boolean;
	canDelete?: (id: number) => Promise<boolean | string>;
}

export const StatusEditorModal = memo<StatusEditorModalProps>(
	({ value: initialValue, isOpen, onClose, result, onSave, canDelete }) => {
		const [value, onChange] = useState(() => initialValue.map(s => ({ ...s })));
		useEffect(() => {
			onChange(initialValue.map(s => ({ ...s })));
		}, [initialValue]);
		return (
			<Modal isOpen={isOpen}>
				<ModalHeader tag="h2" toggle={onClose} className="double-padding-left">
					Edit status
				</ModalHeader>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<StatusEditor value={value} onChange={onChange} canDelete={canDelete} />
					</div>
				</ModalBody>
				<ModalFooter className="no-border-top no-padding-top">
					<Button color="link" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton pending={result.isPending()} onClick={() => onSave(value)} color="primary">
						Save changes
					</LoadingButton>
				</ModalFooter>
			</Modal>
		);
	},
);
