import React, { Fragment, ReactElement, ReactNode } from 'react';
import cn from 'classnames';
import { Option } from 'fp-ts/lib/Option';
import { firstTimeUpgradeRoute } from '../../../models/profile.utils';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import css from './MaxNotesLimit.module.scss';
import { SubscriptionViewContainer } from '../../subscribe/SubscriptionViewContainer';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';

interface MaxNotesLimitProps {
	value: Option<{
		mode: 'owner' | 'guest';
		limit: number;
	}>;
	offerFirstTimeUpgrade: boolean;
	onSetSubscriptionOverlay: (elem: ReactElement | undefined) => void;
	className?: string;
	inlineButton?: boolean;
}

export const MaxNotesLimit = combineReader(
	SubscriptionViewContainer,
	SubscriptionViewContainer =>
		({ value, offerFirstTimeUpgrade, onSetSubscriptionOverlay, className, inlineButton }: MaxNotesLimitProps) => {
			const onUpgrade = () =>
				onSetSubscriptionOverlay(
					<SubscriptionViewContainer
						subtitle={`You've reached your ${value.fold<ReactNode>(
							'',
							({ limit }) => limit,
						)} notes limit. Please upgrade for more notes`}
						onClose={() => onSetSubscriptionOverlay(undefined)}
					/>,
				);

			return value.fold(<Fragment />, ({ mode, limit }) => {
				const upgradeBtn =
					mode === 'owner' &&
					(offerFirstTimeUpgrade ? (
						<Link className={cn('btn btn-sm', !inlineButton && 'btn-success')} to={firstTimeUpgradeRoute}>
							Start free trial
						</Link>
					) : (
						<Button color={inlineButton ? 'default' : 'success'} size="sm" onClick={onUpgrade}>
							See upgrade options
						</Button>
					));
				return (
					<div className={cn('notes-footer-upgrade-promo', css.noteLimitAlert, className)}>
						<div className="alert-container">
							<div className="alert alert-warning no-margin">
								<div className="text-center">
									<h5 className="bold-font text-color">View all your notes</h5>
									<h5 className="text-color" style={{ fontSize: 12 }}>
										{mode === 'owner'
											? `To unlock unlimited notes, rather than just the first ${limit}, upgrade to a paid plan.`
											: `To unlock unlimited notes, rather than just the first ${limit}, the owner of this project can upgrade to a paid plan.`}
										{inlineButton && <Fragment>&nbsp;{upgradeBtn}</Fragment>}
									</h5>
									{!inlineButton && upgradeBtn}
								</div>
							</div>
						</div>
					</div>
				);
			});
		},
);
