import React, { memo, useState, Fragment } from 'react';
import { ButtonGroup, DropdownToggle, Button, DropdownMenu, Dropdown } from 'reactstrap';
import {
	TMember,
	TNotePriority,
	defaultNoteFilter,
	NoteFilterValue,
	NoteStatus,
} from 'volley-common/dist/services/products.service';
import cn from 'classnames';
import { formatStatusName, formatStatusIcon, formatPriorityIcon } from './Review';
import { formatPriorityName } from './review.utils';
import { empty } from 'fp-ts/lib/Array';
import css from './NoteFilter.module.scss';
import { Avatar } from 'volley-common/dist/components/Avatar';
import avatarUnassigned from 'volley-common/dist/assets/images/avatar-unassigned.svg';
import { TCommentAuthor } from 'volley-common/dist/services/notes.service';

interface NoteFilterProps {
	value: NoteFilterValue;
	onChange: (val: NoteFilterValue) => void;
	teamMembers?: TMember[];
	statuses: NoteStatus[];
}

export function renderAssigneeAvatar(assignee: TCommentAuthor | undefined, size: 'xs' | 'xxs' = 'xs') {
	if (!assignee) {
		return <img className="align-middle no-margin" src={avatarUnassigned} width={size === 'xxs' ? 20 : 30} />;
	} else {
		return <Avatar size={size} user={assignee} className="font-weight-normal d-inline-block" />;
	}
}

const priorityOptions: TNotePriority[] = ['none', 'low', 'medium', 'high', 'urgent'];

export const NoteFilter = memo<NoteFilterProps>(({ value, onChange, teamMembers = empty, statuses }) => {
	const disableStatus = statuses.length === 0;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(prevState => !prevState);
	const toggleStatus = (val: number) => () =>
		onChange({
			...value,
			status: disableStatus
				? []
				: value.status.includes(val)
				? value.status.filter(v => v !== val)
				: [...value.status, val],
		});
	const togglePriority = (val: TNotePriority) => () =>
		onChange({
			...value,
			priority: value.priority.includes(val) ? value.priority.filter(v => v !== val) : [...value.priority, val],
			status: disableStatus ? [] : value.status,
		});
	const toggleAssignee = (val: number | null) => () =>
		onChange({
			...value,
			assignee: value.assignee.includes(val) ? value.assignee.filter(v => v !== val) : [...value.assignee, val],
			status: disableStatus ? [] : value.status,
		});
	const filtersNumber = value.status.length + value.priority.length + value.assignee.length;

	return (
		<Dropdown group isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle tag="span">
				<ButtonGroup>
					<Button color="secondary" className="filter-button dropdown-action">
						<span className="light-text-color">
							<div className="material-icons">filter_list</div>
							{filtersNumber > 0 ? filtersNumber : null}
						</span>
					</Button>
					{filtersNumber > 0 && (
						<Button
							color="secondary"
							className="btn-icon filter-button"
							onClick={e => {
								e.stopPropagation();
								onChange(defaultNoteFilter);
							}}>
							<span className="light-text-color">
								<div className="material-icons">clear</div>
							</span>
						</Button>
					)}
				</ButtonGroup>
			</DropdownToggle>

			<DropdownMenu style={{ width: 250 }} positionFixed className="max-height-dropdown filter-dropdown" right>
				<h6 className="dropdown-header dropdown-header-white padding-bottom">Filter notes by:</h6>
				{!disableStatus && (
					<Fragment>
						<h6 className="dropdown-header">Status</h6>
						<div className="quarter-padding-top quarter-padding-bottom">
							{statuses.map(opt => (
								<div
									key={opt.id}
									onClick={toggleStatus(opt.id)}
									className={cn(
										css.option,
										'dropdown-item checkbox-filter-item',
										value.status.includes(opt.id) && 'active',
									)}>
									<div className={cn('d-inline-block', css.statusIcon)}>
										{formatStatusIcon(opt, statuses)}&nbsp;
									</div>
									<div className="d-inline-block">{formatStatusName(opt)}</div>
								</div>
							))}
						</div>
					</Fragment>
				)}
				<h6 className="dropdown-header">Priority</h6>
				<div className="quarter-padding-top quarter-padding-bottom">
					{priorityOptions.map(opt => (
						<div
							key={opt}
							onClick={togglePriority(opt)}
							className={cn(
								'dropdown-item checkbox-filter-item',
								value.priority.includes(opt) && 'active',
							)}>
							<div className="d-inline-block margin-right">
								<img
									src={formatPriorityIcon(opt)}
									className="d-inline-block align-middle no-margin float-right"
									style={{ position: 'relative', top: 5 }}
									width={16}
								/>
							</div>
							<div className="d-inline-block">{formatPriorityName(opt)}</div>
						</div>
					))}
				</div>
				{teamMembers.length > 0 && (
					<Fragment>
						<h6 className="dropdown-header">Assigned to</h6>
						<div className="quarter-padding-top quarter-padding-bottom">
							<div
								key={`member-unassigned`}
								onClick={toggleAssignee(null)}
								className={cn(
									'dropdown-item checkbox-filter-item',
									value.assignee.includes(null) && 'active',
								)}>
								<div className="d-inline-block half-margin-right">
									{renderAssigneeAvatar(undefined)}
								</div>{' '}
								Unassigned
							</div>
							{teamMembers.map(member => (
								<div
									key={`member-${member.id}`}
									onClick={toggleAssignee(member.id)}
									className={cn(
										'dropdown-item checkbox-filter-item',
										value.assignee.includes(member.id) && 'active',
									)}>
									<div className="d-inline-block half-margin-right">
										{renderAssigneeAvatar(member)}
									</div>{' '}
									{member.name}
								</div>
							))}
						</div>
					</Fragment>
				)}
			</DropdownMenu>
		</Dropdown>
	);
});
