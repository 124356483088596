import React, { Fragment, MouseEventHandler, ReactNode, ChangeEvent, useState, FC } from 'react';
import productsZeroImg from 'volley-common/dist/assets/images/products-zero.svg';
import noResultsSvg from 'volley-common/dist/assets/images/no-results.svg';
import teamZeroSvg from 'volley-common/dist/assets/images/team-zero.svg';
import defaultThumbnail from 'volley-common/dist/assets/images/default-thumbnail.png';
import {
	Container,
	Row,
	Col,
	Button,
	CardHeader,
	CardBody,
	CardText,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Alert,
	InputGroup,
	InputGroupAddon,
	Input,
	InputGroupText,
	Card,
} from 'reactstrap';
import { RemoteData } from '@devexperts/remote-data-ts/dist/remote-data';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import LoadingProduct from './LoadingProduct';
import style from './Products.module.scss';
import cn from 'classnames';
import { HeaderContainer } from '../header/HeaderContainer';
import { Link, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { none, Option, some } from 'fp-ts/lib/Option';
import { ShareFormContainer } from '../product/share/ShareFormContainer';
import { TProduct, isArchived } from 'volley-common/dist/services/products.service';
import { TUpdateProductRequest, EditProjectPopup } from './EditProjectPopup';
import { AddProjectForm } from './AddProductForm';
import { not, Lazy } from 'fp-ts/lib/function';
import { Confirmation, YesNoConfirmation } from '../../components/Confirmation';
import { SubscriptionViewContainer } from '../subscribe/SubscriptionViewContainer';
import { Alerts } from '../alerts/Alerts';
import { routes } from 'volley-common/dist/utils/routes';
import { sortBy1, isEmpty } from 'fp-ts/lib/Array';
import { contramap, ordDate, ordString, getDualOrd } from 'fp-ts/lib/Ord';
import { MoveProjectPopup, MoveProjectRequest } from './MoveProjectPopup';
import { Team } from 'volley-common/dist/services/teams.service';
import { TransferOwnership, TransferOwnershipRequest } from './TransferOwnership';
import { TFileData } from 'volley-common/dist/utils/axios';

export type TProductsProps = {
	products: RemoteData<Error, TProduct[]>;
	onStartBrowsing?: (url: string) => void;
	onSetEditingProduct: (product: Option<TProduct>) => void;
	onSetMovedProduct: (product: Option<TProduct>) => void;
	onSetTransferredProduct: (product: Option<TProduct>) => void;
	onAddProduct: (data: { name: string; files?: TFileData[] }) => void;
	onMoveProduct: (request: MoveProjectRequest) => void;
	onTransferProduct: (request: TransferOwnershipRequest) => void;
	onDeleteProduct?: (id: number) => void;
	onLeaveProduct?: (id: number) => void;
	onUpdateProduct: (data: TUpdateProductRequest) => void;
	onArchiveProduct: (data: TArchiveRequest) => void;
	onGoToProduct?: (product: TProduct) => void;
	onReloadProducts?: () => void;
	onResendConfirmationEmail?: Lazy<void>;
	userId: Option<number>;
	updateProductResult: RemoteData<Error, unknown>;
	addProductResult: RemoteData<Error, unknown>;
	activationResult: RemoteData<unknown, unknown>;
	activationResultShown: boolean;
	resendConfirmationResult: RemoteData<unknown, unknown>;
	passwordResultShown: boolean;
	needActivation: boolean;
	editingProduct: Option<TProduct>;
	movedProduct: Option<TProduct>;
	moveProductResult: RemoteData<Error, unknown>;
	transferredProduct: Option<TProduct>;
	transferProductResult: RemoteData<Error, unknown>;
	canCreateMoreProjects: boolean;
	canEditProject: (project: TProduct) => boolean;
	maxProjects: Option<number>;
	teamId: Option<number>;
	teams: RemoteData<Error, Team[]>;
	eligibleForFreeTrial?: boolean;
};

export type TArchiveRequest = {
	archived: boolean;
	productId: number;
};

type TSortBy = 'recent' | 'alphanumeric';
type TProductsState = {
	sharingProduct: Option<number>;
	archiveConfirmation: Option<TArchiveRequest>;
	leaveConfirmation: Option<{
		type: 'delete' | 'leave';
		product: TProduct;
	}>;
	subscriptionOverlay: ReactNode;
	sortBy: TSortBy;
	filterBy: string;
};

const ordProjectTime = getDualOrd(contramap((project: TProduct) => project.created_at, ordDate));
const ordProjectName = contramap((project: TProduct) => project.name.toLowerCase(), ordString);

export const Products = combineReader(
	HeaderContainer,
	ShareFormContainer,
	SubscriptionViewContainer,
	Alerts,
	AddProjectForm,
	(
		HeaderContainer,
		ShareFormContainer,
		SubscriptionViewContainer,
		Alerts,
		AddProjectForm,
	): FC<TProductsProps & RouteComponentProps> =>
		function Products(props) {
			const [state, setState] = useState<TProductsState>({
				sharingProduct: none,
				archiveConfirmation: none,
				leaveConfirmation: none,
				subscriptionOverlay: undefined,
				sortBy: 'recent',
				filterBy: '',
			});

			const sharingProduct = state.sharingProduct.chain(id =>
				props.products.toOption().mapNullable(products => products.find(p => p.id === id)),
			);
			const userId = props.userId.toNullable();

			const handleCancelArchiving = () => {
				setState(prev => ({ ...prev, archiveConfirmation: none }));
			};

			const handleConfirmArchiving = () => {
				state.archiveConfirmation.map(props.onArchiveProduct);
				setState(prev => ({ ...prev, archiveConfirmation: none }));
			};

			const handleSetFilterBy = (e: ChangeEvent<HTMLInputElement>) => {
				const filterBy = e.target.value;
				setState(prev => ({ ...prev, filterBy }));
			};

			const handleSort = (sortBy: TSortBy) => () => setState(prev => ({ ...prev, sortBy }));

			const handleHideSubscriptionView = () => {
				setState(prev => ({ ...prev, subscriptionOverlay: undefined }));
			};

			const suggestUpgradeForMoreProjects = () => {
				setState(prev => ({
					...prev,
					subscriptionOverlay: (
						<SubscriptionViewContainer
							subtitle={`You've reached your ${props.maxProjects.fold(
								'',
								String,
							)} project limit. You can either archive or delete a project, or upgrade for more projects!`}
							onClose={handleHideSubscriptionView}
						/>
					),
				}));
			};

			const handleCheckCreateProject = () => {
				if (props.canCreateMoreProjects) {
					return true;
				} else {
					suggestUpgradeForMoreProjects();
					return false;
				}
			};

			const handleCreateProject = (name: string, files?: TFileData[]) => {
				if (handleCheckCreateProject()) {
					props.onAddProduct({ name, files });
				}
			};

			const handleArchiveProject = (cmd: { productId: number; archived: boolean }) => {
				if (cmd.archived || props.canCreateMoreProjects) {
					setState(prev => ({ ...prev, archiveConfirmation: some(cmd) }));
				} else {
					suggestUpgradeForMoreProjects();
				}
			};

			const handleDelete = () => {
				const { onDeleteProduct } = props;
				onDeleteProduct && state.leaveConfirmation.map(r => onDeleteProduct(r.product.id));
				setState(prev => ({ ...prev, leaveConfirmation: none }));
			};

			const handleLeave = () => {
				const { onLeaveProduct } = props;
				onLeaveProduct && state.leaveConfirmation.map(r => onLeaveProduct(r.product.id));
				setState(prev => ({ ...prev, leaveConfirmation: none }));
			};

			const handleCloseShare = () => {
				setState(prev => ({ ...prev, sharingProduct: none }));
			};

			const handleCloseEdit = () => {
				props.onSetEditingProduct(none);
			};

			const renderPasswordResetStatus = () => {
				return (
					<Alert color="success" className="text-center" isOpen={props.passwordResultShown}>
						Password changed successfully.
					</Alert>
				);
			};

			const handleGoToProduct = (e: React.MouseEvent, product: TProduct) => {
				const { onGoToProduct } = props;
				if (onGoToProduct) {
					e.preventDefault();
					if (!isArchived(product)) {
						onGoToProduct(product);
					}
				}
			};

			const handleResendConfirmationEmail: MouseEventHandler = e => {
				e.preventDefault();
				if (!props.resendConfirmationResult.isPending()) {
					props.onResendConfirmationEmail && props.onResendConfirmationEmail();
				}
			};

			const handleShare = (product: TProduct) => {
				setState(prev => ({ ...prev, sharingProduct: some(product.id) }));
			};

			const handleEdit = (product: TProduct) => {
				props.onSetEditingProduct(some(product));
			};

			const handleMove = (product: TProduct) => {
				props.onSetMovedProduct(some(product));
			};

			const handleProductDropdown: React.MouseEventHandler<any> = e => {
				e.stopPropagation();
			};

			const handleUnarchiveOverlayBtn = (e: React.MouseEvent, product: TProduct) => {
				e.stopPropagation();
				e.preventDefault();
				handleArchiveProject({
					productId: product.id,
					archived: false,
				});
			};

			const renderConfirmations = () => {
				const { archiveConfirmation: confirmation, leaveConfirmation: leave } = state;
				return (
					<Fragment>
						<Confirmation
							isOpen={confirmation.exists(r => r.archived)}
							onClose={handleCancelArchiving}
							title="Confirm archive project"
							actions={
								<Fragment>
									<Button color="link" onClick={handleCancelArchiving}>
										Cancel, don't archive
									</Button>
									<Button color="primary" onClick={handleConfirmArchiving}>
										Yes, archive
									</Button>
								</Fragment>
							}>
							<p>Are you sure you want to continue? No one will be able to view notes in this project.</p>
						</Confirmation>
						<Confirmation
							isOpen={confirmation.exists(r => !r.archived)}
							onClose={handleCancelArchiving}
							title="Unarchive project"
							actions={
								<Fragment>
									<Button color="link" onClick={handleCancelArchiving}>
										Cancel
									</Button>
									<Button color="primary" onClick={handleConfirmArchiving}>
										Yes, unarchive
									</Button>
								</Fragment>
							}>
							<p>Are you sure that you want to activate this project?</p>
						</Confirmation>
						<YesNoConfirmation
							isOpen={leave.isSome()}
							title={leave
								.map(r => (r.type === 'leave' ? 'Leaving project' : 'Deleting project'))
								.toUndefined()}
							onConfirm={leave.map(r => (r.type === 'leave' ? handleLeave : handleDelete)).toUndefined()}
							onCancel={() => setState(prev => ({ ...prev, leaveConfirmation: none }))}
							onClose={() => setState(prev => ({ ...prev, leaveConfirmation: none }))}>
							<p>
								{leave
									.map(r =>
										r.type === 'leave'
											? `Are you sure? All your notes and comments will remain visible.`
											: `Are you sure you want to delete this project: ${r.product.name}?`,
									)
									.toUndefined()}
							</p>
						</YesNoConfirmation>
					</Fragment>
				);
			};

			const renderProducts = (products: TProduct[]) => {
				const userId = props.userId.getOrElse(NaN);
				const moveTargetCandidates = props.teams
					.toOption()
					.fold([], teams => teams.filter(team => team.owner.id === userId));

				return (
					<section>
						<Container className="product-card-container">
							<Row>
								{products.map(product => {
									const canEdit = props.canEditProject(product);
									const isPersonal = !product.team;
									const isOwnProduct =
										(product.team ? product.team.owner.id : product.owner?.id) === userId;
									const canMove =
										canEdit && isOwnProduct && (moveTargetCandidates.length > 0 || !isPersonal);
									const canTransfer = canEdit && isPersonal;
									const isArchive = isArchived(product);
									return (
										<Col key={product.id} sm={12} md={12} lg={6}>
											<div className="product-card-wrapper">
												<Link
													className="card product-card"
													to={routes.product(product.id)}
													onClick={e =>
														isArchive ? e.preventDefault() : handleGoToProduct(e, product)
													}
													key={product.id}>
													<CardHeader
														className={product.thumbnail ? 'no-padding-bottom' : undefined}>
														<div className="thumbnail-wrapper">
															<div className="thumbnail-wrapper">
																<div
																	className="thumbnail"
																	style={{
																		backgroundImage: `url('${
																			product.thumbnail || defaultThumbnail
																		}')`,
																	}}
																/>
															</div>
															{canEdit && isArchive && (
																<div className="overlay">
																	<div className="actions">
																		<Button
																			color="wire"
																			onClick={e =>
																				handleUnarchiveOverlayBtn(e, product)
																			}>
																			Unarchive
																		</Button>
																	</div>
																</div>
															)}
														</div>
													</CardHeader>
													<CardBody>
														<Row>
															<Col xs={8}>
																<h3 className="card-url no-margin-bottom">
																	{product.name
																		? product.name
																		: `Product #${product.id}`}
																</h3>
																<CardText tag="h4" className="card-text">
																	Created {formatCreateDate(product.created_at)}
																</CardText>
															</Col>
															<Col xs={4} />
														</Row>
													</CardBody>
												</Link>
												<div className={style.cardActions}>
													<UncontrolledDropdown className="text-right">
														<DropdownToggle
															className={cn(
																style.cardActions__button,
																'btn-flat btn-icon',
															)}
															onClick={handleProductDropdown}>
															<i className="material-icons">more_horiz</i>
														</DropdownToggle>
														<DropdownMenu right>
															{canEdit && !isArchive && (
																<DropdownItem onClick={() => handleEdit(product)}>
																	Edit
																</DropdownItem>
															)}
															{!isArchive && (
																<DropdownItem onClick={() => handleShare(product)}>
																	Share
																</DropdownItem>
															)}
															{canMove && !isArchive && (
																<DropdownItem onClick={() => handleMove(product)}>
																	Move...
																</DropdownItem>
															)}
															{canTransfer && !isArchive && (
																<DropdownItem
																	onClick={() =>
																		props.onSetTransferredProduct(some(product))
																	}>
																	Transfer ownership
																</DropdownItem>
															)}
															{canEdit && (
																<DropdownItem
																	onClick={() =>
																		handleArchiveProject({
																			productId: product.id,
																			archived: !isArchive,
																		})
																	}>
																	{isArchive ? 'Unarchive' : 'Archive'}
																</DropdownItem>
															)}
															{(canEdit || !isArchive) && <DropdownItem divider />}
															<DropdownItem
																onClick={() =>
																	setState(prev => ({
																		...prev,
																		leaveConfirmation: some({
																			type: canEdit ? 'delete' : 'leave',
																			product,
																		}),
																	}))
																}
																className={style.deleteAction}>
																{canEdit ? 'Delete' : 'Leave project'}
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</div>
											</div>
										</Col>
									);
								})}
							</Row>
						</Container>
					</section>
				);
			};

			const renderNoProducts = (isArchive: boolean) => {
				if (!isArchive && props.teamId.isSome()) {
					return renderNoTeamProducts();
				}

				return (
					<section>
						<Container className={style.productCardContainer}>
							<Row>
								<Col lg={12}>
									<div className="card">
										<div className="padded double-padding-top double-padding-bottom">
											<div className="double-padding-top double-padding-bottom">
												<div className="text-center">
													{isArchive ? (
														<Fragment>
															<img
																className="margin-bottom double-margin-bottom"
																src={productsZeroImg}
																width="204"
															/>
															<h2>No projects in your archive yet</h2>
															<p className="light-text-color no-margin-bottom">
																Once you archive a project, it will appear here.
															</p>
														</Fragment>
													) : (
														<Fragment>
															<div className="projects-video-wrapper">
																<div className="embed-responsive embed-responsive-16by9">
																	<iframe
																		className="embed-responsive-item"
																		src="https://www.youtube.com/embed/mhWBuPi8uwA"
																	/>
																</div>
															</div>
															<h2>Welcome to Volley!</h2>
															<p className="light-text-color no-margin-bottom">
																To get started, Watch the 1 minute video above or create
																your first project.
															</p>
														</Fragment>
													)}
												</div>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				);
			};

			const renderRoute = (type: 'active' | 'archive') => {
				let pageProducts: TProductsProps['products'];
				switch (type) {
					case 'archive':
						pageProducts = props.products.map(products => products.filter(isArchived));
						break;
					default:
						pageProducts = props.products.map(products => products.filter(not(isArchived)));
				}
				const filterTerm = state.filterBy.toLowerCase();
				const filtered = pageProducts
					.map(products => products.filter(p => p.name.toLowerCase().includes(filterTerm)))
					.map(sortBy1(state.sortBy === 'recent' ? ordProjectTime : ordProjectName, []));

				return (
					<Fragment>
						<Container className={cn(style.productCardContainer, 'padding-top')}>
							<Row>
								<Col sm={12}>
									<Alerts />
								</Col>
							</Row>
						</Container>
						{type === 'archive' && (
							<section className="no-padding-bottom">
								<Container className="product-card-container">
									<Row className="margin-bottom">
										<Col>
											<div className="page-header-for-title">
												<h2 className="medium-font title">Archive</h2>
											</div>
										</Col>
									</Row>
								</Container>
							</section>
						)}
						{type === 'active' && (
							<AddProjectForm
								type={type}
								teamId={props.teamId}
								result={props.addProductResult}
								onSave={handleCreateProject}
								canCreateProduct={handleCheckCreateProject}
							/>
						)}
						<section
							className={cn(
								'padding-top no-padding-bottom',
								!pageProducts.exists(not(isEmpty)) && 'd-none',
							)}>
							<Container className="product-card-container">
								<Row>
									<div className="col-6">
										<InputGroup className="simple-prepend">
											<InputGroupAddon addonType="prepend" />
											<InputGroupText>
												<div className="material-icons">search</div>
											</InputGroupText>
											<Input
												placeholder="Search..."
												onChange={handleSetFilterBy}
												value={state.filterBy}
											/>
										</InputGroup>
									</div>
									<div className="col-6">
										<div
											className="inline-block align-middle float-right"
											style={{ lineHeight: '36px' }}>
											<div className="inline-block align-middle">
												<h4 className="no-margin medium-font align-middle">
													Sort by{' '}
													<UncontrolledDropdown tag="span">
														<DropdownToggle
															tag="span"
															className="link-color activity-filter cursor-pointer">
															{state.sortBy === 'recent' ? 'Recent' : 'A-Z'}
															<span className="material-icons align-middle">
																expand_more
															</span>
														</DropdownToggle>
														<DropdownMenu>
															<DropdownItem onClick={handleSort('recent')}>
																Recent
															</DropdownItem>
															<DropdownItem onClick={handleSort('alphanumeric')}>
																A-Z
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</h4>
											</div>
										</div>
									</div>
								</Row>
							</Container>
						</section>
						<RenderRemoteData
							data={filtered}
							success={renderProducts}
							noData={products => products.length === 0}
							DataStateNoData={() =>
								filterTerm.length > 0
									? renderNoFilteredProducts()
									: renderNoProducts(type === 'archive')
							}
							DataStatePending={renderPending}
						/>
					</Fragment>
				);
			};

			const renderActivationStatus = () => {
				const isResetPending = props.resendConfirmationResult.isPending();
				return props.activationResult.foldL(
					() =>
						props.needActivation && (
							<Alert color="success" className="text-center">
								Please confirm your email address. A confirmation message was sent to your email.{' '}
								<a href="#" className="alert-link" onClick={handleResendConfirmationEmail}>
									{isResetPending ? 'Resending email...' : 'Resend email.'}
								</a>
							</Alert>
						),
					() => (
						<Alert color="secondary" className="text-center" isOpen={props.activationResultShown}>
							Confirming your email address...
						</Alert>
					),
					() => (
						<Alert color="danger" className="text-center" isOpen={props.activationResultShown}>
							Error: failed to confirm the email. Please try again later.
						</Alert>
					),
					() => (
						<Alert color="success" className="text-center" isOpen={props.activationResultShown}>
							Success: your email was confirmed
						</Alert>
					),
				);
			};

			return (
				<div id="dashboard-container">
					<HeaderContainer />
					{sharingProduct
						.map(product => (
							<ShareFormContainer
								onClose={handleCloseShare}
								product={product}
								onReloadProduct={props.onReloadProducts}
								onProjectLeft={props.onReloadProducts}
							/>
						))
						.toNullable()}
					{props.editingProduct
						.map(product => (
							<EditProjectPopup
								onClose={handleCloseEdit}
								product={product}
								onSave={props.onUpdateProduct}
								result={props.updateProductResult}
							/>
						))
						.toNullable()}
					{userId &&
						props.movedProduct
							.map(product => (
								<MoveProjectPopup
									onClose={() => props.onSetMovedProduct(none)}
									product={product}
									onSave={props.onMoveProduct}
									teams={props.teams}
									result={props.moveProductResult}
									userId={userId}
								/>
							))
							.toNullable()}
					{props.transferredProduct
						.map(product => (
							<TransferOwnership
								onClose={() => props.onSetTransferredProduct(none)}
								product={product}
								onSave={props.onTransferProduct}
								result={props.transferProductResult}
							/>
						))
						.toNullable()}
					<div className="main">
						{renderPasswordResetStatus()}
						{renderActivationStatus()}
						{renderConfirmations()}
						<Switch>
							<Route path={routes.dashboard} exact={true} render={() => renderRoute('active')} />
							<Route path={routes.dashboardWelcome} exact={true} render={() => renderRoute('active')} />
							<Route path={routes.dashboardArchive} exact={true} render={() => renderRoute('archive')} />
							<Route
								path={routes.teamDashboard.pattern}
								exact={true}
								render={() => renderRoute('active')}
							/>
							<Route
								path={routes.teamArchive.pattern}
								exact={true}
								render={() => renderRoute('archive')}
							/>

							<Redirect to={routes.dashboard} />
						</Switch>
					</div>
					{state.subscriptionOverlay}
				</div>
			);
		},
);

export function formatUrl(url: string): string {
	return url.replace(/^(?:https?:\/\/)?(?:www\.)?(.*?)(\/.*)?$/i, '$1');
}

function formatCreateDate(date: Date): string {
	return format(date.toString(), 'MMM D, YYYY');
}

function renderPending() {
	return (
		<section>
			<Container className={style.productCardContainer}>
				<Row>
					<Col sm={12} md={12} lg={6}>
						<LoadingProduct />
					</Col>
					<Col sm={12} md={12} lg={6}>
						<LoadingProduct />
					</Col>
				</Row>
			</Container>
		</section>
	);
}

function renderNoFilteredProducts() {
	return (
		<section>
			<Container className={style.productCardContainer}>
				<Row>
					<Col lg={12}>
						<div className="padded double-padding-top double-padding-bottom">
							<div className="double-padding-top double-padding-bottom">
								<div className="text-center">
									<img className="max-width double-margin-bottom" src={noResultsSvg} width={200} />
									<h2>No results found</h2>
									<p className="light-text-color no-margin-bottom">
										Try adjusting your search or filter to find what you're looking for.
									</p>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

function renderNoTeamProducts() {
	return (
		<section>
			<Container className={style.productCardContainer}>
				<Row>
					<Col lg={12}>
						<Card>
							<div className="padded double-padding-top double-padding-bottom">
								<div className="double-padding-top double-padding-bottom">
									<div className="text-center">
										<img className="double-margin-bottom max-width" src={teamZeroSvg} width={404} />
										<h2>No team projects... yet</h2>
										<p className="light-text-color no-margin-bottom">
											Once team members create projects, they’ll appear here
										</p>
									</div>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
