import * as React from 'react';
import { FC, useCallback } from 'react';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ExportData } from '../ExportPage';
import { RemoteData } from '@devexperts/remote-data-ts';
import { JiraProjectSelectorContainer } from './JiraProjectSelectorContainer';

type JiraExportProps = {
	onContinue: (data: ExportData) => void;
	exportResult: RemoteData<Error, unknown>;
	onBack: () => void;
	notes: TProductImage[];
	productId: number;
	integrationId: number;
};

export const JiraExport = combineReader(
	JiraProjectSelectorContainer,
	(JiraProjectSelectorContainer): FC<JiraExportProps> =>
		({ notes, onContinue, onBack, exportResult, integrationId, productId }) => {
			const handleContinue = useCallback(
				(projectKey: string) => {
					onContinue({
						note_ids: notes.map(note => note.id),
						jira_project_key: projectKey,
					} as any);
				},
				[onContinue, notes],
			);

			return (
				<JiraProjectSelectorContainer
					productId={productId}
					integrationId={integrationId}
					notes={notes}
					onContinue={handleContinue}
					onBack={onBack}
					isPending={exportResult.isPending()}
				/>
			);
		},
);
