import React, { FC, useCallback } from 'react';
import { initial, RemoteData } from '@devexperts/remote-data-ts';
// eslint-disable-next-line no-restricted-imports
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { TProduct } from 'volley-common/dist/services/products.service';
import { constVoid } from 'fp-ts/lib/function';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';

export interface TransferOwnershipRequest {
	productId: number;
	newOwner: string;
}

export interface TransferOwnershipProps {
	result?: RemoteData<Error, unknown>;
	onSave: (data: TransferOwnershipRequest) => void;
	onClose: () => void;
	product: TProduct;
}

type TFormType = { newOwner: '' };
const initialValue: TFormType = { newOwner: '' };

export const TransferOwnership: FC<TransferOwnershipProps> = ({
	product,
	onClose,
	onSave = constVoid,
	result = initial,
}) => {
	const handleSubmit = useCallback(
		(valuesObj: object) => {
			const { newOwner } = valuesObj as TFormType;
			onSave({
				productId: product.id,
				newOwner,
			});
		},
		[product.id, onSave],
	);

	const renderForm: FC<FormRenderProps> = ({ handleSubmit }) => {
		return (
			<form onSubmit={handleSubmit}>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<p>Which account do you want to transfer this project to?</p>
						<div className="margin-bottom">
							<Field
								name="newOwner"
								render={({ input }) => (
									<div className="margin-bottom">
										<Input
											bsSize="lg"
											className="mb-2"
											placeholder="Enter email..."
											invalid={result.isFailure()}
											{...input}
											type="text"
										/>
										<h5 className="light-text-color">Make sure the email is correct.</h5>
									</div>
								)}
							/>
						</div>
					</div>
				</ModalBody>

				<ModalFooter className="no-border-top">
					<Button color="link" onClick={onClose}>
						Cancel, do not transfer
					</Button>
					<LoadingButton
						color="primary"
						type="submit"
						disabled={result.isPending()}
						pending={result.isPending()}>
						Yes, transfer project
					</LoadingButton>
				</ModalFooter>
			</form>
		);
	};

	return (
		<Modal isOpen={true}>
			<ModalHeader toggle={onClose}>
				<h2 className="padding-left">Transfer ownership</h2>
			</ModalHeader>
			<Form initialValues={initialValue} onSubmit={handleSubmit} render={renderForm} />
		</Modal>
	);
};
