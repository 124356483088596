import React, { ComponentType, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Modal } from '../../components/Modal';
import css from './ProductImageUploader.module.scss';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import cn from 'classnames';
import { AttachmentPreview } from 'volley-common/dist/components/AttachmentPreview';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { map, distinctUntilChanged, switchMap, take, takeWhile, scan, concatMap, startWith } from 'rxjs/operators';
import { Observable, Subject, from, of } from 'rxjs';
import { splitPdf } from '../pdf-import/pdfjs-loader';
import { TFileData, readFiles } from 'volley-common/dist/utils/axios';
import { RemoteData, failure, initial, pending, success } from '@devexperts/remote-data-ts';
import { asks } from 'fp-ts/lib/Reader';
import { isDefined, mapRD, shareReplayRefCount } from 'volley-common/dist/utils/object.utils';

const acceptedFiles = ['image/png', 'image/jpeg', 'image/bmp', 'image/gif', 'application/pdf'];

interface ProductImageUploaderProps {
	visible: boolean;
	onCancel?: () => void;
	onConfirm?: (files: File[]) => void;
	pending?: boolean;
	status?: string;
}

export const ProductImageUploader = ({ onCancel, onConfirm, pending, visible, status }: ProductImageUploaderProps) => {
	const [files, setFiles] = useState<File[]>([]);
	const handleAttach = (files: File[]) => {
		setFiles(prev => [...prev, ...files]);
	};
	const removeAttach = (file: File) => setFiles(f => f.filter(f => f !== file));

	return (
		<Modal isOpen={visible} toggle={onCancel} onClosed={() => setFiles([])} className={css.modal}>
			<ModalHeader className="no-border-bottom" toggle={onCancel} />
			<ModalBody className="double-padded no-padding-top text-center">
				<h3 className={cn('double-margin-bottom', css.title)}>Upload images or PDFs</h3>
				<Dropzone onDropAccepted={handleAttach} accept={acceptedFiles}>
					{({ getRootProps, getInputProps }) => (
						<div {...getRootProps({ className: cn(css.dropZone, 'triple-margin-bottom') })}>
							<input {...getInputProps()} />
							<div className={css.icons}>
								<i className="material-icons-outlined">image</i>
								<i className="material-icons-outlined">image</i>
							</div>
							<p className={css.browse}>
								Drag and drop or <Button color="link">browse</Button>
							</p>
							<div className={css.previews} onClick={e => e.stopPropagation()}>
								{files.map((file, index) => (
									<AttachmentPreview file={file} key={index} onRemove={() => removeAttach(file)} />
								))}
							</div>
						</div>
					)}
				</Dropzone>
				<LoadingButton
					// pending={pending}
					className={css.upload}
					color="primary"
					size="lg"
					onClick={() => onConfirm?.(files)}
					disabled={pending || files.length === 0}>
					{pending && status ? status : 'Upload'}
				</LoadingButton>
			</ModalBody>
		</Modal>
	);
};

const isPdf = (f: File) => !!f.type?.match(/pdf/i);
type PrepareFileResult = RemoteData<unknown, Array<{ error: unknown } | TFileData>>;
function prepareFile(file: File): Observable<PrepareFileResult> {
	if (isPdf(file)) {
		const baseName = file.name.replace(/\.pdf$/i, '');
		return splitPdf(file).pipe(
			mapRD(files =>
				files.map((f, n) =>
					f.foldL<{ error: unknown } | TFileData>(
						() => ({ error: new Error() }),
						() => ({ error: new Error() }),
						error => ({ error }),
						data => ({ data, name: `${baseName}.${n + 1}.png` }),
					),
				),
			),
		);
	} else {
		const result: Promise<PrepareFileResult> = readFiles([file]).then(
			files => success<unknown, TFileData[]>(files),
			e => failure<unknown, TFileData[]>(e),
		);
		return from(result).pipe(startWith<PrepareFileResult>(pending));
	}
}

function prepareFiles(files: File[]) {
	return of(...files).pipe(
		concatMap((file, index) => prepareFile(file).pipe(map(result => ({ index, result })))),
		scan((acc, result) => {
			acc[result.index] = result.result;
			return acc;
		}, [] as PrepareFileResult[]),
		takeWhile(results => !isPrepareFilesCompleted(files.length, results), true),
	);
}

function isPrepareFilesCompleted(totalFiles: number, results: PrepareFileResult[]) {
	return results.length === totalFiles && results.every(r => r.isSuccess() || r.isFailure());
}

function describePrepareFilesResult(totalNum: number, results: PrepareFileResult[]) {
	const last = results[results.length - 1];
	if (last?.isPending()) {
		return last.progress.fold(`Processing file ${results.length} of ${totalNum}`, progress =>
			progress.total.fold(
				`Processing file ${results.length} of ${totalNum} (${progress.loaded})`,
				pages => `Processing file ${results.length} of ${totalNum} (${progress.loaded} / ${pages})`,
			),
		);
	} else if (results.length === totalNum) {
		return 'All files processed';
	} else {
		return `Processing file ${results.length + 1} of ${totalNum}`;
	}
}

export function deriveProjectNameFromFilename(fileName: string): string {
	return fileName;
}

interface CreateProjectFromImagesContext {}
type CreateProjectFromImagesProps = {
	visible: boolean;
	onSubmit?: (projectName: string, files: TFileData[]) => void;
	onCancel?: () => void;
};
export const CreateProjectFromImages = asks(
	(ctx: CreateProjectFromImagesContext): ComponentType<CreateProjectFromImagesProps> =>
		withRX(ProductImageUploader as ComponentType<ProductImageUploaderProps & CreateProjectFromImagesProps>)(
			props$ => {
				const submit$ = new Subject<File[]>();
				const lastProps$ = props$.pipe(shareReplayRefCount(1));
				const status$ = props$.pipe(
					map(p => p.visible),
					distinctUntilChanged(),
					switchMap(visible =>
						!visible
							? of(initial)
							: submit$.pipe(
									switchMap(files =>
										prepareFiles(files).pipe(
											switchMap(results => {
												const projectName = deriveProjectNameFromFilename(files[0].name);
												console.log('processing status', results);
												if (isPrepareFilesCompleted(files.length, results)) {
													const files = results.flatMap(
														r =>
															r
																.toNullable()
																?.filter((r): r is TFileData => 'data' in r) ?? [],
													);
													lastProps$.pipe(take(1)).subscribe(({ onSubmit }) => {
														onSubmit?.(projectName, files);
													});
													return of('Uploading...');
												} else {
													return of(describePrepareFilesResult(files.length, results));
												}
											}),
										),
									),
							  ),
					),
					shareReplayRefCount(1),
				);

				const statusText$ = status$.pipe(
					map(s => (typeof s === 'string' ? s : s.isPending() ? 'Uploading files...' : undefined)),
				);

				return {
					defaultProps: {
						pending: false,
						onConfirm: (files: File[]) => submit$.next(files),
					},
					props: {
						pending: statusText$.pipe(map(isDefined)),
						status: statusText$,
					},
					effects$: lastProps$,
				};
			},
		),
);
