import { none } from 'fp-ts/lib/Option';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { PDFViewer } from '@react-pdf/renderer';
import { loremIpsum } from 'lorem-ipsum';
import { PdfDocument } from './PdfDocument';

const mockNote: TProductImage = {
	browser: 'Safari',
	id: 1,
	product_id: 1,
	source: '',
	resolved: false,
	text: '',
	os: 'OS X 15.6',
	x: 100,
	y: 100,
	pixel_ratio: none,
	status_id: 1,
	priority: 'high',
	created_at: new Date('2022-08-31T12:34:56Z'),
};
let noteCtr = 1;
const avatar = (id: number) =>
	[
		undefined,
		`https://picsum.photos/seed/avatar${id}/200/200.jpg`,
		`https://picsum.photos/seed/avatar${id}/200/400.jpg`,
		`https://picsum.photos/seed/avatar${id}/400/200.jpg`,
	][id % 4];
const makeNote = (lorem: number, w: number, h: number): TProductImage => {
	const id = noteCtr++;
	return {
		...mockNote,
		id,
		source: 'www.google.com/link-with-a-really-longurl-goes-multiple-lines',
		// source: 'wwwgooglecomlinkwithareallylongurlgoesmultiplelines',
		author: {
			name: id > 1 ? 'Very Long Very Very Long Author Name Author Name Author Name' : undefined,
			profile: { avatar: avatar(id) },
		},
		assignee: {
			name: id > 1 ? 'Dmitry K' : undefined,
			profile: { avatar: avatar(id * 3) },
		},
		image: `https://picsum.photos/seed/n${id}/${w}/${h}.jpg`,
		priority: (['high', 'low', 'none', 'medium', 'urgent'] as const)[id % 5],
		text: loremIpsum({ count: lorem }),
	};
};

const mockNotes: TProductImage[] = [30, 15, 10, 2].flatMap(lorem => [
	makeNote(lorem, 1920, 1080),
	makeNote(lorem, 1920, 1920),
	makeNote(lorem, 1920, 400),
	makeNote(lorem, 400, 1920),
]);

export const PdfDemoPage = () => (
	<PDFViewer style={{ width: '100vw', height: '100vh', marginTop: -65 }}>
		<PdfDocument notes={mockNotes} productName="Mobile app review" />
	</PDFViewer>
);
