import { createAnonymousUser, isImageAttachment } from 'volley-common/dist/services/notes.service';
import cn from 'classnames';
import { NoteStatus, TAttachedFile, TProductImage } from 'volley-common/dist/services/products.service';
import imageIcon from 'volley-common/dist/assets/images/card-image-thumbnail.svg';
import { Media, Spinner } from 'reactstrap';
import { CommentNumber } from 'volley-common/dist/components/CommentNumber';
import { TextWithMentions } from 'volley-common/dist/components/Mentions';
import { Tooltip } from 'volley-common/dist/components/Tooltip';
import { formatAuthorName } from '../../../models/profile.utils';
import { formatPriorityIcon, formatStatusIcon, formatStatusName } from './Review';
import { renderAssigneeAvatar } from './NoteFilter';
import { formatPriorityName } from './review.utils';
import { TextLoading } from 'volley-common/dist/components/TextLoading';
import { Fragment, HTMLAttributes, memo, useEffect, useMemo, useRef, useState } from 'react';
import { format, isSameYear } from 'date-fns';
import { useDrag } from 'react-dnd';
import style from './NoteCard.module.scss';

interface NoteCardProps extends Omit<HTMLAttributes<HTMLElement>, 'onSelect'> {
	note: TProductImage;
	isSelected: boolean;
	onSelect: (note: TProductImage) => void;
	statuses: NoteStatus[];
}

export const NoteCard = ({ note, isSelected, onSelect, className, statuses, ...divProps }: NoteCardProps) => {
	const author = note.author || createAnonymousUser(note.fingerprint || String(note.id), note.external_author);
	const finalStatus = statuses[statuses.length - 1]?.id;
	return (
		<div
			className={cn(
				style.noteThumbnail,
				isSelected && style.noteThumbnail_active,
				note.status_id === finalStatus && style.noteThumbnail_resolved,
				className,
			)}
			{...divProps}
			key={note.id}
			onClick={() => onSelect(note)}>
			<Media>
				<CommentNumber
					number={note.number}
					isSmall
					className={cn('margin-right', style.noteThumbnail__number)}
				/>
				<Media body className={style.noteThumbnail__body}>
					<h5 className="bold-font quarter-margin-bottom">{formatAuthorName(author)}</h5>
					<h5>
						<TextWithMentions
							className={isSelected ? style.mention_active : undefined}
							text={note.text || 'No comment'}
						/>
					</h5>
					<div className={cn('note-status-preview d-inline-block half-margin-right', style.statusPreview)}>
						<Tooltip
							modifiers={{
								preventOverflow: {
									enabled: true,
									boundariesElement: 'viewport',
								},
							}}
							target=""
							trigger={<div className="inline-block">{formatStatusIcon(note.status_id, statuses)}</div>}>
							{formatStatusName(note.status_id, statuses)}
						</Tooltip>
					</div>
					<NoteAttributes note={note} />
				</Media>
			</Media>
		</div>
	);
};

export const NoteCardLoading = memo(() => (
	<div className={cn(style.noteThumbnail, style.noteThumbnail_pending)}>
		<Media>
			<CommentNumber
				isSmall
				className={cn('margin-right', style.noteThumbnail__number, style.note__number_pending)}
			/>
			<Media body className={style.noteThumbnail__body}>
				<h5 className="bold-font half-margin-bottom half-margin-top">
					<TextLoading />
				</h5>
				<h5 className={style.note__text_pending}>
					<TextLoading />
				</h5>
			</Media>
		</Media>
	</div>
));

export interface DraggableKanbanCard {
	id: number;
	previewData: NoteCardProps['note'];
}
export const DRAGGABLE_KANBAN_CARD = 'kanban-card';

export const BoardNoteCard = memo<Omit<NoteCardProps, 'statuses'>>(
	({ note, className, isSelected, onSelect, ...divProps }) => {
		const [drag, dragRef] = useDrag(() => ({
			type: DRAGGABLE_KANBAN_CARD,
			item: { id: note.id, previewData: note },
			collect: monitor => ({
				isDragged: monitor.isDragging(),
			}),
		}));
		const attachments = useMemo(() => {
			return note.image
				? [{ id: -1, file: note.image!, name: 'main' }, ...(note.attachments ?? [])]
				: note.attachments;
		}, [note]);

		const dragging = useRef(false);
		useEffect(() => {
			if (drag.isDragged) {
				dragging.current = true;
			} else {
				setTimeout(() => (dragging.current = false));
			}
		}, [drag.isDragged]);

		return (
			<div
				className={cn(style.boardCard, drag.isDragged && style.boardCard_dragged, className)}
				ref={dragRef}
				{...divProps}
				onClick={() => {
					!dragging.current && onSelect(note);
				}}>
				<h5 className="extra-light-text-color bold-font">
					{formatNoteDate(note.updated_at || note.created_at)}
				</h5>
				<h4 className={style.boardCardText}>
					<TextWithMentions text={note.text || 'No comment'} />
				</h4>
				<div className={style.boardCardFooter}>
					<div>
						<NoteAttributes note={note} />
					</div>
					<div>{attachments?.map(renderAttachment)}</div>
				</div>
			</div>
		);
	},
);

const maxAttachments = 3;
function renderAttachment(att: TAttachedFile, i: number, all: TAttachedFile[]) {
	if (i > maxAttachments) {
		return null;
	} else if (i === maxAttachments) {
		return (
			<span key="more" className={cn('light-text-color', style.previewMore)}>
				+{all.length - maxAttachments}
			</span>
		);
	}
	const isImage = isImageAttachment(att.file);
	const trigger = isImage ? (
		<img src={imageIcon} className={style.previewTrigger} />
	) : (
		<i className={cn('material-icons', style.previewTrigger)}>attachment</i>
	);
	return (
		<Tooltip
			// delay={{ show: 0, hide: 1000000000 }}
			key={att.id}
			placement="right-start"
			modifiers={{
				preventOverflow: {
					enabled: true,
					boundariesElement: 'viewport',
				},
			}}
			target=""
			className={style.imagePreviewTooltip}
			trigger={trigger}
			children={({ scheduleUpdate }) => <TooltipContent scheduleUpdate={scheduleUpdate} att={att} />}
		/>
	);
}

interface TooltipContentProps {
	att: TAttachedFile;
	scheduleUpdate: () => void;
}

function TooltipContent({ att, scheduleUpdate }: TooltipContentProps) {
	const [loaded, onLoad] = useState(false);
	const isImage = isImageAttachment(att.file);
	return isImage ? (
		loaded ? (
			<Fragment>
				<img src={att.file} className={style.imagePreview} />
			</Fragment>
		) : (
			<Fragment>
				<img
					src={att.file}
					onLoad={() => {
						onLoad(true);
						scheduleUpdate();
					}}
					style={{ width: 0, height: 0 }}
					className={style.imagePreview}
				/>
				<Spinner />
			</Fragment>
		)
	) : (
		<Fragment>{att.name}</Fragment>
	);
}

function formatNoteDate(date: Date) {
	return format(date, isSameYear(date, Date.now()) ? 'MMM D' : 'MMM D, YYYY');
}

function NoteAttributes({ note }: { note: TProductImage }) {
	return (
		<Fragment>
			<div className={cn(style.assigneePreview, 'note-assignee-preview d-inline-block half-margin-right')}>
				<Tooltip
					modifiers={{
						preventOverflow: {
							enabled: true,
							boundariesElement: 'viewport',
						},
					}}
					target=""
					trigger={<span>{renderAssigneeAvatar(note.assignee, 'xxs')}</span>}>
					{(note.assignee ? note.assignee.name : null) || 'Unassigned'}
				</Tooltip>
			</div>
			<div className={cn(style.priorityPreview, 'note-priority-preview d-inline-block')}>
				<Tooltip
					modifiers={{
						preventOverflow: {
							enabled: true,
							boundariesElement: 'viewport',
						},
					}}
					target=""
					trigger={
						<img className="align-middle no-margin" src={formatPriorityIcon(note.priority)} width={16} />
					}>
					{formatPriorityName(note.priority)}
				</Tooltip>
			</div>
		</Fragment>
	);
}
