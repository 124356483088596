import React, { FC, useCallback, useState } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
// eslint-disable-next-line no-restricted-imports
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Media } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { TProduct } from 'volley-common/dist/services/products.service';
import defaultThumbnail from 'volley-common/dist/assets/images/default-thumbnail.png';
import { constVoid } from 'fp-ts/lib/function';
import Dropzone from 'react-dropzone';
import { TAttachmentPreviewProps, AttachmentPreviewContainer } from 'volley-common/dist/components/AttachmentPreview';
import { TFileOrFileData } from 'volley-common/dist/utils/axios';

export type TUpdateProductRequest = {
	title: string;
	productId: number;
	thumbnail?: File;
};

export type TEditProjectPopupProps = {
	result: RemoteData<Error, unknown>;
	onSave: (data: TUpdateProductRequest) => void;
	onClose: () => void;
	product: TProduct;
};

type TFormType = { [K in 'title']: string };

const ProjectThumbnail: FC<TAttachmentPreviewProps> = ({ fileData }) => {
	return (
		<img
			className="half-margin-right"
			style={{ maxWidth: 200, maxHeight: 500 }}
			src={fileData ? fileData.data : defaultThumbnail}
		/>
	);
};
const ProjectThumbnailContainer = AttachmentPreviewContainer(ProjectThumbnail);

export const EditProjectPopup: FC<TEditProjectPopupProps> = ({ product, onClose, onSave = constVoid, result }) => {
	const [thumbnail, setThumbnail] = useState<TFileOrFileData>(
		product.thumbnail ? { fileData: { data: product.thumbnail, name: '' } } : {},
	);
	const handleAttach = useCallback(
		(files: File[]) => {
			files.length > 0 && setThumbnail({ file: files[0] });
		},
		[setThumbnail],
	);

	const handleSubmit = useCallback(
		(valuesObj: object) => {
			const values = valuesObj as TFormType;
			onSave({
				productId: product.id,
				title: values.title,
				thumbnail: thumbnail.file ? thumbnail.file : undefined,
			});
		},
		[product, onSave, thumbnail],
	);

	const renderForm: FC<FormRenderProps> = ({ handleSubmit, form }) => {
		const title = form.getFieldState('title');
		return (
			<form onSubmit={handleSubmit}>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<div className="margin-bottom">
							<label>Title</label>
							<Field
								name="title"
								render={({ input }) => (
									<div className="margin-bottom">
										<Input
											bsSize="lg"
											placeholder="Project title"
											invalid={result.isFailure()}
											{...input}
											type="text"
										/>
									</div>
								)}
							/>
						</div>
						<label>Thumbnail image</label>
						<Dropzone noClick={true} noKeyboard={true} onDropAccepted={handleAttach} multiple={false}>
							{({ getRootProps, getInputProps, open }) => (
								<Media {...getRootProps()}>
									<ProjectThumbnailContainer {...thumbnail} />
									<input {...getInputProps()} />
									<Media body>
										<Button color="secondary" style={{ marginTop: 24 }} onClick={open}>
											Change image
										</Button>
									</Media>
								</Media>
							)}
						</Dropzone>
					</div>
				</ModalBody>

				<ModalFooter className="no-border-top">
					<Button color="link" onClick={onClose}>
						Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						disabled={!title || !title.value || !title.value.length || result.isPending()}>
						Save
					</Button>
				</ModalFooter>
			</form>
		);
	};

	return (
		<Modal isOpen={true}>
			<ModalHeader toggle={onClose}>
				<h2 className="padding-left">Edit this project</h2>
			</ModalHeader>
			<Form
				initialValues={{
					title: product.name,
				}}
				onSubmit={handleSubmit}
				render={renderForm}
			/>
		</Modal>
	);
};
