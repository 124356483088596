import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { AppSumo } from './AppSumo';
import { initial, fromOption, success, failure } from '@devexperts/remote-data-ts';
import { not, identity } from 'fp-ts/lib/function';
import { Subject, of } from 'rxjs';
import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import { AuthService, planNameToType, isAppSumoPlan, APPSUMO_TIERS } from 'volley-common/dist/services/auth.service';
import { asks } from 'fp-ts/lib/Reader';
import { switchMapRD } from 'volley-common/dist/utils/object.utils';
import { ProfileModelType } from '../../models/profile.model';
import { Option, some, none } from 'fp-ts/lib/Option';
import { TApiError } from 'volley-common/dist/models/api.model';

type AppSumoContainerContext = {
	authService: AuthService;
	profileModel: ProfileModelType;
};

export const AppSumoContainer = asks((ctx: AppSumoContainerContext) =>
	withRX(AppSumo)(() => {
		const currentPlan$ = ctx.profileModel.currentPlan$.pipe(map(plan => plan.type));

		const applyCode$ = new Subject<Option<string>>();
		const applyCodeResult$ = applyCode$.pipe(
			withLatestFrom(currentPlan$),
			switchMap(([code, plan]) => {
				const verifyResult$ = code.foldL(
					() => of(success<TApiError, unknown>(null)),
					code => {
						if (plan === `appsumo_${APPSUMO_TIERS}`) {
							const err: TApiError = new Error();
							err.data = [`You have entered the maximum (${APPSUMO_TIERS}) number of codes`] as any;
							return of(failure(err));
						} else return ctx.authService.verifyAppSumoCode(code);
					},
				);
				return verifyResult$.pipe(
					map(result =>
						result.mapLeft(err => {
							if (err.data && Array.isArray(err.data)) {
								return new Error(err.data.join(' '));
							} else {
								return new Error('Failed to verify the code.');
							}
						}),
					),
					switchMapRD(() =>
						ctx.authService.stackAppSumoCode(code).pipe(
							map(result =>
								result.mapLeft(err => {
									if (Array.isArray(err.data) && typeof err.data[0] === 'string') {
										return new Error(err.data.join(' '));
									} else {
										return new Error('Failed to apply the code.');
									}
								}),
							),
						),
					),
				);
			}),
			switchMapRD(() => ctx.profileModel.refresh()),
			map(profile =>
				profile.chain(profile =>
					fromOption(planNameToType(profile.billing.plan_name), () => new Error('Unrecognized plan')),
				),
			),
		);

		const ownAppSumoCodes$ = ctx.profileModel.profile$.pipe(
			map(
				profile =>
					profile
						.toOption()
						.chain(identity)
						.mapNullable(p =>
							planNameToType(p.billing.plan_name).exists(not(isAppSumoPlan)) ? p.appsumo_codes : null,
						)
						.getOrElse([]).length,
			),
		);

		return {
			defaultProps: {
				ownAppSumoCodes: 0,
				onSubmit: (code: string) => applyCode$.next(some(code)),
				onRestack: () => applyCode$.next(none),
				submitResult: initial,
				currentPlan: 'starter',
			},
			props: {
				ownAppSumoCodes: ownAppSumoCodes$,
				submitResult: applyCodeResult$,
				currentPlan: currentPlan$,
			},
		};
	}),
);
