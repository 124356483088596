import React, { FC, useState, useEffect } from 'react';
import { PaymentForm, PaymentMethod } from '../../subscribe/payment/PaymentForm';
import { StripeProvider } from 'react-stripe-elements';
import { ask } from 'fp-ts/lib/Reader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
// eslint-disable-next-line no-restricted-imports
import { ModalBody, ModalHeader, Modal, Button } from 'reactstrap';
import cn from 'classnames';
import css from './PaymentMethodPopup.module.scss';
import { RemoteData, pending } from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/lib/function';
import { TApiError } from 'volley-common/dist/models/api.model';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';

type TPaymentMethodPopupContext = {
	stripeApiKey: string;
};

type PaymentMethodPopupProps = {
	isOpen: boolean;
	onClose: () => void;
	onSave: (data: PaymentMethod) => void;
	result: RemoteData<TApiError, unknown>;
};

export const PaymentMethodPopup = combineReader(
	ask<TPaymentMethodPopupContext>(),
	({ stripeApiKey }): FC<PaymentMethodPopupProps> => ({ isOpen, onClose, onSave, result }) => {
		const [isWaitingForToken, setWaitingForToken] = useState(false);
		useEffect(() => setWaitingForToken(false), [result]);
		const effectiveResult = isWaitingForToken ? pending : result;

		return (
			<Modal isOpen={isOpen}>
				<ModalHeader toggle={result.isPending() ? constVoid : onClose}>
					<h2 className="padding-left">Update billing information</h2>
				</ModalHeader>
				<ModalBody>
					<StripeProvider apiKey={stripeApiKey}>
						<div className="padded no-padding-top no-padding-bottom">
							<PaymentForm
								onTokenCreated={onSave}
								onWaitingForToken={setWaitingForToken}
								paymentAmount={0}
								result={effectiveResult}
								footer={
									<div className={cn('double-padding-top', css.footer)}>
										<Button color="link" onClick={onClose} disabled={effectiveResult.isPending()}>
											Cancel
										</Button>
										<LoadingButton color="primary" pending={effectiveResult.isPending()}>
											Save changes
										</LoadingButton>
									</div>
								}
							/>
						</div>
					</StripeProvider>
				</ModalBody>
			</Modal>
		);
	},
);
