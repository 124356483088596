import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { Fragment } from 'react';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { exporters } from 'volley-common/dist/services/export/integration.utils';

export const ConnectCompletionContainer = combineReader(exporters, exporters => {
	const result$ = exporters.completeConnect();

	return withRX(Fragment)(() => {
		return {
			effects$: result$,
		};
	});
});
