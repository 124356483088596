import { SFC, useEffect, useState } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Input, Button, Container, Row, Col, Form as BootstrapForm } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import style from './AddProductForm.module.scss';
import cn from 'classnames';
import { CreateProjectFromImages } from './ProductImageUploader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { Option } from 'fp-ts/lib/Option';
import { TFileData } from 'volley-common/dist/utils/axios';

export type TUpdateProductRequest = {
	title: string;
	productId: number;
};

export type TAddProjectFormProps = {
	type: 'archive' | 'active';
	result: RemoteData<Error, unknown>;
	onSave: (name: string, files?: TFileData[]) => void;
	canCreateProduct: () => boolean;
	teamId: Option<number>;
};

type TFormType = { [K in 'name']: string };

export const AddProjectForm = combineReader(
	CreateProjectFromImages,
	CreateProjectFromImages => (props: TAddProjectFormProps) => {
		const [productUploader, setProductUploader] = useState(false);

		const completed = props.result.isFailure() || props.result.isSuccess();
		useEffect(() => {
			if (completed) {
				setProductUploader(false);
			}
		}, [completed]);

		const renderForm: SFC<FormRenderProps> = ({ handleSubmit, form }) => {
			const { result, canCreateProduct, onSave } = props;
			const projectName = form.getFieldState('name');
			return (
				<BootstrapForm onSubmit={handleSubmit} className="add-new-product-bar">
					<CreateProjectFromImages
						visible={productUploader}
						onCancel={() => setProductUploader(false)}
						onSubmit={onSave}
						// onDone={(projectId) => history.push(routes.)}
					/>
					<Row className="form-row">
						<Col className="mb-3">
							<Field
								name="name"
								render={({ input }) => (
									<Input
										bsSize="lg"
										className={style.input}
										invalid={result.isFailure()}
										placeholder="Project name"
										{...input}
										type="text"
									/>
								)}
							/>
						</Col>
						<Col className={cn('mb-3', style.buttons)}>
							<Button
								type="submit"
								color="primary"
								size="lg"
								className="mb-2"
								disabled={
									result.isPending() ||
									!projectName ||
									!projectName.value ||
									!projectName.value.length
								}>
								Create Project
							</Button>
							<span>or</span>
							<Button
								color="primary"
								size="lg"
								className={'mb-2'}
								onClick={() => {
									if (canCreateProduct()) {
										setProductUploader(true);
									}
								}}
								disabled={result.isPending()}>
								Upload <i className="material-icons-outlined">image</i>
							</Button>
						</Col>
					</Row>
				</BootstrapForm>
			);
		};

		const handleSubmit = (valuesObj: object) => {
			const values = valuesObj as TFormType;
			props.onSave?.(values.name);
		};

		return (
			<section className="no-padding-bottom">
				<Container className="product-card-container">
					<Row>
						<Col sm={12}>
							<div className="page-header no-border">
								<Row>
									<Col sm={12}>
										<Form onSubmit={handleSubmit} render={renderForm} />
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	},
);
