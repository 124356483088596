import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, isSuccess } from '@devexperts/remote-data-ts/dist/remote-data';
import { of, Subject } from 'rxjs';
import { switchMap, withLatestFrom, filter, tap, share, map } from 'rxjs/operators';
import { ask } from 'fp-ts/lib/Reader';
import { constUndefined, constNull } from 'fp-ts/lib/function';
import { ShareForm, TShareRequest, TSharingMember } from './ShareForm';
import { ProductService } from 'volley-common/dist/services/products.service';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { isDefined } from 'volley-common/dist/utils/object.utils';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';

type TShareContainerContext = {
	productService: ProductService;
	toastService: ToastService;
	authService: AuthService;
};

export const ShareFormContainer = combineReader(ShareForm, ask<TShareContainerContext>(), (ShareForm, ctx) => {
	return withRX(ShareForm)(props$ => {
		const shareRequest$ = new Subject<TShareRequest>();
		const shareResult$ = shareRequest$.pipe(
			switchMap(({ productId, emails }) => ctx.productService.share(productId, emails)),
			share(),
		);

		const selfCloseEffect$ = shareResult$.pipe(
			tap(result =>
				result.fold(
					null,
					null,
					// () => ctx.toastService.push({text: "Failed to share the project. Please try again later"}),
					constNull,
					() => ctx.toastService.push({ text: 'Project shared' }),
				),
			),
			filter(isSuccess),
			withLatestFrom(props$),
			tap(([_, props]) => {
				props.onReloadProduct && props.onReloadProduct();
				props.onClose();
			}),
		);

		const members$ = props$.pipe(
			map(props => {
				const { product } = props;
				const teamMembers: TSharingMember[] = product.team
					? product.team.members.map(m => ({ ...m, status: 'member' }))
					: [];
				const teamMemberIds = new Set(teamMembers.map(m => m.id));
				return [
					product.owner && { ...product.owner, status: 'owner' as const },
					...teamMembers,
					...product.collaborators
						.filter(m => !teamMemberIds.has(m.id))
						.map(member => ({
							...member,
							status: 'collaborator' as const,
						})),
					...product.prospects.map(prospect => ({
						...prospect,
						status: 'invited' as const,
						name: prospect.email,
						profile: {},
					})),
				].filter(isDefined);
			}),
		);

		const userId$ = ctx.authService.userId$.pipe(map(userId => userId.toUndefined()));

		return {
			defaultProps: {
				onSendRequest: constUndefined,
				result: initial,
				members: [],
			},
			props: {
				onSendRequest: of((data: TShareRequest) => shareRequest$.next(data)),
				result: shareResult$,
				productId: props$.pipe(map(props => props.product.id)),
				members: members$,
				currentUserId: userId$,
			},
			effects$: selfCloseEffect$,
		};
	});
});
