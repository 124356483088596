import { history, getOrigin } from '../utils/history';

export const exportDomContext = {
	pushContext(key: string, data: any) {
		sessionStorage.setItem(`Volley.${key}`, JSON.stringify(data));
	},
	popContext(key: string): unknown | undefined {
		const fullKey = `Volley.${key}`;
		try {
			const raw = sessionStorage.getItem(fullKey);
			if (typeof raw === 'string') {
				const parsed = JSON.parse(raw);
				sessionStorage.removeItem(fullKey);
				return parsed;
			}
		} catch (e) {}
		return undefined;
	},
	historyPush(path: string, state?: any): void {
		history.push(path, state);
	},
	getPathname(): string {
		return history.location.pathname;
	},
	getHash(): string {
		return history.location.hash.substring(1);
	},
	getQuery(): string {
		return history.location.search.substring(1);
	},
	getOrigin,
};
