import React, { memo, Fragment, useMemo } from 'react';
import { CustomInput } from 'reactstrap';
import uuid from 'uuid';
import { BillingPeriod } from 'volley-common/dist/services/auth.service';

interface BillingPeriodSelectorProps {
	billingPeriod: BillingPeriod;
	onBillingPeriodChange: (val: BillingPeriod) => void;
}

export const BillingPeriodSelector = memo<BillingPeriodSelectorProps>(({ billingPeriod, onBillingPeriodChange }) => {
	const formId = useMemo(() => 'form-' + uuid.v4(), []);

	return (
		<Fragment>
			<div className="form-check form-check-inline">
				<CustomInput
					id={`${formId}-period-monthly`}
					name={`${formId}-period`}
					className="quarter-margin-bottom no-padding-left"
					label={<h3 className="no-margin medium-font">Billed monthly</h3>}
					checked={billingPeriod === 'month'}
					onChange={() => onBillingPeriodChange('month')}
					type="radio"
				/>
			</div>{' '}
			<div className="form-check form-check-inline">
				<CustomInput
					id={`${formId}-period-yearly`}
					name={`${formId}-period`}
					className="quarter-margin-bottom no-padding-left"
					label={<h3 className="no-margin medium-font">Billed yearly (20% savings)</h3>}
					checked={billingPeriod === 'year'}
					onChange={() => onBillingPeriodChange('year')}
					type="radio"
				/>
			</div>
		</Fragment>
	);
});
