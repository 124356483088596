import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { IntegrationSelector, Integration, SaveIntegrationRequest } from './IntegrationSelector';
import { history } from '../../../utils/history';
import { Subject, merge, combineLatest } from 'rxjs';
import { withLatestFrom, tap, shareReplay, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { IntegrationType, exporters } from 'volley-common/dist/services/export/integration.utils';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { IntegrationStore } from 'volley-common/dist/services/export/integration.store';
import { ask } from 'fp-ts/lib/Reader';
import { ProfileModelType } from '../../../models/profile.model';
import { routes } from 'volley-common/dist/utils/routes';
import { Option, some, none } from 'fp-ts/lib/Option';
import { initial } from '@devexperts/remote-data-ts';
import { tapRD } from 'volley-common/dist/utils/object.utils';
import { TeamsService } from 'volley-common/dist/services/teams.service';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { canConfigureIntegration, getProjectOwner } from 'volley-common/dist/models/permissions.model';
import { ExportType } from '../../export/export.model';

const defaultIntegrations = [
	{ type: IntegrationType.Trello, active: false },
	{ type: IntegrationType.Jira, active: false },
];

type IntegrationSelectorContainerContext = {
	integrationStore: IntegrationStore;
	profileModel: ProfileModelType;
	teamsService: TeamsService;
	authService: AuthService;
};

export const IntegrationSelectorContainer = combineReader(
	exporters,
	IntegrationSelector,
	ask<IntegrationSelectorContainerContext>(),
	(exporters, IntegrationSelector, ctx) =>
		withRX(IntegrationSelector)(props$ => {
			const { integrationStore } = ctx;

			const product$ = props$.pipe(
				map(props => props.product),
				distinctUntilChanged(),
				shareReplay(1),
			);

			const productId$ = product$.pipe(map(p => p.id));

			const isOwner$ = combineLatest([ctx.profileModel.isSuperUser$, ctx.authService.userId$, product$]).pipe(
				map(
					([isSuperUser, userId, product]) =>
						isSuperUser || userId.exists(id => id === getProjectOwner(product)?.id),
				),
			);
			const canConfigureExport$ = combineLatest([ctx.profileModel.isSuperUser$, product$]).pipe(
				map(([isSuperUser, project]) => {
					return isSuperUser || canConfigureIntegration(project);
				}),
			);

			const export$ = new Subject<{ integrationId: number; type: ExportType }>();
			const exportEffect$ = export$.pipe(
				withLatestFrom(product$),
				tap(([params, product]) => {
					history.push(routes.export, {
						product: product.id,
						...params,
					});
				}),
			);

			const connectionModal$ = new Subject<Option<IntegrationType>>();
			const onCloseConnectionModal = () => connectionModal$.next(none);

			const connect$ = new Subject<IntegrationType>();
			const connectEffect$ = connect$.pipe(
				withLatestFrom(product$),
				tap(([type, product]) => {
					switch (type) {
						case IntegrationType.Jira:
							connectionModal$.next(some(type));
						// eslint-disable-next-line no-fallthrough
						default:
							exporters.connect(type, product.id, routes.export, {
								product: product.id,
								type,
							});
					}
				}),
			);

			const saveIntegration$ = new Subject<SaveIntegrationRequest>();
			const saveIntegrationResult$ = saveIntegration$.pipe(
				withLatestFrom(productId$),
				switchMap(([request, productId]) =>
					integrationStore.set(productId, request.type, request.data, request.config, request.notifications),
				),
				tapRD(onCloseConnectionModal),
			);

			const activeIntegrations$ = productId$.pipe(
				switchMap(productId => integrationStore.getAllWithUpdates(productId)),
				map(data =>
					Object.keys(data).reduce(
						(acc, key) =>
							acc.map(intg => (intg.type === key ? { ...intg, active: true, id: data[key].id } : intg)),
						defaultIntegrations,
					),
				),
			);

			return {
				defaultProps: {
					integrations: defaultIntegrations,
					onExport: (intg: Integration) => export$.next({ integrationId: intg.id!, type: intg.type }),
					onConnect: (intType: IntegrationType) => connect$.next(intType),
					canExport: false,
					isOwner: false,
					connectionModal: none,
					onSaveIntegration: (request: SaveIntegrationRequest) => saveIntegration$.next(request),
					saveIntegrationResult: initial,
					onCloseConnectionModal,
				},
				props: {
					integrations: activeIntegrations$,
					canExport: canConfigureExport$,
					isOwner: isOwner$,
					connectionModal: connectionModal$,
					saveIntegrationResult: saveIntegrationResult$,
				},
				effects$: merge(exportEffect$, connectEffect$),
			};
		}),
);
