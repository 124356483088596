import React, { memo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { Col, FormGroup, Input, Label, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Modal } from '../../../components/Modal';
import chatHqLogo from 'volley-common/dist/assets/images/chathq-logo.png';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { Either, left, right } from 'fp-ts/lib/Either';
import { constUndefined } from 'fp-ts/lib/function';
import { validateRequired } from 'volley-common/dist/utils/object.utils';
import { RemoteData } from '@devexperts/remote-data-ts';

export interface ChatHQCredentialsFormModel {
	host: string;
	auth_token: string;
	user_id: string;
}

interface ChatHQCredentialsProps {
	visible: boolean;
	onClose: () => void;
	onConnect: (val: ChatHQCredentialsFormModel) => void;
	saveResult: RemoteData<Error, unknown>;
}

export const ChatHQCredentials = memo<ChatHQCredentialsProps>(({ visible, onClose, onConnect, saveResult }) => {
	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader className="no-border-bottom no-padding-bottom" toggle={onClose} />
			<ModalBody>
				{/* <FormContext.Provider value={{ verifyAndSaveResult }}> */}
				{/* <Form component={JiraConnectionModalForm} onSubmit={handleSubmit as (val: object) => void} /> */}
				{/* </FormContext.Provider> */}
				<Form
					render={props => <ChatHQCredentialsForm {...props} saveResult={saveResult} />}
					onSubmit={(val: object) => {
						const data = val as ChatHQCredentialsFormModel;
						onConnect({ ...data, host: cleanChatHQUrl(data.host).getOrElse('') });
					}}
				/>
			</ModalBody>
		</Modal>
	);
});

function cleanChatHQUrl(val: string): Either<string, string> {
	const cleaned = val.replace(/((https?)?:\/\/|^)/, 'https://');
	try {
		const url = new URL(cleaned);
		return right(url.host);
	} catch (e) {
		return left('Please make sure the URL is correct');
	}
}

const validateChatHQUrl = (val: string) => {
	if (val && val.length) {
		const parsed = cleanChatHQUrl(val);
		return parsed.fold<string | undefined>(e => e, constUndefined);
	} else {
		return 'Required';
	}
};

const ChatHQCredentialsForm = memo<FormRenderProps & Pick<ChatHQCredentialsProps, 'saveResult'>>(
	({ handleSubmit, saveResult }) => {
		return (
			<form onSubmit={handleSubmit}>
				<div className="padded no-padding-top text-center margin-bottom">
					<img className="margin-bottom" src={chatHqLogo} width={200} />
					<h1 className="no-margin-bottom bold-font">Connect to ChatHQ</h1>
				</div>
				<div className="margin-bottom">
					<Row className="justify-content-center">
						<Col xs={12}>
							<Field
								name="host"
								validate={validateChatHQUrl}
								render={({ input, meta }) => (
									<FormGroup>
										<Label className="full-width medium-font">ChatHQ Host</Label>
										<Input
											invalid={meta.error && meta.touched}
											bsSize="lg"
											placeholder="Your ChatHQ url (e.g. https://your-team.chathq.io)"
											{...input}
											type="text"
										/>
										{meta.error && meta.touched && (
											<div className="invalid-feedback">{meta.error}</div>
										)}
									</FormGroup>
								)}
							/>
							<Field
								name="user_id"
								validate={validateRequired}
								render={({ input, meta }) => (
									<FormGroup>
										<Label className="full-width medium-font">ChatHQ User ID</Label>
										<Input
											invalid={meta.error && meta.touched}
											bsSize="lg"
											{...input}
											type="text"
										/>
										{meta.error && meta.touched && (
											<div className="invalid-feedback">{meta.error}</div>
										)}
									</FormGroup>
								)}
							/>
							<Field
								name="auth_token"
								validate={validateRequired}
								render={({ input, meta }) => (
									<FormGroup>
										<Label className="full-width medium-font">
											<div className="inline-block">API token</div>
											<div className="inline-block float-right">
												<a href="#" target="_blank">
													Get your API token{' '}
													<i className="material-icons helper-icon">launch</i>
												</a>
											</div>
										</Label>
										<Input
											invalid={meta.error && meta.touched}
											bsSize="lg"
											placeholder="Enter API token"
											{...input}
											type="text"
										/>
										{meta.error && meta.touched && (
											<div className="invalid-feedback">{meta.error}</div>
										)}
									</FormGroup>
								)}
							/>
						</Col>
					</Row>
				</div>
				<div className="margin-bottom">
					<LoadingButton size="lg" color="primary" block type="submit" pending={saveResult.isPending()}>
						Connect ChatHQ account
					</LoadingButton>
				</div>
			</form>
		);
	},
);
