import React, { FC } from 'react';
import { ModalBody, ModalHeader, Button } from 'reactstrap';
import css from './DowngradeConfirmation.module.scss';
import { constVoid } from 'fp-ts/lib/function';
import cn from 'classnames';
import { Modal } from '../../components/Modal';

export type TDowngradeConfirmationReason =
	| { type: 'projectsLimit'; limit: number }
	| { type: 'notesLimit'; noteLimit: number; projectLimit: number }
	| { type: 'justPleaseStay' };

export type TDowngradeConfirmationProps = {
	isOpen: boolean;
	isPending: boolean;
	onConfirm: () => void;
	onClose: () => void;
	reason: TDowngradeConfirmationReason;
};

export const DowngradeConfirmation: FC<TDowngradeConfirmationProps> = ({
	isOpen,
	isPending,
	onConfirm,
	onClose,
	reason,
}) => {
	const texts = formatTexts(reason);
	return (
		<Modal isOpen={isOpen} wrapClassName={css.modal}>
			<ModalHeader className="no-border-bottom no-padding-bottom" toggle={isPending ? constVoid : onClose} />
			<ModalBody className={css.body}>
				<h2>Are you sure you want to downgrade?</h2>
				<p className={cn(!!texts.subText && 'padding-top', 'padding-bottom')}>{texts.subText}</p>
				<Button
					block
					size="lg"
					color="primary"
					className={css.button_primary}
					onClick={onClose}
					disabled={isPending}>
					{texts.cancel}
				</Button>
				<Button size="lg" color="link" className="half-margin-top" onClick={onConfirm} disabled={isPending}>
					{texts.confirm}
				</Button>
			</ModalBody>
		</Modal>
	);
};

type Texts = {
	subText?: string;
	cancel: string;
	confirm: string;
};

function formatTexts(reason: TDowngradeConfirmationReason): Texts {
	switch (reason.type) {
		case 'projectsLimit':
			return {
				subText: `You will loose access to all but ${reason.limit} of your projects until you upgrade your plan.`,
				cancel: 'Nevermind',
				confirm: 'Yes, cancel and loose access',
			};
		case 'notesLimit':
			return {
				subText: `You will loose access to all but ${reason.projectLimit} of your projects and only have access to your first ${reason.noteLimit} notes until you upgrade your plan.`,
				cancel: 'Nevermind',
				confirm: 'Yes, cancel and loose access',
			};
		case 'justPleaseStay':
			return {
				cancel: 'Nevermind',
				confirm: 'Yes, downgrade my plan',
			};
	}
}
