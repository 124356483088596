import React, { Component, SFC, Fragment } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Input, Button, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { TProspect, TRegistrationData, TProspectHash } from 'volley-common/dist/services/auth.service';
import { Link, Redirect } from 'react-router-dom';
import logoImg from 'volley-common/dist/assets/images/logo.svg';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import { PageNotFoundContainer } from '../errors/PageNotFoundContainer';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { getLoginUrl } from '../../components/RedirectToLogin';
import { routes } from 'volley-common/dist/utils/routes';
import { ask } from 'fp-ts/lib/Reader';
import { capterraHandler } from '../../utils/capterra';
import { PasswordRequirements } from '../../components/PasswordRequirements';
import { getErrors, hasFieldOrGlobalErrors } from 'volley-common/dist/models/api.model';
import { validatePassword } from 'volley-common/dist/utils/string.utils';

export type TProspectViewProps = {
	result: RemoteData<Error, unknown>;
	onRegister: (data: TRegistrationData) => void;
	prospectHash: string;
	decodedHash: RemoteData<Error, TProspectHash>;
	prospect: RemoteData<Error, TProspect>;
	isLoggedIn: RemoteData<Error, boolean>;
};

interface ProspectViewContext {
	websiteUrl: string;
}

type TFormType = { [K in 'email' | 'fullName' | 'password']?: string };

const renderGoToProducts = (productId?: number) => {
	return <Redirect to={productId ? routes.product(productId) : routes.dashboard} />;
};

export const ProspectView = combineReader(
	PageNotFoundContainer,
	ask<ProspectViewContext>(),
	(PageNotFoundContainer, ctx) => {
		const renderHashDecodeError = () => {
			return <PageNotFoundContainer />;
		};

		return class ProspectView extends Component<TProspectViewProps> {
			render() {
				const { decodedHash, isLoggedIn, prospect } = this.props;
				// Decode the hash:
				// - failure => show the 404 page
				// - success => render the block
				return (
					<RenderRemoteData
						data={decodedHash}
						DataStateFailure={renderHashDecodeError}
						success={hash => {
							// No prospect: the user was already registered at the moment of invitation
							// => redirect to /products/{id} (with subsequent redirect to /welcome/signup if not authorized)
							if (hash.prospectId <= 0) {
								return renderGoToProducts(hash.productId);
							}
							return (
								<RenderRemoteData
									data={isLoggedIn}
									success={isLoggedIn => {
										if (!isLoggedIn) {
											return <RenderRemoteData data={prospect} success={this.renderSuccess} />;
										} else {
											// TODO: this may show the "forbidden" page if the user is logged in using an account
											// that does not have access to that product
											return renderGoToProducts(hash.productId);
										}
									}}
								/>
							);
						}}
					/>
				);
			}

			/**
			 * Assumes that the user is not authenticated
			 */
			renderSuccess = (prospect: TProspect) => {
				if (prospect.is_user && this.props.decodedHash.isSuccess()) {
					// Redirect to the product page but this will immediately redirect to the login page
					// In future we may want to capture the URL and navigate the user there after the successful login
					return renderGoToProducts(this.props.decodedHash.value.productId);
				}
				return (
					<Fragment>
						<section className="no-padding-top no-padding-sm">
							<Container>
								<Row>
									<Col className="text-center">
										<a href={ctx.websiteUrl}>
											<img src={logoImg} width={105} />
										</a>
									</Col>
								</Row>
							</Container>
						</section>
						<section>
							<Container>
								<Row className="align-items-center">
									<Col lg={1} />
									<Col>
										<div className="padded text-center-sm">
											<h1 className="bold-font">Sign up to collaborate</h1>
											<p className="market">
												Volley is the easiest way to provide feedback on websites.
											</p>
										</div>
									</Col>
									<Col className="align-middle">
										<Card>
											<CardBody>
												<div className="padded">
													<Form
														initialValues={{ email: prospect.email }}
														onSubmit={this.handleSubmit}
														render={this.renderForm}
													/>
												</div>
											</CardBody>
										</Card>
									</Col>
									<Col lg={1} />
								</Row>
							</Container>
						</section>
						<section>
							<Container>
								<Row>
									<Col className="text-center">
										<p className="light-text-color half-margin-bottom">
											By creating an account, you agree to our{' '}
											<a href={`${ctx.websiteUrl}terms`}>Terms</a>
										</p>
										<p className="light-text-color">
											Already have an account?<Link to={getLoginUrl()}>Log in</Link>
										</p>
									</Col>
								</Row>
							</Container>
						</section>
					</Fragment>
				);
			};

			private handleSubmit = (valuesObj: object) => {
				capterraHandler();
				const values = valuesObj as TFormType;
				this.props.onRegister({
					fullName: values.fullName || '',
					password: values.password || '',
					email: this.props.prospect.map(p => p.email).getOrElse(values.email || ''),
					signupType: 'share',
				});
			};

			private renderForm: SFC<FormRenderProps> = ({ handleSubmit }) => {
				const { result } = this.props;
				return (
					<form onSubmit={handleSubmit}>
						<Field
							name="fullName"
							render={({ input, meta }) => (
								<div className="margin-bottom">
									<Input
										bsSize="lg"
										autoComplete="name"
										placeholder="Full name"
										invalid={result.isFailure() && hasFieldOrGlobalErrors(result.error, 'name')}
										{...input}
										type="text"
									/>
									{result.isFailure() &&
										getErrors(result.error, 'name').map((e, index) => (
											<div className="invalid-feedback" key={index}>
												{e}
											</div>
										))}
								</div>
							)}
						/>
						<Field
							name="email"
							render={({ input, meta }) => (
								<div className="margin-bottom">
									<Input bsSize="lg" disabled placeholder="Email" {...input} type="email" />
								</div>
							)}
						/>
						<Field
							name="password"
							validate={(pwd: string | undefined) =>
								validatePassword(pwd || '').valid ? null : 'Please check the password requirements'
							}
							render={({ input, meta }) => {
								const err =
									meta.submitFailed && meta.error
										? [meta.error]
										: result.isFailure()
										? getErrors(result.error, 'password')
										: [];
								return (
									<div className="margin-bottom">
										<Input
											bsSize="lg"
											autoComplete="new-password"
											placeholder="Password"
											invalid={
												err.length > 0 ||
												(result.isFailure() && hasFieldOrGlobalErrors(result.error, 'password'))
											}
											{...input}
											type="password"
										/>
										{err.map((e, index) => (
											<div className="invalid-feedback" key={index}>
												{e}
											</div>
										))}
										{result.isFailure() &&
											getErrors(result.error, 'password').map((e, index) => (
												<div className="invalid-feedback" key={index}>
													{e}
												</div>
											))}
									</div>
								);
							}}
						/>
						<Field
							name={'password'}
							render={({ input: { value } }) => (
								<div className="margin-bottom">
									<PasswordRequirements password={value} />
								</div>
							)}
						/>
						<div className="double-margin-top">
							<Button color="primary" size="lg" disabled={result.isPending()} block type="submit">
								Sign up
							</Button>
						</div>
					</form>
				);
			};
		};
	},
);
