import React, { Ref, memo } from 'react';
import cn from 'classnames';
import { Media } from 'reactstrap';
import style from './Comment.module.scss';
import { Avatar } from 'volley-common/dist/components/Avatar';
import { TextLoading } from 'volley-common/dist/components/TextLoading';

export type TCommentLoadingProps = {
	containerRef?: Ref<HTMLDivElement>;
};

export const CommentLoading = memo<TCommentLoadingProps>(({ containerRef }) => (
	<div className={style.comment} ref={containerRef}>
		<Media>
			<div className={cn(style.comment__avatar, style.comment__avatar_xs)} />
			<Avatar initials="" size="xs" className="half-margin-right" />
			<Media body className={style.comment__body}>
				<h4 className="bold-font quarter-margin-bottom">
					<TextLoading />
				</h4>
				<h4 className={style.text_pending}>
					<TextLoading />
				</h4>
			</Media>
		</Media>
	</div>
));
