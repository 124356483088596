import React, { memo, Fragment, useCallback } from 'react';
import { Row, Button, Spinner } from 'reactstrap';
import { empty } from 'fp-ts/lib/Array';
import { fromArray } from 'fp-ts/lib/NonEmptyArray';
import { ask } from 'fp-ts/lib/Reader';
import { fromNullable } from 'fp-ts/lib/Option';
import { contramap, ordNumber } from 'fp-ts/lib/Ord';
import { constVoid } from 'fp-ts/lib/function';
import avatarUnassigned from 'volley-common/dist/assets/images/avatar-unassigned.svg';
import boltIcon from 'volley-common/dist/assets/images/icon-bolt.svg';
import attachIcon from 'volley-common/dist/assets/images/icon-attach.svg';
import {
	TMember,
	TProductImage,
	priorityOrdering,
	TNotePriority,
	TAttachedFile,
} from 'volley-common/dist/services/products.service';
import cn from 'classnames';
import css from './NoteAttributes.module.scss';
import { Selectbox, Option as SelectboxOption } from 'volley-common/dist/components/Selectbox';
import { formatPriorityIcon } from './Review';
import { formatPriorityName } from './review.utils';
import { RemoteData, initial, failure, success } from '@devexperts/remote-data-ts';
import { renderAssigneeAvatar } from './NoteFilter';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { NoteAttachmentContainer } from './NoteAttachment';
import Dropzone from 'react-dropzone';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { distinctUntilChanged, map, withLatestFrom, switchMap, filter } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { NotesService } from 'volley-common/dist/services/notes.service';
import { combineRemoteData } from 'volley-common/dist/utils/object.utils';

interface NoteAttributesProps {
	note: TProductImage;
	members?: TMember[];
	onPreviewAttachment?: (src: string) => void;
	onSetPriority?: (priority: TNotePriority) => void;
	setPriorityResult: RemoteData<Error, unknown>;
	onSetAssignee?: (assignee: number | null) => void;
	setAssigneeResult: RemoteData<Error, unknown>;
	onUpload: (files: File[]) => void;
	uploadStatus: RemoteData<Error, unknown>;
	uploadCount?: number;
	onAttachmentDeleted: (attachmentId: number) => void;
	onAttachmentsUpdated: (attachments: TAttachedFile[]) => void;
}

const selectboxTheme = {
	container: 'dropdown-subtle',
	button: css.selectboxButton,
};

const renderPriorityOption = (opt: TNotePriority | null | undefined, float: boolean) =>
	opt ? (
		<Fragment>
			<h4 className="no-margin-bottom d-inline-block align-middle margin-right">{formatPriorityName(opt)}</h4>
			<img
				className={cn('d-inline-block align-middle no-margin', float && 'float-right')}
				src={formatPriorityIcon(opt)}
				width={16}
				style={
					float
						? {
								position: 'relative',
								top: 5,
						  }
						: undefined
				}
			/>
		</Fragment>
	) : null;

export const NoteAttributes = combineReader(NoteAttachmentContainer, NoteAttachmentContainer =>
	memo<NoteAttributesProps>(
		({
			note,
			members = empty,
			onPreviewAttachment,
			setPriorityResult,
			onSetPriority = constVoid,
			setAssigneeResult,
			onSetAssignee = constVoid,
			onUpload,
			uploadStatus,
			uploadCount = 0,
			onAttachmentDeleted,
		}) => {
			const renderAssigneeOption = useCallback(
				(opt: number | null | undefined) => (
					<h4 className="no-margin-bottom d-inline-block">
						{opt
							? fromNullable(members.find(m => m.id === opt))
									.map(m => m.name)
									.getOrElse('User #' + opt)
							: 'Unassigned'}
					</h4>
				),
				[members],
			);

			return (
				<div className="note-comment-container">
					<div className={css.content}>
						<Row tag="dl" className="half-margin-bottom">
							<dt className="col-5">
								{note.assignee ? (
									renderAssigneeAvatar(note.assignee)
								) : (
									<img
										className="d-inline-block no-margin align-middle"
										src={avatarUnassigned}
										width={30}
									/>
								)}
								<h4 className="bold-font no-margin-bottom d-inline-block half-margin-left">Assignee</h4>
							</dt>
							<dd className="col-7">
								<Selectbox<number | null>
									caret={false}
									renderOption={renderAssigneeOption}
									value={note.assignee && note.assignee.id ? note.assignee.id : null}
									pending={setAssigneeResult.isPending()}
									onChange={onSetAssignee}
									theme={selectboxTheme}>
									<SelectboxOption key={'none'} value={null} active={!note.assignee}>
										{renderAssigneeAvatar(undefined)}
										<span className="margin-left">Unassigned</span>
									</SelectboxOption>
									{members.map(member => (
										<SelectboxOption
											key={member.id}
											value={member.id}
											active={note.assignee && note.assignee.id === member.id}>
											{renderAssigneeAvatar(member)}
											<span className="margin-left">{member.name}</span>
										</SelectboxOption>
									))}
								</Selectbox>
							</dd>
						</Row>
						<Row tag="dl" className="half-margin-bottom">
							<dt className="col-5">
								<img
									className="d-inline-block align-middle"
									src={boltIcon}
									width={30}
									style={{ marginBottom: 5 }}
								/>
								<h4 className="bold-font no-margin-bottom d-inline-block half-margin-left">Priority</h4>
							</dt>
							<dd className="col-7">
								<Selectbox<TNotePriority>
									caret={false}
									renderOption={opt => renderPriorityOption(opt, false)}
									value={note.priority}
									pending={setPriorityResult.isPending()}
									onChange={onSetPriority}
									theme={selectboxTheme}>
									{priorityOrdering.map(priority => (
										<SelectboxOption
											key={priority}
											value={priority}
											active={priority === note.priority}>
											{renderPriorityOption(priority, true)}
										</SelectboxOption>
									))}
								</Selectbox>
							</dd>
						</Row>
						<Dropzone
							noClick={true}
							noKeyboard={true}
							onDropAccepted={files => onUpload(files)}
							multiple={true}>
							{({ getRootProps, getInputProps, open, isDragActive }) => (
								<div {...getRootProps()} className={isDragActive ? css.dropActive : undefined}>
									<Row tag="dl" className="no-margin-bottom">
										<div className="col-12">
											{uploadStatus.isPending() ? (
												<Fragment>
													<span className={css.uploadSpinner}>
														<Spinner className={css.uploadSpinnerCircle} />
													</span>
													<h4 className="bold-font no-margin-bottom d-inline-block half-margin-left">
														Uploading {uploadCount} {uploadCount > 1 ? 'files' : 'file'}...
													</h4>
												</Fragment>
											) : (
												<Fragment>
													<img
														className="d-inline-block no-margin align-middle"
														src={attachIcon}
														width={30}
														style={{
															opacity:
																note.attachments && note.attachments.length ? 1 : 0.5,
														}}
													/>
													<h4
														className="bold-font no-margin-bottom d-inline-block half-margin-left"
														style={{
															opacity:
																note.attachments && note.attachments.length ? 1 : 0.5,
														}}>
														{renderAttachmentsCount(note)}
													</h4>
													<Button
														color="secondary"
														className="float-right btn-circle"
														onClick={open}
														disabled={uploadStatus.isPending()}
														style={{ padding: '3px 6px' }}>
														<i className="material-icons" style={{ fontSize: 12 }}>
															add
														</i>
													</Button>
												</Fragment>
											)}
										</div>
									</Row>
									<Row>
										<div className="attachment-grid">
											<input {...getInputProps()} />
											{(note.attachments || []).map(att => (
												<NoteAttachmentContainer
													key={att.id}
													attachment={att}
													noteId={note.id}
													onPreview={onPreviewAttachment}
													onDelete={onAttachmentDeleted}
												/>
											))}
										</div>
									</Row>
								</div>
							)}
						</Dropzone>
					</div>
				</div>
			);
		},
	),
);

function renderAttachmentsCount(note: TProductImage) {
	const len = note.attachments ? note.attachments.length : 0;
	if (len === 0) {
		return 'No files';
	} else if (len === 1) {
		return '1 file';
	} else {
		return `${len} files`;
	}
}

const ordByAttachmentCount = contramap(
	(note: TProductImage) => (note.attachments ? note.attachments.length : 0),
	ordNumber,
);

export const NoteAttributesContainer = combineReader(
	ask<{ notesService: NotesService }>(),
	NoteAttributes,
	(ctx, NoteAttributes) =>
		withRX(NoteAttributes)(props$ => {
			const noteId$ = props$.pipe(
				map(props => props.note.id),
				distinctUntilChanged(),
			);

			const upload$ = new Subject<File[]>();
			const uploadResult$ = upload$.pipe(
				filter(files => files.length > 0),
				withLatestFrom(noteId$),
				switchMap(([files, noteId]) =>
					combineLatest(files.map(file => ctx.notesService.addNoteAttachment(noteId, file))).pipe(
						map(combineRemoteData),
					),
				),
				map(results =>
					results.chain(results =>
						fromArray(results)
							.map(results => success<Error, TProductImage>(results.max(ordByAttachmentCount)))
							.getOrElse(failure(new Error('No uploaded files'))),
					),
				),
				withLatestFrom(props$),
				map(([result, props]) => {
					if (result.isSuccess()) {
						props.onAttachmentsUpdated(result.value.attachments || []);
					}
					return result;
				}),
			);

			return {
				defaultProps: {
					uploadStatus: initial,
					onUpload: (files: File[]) => upload$.next(files),
				},
				props: {
					uploadStatus: uploadResult$,
					uploadCount: upload$.pipe(map(files => files.length)),
				},
			};
		}),
);
