import React, { Fragment, memo, useState } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Card, CardBody, Media, Button, Input, Alert } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import cn from 'classnames';
import theme from './Profile.module.scss';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import Dropzone from 'react-dropzone';
import { TAttachmentPreviewProps, AttachmentPreviewContainer } from 'volley-common/dist/components/AttachmentPreview';
import { TFileOrFileData } from 'volley-common/dist/utils/axios';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';

export type TProfileInfo = {
	avatar?: string;
	fullName: string;
	email: string;
};

export type TUpdateProfile = {
	avatar?: File;
	name: string;
	email: string;
};

export type TProfileProps = {
	profile: RemoteData<Error, TProfileInfo>;

	onSave: (update: TUpdateProfile) => unknown;
	saveResult: RemoteData<Error, unknown>;
	resultShown: boolean;
};

const AvatarPreviewComponent = memo<TAttachmentPreviewProps & { fullName: string }>(({ fileData, fullName }) => {
	if (fileData) {
		return (
			<div className={cn(theme.avatar, theme.avatar_xl, theme.avatar_img, 'margin-right')}>
				<img className={theme.avatar__img} src={fileData.data} />
			</div>
		);
	} else {
		return <div className={cn(theme.avatar, theme.avatar_xl, 'margin-right')}>{formatAvatarLetters(fullName)}</div>;
	}
});

const ProfileAvatar = AttachmentPreviewContainer(AvatarPreviewComponent);

export const Profile = memo<TProfileProps>(function Profile({ saveResult, resultShown, profile, onSave }) {
	const [newAvatar, setNewAvatar] = useState<File>();

	const handleSubmit = (values: object) => {
		values = {
			...values,
			avatar: newAvatar,
			name: (values as any)['fullName'],
		};
		onSave?.(values as TUpdateProfile);
	};

	const renderAvatar = (profile: TProfileInfo) => {
		const file: TFileOrFileData = newAvatar
			? {
					file: newAvatar,
			  }
			: {
					fileData: profile.avatar
						? {
								data: profile.avatar,
								name: '',
						  }
						: undefined,
			  };
		return <ProfileAvatar fullName={profile.fullName} {...file} />;
	};

	const handleAttach = (files: File[]) => {
		setNewAvatar(files[0]);
	};

	return (
		<Fragment>
			{saveResult.fold(
				null,
				null,
				e => (
					<Alert color="danger" isOpen={resultShown}>
						Error: failed to update the profile.
					</Alert>
				),
				() => (
					<Alert color="success" isOpen={resultShown}>
						<strong>Success:</strong> Your profile has been updated.
					</Alert>
				),
			)}
			<Card>
				<RenderRemoteData
					data={profile}
					success={profile => (
						<CardBody>
							<Form onSubmit={handleSubmit} initialValues={profile}>
								{({ handleSubmit }) => (
									<form onSubmit={handleSubmit}>
										<h4 className="bold-font">Avatar</h4>
										<Dropzone noClick noKeyboard onDropAccepted={handleAttach} multiple={false}>
											{({ getRootProps, getInputProps, open }) => (
												<Media {...getRootProps()}>
													{renderAvatar(profile)}
													<input {...getInputProps()} />
													<Media body>
														<Button color="secondary" className="margin-top" onClick={open}>
															Change avatar
														</Button>
													</Media>
												</Media>
											)}
										</Dropzone>
										<div className="margin-top" />

										<Field name="fullName">
											{({ input }) => (
												<div className="margin-bottom">
													<h4 className="bold-font">Name</h4>
													<Input
														className="form-control form-control-lg"
														{...input}
														type="text"
													/>
												</div>
											)}
										</Field>
										<Field name="email">
											{({ input }) => (
												<div className="margin-bottom">
													<h4 className="bold-font">Email</h4>
													<Input bsSize="lg" {...input} type="email" />
												</div>
											)}
										</Field>
										<LoadingButton
											type="submit"
											color="primary"
											disabled={saveResult.isPending()}
											pending={saveResult.isPending()}
											size="lg">
											Save
										</LoadingButton>
									</form>
								)}
							</Form>
						</CardBody>
					)}
				/>
			</Card>
		</Fragment>
	);
});

function formatAvatarLetters(fullName: string): string {
	const words = fullName.trim().split(/\s+/, 2);
	return words.length ? words.map(word => word[0]).join('') : 'V'; // 'V' for Volley
}
