import React, { Fragment, useState, useEffect, useCallback } from 'react';
import {
	Navbar,
	Container,
	DropdownToggle,
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem,
	Badge,
	Button,
} from 'reactstrap';
import logoImg from '../../assets/images/logo.svg';
import avatarImg from '../../assets/images/img_avatar.png';
import { RemoteData } from '@devexperts/remote-data-ts';
import { TextLoading } from 'volley-common/dist/components/TextLoading';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { TPlan } from 'volley-common/dist/services/auth.service';
import { routes } from 'volley-common/dist/utils/routes';
import cn from 'classnames';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { TeamSelectorContainer } from '../products/TeamSelectorContainer';
import { history } from '../../utils/history';
import { Option } from 'fp-ts/lib/Option';
import style from './Header.module.scss';
import { Team } from 'volley-common/dist/services/teams.service';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import { UpgradeButtonContainer } from '../../models/profile.utils';

export type THeaderUserInfo = {
	fullName: string;
	email: string;
	isSuperUser: boolean;
	avatar?: string;
	plan: TPlan;
};

export type THeaderProps = {
	onSignOut?: () => void;
	userInfo: RemoteData<Error, THeaderUserInfo>;
	teamId: Option<number>;
	canCreateTeams: boolean;
	teams: RemoteData<Error, Team[]>;
};

const superUserBadge = (
	<Fragment>
		&nbsp;
		<Badge color="primary">Admin</Badge>
	</Fragment>
);

const renderAvatarSuccess = (source: string) => {
	return (
		<img
			alt="Avatar"
			className={cn('avatar avatar-xs quarter-margin-right align-middle', style.avatarSuccess)}
			src={source}
		/>
	);
};

const renderAvatar = (userInfo: RemoteData<Error, THeaderUserInfo>) => {
	if (userInfo.isPending() || userInfo.isInitial()) {
		return <div className={cn('avatar avatar-xs quarter-margin-right align-middle', style.avatarLoading)} />;
	}
	return renderAvatarSuccess(userInfo.map(u => u.avatar).toNullable() || avatarImg);
};

const Header = combineReader(
	TeamSelectorContainer,
	UpgradeButtonContainer,
	(TeamSelectorContainer, UpgradeButtonContainer) =>
		({ userInfo, onSignOut, teamId, canCreateTeams, teams }: THeaderProps) => {
			const showUpgradeBadge = userInfo.exists(
				({ plan }) => plan.type !== 'unlimited' && plan.type !== 'unlimited_early',
			);
			const [pathName, setPathName] = useState(history.location.pathname);
			useEffect(() => {
				return history.listen(location => {
					setPathName(location.pathname);
				});
			}, []);
			const [mainNavOpen, setMainNavOpen] = useState(false);
			const hideMobileMenu = useCallback(() => setMainNavOpen(false), []);

			return (
				<Fragment>
					<div id="main-nav" className={cn(mainNavOpen && 'menu-open')}>
						<a
							className="menu-close menu-toggle-action close-x d-lg-none"
							href="#"
							onClick={e => {
								e.preventDefault();
								hideMobileMenu();
							}}>
							<i className="material-icons">clear</i>
						</a>
						<Navbar className="admin-bar">
							<UncontrolledDropdown nav inNavbar direction="up" tag="div">
								<DropdownToggle nav caret>
									{renderAvatar(userInfo)}{' '}
									<span className="medium-font menu-text align-middle">
										{userInfo
											.map<ReactNode>(user => user.fullName)
											.getOrElseL(() => (
												<TextLoading className={style.userNameLoading} />
											))}
									</span>
									{userInfo.isSuccess() && <i className="material-icons align-middle">unfold_more</i>}
								</DropdownToggle>
								<DropdownMenu className="admin-menu">
									<DropdownItem header tag="div">
										<div className="medium-font no-margin-bottom text-color">
											{userInfo
												.map<ReactNode>(user => (
													<Fragment>
														{user.fullName}
														{user.isSuperUser && superUserBadge}
													</Fragment>
												))
												.getOrElseL(() => (
													<TextLoading />
												))}
										</div>
										<h5 className="extra-light-text-color no-margin-bottom">
											{userInfo
												.map<ReactNode>(user => user.email)
												.getOrElseL(() => (
													<TextLoading />
												))}
										</h5>
									</DropdownItem>
									<DropdownItem divider />
									<Link to={routes.profile} className="dropdown-item" onClick={hideMobileMenu}>
										<i className="material-icons-outlined icon-left">settings</i>
										Profile
									</Link>
									<Link to={routes.notifications} className="dropdown-item" onClick={hideMobileMenu}>
										<i className="material-icons-outlined icon-left">notifications</i>
										Notification settings
									</Link>
									<Link to={routes.billing} className="dropdown-item" onClick={hideMobileMenu}>
										<i className="material-icons-outlined icon-left">credit_card</i>
										Subscription
									</Link>
									<DropdownItem divider />
									<DropdownItem onClick={onSignOut}>
										<i className="material-icons icon-left">exit_to_app</i>
										Sign out
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Navbar>
						<div className="logo-container text-center">
							<Link to={routes.dashboard} className="navbar-brand" onClick={hideMobileMenu}>
								<img src={logoImg} width="100" />
							</Link>
						</div>
						<TeamSelectorContainer canCreateTeams={canCreateTeams} />
						<Navbar className="flex-column margin-bottom">
							<RenderRemoteData
								data={teams}
								DataStatePending={() => (
									<Fragment>
										<div className="nav-link">
											<TextLoading inline>Projects</TextLoading>
										</div>
										<div className="nav-link">
											<TextLoading inline>Archive</TextLoading>
										</div>
									</Fragment>
								)}
								success={() => (
									<Fragment>
										<Link
											to={teamId.fold(routes.dashboard, id => routes.teamDashboard(id))}
											onClick={hideMobileMenu}
											className={cn(
												'nav-link',
												(pathName === routes.dashboard ||
													routes.teamDashboard.regexp.test(pathName)) &&
													'active',
											)}>
											Projects
										</Link>
										<Link
											to={teamId.fold(routes.dashboardArchive, id => routes.teamArchive(id))}
											onClick={hideMobileMenu}
											className={cn(
												'nav-link',
												(pathName === routes.dashboardArchive ||
													routes.teamArchive.regexp.test(pathName)) &&
													'active',
											)}>
											Archive
										</Link>
										{teamId
											.map(teamId => (
												<Fragment>
													<Link
														to={routes.productTeam(teamId)}
														onClick={hideMobileMenu}
														className={cn(
															'nav-link',
															routes.productTeam.regexp.test(pathName) && 'active',
														)}>
														Members
													</Link>
													<Link
														to={routes.productTeamSettings(teamId)}
														onClick={hideMobileMenu}
														className={cn(
															'nav-link',
															routes.productTeamSettings.regexp.test(pathName) &&
																'active',
														)}>
														Team settings
													</Link>
												</Fragment>
											))
											.toUndefined()}
									</Fragment>
								)}
							/>
						</Navbar>
						{showUpgradeBadge && (
							<div className="upgrade-button-container">
								<UpgradeButtonContainer onClick={hideMobileMenu} />
							</div>
						)}
					</div>
					<Navbar fixed="top" id="mobile-nav">
						<Container className="product-card-container">
							<Button
								size="sm"
								color="flat"
								className="menu-toggle menu-toggle-action"
								onClick={() => setMainNavOpen(true)}>
								<i className="material-icons">menu</i>
							</Button>
							<Link to={routes.dashboard} className="navbar-brand mx-auto">
								<img src={logoImg} width={90} />
							</Link>
						</Container>
					</Navbar>
				</Fragment>
			);
		},
);

export default Header;
