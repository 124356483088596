import React, { FC, useState, useCallback, ChangeEvent, useEffect, useMemo, Fragment } from 'react';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { HeaderContainer } from '../header/HeaderContainer';
// eslint-disable-next-line no-restricted-imports
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Form as BootstrapForm,
	Input,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Media,
	Alert,
} from 'reactstrap';
import { Form, Field, FormRenderProps } from 'react-final-form';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { RemoteData } from '@devexperts/remote-data-ts';
import { some } from 'fp-ts/lib/Option';
import Dropzone from 'react-dropzone';
import { AttachmentPreviewContainer, TAttachmentPreviewProps } from 'volley-common/dist/components/AttachmentPreview';
import { Team } from 'volley-common/dist/services/teams.service';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import { formatInitials } from 'volley-common/dist/utils/string.utils';
import css from './TeamSettings.module.scss';
import cn from 'classnames';

export type TUpdateTeamData = {
	avatar?: File;
	name: string;
};

export type TeamSettingsProps = {
	canEdit: boolean;
	resultShown: boolean;
	teamId: number;
	team: RemoteData<Error, Team>;
	onSave: (data: TUpdateTeamData) => void;
	saveResult: RemoteData<Error, unknown>;
	onDelete: () => void;
	deleteResult: RemoteData<Error, unknown>;
};

export const TeamSettingsContent: FC<Omit<TeamSettingsProps, 'team'> & { team: Team }> = ({
	saveResult,
	onSave,
	team,
	deleteResult,
	onDelete,
	teamId,
	canEdit,
}) => {
	const [deleteConfirmation, toggleDeleteConfirmation] = useState(false);
	const showDeleteConfirmation = useCallback(() => toggleDeleteConfirmation(true), []);
	const hideDeleteConfirmation = useCallback(() => toggleDeleteConfirmation(false), []);

	const [newAvatar, setNewAvatar] = useState<File | undefined>(undefined);
	const handleAttach = useCallback((files: File[]) => {
		setNewAvatar(files[0]);
	}, []);

	const renderAvatar = useCallback(
		(props: TAttachmentPreviewProps) => {
			if (props.fileData) {
				return (
					<img
						className={cn('avatar avatar-xl margin-right avatar-rounded', css.avatar)}
						src={props.fileData.data}
					/>
				);
			} else {
				return <div className="avatar avatar-xl margin-right avatar-rounded">{formatInitials(team.name)}</div>;
			}
		},
		[team.name],
	);

	const TeamAvatarContainer = useMemo(() => AttachmentPreviewContainer(renderAvatar), [renderAvatar]);

	const avatarProps: Partial<TAttachmentPreviewProps> = newAvatar
		? {
				file: newAvatar,
		  }
		: team.avatar
		? {
				fileData: {
					data: team.avatar,
					name: '',
				},
		  }
		: {};

	const renderForm: FC<FormRenderProps> = ({ handleSubmit }) => {
		return (
			<BootstrapForm onSubmit={handleSubmit}>
				<div className="margin-bottom">
					<h4 className="bold-font">Team Name</h4>
					<Field
						name="name"
						render={({ input }) => <Input readOnly={!canEdit} bsSize="lg" {...input} type="text" />}
					/>
				</div>
				<div className="padding-top padding-bottom">
					<hr />
				</div>
				<h4 className="bold-font">Team icon</h4>
				<h5 className="light-text-color margin-bottom">
					Upload an image that is 132px square or larger for best results
				</h5>
				<Dropzone noClick={true} noKeyboard={true} onDropAccepted={handleAttach} multiple={false}>
					{({ getRootProps, getInputProps, open }) => (
						<Media {...getRootProps()}>
							<TeamAvatarContainer {...avatarProps} />
							<input {...getInputProps()} />
							{canEdit && (
								<Media body>
									<Button color="secondary" style={{ marginTop: 24 }} onClick={open}>
										Change icon
									</Button>
								</Media>
							)}
						</Media>
					)}
				</Dropzone>

				{canEdit && (
					<Fragment>
						<div className="padding-top padding-bottom">
							<hr />
						</div>
						<LoadingButton pending={saveResult.isPending()} color="primary" size="lg" type="submit">
							Save changes
						</LoadingButton>
					</Fragment>
				)}
			</BootstrapForm>
		);
	};

	const handleSave = useCallback(
		(values: any) => {
			onSave({ name: values.name, avatar: newAvatar });
		},
		[onSave, newAvatar],
	);

	useEffect(() => {
		if (deleteResult.isSuccess()) {
			hideDeleteConfirmation();
		}
	}, [deleteResult, hideDeleteConfirmation]);

	return (
		<Fragment>
			<DeleteConfirmationModal
				isOpen={deleteConfirmation}
				isPending={deleteResult.isPending()}
				onCancel={hideDeleteConfirmation}
				onConfirm={onDelete}
			/>
			<Row className="justify-content-center">
				<Col xl={10}>
					<Card className="margin-top">
						<CardBody>
							<Form initialValues={{ name: team.name }} onSubmit={handleSave} render={renderForm} />
						</CardBody>
					</Card>
					{canEdit && (
						<div className="double-margin-top">
							<Button color="secondary" onClick={showDeleteConfirmation}>
								Delete team
							</Button>
						</div>
					)}
				</Col>
			</Row>
		</Fragment>
	);
};

export const TeamSettings = combineReader(
	HeaderContainer,
	(HeaderContainer): FC<TeamSettingsProps> => props => {
		return (
			<div id="dashboard-container">
				<HeaderContainer teamId={some(props.teamId)} />
				<div className="main">
					<section>
						<Container>
							<Row className="justify-content-center">
								<Col xl={10}>
									<div className="page-header-for-title">
										<h2 className="medium-font title">Team settings</h2>
									</div>
									{props.saveResult.fold(
										null,
										null,
										e => (
											<Alert color="danger" isOpen={props.resultShown}>
												Error: failed to update the team.
											</Alert>
										),
										() => (
											<Alert color="success" isOpen={props.resultShown}>
												<strong>Success:</strong> Team settings have been updated.
											</Alert>
										),
									)}
								</Col>
							</Row>
							<RenderRemoteData
								data={props.team}
								success={data => <TeamSettingsContent {...props} team={data} />}
							/>
						</Container>
					</section>
				</div>
			</div>
		);
	},
);

type DeleteConfirmationModalProps = {
	isOpen: boolean;
	isPending: boolean;
	onCancel: () => void;
	onConfirm: () => void;
};

function DeleteConfirmationModal({ isOpen, isPending, onCancel, onConfirm }: DeleteConfirmationModalProps) {
	const [input, setInput] = useState('');
	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value), []);
	useEffect(() => {
		if (isOpen) {
			setInput('');
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} toggle={onCancel}>
			<ModalHeader toggle={onCancel}>
				<h2 className="padding-left">Delete team?</h2>
			</ModalHeader>
			<ModalBody>
				<div className="padded no-padding-top no-padding-bottom">
					<p>
						This will instantly delete this team, including all comments, notes and projects. This cannot be
						undone.
					</p>
					<form>
						<div className="margin-bottom">
							<Input
								bsSize="lg"
								placeholder="Type 'delete' to confirm"
								type="text"
								value={input}
								onChange={handleChange}
							/>
						</div>
					</form>
				</div>
			</ModalBody>
			<ModalFooter className="no-border-top">
				<Button color="link" className="light-text-color" onClick={onCancel}>
					Cancel
				</Button>
				<LoadingButton
					color="danger"
					pending={isPending}
					disabled={input.toLowerCase() !== 'delete'}
					onClick={onConfirm}>
					Yes, delete this team
				</LoadingButton>
			</ModalFooter>
		</Modal>
	);
}
