import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, success, pending } from '@devexperts/remote-data-ts';
import { map } from 'rxjs/operators';
import { ask } from 'fp-ts/lib/Reader';
import { Billing } from './Billing';
import { ProfileModelType } from '../../../models/profile.model';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { mapRD } from 'volley-common/dist/utils/object.utils';
import { parsePaymentMethod } from 'volley-common/dist/services/auth.service';

export type TBillingContainerContext = {
	profileModel: ProfileModelType;
};

export const BillingContainer = combineReader(Billing, ask<TBillingContainerContext>(), (Billing, { profileModel }) => {
	return withRX(Billing)(() => {
		return {
			defaultProps: {
				plan: initial,
				paymentMethod: initial,
				billingInfo: initial,
			},
			props: {
				plan: profileModel.currentPlan$.pipe(map(success)),
				paymentMethod: profileModel.profile$.pipe(mapRD(profile => profile.chain(parsePaymentMethod))),
				billingInfo: profileModel.profile$.pipe(
					map(profile => profile.chain(p => p.fold(pending, p => success(p.billing)))),
				),
			},
		};
	});
});
