import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, RemoteData } from '@devexperts/remote-data-ts';
import { Subject, Observable } from 'rxjs';
import { map, switchMap, share } from 'rxjs/operators';
import { asks } from 'fp-ts/lib/Reader';
import { shouldShowResult } from 'volley-common/dist/utils/object.utils';
import { Password, TPasswordChange } from './Password';
import { fixNonFieldErrors } from 'volley-common/dist/models/api.model';

export type TPasswordContainerContext = {
	authService: {
		updatePassword(oldPassword: string, newPassword: string): Observable<RemoteData<Error, unknown>>;
	};
};

export const PasswordContainer = asks((ctx: TPasswordContainerContext) => {
	const { authService } = ctx;
	return withRX(Password)(() => {
		const save$ = new Subject<TPasswordChange>();
		const saveResult$ = save$.pipe(
			switchMap(data => authService.updatePassword(data.oldPassword, data.newPassword)),
			map(result => result.mapLeft(fixNonFieldErrors)),
			share(),
		);

		const resultShown$ = saveResult$.pipe(shouldShowResult());

		return {
			defaultProps: {
				saveResult: initial,
				onSave: val => save$.next(val),
				resultShown: false,
			},
			props: {
				saveResult: saveResult$,
				resultShown: resultShown$,
			},
		};
	});
});
