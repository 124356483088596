import { RemoteData } from '@devexperts/remote-data-ts';
import { RenderRemoteData } from '../../../components/RenderRemoteData';
import cn from 'classnames';
import css from './ThumbnailBar.module.scss';
import { Fragment } from 'react';
import { TextLoading } from 'volley-common/dist/components/TextLoading';
import { Button } from 'reactstrap';
// import { ProductImageUploaderContainer } from '../../products/ProductImageUploader';
import { Link } from 'react-router-dom';
import { routes } from 'volley-common/dist/utils/routes';
import { TProductUploadedImage } from 'volley-common/dist/services/products.service';

interface ThumbnailBarProps {
	projectId: number;
	images: RemoteData<Error, TProductUploadedImage[]>;
	selectedImage?: number;
	onSelect?: (url: string) => void;
	onUpload?: () => void;
}

const pending = () => (
	<Fragment>
		<TextLoading className={css.pending} inline />
		<TextLoading className={css.pending} inline />
		<TextLoading className={css.pending} inline />
		<TextLoading className={css.pending} inline />
	</Fragment>
);

export const ThumbnailBar = ({ projectId, images, selectedImage, onSelect, onUpload }: ThumbnailBarProps) => {
	return (
		<div className={css.thumbs}>
			<RenderRemoteData
				data={images}
				DataStatePending={pending}
				success={images => (
					<Fragment>
						{images.map((img, index) => (
							<Link key={img.id} to={routes.productImages(projectId, img.id)}>
								<img
									key={index}
									src={img.file}
									className={cn(css.image, selectedImage === img.id && css.image_selected)}
									onClick={() => onSelect?.(img.file)}
								/>
							</Link>
						))}
					</Fragment>
				)}
			/>
			<Button
				color="secondary"
				className={cn('btn-circle', css.add)}
				onClick={onUpload}
				disabled={images.isPending()}>
				<i className="material-icons">add</i>
			</Button>
		</div>
	);
};
