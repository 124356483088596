import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { DropdownItem } from 'reactstrap';
import { IntegrationCard } from './Integrations';
import slackSvg from 'volley-common/dist/assets/images/slack-logo.svg';
import { IntegrationsMap, IntegrationStore } from 'volley-common/dist/services/export/integration.store';
import { distinctUntilChanged, map, mapTo, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ask } from 'fp-ts/lib/Reader';
import { Subject, EMPTY, merge, of } from 'rxjs';
import { exporters, IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { RemoteData, initial, success, failure } from '@devexperts/remote-data-ts';
import { tuple } from 'fp-ts/lib/function';
import { PaidOption } from 'volley-common/dist/components/PaidOption';
import { routes } from 'volley-common/dist/utils/routes';
import { SlackSettings, SlackSettingsFormModel } from '../integrations/SlackSettings';
import { slack, SlackChannel } from 'volley-common/dist/services/export/slack.service';

interface SlackSettingsToSave {
	channel_id?: string;
	channel_name?: string;
	note_create?: boolean;
}

interface SlackCardProps {
	onConnect: () => void;
	onSaveSettings: (data: SlackSettingsToSave) => void;
	onDisconnect: () => void;
	hasCredentials: boolean;
	integrations: IntegrationsMap;
	productId: number;
	saveResult: RemoteData<Error, unknown>;
	onSubscriptionNeeded?: () => void;
	channels: RemoteData<Error, SlackChannel[]>;
	initialIsEditing?: boolean;
}

const SlackCard = memo<SlackCardProps>(
	({
		onConnect,
		onSaveSettings,
		onDisconnect,
		hasCredentials,
		integrations,
		saveResult,
		onSubscriptionNeeded,
		channels,
		initialIsEditing,
	}) => {
		const [isEditing, setIsEditing] = useState(initialIsEditing ?? false);
		const onStartEditing = () => setIsEditing(true);
		const integration = integrations.slack;

		const menu = hasCredentials ? (
			<Fragment>
				<PaidOption
					isUnlocked={!onSubscriptionNeeded}
					onAllowed={onStartEditing}
					onLocked={onSubscriptionNeeded}>
					Settings
				</PaidOption>
				<DropdownItem divider />
				<DropdownItem onClick={onDisconnect}>Disconnect</DropdownItem>
			</Fragment>
		) : (
			<PaidOption isUnlocked={!onSubscriptionNeeded} onAllowed={onConnect} onLocked={onSubscriptionNeeded}>
				Connect
			</PaidOption>
		);

		const handleSave = useCallback(
			(val: SlackSettingsFormModel) => {
				const channel = val.channel_id ? channels.getOrElse([]).find(c => c.id === val.channel_id) : null;
				onSaveSettings({
					channel_id: channel?.id,
					channel_name: channel?.name,
					note_create: val.note_create,
				});
			},
			[onSaveSettings, channels],
		);

		const initialValue = useMemo<SlackSettingsFormModel>(() => {
			const config: any = integration?.config_details;
			const notifications: any = integration?.notification_details;
			return {
				note_create: notifications?.note_create,
				channel_id: config?.channel_id,
			};
		}, [integration]);

		return (
			<Fragment>
				<SlackSettings
					onClose={() => setIsEditing(false)}
					visible={hasCredentials && isEditing}
					onSave={handleSave}
					channels={channels}
					saveResult={saveResult}
					value={initialValue}
				/>
				<IntegrationCard active={hasCredentials} logo={slackSvg} name={'Slack'} menu={menu} />
			</Fragment>
		);
	},
);

type SlackCardContainerContext = {
	integrationStore: IntegrationStore;
};

export const SlackCardContainer = combineReader(
	exporters,
	slack,
	ask<SlackCardContainerContext>(),
	(exporters, slack, ctx) =>
		withRX(SlackCard)(props$ => {
			const hasCredentials$ = props$.pipe(
				map(p => !!p.integrations.slack),
				distinctUntilChanged(),
			);

			const productId$ = props$.pipe(pluck('productId'), distinctUntilChanged());
			const connect$ = new Subject<void>();
			const connectEffect$ = connect$.pipe(
				withLatestFrom(productId$),
				tap(([, product]) =>
					exporters.connect(IntegrationType.Slack, product, routes.product(product), {
						product,
						type: IntegrationType.Slack,
					}),
				),
				mapTo(success<Error, unknown>(undefined)),
			);

			const saveSettings$ = new Subject<SlackSettingsToSave>();
			const saveSettingsEffect$ = saveSettings$.pipe(
				withLatestFrom(props$),
				switchMap(([{ note_create, ...settings }, { productId, integrations }]) =>
					integrations.slack
						? ctx.integrationStore.update(productId, integrations.slack.id, undefined, settings, {
								note_create,
						  })
						: EMPTY,
				),
			);

			const disconnect$ = new Subject<void>();
			const disconnectEffect$ = disconnect$.pipe(
				withLatestFrom(props$),
				switchMap(([, props]) =>
					props.integrations.slack
						? ctx.integrationStore.remove(props.productId, props.integrations.slack.id)
						: EMPTY,
				),
			);

			const channels$ = props$.pipe(
				map(p => tuple(p.productId, p.integrations.slack?.id)),
				distinctUntilChanged((a, b) => a.every((val, i) => val === b[i])),
				switchMap(([productId, configId]) =>
					configId
						? slack.fold(
								of(failure<Error, SlackChannel[]>(new Error('Slack is not available now'))),
								slack => slack.getChannels(productId, configId),
						  )
						: of(initial),
				),
			);

			return {
				defaultProps: {
					onConnect: () => connect$.next(),
					onSaveSettings: val => saveSettings$.next(val),
					onDisconnect: () => disconnect$.next(),
					hasCredentials: false,
					saveResult: initial,
					channels: initial,
				},
				props: {
					hasCredentials: hasCredentials$,
					saveResult: merge(saveSettingsEffect$, connectEffect$),
					channels: channels$,
				},
				effects$: disconnectEffect$,
			};
		}),
);
