import TimeAgoComponent, { ReactTimeagoProps, Formatter } from 'react-timeago';
import React, { SFC } from 'react';

export const formatter: Formatter = (num, unit, suffix, ms, next) => {
	return unit === 'second' && num < 60 ? 'Less than 1m ago' : next!(num, unit, suffix, ms);
};

export const TimeAgo: SFC<ReactTimeagoProps> = props => (
	<TimeAgoComponent minPeriod={60} formatter={formatter} {...props} />
);
