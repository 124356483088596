import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { ProductService } from 'volley-common/dist/services/products.service';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { asks } from 'fp-ts/lib/Reader';
import { Option } from 'fp-ts/lib/Option';
import { Subject } from 'rxjs';
import React, { memo, ComponentType } from 'react';
import { map, share, withLatestFrom, switchMap, tap, filter } from 'rxjs/operators';
import { history } from '../../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import { SessionServiceClass } from '../../../services/token.service';

type TLeaveButtonProps = {
	loading?: boolean;
	onClick: () => void;
};

const LeaveButton = memo<TLeaveButtonProps>(({ loading, onClick }) => (
	<LoadingButton block color="secondary" size="sm" pending={loading} disabled={loading} onClick={onClick}>
		Leave project
	</LoadingButton>
));

type TLeaveButtonContext = {
	productService: ProductService;
	toastService: ToastService;
	sessionService: SessionServiceClass;
};

type TLeaveButtonContainerProps = TLeaveButtonProps & {
	projectId: number;
	teamId: Option<number>;
	onSuccess?: () => void;
};

export const LeaveButtonContainer = asks((ctx: TLeaveButtonContext) =>
	withRX(LeaveButton as ComponentType<TLeaveButtonContainerProps>)(props$ => {
		const remove$ = new Subject<void>();
		const userId$ = ctx.sessionService.decoded$.pipe(map(token => token.map(t => t.user_id)));

		const isLoading$ = remove$.pipe(
			withLatestFrom(props$, userId$),
			filter(([, , userId]) => userId.isSome()),
			switchMap(([, props, userId]) =>
				ctx.productService.removeCollaborator(props.projectId, userId.getOrElse(-1)).pipe(
					tap(result =>
						result.fold(
							null,
							null,
							() =>
								ctx.toastService.push({
									text: 'Failed to leave the project. Please try again later',
								}),
							() => {
								ctx.toastService.push({ text: 'You have left the project' });
								props.onSuccess && props.onSuccess();
								history.push(props.teamId.fold(routes.dashboard, id => routes.teamDashboard(id)));
							},
						),
					),
				),
			),
			map(rd => rd.isPending()),
			share(),
		);

		return {
			defaultProps: {
				onClick: () => remove$.next(),
			},
			props: {
				loading: isLoading$,
			},
		};
	}),
);
