import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { NoteSelectionView } from './NoteSelectionView';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { asks } from 'fp-ts/lib/Reader';
import { empty } from 'fp-ts/lib/Array';
import { some } from 'fp-ts/lib/Option';
import { ProductService, getMembersOrEmpty } from 'volley-common/dist/services/products.service';
import { initial } from '@devexperts/remote-data-ts';
import { mapRD } from 'volley-common/dist/utils/object.utils';

export const NoteSelectionViewContainer = asks((ctx: { productService: ProductService }) =>
	withRX(NoteSelectionView)(props$ => {
		const productId$ = props$.pipe(map(props => props.productId));

		const imagesFromApi$ = productId$.pipe(switchMap(productId => ctx.productService.getNotes(productId, true)));

		const product$ = productId$.pipe(
			distinctUntilChanged(),
			switchMap(id => ctx.productService.getById(id)),
		);

		const statuses$ = product$.pipe(
			map(p =>
				p
					.toOption()
					.mapNullable(p => p.statuses)
					.getOrElse([]),
			),
		);

		const teamMembers$ = product$.pipe(
			mapRD(some),
			mapRD(getMembersOrEmpty),
			map(rd => rd.getOrElse(empty)),
		);

		return {
			defaultProps: {
				notes: initial,
				teamMembers: [],
				product: initial,
				statuses: empty,
			},
			props: {
				notes: imagesFromApi$,
				teamMembers: teamMembers$,
				product: product$,
				statuses: statuses$,
			},
		};
	}),
);
