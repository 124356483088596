import { planNameToType, PLAN_PRO_NEW, TBillingInfo } from 'volley-common/dist/services/auth.service';
import type { TCommentAuthor } from 'volley-common/dist/services/notes.service';
import { routes } from 'volley-common/dist/utils/routes';
import { LocationDescriptorObject } from 'history';
import { Link, LinkProps } from 'react-router-dom';
import { ProfileModelType } from './profile.model';
import { asks } from 'fp-ts/lib/Reader';
import { memo } from 'react';
import { useObservable } from 'volley-common/dist/utils/react.utils';
import { distinctUntilChanged, map } from 'rxjs/operators';

export function formatAuthorName(author?: Pick<TCommentAuthor, 'name'>): string {
	return (!!author && author.name) || 'Anonymous';
}

export function shouldOfferFirstTimeCoupon(billing: TBillingInfo) {
	return planNameToType(billing.plan_name).exists(p => p === 'starter') && !billing.payment_method_id;
}

export const firstTimeUpgradeRoute: LocationDescriptorObject = {
	pathname: routes.billingPay,
	state: {
		prospectivePlan: PLAN_PRO_NEW.type,
		coupon: 'ONEMONTHFREE',
	},
};

interface UpgradeButtonContainerCtx {
	profileModel: ProfileModelType;
}

export const UpgradeButtonContainer = asks((ctx: UpgradeButtonContainerCtx) => {
	const offerFirstTimeUpgrade$ = ctx.profileModel.profile$.pipe(
		map(p => p.exists(p => p.exists(p => shouldOfferFirstTimeCoupon(p.billing)))),
		distinctUntilChanged(),
	);

	return memo((props: Omit<LinkProps, 'to'> & { trialText?: string; regularText?: string }) => {
		const { trialText = 'Start free trial', regularText = 'Upgrade' } = props;
		const offerFirstTimeUpgrade = useObservable(offerFirstTimeUpgrade$, false);
		const text = offerFirstTimeUpgrade ? trialText : regularText;
		const link = offerFirstTimeUpgrade ? firstTimeUpgradeRoute : routes.billingPay;

		return (
			<Link className={'btn btn-terciary'} style={{ fontSize: 14 }} {...props} to={link}>
				{text}
			</Link>
		);
	});
});
