import React from 'react';
import cn from 'classnames';
import style from './Review.module.scss'; // TODO: extract styles
import { TProductImage, TProductUploadedImage } from 'volley-common/dist/services/products.service';
import { VideoPlayer } from './VideoPlayer';
import { TReviewState } from './Review';
import { Button } from 'reactstrap';
import { CreateNoteForUploadedImage, ImagePanelV2 } from './ImagePanelV2';

export type TProjectType = 'extension' | 'uploaded';

interface NoteViewProps {
	maxHeight?: boolean;
	notes: TProductImage[];
	currentNoteId?: number;
	onClickNote?: (note: TProductImage) => void;
	onPostNote?: (note: Omit<CreateNoteForUploadedImage, 'productId'>) => void;
	uploadedImage?: TProductUploadedImage;
	onReceivedNaturalSize?: (val: TReviewState['naturalSize']) => void;
	onToggleCommentsBar?: () => void;
	className?: string;
	theme?: {
		container?: string;
		wrapper?: string;
		commentsToggle?: string;
	};
	type: TProjectType | null;
}

export const NoteView = ({
	notes,
	uploadedImage,
	onClickNote,
	currentNoteId,
	onPostNote,
	onReceivedNaturalSize,
	onToggleCommentsBar,
	className,
	theme,
	maxHeight,
	type,
}: NoteViewProps) => {
	const firstNote = notes[0];
	const isUploaded = type === 'uploaded';
	const isVideo = !isUploaded && !!firstNote?.video;
	const imageSrc = isUploaded ? uploadedImage?.file : firstNote?.image;
	return (
		<div
			className={cn(
				style.imgContainer,
				type !== null && style[`imgContainer_${type}`],
				className,
				theme?.container,
			)}>
			<div className={cn(style.imgWrapper, theme?.wrapper, isVideo && style.imgWrapper_video)}>
				<div className={cn(style.imgCommentContainer, isVideo && style.imgCommentContainer_video)}>
					{isVideo ? (
						<VideoPlayer
							key={'video-' + firstNote.id}
							src={firstNote.video!}
							duration={firstNote.video_duration ?? 0}
						/>
					) : (
						/*<ImagePanel
							maxHeight={maxHeight}
							pixelRatio={image.pixel_ratio}
							src={image.image!}
							left={image.x}
							top={image.y}
							overlaySrc={image.overlay}
							noteId={image.id}
							onReceivedNaturalSize={onReceivedNaturalSize}
						/>*/
						<ImagePanelV2
							isUploaded={isUploaded}
							maxHeight={maxHeight}
							// pixelRatio={image.pixel_ratio}
							image={imageSrc!}
							notes={notes}
							// left={image.x}
							// top={image.y}
							overlaySrc={isUploaded ? undefined : firstNote.overlay}
							// noteId={image.id}
							onReceivedNaturalSize={onReceivedNaturalSize}
							currentNoteId={currentNoteId}
							onPostNote={note => onPostNote?.({ ...note, uploadedImageId: uploadedImage!.id })}
							onClickNote={onClickNote}
						/>
					)}
					<div className={cn('half-margin-top text-center d-lg-none d-md-none', style.showCommentToggle)}>
						<Button
							color="link"
							onClick={onToggleCommentsBar}
							size="sm"
							className={cn('light-text-color', theme?.commentsToggle)}>
							Show comments
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
