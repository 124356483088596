import { ask } from 'fp-ts/lib/Reader';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { PaymentPage, TPaymentPageResult } from './PaymentPage';
import { initial, success, failure, RemoteData } from '@devexperts/remote-data-ts';
import {
	AuthService,
	getPlan,
	parsePaymentMethod,
	isAppSumoPlan,
	offers,
	coupons,
	CouponInfo,
	TPlan,
	planNameToType,
} from 'volley-common/dist/services/auth.service';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { Subject, of, Observable, merge } from 'rxjs';
import { switchMap, map, tap, withLatestFrom, startWith, pluck, filter, distinctUntilChanged } from 'rxjs/operators';
import { switchMapRD, mapRD, tapRD, isDefined } from 'volley-common/dist/utils/object.utils';
import { ProfileModelType } from '../../../models/profile.model';
import { toPaymentMethodData } from './PaymentForm';
import { AppState } from '../../../models/app-state.model';
import { history } from '../../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import { identity } from 'fp-ts/lib/function';
import { none, some, Option } from 'fp-ts/lib/Option';
import { YouRockAppState } from '../YouRockContainer';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { TApiError, getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';

type TPaymentPageContainerContext = {
	authService: AuthService;
	profileModel: ProfileModelType;
	appState: AppState<YouRockAppState>;
	toastService: ToastService;
};

export const PaymentPageContainer = combineReader(
	PaymentPage,
	ask<TPaymentPageContainerContext>(),
	(PaymentPage, ctx) =>
		withRX(PaymentPage)(props$ => {
			const currentPlanType$ = ctx.profileModel.currentPlan$.pipe(map(plan => plan.type));

			const prospectivePlan$ = props$.pipe(
				map(props => {
					if (
						props.prospectivePlan === offers.blackFri2020.plan.type &&
						props.coupon === offers.blackFri2020.coupon
					) {
						return offers.blackFri2020.plan;
					} else {
						return getPlan(props.prospectivePlan);
					}
				}),
			);

			// TODO: coupon provided via URL should be passed to this page - need to retest

			const applyCoupon$ = new Subject<string | null>();
			const couponFromUrl$ = props$.pipe(pluck('coupon'), filter(isDefined), distinctUntilChanged());
			const appliedCoupon$ = merge(applyCoupon$, couponFromUrl$).pipe(
				withLatestFrom(prospectivePlan$),
				map<[string | null, TPlan], RemoteData<string, Option<CouponInfo>>>(([code, plan]) => {
					if (code === null) {
						return success(none);
					}
					const match = coupons.find(
						c =>
							c.code === code &&
							(c.applicableToPlans === undefined || c.applicableToPlans.includes(plan.type)),
					);
					return match ? success(some(match)) : failure('The code is not valid');
				}),
				startWith(success<string, Option<CouponInfo>>(none)),
			);

			const saveData$ = new Subject<TPaymentPageResult>();
			const saveResult$ = saveData$.pipe(
				withLatestFrom(currentPlanType$, appliedCoupon$),
				switchMap(([data, currentPlan, coupon]) => {
					const savePaymentMethodResult$: Observable<RemoteData<Error, unknown>> = data.paymentMethod
						? ctx.authService.savePaymentMethod(toPaymentMethodData(data.paymentMethod))
						: of(success(undefined));
					const couponCode = coupon
						.toOption()
						.chain(identity)
						.fold(undefined, coupon => coupon.code);
					return savePaymentMethodResult$.pipe(
						switchMapRD(() =>
							currentPlan === 'starter' || isAppSumoPlan(currentPlan)
								? ctx.authService.switchFromFreeToPaidPlan(
										data.prospectivePlan,
										data.billingPeriod,
										couponCode,
								  )
								: ctx.authService.switchToAnotherPaidPlan(
										{ planType: data.prospectivePlan },
										data.billingPeriod,
										couponCode,
								  ),
						),
						tap(rd => {
							if (rd.isFailure()) {
								const err = rd.error as TApiError;
								try {
									const message = getNonFieldErrors(err);
									ctx.toastService.push({ text: message.join(' ') });
								} catch (e) {
									ctx.toastService.push({ text: genericErrorMessage });
								}
							}
						}),
						switchMapRD(subscription =>
							ctx.profileModel.refresh().pipe(
								tapRD(() => {
									history.push(routes.dashboard);
									ctx.appState.setState({
										showYouRock: { ...subscription, couponCode },
									});
								}),
							),
						),
					);
				}),
			);

			const prospectivePlanInfo$ = prospectivePlan$.pipe(
				map(plan => ({
					annualCost: plan.yearlyPrice,
					monthlyCost: plan.monthlyPrice,
				})),
			);

			return {
				defaultProps: {
					result: initial,
					currentPaymentMethod: initial,
					onSubmit: (data: TPaymentPageResult) => saveData$.next(data),
					onApplyCoupon: (code: string | null) => applyCoupon$.next(code),
					prospectivePlanInfo: {
						annualCost: 0,
						monthlyCost: 0,
					},
					appliedCoupon: success(none),
				},
				props: {
					result: saveResult$,
					prospectivePlanInfo: prospectivePlanInfo$,
					appliedCoupon: appliedCoupon$,
					currentPaymentMethod: ctx.profileModel.profile$.pipe(
						mapRD(p =>
							p.chain(profile =>
								parsePaymentMethod(profile).map(card => ({
									...card,
									name: profile.name,
									billingPeriod: profile.billing.interval,
									planType: planNameToType(profile.billing.plan_name).getOrElse('starter'),
								})),
							),
						),
					),
				},
			};
		}),
);
