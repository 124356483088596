import * as React from 'react';
import { FC, ReactNode, Fragment } from 'react';
import { Option } from 'fp-ts/lib/Option';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { constant } from 'fp-ts/lib/function';
import { TPlanType, getPlan, BillingPeriod, CouponInfo } from 'volley-common/dist/services/auth.service';
import cn from 'classnames';
import { PartialKeys } from 'volley-common/dist/utils/object.utils';
import { Tooltip } from 'volley-common/dist/components/Tooltip';
import { formatPrice } from 'volley-common/dist/utils/string.utils';
import iconLightning from 'volley-common/dist/assets/images/lightning.png';
import css from './PlanInfoCard.module.scss';

export type TPlanInfoCardProps = {
	title: ReactNode;
	subTitle?: string;
	checkoutSubTitle?: string;
	billingPeriod: BillingPeriod;
	pricePerMonth: number;
	pricePerYear: number;
	features?: ReactNode;
	features2?: ReactNode;
	footer?: ReactNode;
	className?: string;
	topColor?: string;
	priceElement?: ReactNode;
	isCheckout?: boolean;
	coupon?: CouponInfo;
};

export const PlanInfoCard: FC<TPlanInfoCardProps> = ({
	className,
	title,
	subTitle,
	checkoutSubTitle,
	billingPeriod,
	pricePerMonth,
	pricePerYear,
	features,
	features2,
	footer,
	topColor,
	priceElement,
	isCheckout = false,
	coupon,
}) => {
	return (
		<Card className={cn('price-card margin-auto', css.priceCard, className)}>
			<CardHeader style={{ borderTopColor: topColor }}>
				<h1 className={cn('medium-font', !isCheckout && 'half-margin-bottom')}>{title}</h1>
				{isCheckout ? (
					<Fragment>
						{checkoutSubTitle && <h3 className={'double-margin-bottom'}>{checkoutSubTitle}</h3>}
						{billingPeriod === 'month' ? <h4>Total per month</h4> : <h4>Total per year</h4>}
					</Fragment>
				) : subTitle ? (
					<h5 className={'double-margin-bottom'}>{subTitle}</h5>
				) : null}
				{priceElement || renderPrice(pricePerMonth, pricePerYear, billingPeriod, isCheckout, coupon)}
				{/*!isCheckout && billingPeriod === 'year' && (
					<h5 className="no-margin-bottom half-margin-top">
						{pricePerYear > 0 ? 'Billed yearly' : <Fragment>&nbsp;</Fragment>}
					</h5>
				)*/}
				{/* <ul className="card-list list-unstyled margin-top medium-font">{features}</ul> */}
			</CardHeader>
			<CardBody>
				{features2}
				{footer && <div className="half-padding-top">{footer}</div>}
			</CardBody>
		</Card>
	);
};

function renderPrice(
	pricePerMonth: number,
	pricePerYear: number,
	billingPeriod: BillingPeriod,
	isCheckout: boolean,
	coupon?: CouponInfo,
) {
	if (!isCheckout) {
		const actualPricePerMonth = billingPeriod === 'month' ? pricePerMonth : pricePerYear / 12;
		return (
			<h1 className="display-5 inline">
				<span className="price bold-font">$ </span>
				<span className="bold-font dollar-ammount">{formatPrice(actualPricePerMonth)} </span>
				<span className="month">/ month</span>
			</h1>
		);
	}

	const pricePerPeriod = billingPeriod === 'month' ? pricePerMonth : pricePerYear;
	if (coupon) {
		const currentPayment = pricePerPeriod * (1 - coupon.discountPercent / 100);
		if (coupon.numPeriods === 'forever') {
			// Case 1: an eternal discount
			const fullPrice = billingPeriod === 'month' ? pricePerMonth : pricePerMonth * 12;
			return renderPriceWithOriginal(currentPayment, fullPrice);
		} else {
			// Case 2: discount for several periods
			return (
				<Fragment>
					<h1 className="display-5 inline">
						<span className="price bold-font">$ </span>
						<span className="bold-font dollar-ammount">{formatPrice(currentPayment)}</span>
					</h1>
					<p className="bold-font no-margin-bottom quarter-margin-top">
						Then ${formatPrice(pricePerPeriod)}/{renderPeriodName(billingPeriod, 1)} after{' '}
						{coupon.numPeriods} {renderPeriodName(billingPeriod, coupon.numPeriods)}
					</p>
				</Fragment>
			);
		}
	} else if (billingPeriod === 'year') {
		return renderPriceWithOriginal(pricePerYear, pricePerMonth * 12);
	} else {
		return renderPriceWithOriginal(pricePerMonth);
	}
}

function renderPriceWithOriginal(discountedPrice: number, originalPrice?: number) {
	return (
		<h1 className="display-5 inline">
			<span className="price bold-font">$ </span>
			<span className="bold-font dollar-ammount">{formatPrice(discountedPrice)} </span>
			{originalPrice !== undefined && originalPrice !== discountedPrice && (
				<div className="line-through inline" style={{ fontSize: 16, position: 'relative', top: -20 }}>
					<span className="regular-font">$ </span>
					<span className="regular-font" style={{ textDecoration: 'line-through' }}>
						{formatPrice(originalPrice)}
					</span>
				</div>
			)}
		</h1>
	);
}

function renderPeriodName(billingPeriod: BillingPeriod, num: number) {
	return num === 1 ? (billingPeriod === 'month' ? 'month' : 'year') : billingPeriod === 'month' ? 'months' : 'years';
}

const collaboratorsFeature = (
	<Fragment>
		{' '}
		<Tooltip
			parent={'.subscription-view-overlay'}
			target={''}
			trigger={<span className="tooltip-highlight">Unlimited collaborators</span>}>
			Collaborators are invited into a single project. Collaborators can create and respond to feedback within a
			project.
		</Tooltip>
	</Fragment>
);

export const genericPlanFeatures = (plan: TPlanType) => {
	const projects = getPlan(plan).features.maxProjects;
	const projectsPlural = projects.exists(p => p === 1) ? 'project' : 'projects';
	return (
		<Fragment>
			<li>
				<b>{projects.fold('Unlimited', String)}</b> {projectsPlural}
			</li>
			<li>{formatBoldNotesNumber(getPlan(plan).features.maxNotes)}</li>
		</Fragment>
	);
};

export const FreePlanFeatures = constant(
	<ul className="card-list list-unstyled icon-list-better check-list">
		{genericPlanFeatures('starter')}
		<li>Invite unlimited project members</li>
		<li>Integrations (Slack, Trello, Jira...)</li>
		<li>Chrome extension</li>
		<li>Embedable widget</li>
		<li>Export feedback</li>
		<li>Kanban and inbox views</li>
		<li>Every other feature Volley has to offer!</li>
	</ul>,
);

export const FreePlanInfoCard: FC<
	Omit<TPlanInfoCardProps, 'title' | 'subTitle' | 'pricePerMonth' | 'pricePerYear' | 'features'>
> = props => (
	<PlanInfoCard
		title="Starter" // sic!
		subTitle="Get a feel for collaboration with Volley"
		priceElement={<div className={css.textPrice}>Free</div>}
		pricePerMonth={getPlan('starter').monthlyPrice}
		pricePerYear={getPlan('starter').yearlyPrice}
		features2={<FreePlanFeatures />}
		{...props}
	/>
);

export const ProPlanFeatures = constant(
	<Fragment>
		<h4 className="bold-font margin-bottom">Everything in Starter plus...</h4>
		<ul className="card-list list-unstyled icon-list-better check-list">
			{genericPlanFeatures('pro')}
			<li>Remove Volley branding</li>
		</ul>
	</Fragment>,
);

export const ProPlanInfoCard: FC<
	Omit<TPlanInfoCardProps, 'title' | 'subTitle' | 'pricePerMonth' | 'pricePerYear' | 'features'>
> = props => (
	<PlanInfoCard
		title={
			props.isCheckout ? (
				<Fragment>
					<span className={css.shinyText}>Volley Pro</span>{' '}
					<img src={iconLightning} style={{ height: 23, marginLeft: 3 }} />
				</Fragment>
			) : (
				'Pro'
			)
		}
		subTitle="For website professionals"
		checkoutSubTitle="For website professionals... like you!"
		pricePerMonth={getPlan('pro').monthlyPrice}
		pricePerYear={getPlan('pro').yearlyPrice}
		features2={<ProPlanFeatures />}
		{...props}
		className={cn(props.className, css.priceCard_huge)}
	/>
);

export const UnlimitedPlanFeatures = constant(
	<Fragment>
		<h4 className="bold-font margin-bottom">Everything in Basic, plus</h4>
		<ul className="card-list list-unstyled icon-list-better check-list">
			<li>Unlimited team members</li>
			<li>Enterprise level support</li>
			<li>Dedicated account manager</li>
		</ul>
	</Fragment>,
);

export const UnlimitedPlanInfoCard: FC<
	PartialKeys<TPlanInfoCardProps, 'title' | 'subTitle' | 'pricePerMonth' | 'pricePerYear' | 'features'>
> = props => (
	<PlanInfoCard
		title="Unlimited"
		pricePerMonth={getPlan('unlimited').monthlyPrice}
		pricePerYear={getPlan('unlimited').yearlyPrice}
		features={genericPlanFeatures('unlimited')}
		features2={<UnlimitedPlanFeatures />}
		{...props}
	/>
);

export const EnterprisePlanInfoCard: FC<
	PartialKeys<TPlanInfoCardProps, 'title' | 'subTitle' | 'pricePerMonth' | 'pricePerYear' | 'features'>
> = props => (
	<PlanInfoCard
		title="Enterprise"
		subTitle="For scaling teams"
		priceElement={<div className={css.textPrice}>Let's chat</div>}
		pricePerMonth={getPlan('unlimited').monthlyPrice}
		pricePerYear={getPlan('unlimited').yearlyPrice}
		features2={
			<Fragment>
				<h4 className="bold-font margin-bottom">Everything in Pro plus...</h4>
				<ul className="card-list list-unstyled icon-list-better check-list">
					<li>
						<b>Unlimited</b> projects
					</li>
					<li>Dedicated account representative</li>
					<li>Single sign-on (SAML SSO)</li>
					<li>Onboarding & training support</li>
					<li>Webhooks</li>
				</ul>
			</Fragment>
		}
		{...props}
	/>
);

const teamMembersFeature = (plan: TPlanType) => (
	<Tooltip
		target={''}
		parent={'.subscription-view-overlay'}
		trigger={
			<span className="tooltip-highlight">{formatBoldUsersNumber(getPlan(plan).features.maxTeamMembers)}</span>
		}>
		Team members can create and join projects within shared team workspaces. It is posible to add members to
		multiple workspaces.
	</Tooltip>
);

export const TeamPlanFeatures = constant(
	<Fragment>
		<h4 className="bold-font margin-bottom">Everything in Freelance, plus</h4>
		<ul className="card-list list-unstyled icon-list-better check-list">
			<li>Team workspaces</li>
			<li>{teamMembersFeature('team')}</li>
			<li>Project folders - coming soon</li>
		</ul>
	</Fragment>,
);

export const TeamPlanInfoCard: FC<
	PartialKeys<TPlanInfoCardProps, 'title' | 'subTitle' | 'pricePerMonth' | 'pricePerYear' | 'features'>
> = props => (
	<PlanInfoCard
		title="Team"
		pricePerMonth={getPlan('team').monthlyPrice}
		pricePerYear={getPlan('team').yearlyPrice}
		features={genericPlanFeatures('team')}
		features2={<TeamPlanFeatures />}
		{...props}
		className={cn(props.className, !props.isCheckout && 'popular')}
	/>
);

/**
 * Displays the plan card with the price and features
 * This component must be able to render all currently offered plans (i.e. all plans that should be
 * available at /settings/billing/pay). All other cases may be ignored.
 */
export const DisplayPlanInfoCard: FC<
	{ plan: TPlanType; coupon?: CouponInfo } & Omit<
		TPlanInfoCardProps,
		'title' | 'subTitle' | 'pricePerMonth' | 'pricePerYear' | 'features'
	>
> = ({ plan, ...props }): JSX.Element => {
	switch (plan) {
		case 'starter':
			return <FreePlanInfoCard {...props} />;
		case 'pro':
			return <ProPlanInfoCard {...props} />;
		case 'team':
			return <TeamPlanInfoCard {...props} />;
		case 'unlimited':
			return <UnlimitedPlanInfoCard {...props} />;
		default:
			return <Fragment />;
	}
};

/**
 * Displays the plan features.
 * This component must support all plans that are currently used by at least 1 user. This is required
 * because these users should be able to see their plan info in the Settings / Billing page.
 */
export const DisplayPlanFeatures: FC<{ plan: TPlanType; withTeamMembersCount?: boolean }> = ({
	plan,
	withTeamMembersCount,
}): JSX.Element => {
	const base = genericPlanFeatures(plan);
	if (withTeamMembersCount) {
		const canUseTeams = !getPlan(plan).features.maxTeamMembers.exists(limit => limit === 0);
		if (canUseTeams) {
			return (
				<Fragment>
					{base}
					<li>{teamMembersFeature(plan)}</li>
				</Fragment>
			);
		}
	}
	return base;
};

function formatBoldUsersNumber(n: Option<number>) {
	const isMany = n.toNullable() !== 1;
	return (
		<Fragment>
			{n.fold<ReactNode>('Unlimited', x => x + 1)} {isMany ? 'team members' : 'team member'}
		</Fragment>
	);
}

function formatBoldNotesNumber(n: Option<number>) {
	const isMany = n.toNullable() !== 1;
	return (
		<Fragment>
			<b>{n.fold<ReactNode>('Unlimited', x => x)}</b> {isMany ? 'notes' : 'note'}
		</Fragment>
	);
}
