import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { RemoteData } from '@devexperts/remote-data-ts';
import { TProduct, getMembers } from 'volley-common/dist/services/products.service';
import { format } from 'date-fns';
import { Button } from 'reactstrap';
import MembersIndicator from './MembersIndicator';
import { Option } from 'fp-ts/lib/Option';
import { routes } from 'volley-common/dist/utils/routes';
import { asks } from 'fp-ts/lib/Reader';
import { ProductView } from './review/Review';
import { Selectbox } from 'volley-common/dist/components/Selectbox';
import cn from 'classnames';
import css from './NavBar.module.scss';

type TBrowseModeProps = {
	onShare: () => unknown;
	onExport?: () => void;
	onOpenSettings?: () => void;
	product: RemoteData<Error, TProduct>;
	userId: Option<number>;
	teamId: Option<number>;
	view: ProductView;
	onChangeView?: (view: ProductView) => void;
};

const viewOptions: ProductView[] = ['inbox', 'board'];

const selectboxTheme = {
	container: 'ml-2',
	button: cn(css.viewSelector),
	menu: cn(css.viewSelectorMenu, 'full-width'),
};

const BrowseModeHeaderReader = asks(
	(): FC<TBrowseModeProps> =>
		({ product, onShare, onExport, onOpenSettings, userId, teamId, view, onChangeView }) => {
			return (
				<nav className="navbar navbar-light bg-light fixed-bottom no-padding" id="bottom-bar">
					<div className="container-fluid">
						<div className={cn('col text-nowrap', css.left)}>
							<div className="d-inline-block">
								<Link
									className="navbar-brand padding-bottom padding-top"
									to={teamId.fold(routes.dashboard, id => routes.teamDashboard(id))}>
									<div className="d-inline-block">
										<i
											className="material-icons light-text-color"
											style={{ position: 'relative', top: 3 }}>
											keyboard_arrow_left
										</i>
									</div>
								</Link>
							</div>
							{product
								.map(product => (
									<div className={cn('d-inline-block product-title', css.leftTitle)}>
										<p className="no-margin-bottom product-name">{product.name}</p>
										<h6 className={cn('product-date no-margin-bottom', css.created)}>
											Created {formatCreateDate(product.created_at)}
										</h6>
									</div>
								))
								.toNullable()}
						</div>
						<div className={cn('col', css.right)}>
							{product
								.map(product => (
									<MembersIndicator
										onShowMore={onShare}
										ownerUserId={product.owner && product.owner.id}
										currentUserId={userId.toUndefined()}
										members={getMembers(product)}
									/>
								))
								.toNullable()}
							<Button color="primary" className="share-button quarter-margin-right" onClick={onShare}>
								Share
							</Button>
							{onExport && (
								<Button
									color="secondary"
									className="share-button quarter-margin-right"
									onClick={onExport}>
									Export
								</Button>
							)}{' '}
							{onChangeView && (
								<Selectbox<ProductView>
									theme={selectboxTheme}
									renderOption={renderViewOption}
									options={viewOptions}
									value={view}
									onChange={onChangeView}
								/>
							)}
							&nbsp;
							{onOpenSettings && (
								<Button color="secondary" className="btn-icon d-inline-block" onClick={onOpenSettings}>
									<i className="material-icons-outlined">settings</i>
								</Button>
							)}
						</div>
					</div>
				</nav>
			);
		},
);

function formatCreateDate(date: Date): string {
	return format(date.toString(), 'MMM D, YYYY');
}

function renderViewOption(opt: ProductView | undefined) {
	switch (opt) {
		case 'board':
			return (
				<Fragment>
					<div className="d-inline-block">
						<i className="material-icons-outlined">view_week</i>
						&nbsp;
					</div>
					Board view
				</Fragment>
			);
		case 'inbox':
			return (
				<Fragment>
					<div className="d-inline-block">
						<i className="material-icons-outlined">inbox</i>
						&nbsp;
					</div>
					Inbox view
				</Fragment>
			);
		default:
			return '';
	}
}

export default BrowseModeHeaderReader;
