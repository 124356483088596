import React, { ReactNode, useState, memo, useCallback, useMemo, Fragment } from 'react';
import {
	Col,
	Row,
	CustomInput,
	ModalBody,
	Button,
	ModalHeader,
	Input,
	Media,
	UncontrolledPopover,
	PopoverBody,
	UncontrolledTooltip,
	ModalFooter,
} from 'reactstrap';
import widgetLogoSvg from 'volley-common/dist/assets/images/widget-logo.svg';
import unlockImg from 'volley-common/dist/assets/images/unlock.svg';
import widgetPreviewSvg from 'volley-common/dist/assets/images/widget-button-preview.svg';
import cn from 'classnames';
import { TProduct, TProductWidget, TProductWidgetUpdate } from 'volley-common/dist/services/products.service';
import { RemoteData } from '@devexperts/remote-data-ts';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { Form, Field } from 'react-final-form';
import { TFileOrFileData } from 'volley-common/dist/utils/axios';
import Dropzone from 'react-dropzone';
import { ask } from 'fp-ts/lib/Reader';
import { AxiosInstance } from 'axios';
import { WidgetTrigger } from 'volley-common/dist/components/WidgetTrigger';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { trimTrailingSlashes } from 'volley-common/dist/utils/string.utils';
import { env } from '../../../env';
import { WidgetPreview, WidgetLogoContainer } from './WidgetPreview';
import css from './WidgetSettingsTab.module.scss';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import CopyToClipboard from 'react-copy-to-clipboard';
import { v4 as uuid } from 'uuid';
import { CirclePicker } from 'react-color';
import { Modal } from '../../../components/Modal';
import { planNameToType, getPlan } from 'volley-common/dist/services/auth.service';
import { fromNullable } from 'fp-ts/lib/Option';
import { useSubscriptionView } from '../../subscribe/SubscriptionViewContainer';
import { UpgradeButtonContainer } from '../../../models/profile.utils';

export const colors = [
	'#ffffff',
	'#f44336',
	'#e91e63',
	'#9c27b0',
	'#673ab7',
	'#2196f3',
	'#03a9f4',
	'#00bcd4',
	'#009688',
	'#4caf50',
	'#8bc34a',
	'#cddc39',
	'#ffeb3b',
	'#ff9800',
	'#ff5722',
	'#795548',
	'#607d8b',
	'#000000',
];

export const projectOwnerShouldUpgrade = 'The project owner can upgrade their plan to unlock this feature';
export const teamOwnerShouldUpgrade = 'The team owner can upgrade their plan to unlock this feature';

interface ColorFieldProps {
	name: string;
	label: string;
}

const ColorField = memo<ColorFieldProps>(({ name, label }) => {
	const id = useMemo(() => 'color_' + uuid().replace(/-/g, ''), []);
	return (
		<div className="col-6">
			<div className="margin-bottom">
				<h4 className="medium-font">{label}</h4>
				<Field name={name}>
					{({ input }) => (
						<Fragment>
							<div className={css.colorPreviewField} id={id}>
								<span
									className={cn(css.colorPreview, {
										[css.colorPreview_white]: ['#fff', '#ffffff'].includes(
											input.value.toLowerCase(),
										),
									})}
									style={{ backgroundColor: input.value }}
								/>
								<Input bsSize="lg" {...input} className={css.colorPreviewInput} type="text" />
							</div>
							<UncontrolledPopover
								hideArrow
								className={css.colorPopover}
								delay={{ show: 0, hide: 300 }}
								placement="bottom-start"
								trigger="hover"
								target={`#${id}`}>
								<PopoverBody>
									<CirclePicker
										className={css.colorBox}
										colors={colors}
										onChangeComplete={e => input.onChange(e.hex)}
										color={input.value}
									/>
								</PopoverBody>
							</UncontrolledPopover>
						</Fragment>
					)}
				</Field>
			</div>
		</div>
	);
});

interface UpgradeToUnlockProps {
	visible: boolean;
	onClose: () => void;
	onUpgrade: () => void;
}

const UpgradeToUnlock = combineReader(UpgradeButtonContainer, UpgradeButtonContainer =>
	memo<UpgradeToUnlockProps>(({ visible, onClose, onUpgrade }) => {
		return (
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader className="no-border-bottom no-padding-bottom" toggle={onClose} />
				<ModalBody className="text-center">
					<div className="padded no-padding-top no-padding-bottom">
						<img src={unlockImg} width={70} className="margin-bottom" />
						<h2>Upgrade to unlock</h2>
						<p className="light-text-color">
							This feature is not available in your plan. Upgrade your account for access.
						</p>
						<UpgradeButtonContainer className="btn btn-primary btn-lg" onClick={onUpgrade} />
					</div>
				</ModalBody>
				<ModalFooter className="no-border-top" />
			</Modal>
		);
	}),
);

interface WidgetSettingsTabProps {
	product: TProduct;
	saveResult: RemoteData<Error, unknown>;
	onSaveWidget: (val: TProductWidgetUpdate) => void;
	userId?: number;
}

export const initialWidgetData = {
	text_color: '#ffffff',
	background_color: '#0042FF',
	button_text: 'Leave feedback',
	position: 'middle_right',
	powered_by_volley: true,
	only_members: false,
};

type AllowStatus = 'allow' | 'suggestUpgrade' | 'block';

export const WidgetSettingsTab = combineReader(
	UpgradeToUnlock,
	WidgetPreview,
	useSubscriptionView,
	ask<{ toastService: ToastService; axios: AxiosInstance }>(),
	(UpgradeToUnlock, WidgetPreview, useSubscriptionView, ctx) => {
		function formatCodeSnippet(widgetId: string) {
			return `
<link rel="stylesheet" href="${trimTrailingSlashes(env.widgetUrl || '')}/static/css/widget.css">
<script type="text/javascript" data-widget="${trimTrailingSlashes(
				ctx.axios.defaults.baseURL || '',
			)}/api/widgets/public/${widgetId}" src="${trimTrailingSlashes(env.widgetUrl || '')}/widget.js"></script>
	`.trim();
		}

		return memo<WidgetSettingsTabProps>(({ product, onSaveWidget, saveResult, userId }) => {
			const { showSubscriptionView, subscriptionView } = useSubscriptionView();
			const id = useMemo(() => uuid(), []);

			const [thumbnail, setThumbnail] = useState<TFileOrFileData>(
				product.widget && product.widget.logo ? { fileData: { data: product.widget.logo, name: '' } } : {},
			);

			const handleAttach = useCallback(
				(files: File[]) => {
					files.length > 0 && setThumbnail({ file: files[0] });
				},
				[setThumbnail],
			);

			const handleSaveWidget = useCallback(
				(values: any) => {
					onSaveWidget({
						...values,
						logo: thumbnail ? thumbnail.file : undefined,
					});
				},
				[onSaveWidget, thumbnail],
			);

			const teamOwnerPlan = product.team && product.team.owner.billing && product.team.owner.billing.plan_name;
			const productOwnerPlan = product.owner && product.owner.billing && product.owner.billing.plan_name;
			const ownerPlan = fromNullable(teamOwnerPlan)
				.chain(planNameToType)
				.orElse(() => fromNullable(productOwnerPlan).chain(planNameToType));
			const isOwner = userId === (product.team ? product.team.owner.id : product.owner && product.owner.id);
			const canHideBranding: AllowStatus = ownerPlan
				.map(getPlan)
				.fold('suggestUpgrade', plan =>
					plan.features.removeWidgetBranding ? 'allow' : isOwner ? 'suggestUpgrade' : 'block',
				);
			const canUseOnlyMembers: AllowStatus = ownerPlan
				.map(getPlan)
				.fold('suggestUpgrade', plan =>
					plan.features.restrictWidgetToMembersOnly ? 'allow' : isOwner ? 'suggestUpgrade' : 'block',
				);
			const upgradeTooltip = product.team ? teamOwnerShouldUpgrade : projectOwnerShouldUpgrade;

			const initialWidget = product.widget || ({} as Partial<TProductWidget>);
			const initialValue = useMemo(
				() => ({
					text_color: initialWidget.text_color || initialWidgetData.text_color,
					background_color: initialWidget.background_color || initialWidgetData.background_color,
					button_text: initialWidget.button_text || initialWidgetData.button_text,
					position: initialWidget.position || initialWidgetData.position,
					only_members:
						initialWidget.only_members === undefined
							? initialWidgetData.only_members
							: initialWidget.only_members,
					powered_by_volley:
						initialWidget.powered_by_volley === undefined
							? initialWidgetData.powered_by_volley
							: initialWidget.powered_by_volley,
				}),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[initialWidget],
			);

			const widgetId = product.widget && product.widget.id;
			const codeSnippet = useMemo(() => (widgetId ? formatCodeSnippet(widgetId) : undefined), [widgetId]);

			const [brandingRemoveStatus, setBrandingRemoveStatus] = useState(null as null | 'warning' | 'upgrade');

			function renderRestrictedFeature(
				name: string,
				label: ReactNode,
				allowStatus: AllowStatus,
				inverse: boolean,
			) {
				return (
					<Fragment>
						<h4 className="medium-font d-inline align-middle">{label}</h4>
						<div className="d-inline float-right" id={`WidgetSettingsTab-${id}-${name}-switch`}>
							<Field name={name} type="checkbox">
								{({ input }) => (
									<CustomInput
										className={css.switcher}
										checked={inverse ? !input.checked : input.checked}
										onChange={e => {
											if (!e.target.checked || allowStatus === 'allow') {
												input.onChange(inverse ? !e.target.checked : e.target.checked);
											} else if (allowStatus === 'suggestUpgrade') {
												setBrandingRemoveStatus('warning');
											}
										}}
										id={`WidgetSettingsTab-${name}`}
										name={`WidgetSettingsTab-${name}`}
										type="switch"
									/>
								)}
							</Field>
						</div>
						{allowStatus !== 'allow' && (
							<i
								className="material-icons d-inline float-right align-middle"
								style={{ color: '#FFAA00' }}
								id={`WidgetSettingsTab-${id}-${name}-lock`}>
								lock
							</i>
						)}
						{allowStatus === 'block' && (
							<Fragment>
								<UncontrolledTooltip target={`WidgetSettingsTab-${id}-${name}-lock`}>
									{upgradeTooltip}
								</UncontrolledTooltip>
								<UncontrolledTooltip target={`WidgetSettingsTab-${id}-${name}-switch`}>
									{upgradeTooltip}
								</UncontrolledTooltip>
							</Fragment>
						)}
						<hr />
					</Fragment>
				);
			}

			return (
				<Form onSubmit={handleSaveWidget} initialValues={initialValue}>
					{({ handleSubmit, values }: any) => (
						<div className="settings-container double-margin-top">
							<UpgradeToUnlock
								visible={brandingRemoveStatus === 'warning'}
								onClose={() => setBrandingRemoveStatus(null)}
								onUpgrade={() => {
									setBrandingRemoveStatus(null);
									showSubscriptionView();
								}}
							/>
							{subscriptionView}
							<div className="settings-section double-margin-bottom">
								<img className="margin-bottom" src={widgetLogoSvg} width="317px" />
								<p>
									Embed this brandable widget on your site to quickly capture feedback from anyone,
									sent directly to this project.
								</p>
							</div>
							<div className="settings-section double-margin-bottom">
								<h3 className="half-margin-bottom medium-font">Widget code</h3>
								<p>
									Copy the following JavaScript and paste it as close to the opening <b>{'<head>'}</b>{' '}
									tag as possible on every page you wish to display this widget.
								</p>
								{codeSnippet && (
									<div className="code-panel">
										<div className="panel-body">
											<code>{codeSnippet}</code>
											<div className="text-right padding-top">
												<CopyToClipboard
													text={codeSnippet}
													onCopy={() =>
														ctx.toastService.push({ text: 'Widget code copied' })
													}>
													<Button color="primary">Copy</Button>
												</CopyToClipboard>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="settings-section double-margin-bottom">
								<h3 className="half-margin-bottom medium-font">Widget button</h3>
								<p>Style the appearance of your feedback button</p>
								<Row>
									<Col className="col-12" sm={5}>
										<div className="padded">
											<div className={cn(css.trigger)}>
												<img className="margin-bottom" src={widgetPreviewSvg} width="100%" />
												<WidgetTrigger
													className={css.triggerPreview}
													position={values.position}
													theme={{
														bgColor: values.background_color,
														fgColor: values.text_color,
													}}
													children={values.button_text}
												/>
											</div>
										</div>
									</Col>
									<Col className="col-12" sm={7}>
										<Row>
											<ColorField label="Text color" name="text_color" />
											<ColorField label="Button color" name="background_color" />
										</Row>
										<div className="margin-bottom">
											<h4 className="medium-font">Button text</h4>
											<Field name="button_text">
												{({ input }) => <Input bsSize="lg" {...input} type="text" />}
											</Field>
										</div>
										<div className="margin-bottom">
											<h4 className="medium-font">Position</h4>
											<Field name="position">
												{({ input }) => (
													<select className="form-control form-control-lg" {...input}>
														<option value="middle_right">Center right</option>
														<option value="middle_left">Center left</option>
													</select>
												)}
											</Field>
										</div>
									</Col>
								</Row>
							</div>
							<div className="settings-section double-margin-bottom">
								<h3 className="half-margin-bottom medium-font">Widget panel</h3>
								<p>Style the appearance of your Volley widget</p>
								<div className="row">
									<div className="col-12 col-sm-5">
										<WidgetPreview logo={thumbnail} poweredByVolley={!!values.powered_by_volley} />
									</div>
									<div className="col-12 col-sm-7">
										<Dropzone
											noClick={true}
											noKeyboard={true}
											onDropAccepted={handleAttach}
											multiple={false}>
											{({ getRootProps, getInputProps, open }) => (
												<div className="margin-bottom">
													<h4 className="medium-font">Upload your logo</h4>
													<input {...getInputProps()} />
													<Media className={cn('margin-top', css.logo)}>
														<WidgetLogoContainer className="margin-right" {...thumbnail} />
														<Media body>
															{/*<a className="btn btn-link text-color" href="#">
                                                                            <i className="material-icons">delete</i>
                                                            </a>*/}
															<Button color="secondary" onClick={open}>
																Change image
															</Button>
														</Media>
													</Media>
												</div>
											)}
										</Dropzone>

										<hr />
										{renderRestrictedFeature(
											'powered_by_volley',
											'Remove Volley branding',
											canHideBranding,
											true,
										)}
										{renderRestrictedFeature(
											'only_members',
											'Only show widget to project members',
											canUseOnlyMembers,
											false,
										)}
									</div>
								</div>
							</div>
							<hr />
							<div className="text-right">
								<LoadingButton
									color="primary"
									size="lg"
									pending={saveResult.isPending()}
									onClick={handleSubmit}>
									Save changes
								</LoadingButton>
							</div>
						</div>
					)}
				</Form>
			);
		});
	},
);
