import { initial, RemoteData } from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/lib/function';
import React, {
	Fragment,
	memo,
	ReactElement,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	filterNotesByStatuses,
	NoteFilterValue,
	NoteStatus,
	TMember,
	TProductImage,
} from 'volley-common/dist/services/products.service';
import completedIcon from 'volley-common/dist/assets/images/completed-lane-check.svg';
import { BoardNoteCard, DraggableKanbanCard, DRAGGABLE_KANBAN_CARD } from './NoteCard';
import { useDragLayer, useDrop } from 'react-dnd';
import cn from 'classnames';
import { NoteFilter } from './NoteFilter';
import { Button } from 'reactstrap';
import { none, Option, some } from 'fp-ts/lib/Option';
import { MaxNotesLimit } from './MaxNotesLimit';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { formatNoteCount, TNoteLimit, TReviewState } from './Review';
import css from './BoardView.module.scss';
import { RenderRemoteData } from '../../../components/RenderRemoteData';
import { Overlay } from 'volley-common/dist/components/Overlay';
import { NoteDetailsPanel } from './NoteDetailsPanel';
import { NoteView } from './NoteView';
import { AttachmentOverlay } from './AttachmentOverlay';
import { empty } from 'fp-ts/lib/Array';
import { StatusEditorModal } from '../settings/StatusEditorModal';
import { DraggableStatus, DraggableStatusItem, DRAGGABLE_STATUS } from '../settings/StatusEditor';

export interface Column {
	status_id: number;
	label: string;
	color: string;
	deleted?: boolean;
}

export function toColumn(s: NoteStatus): Column {
	return {
		status_id: s.id,
		color: s.color,
		label: s.name,
	};
}

export const toNoteStatus = (c: Column, i: number): NoteStatus => ({
	color: c.color,
	id: c.status_id,
	name: c.label,
	order: i,
	is_deleted: !!c.deleted,
});

export interface NoteMove {
	id: number;
	newStatus: number;
}

export interface BoardViewProps {
	images: RemoteData<Error, TProductImage[]>;
	onNoteMove: (move: NoteMove) => void;
	noteFilter: NoteFilterValue;
	onSetNoteFilter: (val: NoteFilterValue) => unknown;
	teamMembers?: TMember[];
	onSaveStatuses: (columns: NoteStatus[]) => void;
	saveStatusesResult: RemoteData<Error, unknown>;
	canDeleteStatus?: (id: number) => Promise<boolean | string>;
	showNotesLimit: Option<TNoteLimit>;
	offerFirstTimeUpgrade: boolean;
	subscriptionOverlay?: ReactElement;
	setSubscriptionOverlay?: (elem: ReactElement | undefined) => void;
	onSelectNote: (note: TProductImage | Pick<TProductImage, 'product_id'>) => void;
	selectedNote: RemoteData<Error, Option<TProductImage>>;
	statuses: NoteStatus[];
}

const noteViewTheme = {
	container: css.noteView,
	wrapper: css.noteViewWrapper,
	commentsToggle: css.commentsToggle,
};

export const BoardView = combineReader(MaxNotesLimit, NoteDetailsPanel, (MaxNotesLimit, NoteDetailsPanel) =>
	memo<BoardViewProps>(
		({
			images,
			onNoteMove,
			noteFilter,
			onSetNoteFilter,
			teamMembers,
			statuses,
			onSaveStatuses,
			saveStatusesResult,
			canDeleteStatus,
			showNotesLimit,
			offerFirstTimeUpgrade,
			subscriptionOverlay,
			setSubscriptionOverlay,
			onSelectNote,
			selectedNote,
		}) => {
			// reset the status filter when switching from inbox to board
			useEffect(() => {
				if (noteFilter.status.length) {
					onSetNoteFilter({ ...noteFilter, status: [] });
				}
			}, [noteFilter, onSetNoteFilter]);

			const [editingStatuses, setEditingStatuses] = useState<Option<Column[]>>(none);
			useEffect(() => {
				saveStatusesResult.isSuccess() && setEditingStatuses(none);
			}, [saveStatusesResult]);

			const saveStatuses = useCallback(
				(cols: Column[]) => {
					onSaveStatuses(cols.map(toNoteStatus));
				},
				[onSaveStatuses],
			);

			const filteredImages = useMemo(
				() => images.map(filterNotesByStatuses(statuses, noteFilter, true)),
				[statuses, images, noteFilter],
			);

			const columns = useMemo(() => statuses.map(toColumn), [statuses]);

			const [commentListOpened, setCommentListOpened] = useState(false);
			const [attachmentOverlay, setAttachmentOverlay] = useState<string>();
			const [naturalSize, setNaturalSize] = useState<TReviewState['naturalSize']>();

			return (
				<div className={css.board}>
					<StatusEditorModal
						isOpen={editingStatuses.isSome()}
						onClose={() => setEditingStatuses(none)}
						onSave={saveStatuses}
						value={editingStatuses.getOrElse(empty)}
						result={initial}
						canDelete={canDeleteStatus}
					/>
					<div className={css.header}>
						<div className={'mr-auto'}>
							<h3 className="medium-font d-inline mr-4 mb-0 align-middle">Status board</h3>
							{filteredImages
								.map(images => (
									<h4 className="medium-font d-inline mb-0 align-middle">
										{formatNoteCount(images.length)}
									</h4>
								))
								.toNullable()}
						</div>
						<div>
							<NoteFilter
								onChange={onSetNoteFilter}
								value={noteFilter}
								teamMembers={teamMembers}
								statuses={empty}
							/>
						</div>
					</div>
					<MaxNotesLimit
						inlineButton
						className={css.noteLimitAlert}
						value={showNotesLimit}
						offerFirstTimeUpgrade={offerFirstTimeUpgrade}
						onSetSubscriptionOverlay={setSubscriptionOverlay ?? constVoid}
					/>
					<div className={css.columns}>
						{columns.map((c, i, arr) => (
							<BoardColumn
								key={c.status_id}
								column={c}
								images={filteredImages}
								onNoteDrop={id => onNoteMove({ id, newStatus: c.status_id })}
								onSettings={() => setEditingStatuses(some(columns))}
								onSelectNote={onSelectNote}
								final={i === arr.length - 1}
							/>
						))}
					</div>
					<RenderRemoteData
						data={selectedNote}
						success={note =>
							note.fold(<Fragment />, note => (
								<Overlay className={cn(css.overlay, commentListOpened && css.overlay_top)}>
									<div className={css.overlayContent}>
										<div className={css.overlayImg}>
											<NoteView
												maxHeight
												theme={noteViewTheme}
												notes={[note]}
												currentNoteId={note.id}
												onReceivedNaturalSize={setNaturalSize}
												onToggleCommentsBar={() => setCommentListOpened(x => !x)}
												type="extension"
											/>
											<Button
												className={css.overlayClose}
												onClick={() => onSelectNote({ product_id: note.product_id })}>
												&times;
											</Button>
										</div>
										<NoteDetailsPanel
											className={css.noteDetailsPanel}
											note={note}
											commentEffect={undefined /* TODO */}
											commentListOpened={commentListOpened}
											onClickAttachment={setAttachmentOverlay}
											onDeleteNote={constVoid /* TODO */}
											onToggleCommentListOpened={val =>
												setCommentListOpened(val === undefined ? x => !x : val)
											}
											onToggleNotesBar={constVoid}
											handleCommentRef={undefined /* TODO */}
											naturalSize={naturalSize}
										/>
									</div>
								</Overlay>
							))
						}
					/>
					{subscriptionOverlay}
					{attachmentOverlay && (
						<AttachmentOverlay src={attachmentOverlay} onClose={() => setAttachmentOverlay(undefined)} />
					)}
				</div>
			);
		},
	),
);

interface BoardColumnProps {
	images: RemoteData<Error, TProductImage[]>;
	column: Column;
	onNoteDrop: (id: number) => void;
	onSettings: () => void;
	onSelectNote: (note: TProductImage) => void;
	final?: boolean;
}

const BoardColumn = memo<BoardColumnProps>(({ images, column, onNoteDrop, onSettings, onSelectNote, final }) => {
	const [drop, dropRef] = useDrop(() => ({
		accept: DRAGGABLE_KANBAN_CARD,
		collect: monitor => ({
			isOver: monitor.isOver(),
		}),
		drop: (item: DraggableKanbanCard) => onNoteDrop(item.id),
	}));

	const noteCount = images
		.map(images => images.reduce((acc, i) => acc + (i.status_id === column.status_id ? 1 : 0), 0))
		.getOrElse(0);
	return (
		<div key={column.status_id} className={cn(css.column, drop.isOver && css.column_drop)} ref={dropRef}>
			<div className={css.columnHeader}>
				<div className={css.columnHeaderTitle}>
					{final ? (
						<img className="mr-2" src={completedIcon} />
					) : (
						<span className={css.columnColor} style={{ backgroundColor: column.color }} />
					)}
					<h4 className="d-inline-block bold-font mb-0">
						{column.label}
						{noteCount ? ` (${noteCount})` : ''}
					</h4>
				</div>
				<div>
					<Button color="link" className={css.columnSettings} onClick={onSettings}>
						<i className="material-icons">settings</i>
					</Button>
				</div>
			</div>
			<div className={css.cards}>
				{images.fold(
					<div className={css.columnStatus}>Initial</div>,
					<div className={css.columnStatus}>Loading...</div>,
					e => (
						<div className={css.columnStatus}>Failed to load</div>
					),
					images =>
						noteCount > 0 ? (
							<Fragment>
								{images.map(p =>
									p.status_id === column.status_id ? (
										<BoardNoteCard
											className={css.card}
											key={p.id}
											note={p}
											isSelected={false}
											onSelect={onSelectNote}
										/>
									) : null,
								)}
							</Fragment>
						) : (
							<div className={css.columnStatus}>No feedback in this column</div>
						),
				)}
			</div>
		</div>
	);
});

const tiltThreshold = 5;

export const DragLayer = () => {
	const d = useDragLayer(monitor => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		currentOffset: monitor.getSourceClientOffset(),
	}));

	const { item, itemType, currentOffset } = d;
	// Debug code below
	// const lastState = useRef<typeof d>(d);
	// if (d.isDragging) {
	// lastState.current = d;
	// }
	// const { item, itemType, currentOffset, isDragging } = lastState.current;

	const isDragging = !!item;
	useEffect(() => {
		document.body.classList.toggle(css.dragging, isDragging);
	}, [isDragging]);

	const tilt = useRef<TiltState | null>(null);
	if (currentOffset) {
		if (tilt.current) {
			switch (tilt.current.direction) {
				case 1: {
					if (currentOffset.x < tilt.current.maxCoord - tiltThreshold) {
						tilt.current.direction = -1;
						tilt.current.maxCoord = currentOffset.x;
					} else {
						tilt.current.maxCoord = Math.max(currentOffset.x, tilt.current.maxCoord);
					}
					break;
				}
				case -1: {
					if (currentOffset.x > tilt.current.maxCoord + tiltThreshold) {
						tilt.current.direction = 1;
						tilt.current.maxCoord = currentOffset.x;
					} else {
						tilt.current.maxCoord = Math.min(currentOffset.x, tilt.current.maxCoord);
					}
					break;
				}
				case 0: {
					if (currentOffset.x > tilt.current.maxCoord + tiltThreshold) {
						tilt.current.direction = 1;
						tilt.current.maxCoord = currentOffset.x;
					} else if (currentOffset.x < tilt.current.maxCoord - tiltThreshold) {
						tilt.current.direction = -1;
						tilt.current.maxCoord = currentOffset.x;
					}
				}
			}
		} else {
			tilt.current = { maxCoord: currentOffset.x, direction: 0 };
		}
	} else {
		tilt.current = null;
	}

	switch (itemType) {
		case DRAGGABLE_KANBAN_CARD: {
			return wrapPreview(
				<BoardNoteCard
					note={(item as DraggableKanbanCard).previewData}
					isSelected={false}
					onSelect={constVoid}
					style={{
						width: 316,
						position: 'fixed',
						transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px) rotateZ(${
							(tilt.current?.direction ?? 0) * 5
						}deg)`,
					}}
				/>,
			);
		}
		case DRAGGABLE_STATUS: {
			return wrapPreview(
				<div
					style={{
						position: 'fixed',
						width: (item as DraggableStatusItem).originalWidth || 434,
						transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
					}}>
					<DraggableStatus
						index={-100}
						status={(item as DraggableStatusItem).previewData}
						onDelete={constVoid}
						onUndelete={constVoid}
					/>
				</div>,
			);
		}
	}
	return <div />;
};

function wrapPreview(children: ReactNode) {
	return (
		<div
			style={{
				position: 'fixed',
				zIndex: 1070,
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				pointerEvents: 'none',
			}}>
			{children}
		</div>
	);
}

type TiltState = {
	maxCoord: number;
	direction: number;
};
