import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, RemoteData } from '@devexperts/remote-data-ts/dist/remote-data';
import { of, Subject, Observable } from 'rxjs';
import { switchMap, filter, delay, startWith, share, map, withLatestFrom } from 'rxjs/operators';
import { ask } from 'fp-ts/lib/Reader';
import { fixNonFieldErrors, TApiError } from 'volley-common/dist/models/api.model';
import { ResetPassword } from './ResetPassword';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';

export type TResetPasswordContainerContext = {
	authService: {
		resetPassword(userId: number, token: string, password: string): Observable<RemoteData<TApiError, unknown>>;
	};
};

export const ResetPasswordContainer = combineReader(
	ResetPassword,
	ask<TResetPasswordContainerContext>(),
	(ResetPassword, ctx) => {
		return withRX(ResetPassword)(props$ => {
			const resetRequest$ = new Subject<string>();
			const resetResult$ = resetRequest$.pipe(
				withLatestFrom(props$),
				switchMap(([newPassword, props]) =>
					ctx.authService.resetPassword(props.userId, props.token, newPassword),
				),
				map(result => result.mapLeft(fixNonFieldErrors)),
				share(),
			);

			const showResult$ = resetResult$.pipe(
				filter(rd => rd.isFailure() || rd.isSuccess()),
				switchMap(() =>
					of(false).pipe(
						delay(5000),
						startWith(true),
					),
				),
			);

			return {
				defaultProps: {
					result: initial,
					showResult: false,
					onSendRequest: (newPassword: string) => resetRequest$.next(newPassword),
				},
				props: {
					result: resetResult$,
					showResult: showResult$,
				},
			};
		});
	},
);
