import React, { useCallback, memo, useEffect, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { CustomInput, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Modal } from '../../../components/Modal';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { v4 as uuid } from 'uuid';
import { isSuccess, RemoteData } from '@devexperts/remote-data-ts';
import { Selectbox, Option } from 'volley-common/dist/components/Selectbox';
import { JiraProject } from 'volley-common/dist/services/export/jira.service';
import { FormState } from 'final-form';
import { empty } from 'fp-ts/lib/Array';
import { validateRequired } from 'volley-common/dist/utils/object.utils';

export interface JiraSettingsFormModel {
	host: string;
	project_name?: string;
	project_key?: string;
	auto_export?: boolean;
}

export const emptyJiraSettings: JiraSettingsFormModel = { host: '' };

interface JiraSettingsProps {
	visible: boolean;
	onClose: () => void;
	onSave: (val: JiraSettingsFormModel) => void;
	saveResult: RemoteData<Error, unknown>;
	value: JiraSettingsFormModel;
	projects: RemoteData<Error, JiraProject[]>;
}

export const JiraSettings = memo<JiraSettingsProps>(({ visible, onClose, onSave, saveResult, value, projects }) => {
	const isSaveSuccess = isSuccess(saveResult);
	const prevSuccess = useRef(isSaveSuccess);
	useEffect(() => {
		if (visible && isSaveSuccess && !prevSuccess.current) {
			onClose();
		}
		prevSuccess.current = isSaveSuccess;
	}, [visible, onClose, isSaveSuccess]);

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader tag="h2" className="no-border-bottom no-padding-bottom double-padding-left" toggle={onClose}>
				Integration Settings
			</ModalHeader>
			<Form
				initialValues={value}
				render={props => <JiraSettingsForm {...props} projects={projects} saveResult={saveResult} />}
				onSubmit={(val: object) => {
					const data = val as JiraSettingsFormModel;
					onSave(data);
				}}
			/>
		</Modal>
	);
});

const validateProject = (val: string, form: JiraSettingsFormModel) => (form.auto_export ? validateRequired(val) : null);

const JiraSettingsForm = memo<FormRenderProps & Pick<JiraSettingsProps, 'saveResult' | 'projects'>>(
	({ handleSubmit, projects, saveResult, ...rest }) => {
		const { values } = rest as unknown as FormState<JiraSettingsFormModel>;
		const [componentId] = useState(() => uuid());
		const projectOptions = projects.getOrElse(empty);
		const renderProjectOption = useCallback(
			(val: string | undefined) =>
				val?.length ? projectOptions.find(o => o.project_key === val)?.project_name ?? val : 'not selected',
			[projectOptions],
		);

		return (
			<form onSubmit={handleSubmit}>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<p className="bold-font half-margin-bottom">Jira settings</p>
						<p>You can manually export notes to Jira using the "Export" button on your project overview.</p>
						<div className="mt-3">
							<div className="form-check custom-checkbox no-padding-left">
								<Field name="auto_export" type="checkbox">
									{({ input }) => (
										<CustomInput
											{...input}
											className="no-padding-left"
											type="checkbox"
											label="Automatically export notes"
											id={`${componentId}-auto-export`}
										/>
									)}
								</Field>
							</div>
						</div>
						<div className="settings-section mb-2" style={{ paddingLeft: 28 }}>
							<Field name="project_key" validate={validateProject}>
								{({ input, meta }) => (
									<FormGroup>
										<label className="mb-0 half-margin-top">Select project</label>
										<Selectbox<string>
											invalid={meta.touched && meta.error}
											disabled={!values.auto_export}
											renderOption={renderProjectOption}
											{...input}
											pending={projects.isPending()}
											children={projectOptions.map(opt => (
												<Option value={opt.project_key} key={opt.project_key}>
													{opt.project_name}
												</Option>
											))}
										/>
									</FormGroup>
								)}
							</Field>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className="no-border-top">
					<LoadingButton type="submit" pending={saveResult.isPending()} color="primary">
						Save changes
					</LoadingButton>
				</ModalFooter>
			</form>
		);
	},
);
