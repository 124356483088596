import { of, Subject, merge } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

function loadBoolean(key: string) {
	const data = localStorage.getItem(key);
	if (data) {
		try {
			const parsed = JSON.parse(data);
			return parsed === true;
		} catch (e) {
			/* fall through */
		}
	}
	return false;
}

export const dismissable = (key: string) => {
	const dismiss$ = new Subject<boolean>();
	const isDismissed$ = of(key).pipe(
		map(loadBoolean),
		share(),
	);
	const isOpen$ = merge(
		isDismissed$,
		dismiss$.pipe(
			tap(val => (val ? localStorage.setItem(key, JSON.stringify(val)) : localStorage.removeItem(key))),
		),
	).pipe(map(dismissed => !dismissed));
	return {
		isOpen$,
		dismiss: (val: boolean) => dismiss$.next(val),
	};
};
