import { ProductService } from 'volley-common/dist/services/products.service';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { asks } from 'fp-ts/lib/Reader';
import { Subject, merge } from 'rxjs';
import React, { memo, ComponentType } from 'react';
import { withLatestFrom, switchMap, tap, distinctUntilChanged, map } from 'rxjs/operators';
import { SessionServiceClass } from '../../../services/token.service';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

type TProspectActionsProps = {
	loadingStatus?: string;
	onResend: () => void;
	onRemove: () => void;
};

const ProspectActions = memo<TProspectActionsProps>(({ loadingStatus, onResend, onRemove }) => (
	<UncontrolledDropdown>
		<DropdownToggle className="btn btn-secondary btn-sm btn-block" disabled={!!loadingStatus}>
			{loadingStatus || '•••'}
		</DropdownToggle>
		<DropdownMenu right>
			<DropdownItem onClick={onResend}>Resend invite</DropdownItem>
			<DropdownItem onClick={onRemove}>Remove</DropdownItem>
		</DropdownMenu>
	</UncontrolledDropdown>
));

type TProspectActionsContext = {
	productService: ProductService;
	toastService: ToastService;
	sessionService: SessionServiceClass;
};

type TProspectActionsContainerProps = TProspectActionsProps & {
	projectId: number;
	prospectId: number;
	onReloadProduct?: () => void;
};

export const ProspectActionsContainer = asks((ctx: TProspectActionsContext) =>
	withRX(ProspectActions as ComponentType<TProspectActionsContainerProps>)(props$ => {
		const remove$ = new Subject<void>();
		const removeResult$ = remove$.pipe(
			withLatestFrom(props$),
			switchMap(([, props]) =>
				ctx.productService.removeProspect(props.projectId, props.prospectId).pipe(
					tap(result =>
						result.fold(
							null,
							null,
							() =>
								ctx.toastService.push({
									text: 'Failed to remove the member. Please try again later',
								}),
							() => {
								props.onReloadProduct && props.onReloadProduct();
							},
						),
					),
				),
			),
		);

		const resend$ = new Subject<void>();
		const resendResult$ = resend$.pipe(
			withLatestFrom(props$),
			switchMap(([, props]) =>
				ctx.productService.resendProspectInvite(props.projectId, props.prospectId).pipe(
					tap(result =>
						result.fold(
							null,
							null,
							() =>
								ctx.toastService.push({
									text: 'Failed to resend the invite. Please try again later',
								}),
							() =>
								ctx.toastService.push({
									text: 'The email has been sent.',
								}),
						),
					),
				),
			),
		);

		const loadingStatus$ = merge(
			removeResult$.pipe(map(rd => (rd.isPending() ? 'Removing...' : undefined))),
			resendResult$.pipe(map(rd => (rd.isPending() ? 'Sending...' : undefined))),
		).pipe(distinctUntilChanged());

		return {
			defaultProps: {
				onRemove: () => remove$.next(),
				onResend: () => resend$.next(),
			},
			props: {
				loadingStatus: loadingStatus$,
			},
		};
	}),
);
