import React, { FC, Fragment, useCallback, useState, useEffect } from 'react';
import { Team } from 'volley-common/dist/services/teams.service';
import { RemoteData, remoteData, success } from '@devexperts/remote-data-ts';
// eslint-disable-next-line no-restricted-imports
import {
	UncontrolledDropdown,
	DropdownToggle,
	Navbar,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalHeader,
	ModalBody,
	Input,
	ModalFooter,
} from 'reactstrap';
import { Option, some, none } from 'fp-ts/lib/Option';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import { sequenceT } from 'fp-ts/lib/Apply';
import cn from 'classnames';
import { TAccountInfo } from 'volley-common/dist/services/auth.service';
import { Avatar } from 'volley-common/dist/components/Avatar';
import { formatInitials } from 'volley-common/dist/utils/string.utils';
import { TextLoading } from 'volley-common/dist/components/TextLoading';
import { FormRenderProps, Field, Form } from 'react-final-form';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { history } from '../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import style from './TeamSelector.module.scss';

interface TeamCreateModalProps {
	isOpen: boolean;
	onCancel: () => void;
	onCreate: (name: string) => void;
	result: RemoteData<Error, unknown>;
}

const TeamCreateModal: FC<TeamCreateModalProps> = ({ isOpen, onCancel, onCreate, result }) => {
	const renderForm: FC<FormRenderProps> = ({ handleSubmit }) => {
		return (
			<form onSubmit={handleSubmit}>
				<div className="double-margin-bottom">
					<div className="position-relative">
						<Field
							name="name"
							render={({ input }) => (
								<Input bsSize="lg" placeholder="E.g. Acme or Acme design" {...input} type="text" />
							)}
						/>
					</div>
				</div>
				<div className="text-center">
					<LoadingButton pending={result.isPending()} color="primary" size="lg">
						Create team
					</LoadingButton>
				</div>
			</form>
		);
	};

	const handleSubmit = useCallback(
		(values: any) => {
			onCreate(values.name);
		},
		[onCreate],
	);

	useEffect(() => {
		if (result.isSuccess()) {
			onCancel();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result]);

	return (
		<Modal isOpen={isOpen} toggle={onCancel}>
			<ModalHeader className="no-border-bottom no-padding-bottom" toggle={onCancel} />
			<ModalBody>
				<div className="padded no-padding-top no-padding-bottom">
					<h2 className="text-center">Create your team</h2>
					<p className="light-text-color text-center">
						Create a workspace where you can collaborate on projects with your whole team
					</p>
					<Form onSubmit={handleSubmit} render={renderForm} />
				</div>
			</ModalBody>
			<ModalFooter className="no-border-top" />
		</Modal>
	);
};

interface TeamSelectorProps {
	teams: RemoteData<Error, Team[]>;
	selectedTeam: RemoteData<Error, Option<Team>>;
	onSelect: (team: Option<number>) => void;
	onCreate: (name: string) => void;
	createResult: RemoteData<Error, unknown>;
	profile: RemoteData<Error, Option<TAccountInfo>>;
	canCreateTeams: boolean;
}

export const TeamSelector: FC<TeamSelectorProps> = ({
	teams,
	selectedTeam,
	onSelect,
	profile,
	onCreate,
	createResult,
	canCreateTeams,
}) => {
	const [createModalVisible, toggleCreateModal] = useState(false);
	const hideCreateModal = useCallback(() => toggleCreateModal(false), [toggleCreateModal]);
	const teamData = sequenceT(remoteData)(teams, selectedTeam);
	const handleCreateTeam = useCallback(() => {
		if (canCreateTeams) {
			toggleCreateModal(true);
		} else {
			history.push(routes.billingPay);
		}
	}, [canCreateTeams]);

	const renderAvatar = (team: Team) =>
		team.avatar ? (
			<Avatar size="xs" user={{ profile: { avatar: team.avatar } }} />
		) : (
			<div className="avatar-default">{formatInitials(team.name)}</div>
		);

	const renderMenu = useCallback(
		(teamData: [Team[], Option<Team>]) => {
			const [teams, selectedTeam] = teamData;
			const selectedTeamId = selectedTeam.map(t => t.id).toNullable();
			return (
				<Fragment>
					<div className={'workspace-menu-inner'}>
						<RenderRemoteData
							data={profile}
							success={profile =>
								profile
									.map(profile => (
										<DropdownItem
											onClick={() => onSelect(none)}
											className={cn(
												'd-flex align-items-center',
												selectedTeam.isNone() && 'active',
											)}>
											<div className="avatar-container">
												<Avatar fallbackToImage size="xs" user={profile} />
											</div>
											<span className="text p-2 mr-auto align-middle">
												<p className="menu-title">{profile.name}</p>
												<h5 className="light-text-color">My projects</h5>
											</span>
										</DropdownItem>
									))
									.getOrElse(<Fragment />)
							}
						/>
						{teams.map(team => (
							<DropdownItem
								onClick={() => onSelect(some(team.id))}
								key={team.id}
								className={cn('d-flex align-items-center', team.id === selectedTeamId && 'active')}>
								<div className="avatar-container">{renderAvatar(team)}</div>
								<span className="text p-2 mr-auto align-middle">
									<p className="menu-title">{team.name}</p>
									<h5 className="light-text-color">Team</h5>
								</span>
							</DropdownItem>
						))}
					</div>
					<div className="add-team-option">
						<DropdownItem divider />
						<DropdownItem className="d-flex align-items-center menu-title" onClick={handleCreateTeam}>
							<div className="avatar-container invert-avatar">
								<div className="avatar-default">+</div>
							</div>
							<span className="text p-2 mr-auto link-color">Create a new team</span>
						</DropdownItem>
					</div>
				</Fragment>
			);
		},
		[profile, onSelect, handleCreateTeam],
	);

	const selectedInfo = selectedTeam.chain(team =>
		team.fold(
			profile.map(user => ({
				avatar: user.fold(<Fragment />, user => (
					<Avatar className={style.userAvatar} fallbackToImage size="xs" user={user} />
				)),
				team: 'My projects',
			})),
			team => success({ avatar: renderAvatar(team), team: team.name }),
		),
	);

	return (
		<Navbar className="team-select-container">
			<TeamCreateModal
				isOpen={createModalVisible}
				onCancel={hideCreateModal}
				onCreate={onCreate}
				result={createResult}
			/>
			<UncontrolledDropdown inNavbar className="nav-item workspace-selector">
				<DropdownToggle
					disabled={teams.isPending()}
					tag="div"
					className="medium-font menu-avatar nav-link dropdown-toggle cursor-pointer">
					<RenderRemoteData
						data={selectedInfo}
						DataStatePending={() => (
							<Fragment>
								<div className="avatar-container inline-block align-middle">
									<Avatar className={style.loadingAvatar} size="xs" />
								</div>{' '}
								<div className="menu-text no-margin align-middle">
									<TextLoading inline>My projects</TextLoading>
								</div>
							</Fragment>
						)}
						success={({ avatar, team }) => (
							<Fragment>
								<div className="avatar-container inline-block align-middle">{avatar}</div>{' '}
								<div className="menu-text no-margin align-middle">{team}</div>{' '}
								<i className="material-icons align-middle">unfold_more</i>
							</Fragment>
						)}
					/>
				</DropdownToggle>
				<DropdownMenu className={cn('workspace-menu menu-avatar')}>
					<RenderRemoteData data={teamData} success={renderMenu} />
				</DropdownMenu>
			</UncontrolledDropdown>
		</Navbar>
	);
};
