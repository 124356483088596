import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { trello } from 'volley-common/dist/services/export/trello.service';
import { TrelloListSelector } from './TrelloListSelector';

export const TrelloListSelectorContainer = combineReader(trello, trello =>
	withRX(TrelloListSelector)(props$ => {
		return {
			defaultProps: {
				// lists: initial,
			},
			props: {
				// lists: lists$,
			},
		};
	}),
);
