import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial } from '@devexperts/remote-data-ts';
import { switchMap, share, withLatestFrom, tap } from 'rxjs/operators';
import { ask } from 'fp-ts/lib/Reader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { filterSuccess, switchMapRD } from 'volley-common/dist/utils/object.utils';
import { PaymentMethodPopup } from './PaymentMethodPopup';
import { PaymentMethod, toPaymentMethodData } from '../../subscribe/payment/PaymentForm';
import { Subject } from 'rxjs';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { ProfileModelType } from '../../../models/profile.model';

export type TPaymentMethodPopupContainerContext = {
	authService: AuthService;
	profileModel: ProfileModelType;
};

export const PaymentMethodPopupContainer = combineReader(
	PaymentMethodPopup,
	ask<TPaymentMethodPopupContainerContext>(),
	(PaymentMethodPopup, { authService, profileModel }) => {
		return withRX(PaymentMethodPopup)(props$ => {
			const save$ = new Subject<PaymentMethod>();
			const saveResult$ = save$.pipe(
				switchMap(data => authService.savePaymentMethod(toPaymentMethodData(data))),
				switchMapRD(() => profileModel.refresh()),
				share(),
			);

			const selfCloseEffect$ = saveResult$.pipe(
				filterSuccess,
				withLatestFrom(props$),
				tap(([_, props]) => props.onClose()),
			);

			return {
				defaultProps: {
					onSave: (data: PaymentMethod) => save$.next(data),
					result: initial,
				},
				props: {
					result: saveResult$,
				},
				effects$: selfCloseEffect$,
			};
		});
	},
);
