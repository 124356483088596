import * as React from 'react';
import { FC, useCallback, Fragment, useMemo } from 'react';
import { ModalHeader, ModalBody, Card, CardBody, Row, Button } from 'reactstrap';
import trelloPng from 'volley-common/dist/assets/images/trello-logo.png';
import asanaPng from 'volley-common/dist/assets/images/asana-logo.png';
import jiraSvg from 'volley-common/dist/assets/images/jira-software-logo.svg';
import pdfSvg from 'volley-common/dist/assets/images/pdf-icon.svg';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { ExportType } from '../../export/export.model';
import { useSubscriptionView } from '../../subscribe/SubscriptionViewContainer';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { Modal } from '../../../components/Modal';
import { Option } from 'fp-ts/lib/Option';
import { RemoteData } from '@devexperts/remote-data-ts';
import { JiraConnectionModal } from '../../export/jira/JiraConnectionModal';
import { isEnum } from 'volley-common/dist/utils/object.utils';
import { TProduct } from 'volley-common/dist/services/products.service';
import { Tooltip } from 'volley-common/dist/components/Tooltip';
import cn from 'classnames';
import css from './IntegrationSelector.module.scss';
import { UpgradeButtonContainer } from '../../../models/profile.utils';

export type Integration = {
	id?: number;
	type: ExportType;
	active: boolean;
};

export type SaveIntegrationRequest = {
	type: IntegrationType;
	data: unknown;
	config: unknown;
	notifications: unknown;
};

type IntegrationSelectorProps = {
	isOpen: boolean;
	onClose: () => void;
	onExport: (intg: Integration) => void;
	onConnect: (integrationType: IntegrationType) => void;
	integrations: Integration[];
	product: TProduct;
	/**
	 * Specifies whether the project or team owner's plan allows export.
	 * If it does, than any user who can see the project can use its configured integrations.
	 */
	canExport: boolean;
	/**
	 * Specifies whether the current user is the project or team owner.
	 * The owner is the only user who can configure integrations for this project.
	 */
	isOwner: boolean;
	connectionModal: Option<IntegrationType>;
	onCloseConnectionModal: () => void;
	onSaveIntegration: (request: SaveIntegrationRequest) => void;
	saveIntegrationResult: RemoteData<Error, unknown>;
};

const ActiveIntegrationCard: FC<{
	integration: Integration;
	onExport: (integration: Integration) => void;
}> = ({ integration, onExport }) => {
	const handleExport = useCallback(() => onExport(integration), [integration, onExport]);

	return (
		<Card className="quarter-margin-bottom">
			<CardBody>
				<Row className="align-items-center">
					<div className="col-5">{getIntegrationImage(integration.type)}</div>
					<div className="col">
						<div className="float-right">
							<Button onClick={handleExport} color="primary">
								Export
							</Button>
						</div>
					</div>
				</Row>
			</CardBody>
		</Card>
	);
};

const InactiveIntegrationCard = combineReader(
	UpgradeButtonContainer,
	(
			UpgradeButtonContainer,
		): FC<{
			integration: Integration;
			onConfigure: (type: ExportType) => void;
			canExport: boolean;
			isOwner: boolean;
			product: TProduct;
		}> =>
		({ integration, onConfigure, canExport, isOwner, product }) => {
			const handleConfigure = useCallback(() => onConfigure(integration.type), [integration.type, onConfigure]);

			let button: React.ReactNode;
			if (isOwner) {
				button = canExport ? (
					<Button color="secondary" onClick={handleConfigure}>
						Configure
					</Button>
				) : (
					<UpgradeButtonContainer
						className="btn btn-secondary"
						onClick={handleConfigure}
						regularText="Upgrade to Use"
					/>
				);
			} else {
				button = (
					<Tooltip
						target=""
						placement="left"
						trigger={
							<div>
								<Button color="link" className={cn('disabled', css.notConfiguredBtn)}>
									Not configured
								</Button>
							</div>
						}>
						{product.team ? 'Team' : 'Project'} owner must {!canExport && 'upgrade and '}configure
						integration
					</Tooltip>
				);
			}

			return (
				<Card className="quarter-margin-bottom">
					<CardBody>
						<Row className="align-items-center">
							<div className="col-5">{getIntegrationImage(integration.type)}</div>
							<div className="col">
								<div className="float-right">{button}</div>
							</div>
						</Row>
					</CardBody>
				</Card>
			);
		},
);

export const IntegrationSelector = combineReader(
	useSubscriptionView,
	InactiveIntegrationCard,
	(useSubscriptionView, InactiveIntegrationCard): FC<IntegrationSelectorProps> =>
		({
			isOpen,
			onClose,
			integrations,
			onExport,
			onConnect,
			canExport,
			product,
			isOwner,
			connectionModal,
			onCloseConnectionModal,
			onSaveIntegration,
			saveIntegrationResult,
		}) => {
			const { showSubscriptionView, subscriptionView } = useSubscriptionView();
			const handleShowSubscriptionView = useCallback(
				() =>
					showSubscriptionView({
						title: 'Upgrade to start exporting your notes',
					}),
				[showSubscriptionView],
			);
			const integrationsWithPdf = useMemo(() => {
				return [
					...integrations,
					{
						id: 0,
						type: 'pdf' as const,
						active: canExport,
					},
				];
			}, [integrations, canExport]);
			const active = integrationsWithPdf.filter(int => int.active);
			const inactive = integrationsWithPdf.filter(int => !int.active);
			const handleExport = canExport ? onExport : handleShowSubscriptionView;
			const handleConnect = useCallback(
				(type: ExportType) => {
					if (!canExport) {
						handleShowSubscriptionView();
					} else if (isEnum(IntegrationType)(type)) {
						onConnect(type);
					}
				},
				[canExport, onConnect, handleShowSubscriptionView],
			);

			return (
				<Fragment>
					{subscriptionView}
					<Modal isOpen={isOpen} toggle={onClose}>
						<ModalHeader toggle={onClose} className="no-padding-bottom no-border-bottom" />
						<ModalBody>
							<div className="padded no-padding-top">
								<h2 className="no-margin-bottom">Export Notes</h2>

								{active.length > 0 && (
									<div className="double-margin-top">
										<h5 className="bold-font">Active</h5>
										{active.map(int => (
											<ActiveIntegrationCard
												key={int.type}
												integration={int}
												onExport={handleExport}
											/>
										))}
									</div>
								)}

								{inactive.length > 0 && (
									<div className="double-margin-top">
										<h5 className="bold-font">Not configured</h5>
										{inactive.map(int => (
											<InactiveIntegrationCard
												key={int.type}
												integration={int}
												onConfigure={handleConnect}
												canExport={canExport}
												isOwner={isOwner}
												product={product}
											/>
										))}
									</div>
								)}
							</div>
						</ModalBody>
					</Modal>
					{connectionModal
						.map(type => {
							switch (type) {
								case IntegrationType.Jira:
									return (
										<JiraConnectionModal
											onBack={onCloseConnectionModal}
											verifyAndSaveResult={saveIntegrationResult}
											onVerifyAndComplete={({ host, ...data }: any) =>
												onSaveIntegration({
													type: IntegrationType.Jira,
													data,
													config: { host },
													notifications: {},
												})
											}
										/>
									);
								default:
									return null;
							}
						})
						.toNullable()}
				</Fragment>
			);
		},
);

function getIntegrationImage(type: ExportType): React.ReactNode {
	switch (type) {
		case IntegrationType.Trello:
			return <img className="max-width" src={trelloPng} width="100px" title="Trello" />;
		case IntegrationType.Asana:
			return <img className="max-width" src={asanaPng} width="100px" title="Asana" />;
		case IntegrationType.Jira:
			return <img src={jiraSvg} width="150px" title="Jira" />;
		case 'pdf':
			return <img className="max-width" src={pdfSvg} width="70px" title="PDF" />;
	}
}
