import { ask } from 'fp-ts/lib/Reader';
import { ThumbnailBar } from './ThumbnailBar';
import { memo, useMemo, useState } from 'react';
import { pending } from '@devexperts/remote-data-ts';
import { useObservable } from 'volley-common/dist/utils/react.utils';
import { ReviewViewModel } from './review.view-model';
import { CreateProjectFromImages } from '../../products/ProductImageUploader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { tapRD } from 'volley-common/dist/utils/object.utils';

interface Ctx {
	viewModel: ReviewViewModel;
}

interface ThumbnailBarContainerProps {
	projectId: number;
}

export const ThumbnailBarContainer = combineReader(
	CreateProjectFromImages,
	ask<Ctx>(),
	(CreateProjectFromImages, { viewModel }) =>
		memo(({ projectId }: ThumbnailBarContainerProps) => {
			const [uploaderVisible, setUploaderVisible] = useState(false);

			const {
				uploadedImages$,
				selectUploadedImage,
				selectedUploadedImage$,
				addMoreUploadedImages,
				refreshUploadedImages$,
				addMoreUploadedImagesResult$,
			} = viewModel;
			const images = useObservable(uploadedImages$, pending);

			const selectedImage = useObservable(selectedUploadedImage$, null);
			const onSelect = (url: string) => {
				const id = images.toNullable()?.find(i => i.file === url)?.id;
				if (id) {
					selectUploadedImage(id);
				}
			};

			const uploadDoneEffect$ = useMemo(
				() =>
					addMoreUploadedImagesResult$.pipe(
						tapRD(() => {
							setUploaderVisible(false);
							refreshUploadedImages$.next(undefined);
						}),
					),
				[addMoreUploadedImagesResult$, refreshUploadedImages$],
			);
			useObservable(uploadDoneEffect$);

			return (
				<>
					<ThumbnailBar
						images={images}
						projectId={projectId}
						selectedImage={selectedImage?.id}
						onSelect={onSelect}
						// onUploadCompleted={() => refresh$.next()}
						onUpload={() => setUploaderVisible(true)}
					/>
					<CreateProjectFromImages
						visible={uploaderVisible}
						onCancel={() => setUploaderVisible(false)}
						onSubmit={(name, files) => addMoreUploadedImages(projectId, files)}
					/>
				</>
			);
		}),
);
