import BrowseMode from './Product';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial } from '@devexperts/remote-data-ts';
import { combineLatest, Subject, merge } from 'rxjs';
import { map, distinctUntilChanged, switchMap, withLatestFrom, filter, tap, shareReplay } from 'rxjs/operators';
import { ProductService, productIdAndDateSetoid, NoteStatus } from 'volley-common/dist/services/products.service';
import { ProductsViewModel } from 'volley-common/dist/services/products.view-model';
import { ask } from 'fp-ts/lib/Reader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { SessionServiceClass } from '../../services/token.service';
import { none, some, getSetoid } from 'fp-ts/lib/Option';
import { getProductTeam } from 'volley-common/dist/services/teams.service';
import { constVoid, identity, not } from 'fp-ts/lib/function';
import { getRightSetoid, mapRD, shareReplayRefCount } from 'volley-common/dist/utils/object.utils';
import { history } from '../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';
import { AxiosError } from 'axios';

type TProductContainerContext = {
	productService: ProductService;
	sessionService: SessionServiceClass;
	productsViewModel: ProductsViewModel;
	toastService: ToastService;
};

export const ProductContainer = combineReader(BrowseMode, ask<TProductContainerContext>(), (BrowseMode, ctx) =>
	withRX(BrowseMode)(props$ => {
		const productId$ = props$.pipe(
			map(p => p.productId),
			distinctUntilChanged(),
		);

		function loadProduct(id: number) {
			return ctx.productService.getById(id).pipe(
				map(response =>
					response.recoverMap((err: any) => {
						if (err && typeof err.response === 'object' && [403, 404].includes(err.response.status)) {
							return some(none);
						}
						return none;
					}, some),
				),
			);
		}

		const reloadProduct$ = new Subject<void>();
		const reloadedProduct$ = reloadProduct$.pipe(
			withLatestFrom(productId$),
			switchMap(([, id]) => loadProduct(id)),
			filter(data => data.isSuccess()),
		);

		const product$ = merge(productId$.pipe(switchMap(loadProduct)), reloadedProduct$).pipe(
			distinctUntilChanged(getRightSetoid(getSetoid(productIdAndDateSetoid)).equals),
			shareReplayRefCount(),
		);

		const teamId$ = combineLatest([product$, ctx.productsViewModel.isPersonalOrSharedProduct$]).pipe(
			map(([product, isPersonalOrSharedProduct]) =>
				product.toOption().chain(identity).filter(not(isPersonalOrSharedProduct)).chain(getProductTeam),
			),
			distinctUntilChanged(),
		);

		const handleProjectLeft$ = new Subject();
		const handleProjectLeftEffect$ = handleProjectLeft$.pipe(
			withLatestFrom(teamId$),
			tap(([, teamId]) => history.push(teamId.fold(routes.dashboard, id => routes.teamDashboard(id)))),
		);

		const onReloadProduct = () => reloadProduct$.next();

		const saveStatuses$ = new Subject<NoteStatus[]>();
		const saveStatusesResult$ = saveStatuses$.pipe(
			withLatestFrom(productId$),
			switchMap(([statuses, productId]) => ctx.productService.updateWorkflow(productId, statuses)),
			tap(rd =>
				rd.foldL(
					constVoid,
					constVoid,
					() => ctx.toastService.push({ text: genericErrorMessage }),
					onReloadProduct,
				),
			),
			shareReplay(1),
		);

		const canDeleteStatus = (id: number) => {
			return ctx.productService
				.checkCanDeleteStatus(id)
				.toPromise()
				.then(t =>
					t.fold(
						true,
						true,
						e => {
							if ((e as AxiosError).isAxiosError && (e as AxiosError).response?.status === 400) {
								const error =
									(e as AxiosError).response?.data.errors[0]?.message ??
									'Cannot remove a status with notes. Try deleting all associated notes first';
								ctx.toastService.push({
									text: error,
								});
								return error;
							}
							return true; // unknown error - don't prohibit removal
						},
						() => true,
					),
				);
		};

		return {
			defaultProps: {
				userId: none,
				product: initial,
				teamId: none,
				onReloadProduct,
				onProjectLeft: () => handleProjectLeft$.next(),
				onSaveStatuses: (statuses: NoteStatus[]) => {
					saveStatuses$.next(statuses);
				},
				saveStatusesResult: initial,
				canDeleteStatus,
				type: initial,
			},
			props: {
				userId: ctx.sessionService.decoded$.pipe(map(token => token.map(t => t.user_id))),
				product: product$,
				teamId: teamId$,
				saveStatusesResult: saveStatusesResult$,
				type: product$.pipe(mapRD(p => (p.exists(p => p.type === 'upload_image') ? 'uploaded' : 'extension'))),
			},
			effects$: handleProjectLeftEffect$,
		};
	}),
);
