import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { ReactNode } from 'react';
import type { TNotePriority, TProductImage } from 'volley-common/dist/services/products.service';
import defaultAvatarImg from 'volley-common/dist/assets/images/img_avatar.png';
import sourceSansNormal from 'volley-common/dist/assets/fonts/source-sans-pro/source-sans-pro-v12-latin-regular.woff';
import sourceSansSemibold from 'volley-common/dist/assets/fonts/source-sans-pro/source-sans-pro-v12-latin-600.woff';
import sourceSansBold from 'volley-common/dist/assets/fonts/source-sans-pro/source-sans-pro-v12-latin-700.woff';
// NOTE: the images below have to be PNG, react-pdf does not understand SVG
import avatarUnassigned from 'volley-common/dist/assets/images/avatar-unassigned.png';
import priorityNonePng from 'volley-common/dist/assets/images/priority-none.png';
import priorityLowPng from 'volley-common/dist/assets/images/priority-low.png';
import priorityMediumPng from 'volley-common/dist/assets/images/priority-medium.png';
import priorityHighPng from 'volley-common/dist/assets/images/priority-high.png';
import priorityUrgentPng from 'volley-common/dist/assets/images/priority-urgent.png';
import poweredBy from 'volley-common/dist/assets/images/powered-by-volley.png';
import { formatAuthorName } from '../../../models/profile.utils';
import { formatPriorityName } from '../../product/review/review.utils';
import { fromNullable } from 'fp-ts/lib/Option';

interface PdfDocumentProps {
	notes: TProductImage[];
	productName: string;
}

Font.register({
	family: 'Source Sans',
	fonts: [
		{ src: sourceSansNormal },
		{ src: sourceSansSemibold, fontWeight: 'semibold' },
		{ src: sourceSansBold, fontWeight: 'bold' },
	],
});

const colorDarkGray = '#354052';
const colorLightGray = '#b4bbc6';
const color3 = '#7f8fa4';
const colorUltraLight = '#e6eaee';

const styles = StyleSheet.create({
	page: {
		fontSize: 7,
		fontFamily: 'Source Sans',
		lineHeight: 1.2,
		color: colorDarkGray,
		paddingLeft: '0.25in',
		paddingRight: '31pt',
		paddingTop: '13pt',
		paddingBottom: '31pt',
	},
	productName: {
		fontSize: 13,
		fontWeight: 'bold',
		paddingBottom: '6pt',
		borderStyle: 'solid',
		borderColor: colorUltraLight,
		borderBottomWidth: '1pt',
	},
	note: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		borderStyle: 'solid',
		borderColor: colorUltraLight,
		borderBottomWidth: '1pt',
		paddingTop: '0.25in',
		paddingBottom: '0.25in',
		minHeight: '3.25in',
	},
	contentCol: { flexShrink: 0, width: '2.375in' },
	imageCol: { flexGrow: 1 },
	avatarCol: { width: '36pt', paddingLeft: '10pt', paddingRight: '10pt', paddingTop: '3pt' },
	image: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		maxHeight: '100%',
		maxWidth: '100%',
		objectFit: 'contain',
		objectPositionY: '0',
	},
	avatar: { width: '16pt', height: '16pt', objectFit: 'cover', borderRadius: '8pt' },
	username: { fontWeight: 'semibold', marginTop: '6pt', marginBottom: '5pt' },
	noteText: { fontSize: 8 },
	meta: { display: 'flex', flexDirection: 'row', paddingTop: '5pt', justifyContent: 'space-between' },
	metaLabel: { color: color3, flexShrink: 0 },
	metaValue: { flexGrow: 0, width: '1.875in' },
	metaWithIcon: { flexDirection: 'row', alignItems: 'center' },
	priorityIcon: { width: '8pt', height: '8pt', marginRight: '6pt' },
	assigneeAvatar: { width: '8pt', height: '8pt', marginRight: '6pt', borderRadius: '4pt' },
	footer: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: colorLightGray,
		left: '0.25in',
		right: '31pt',
		bottom: '11pt',
	},
	logo: { width: '90px' },
	pageCtr: { display: 'flex', flexDirection: 'row', fontSize: 8 },
	pageCtrValue: { fontWeight: 'bold' },
});

function getAssigneeAvatar(note: TProductImage) {
	if (note.assignee) {
		return note.assignee.profile.avatar ?? defaultAvatarImg;
	} else {
		return avatarUnassigned;
	}
}

export const PdfDocument = ({ notes, productName }: PdfDocumentProps) => {
	return (
		<Document>
			<Page size={'LETTER'} style={styles.page}>
				<Text fixed style={styles.productName}>
					{productName}
				</Text>
				{notes.map(note => (
					<View wrap={false} key={note.id} style={styles.note}>
						<View style={styles.imageCol}>
							{fromNullable(note.image || note.video_thumbnail).fold(null, src => (
								<Image src={src} style={styles.image} />
							))}
							{note.overlay && <Image src={note.overlay} style={styles.image} />}
						</View>
						<View style={styles.avatarCol}>
							<Image src={note.author?.profile.avatar || defaultAvatarImg} style={styles.avatar} />
						</View>
						<View style={styles.contentCol}>
							<Text style={styles.username}>{formatAuthorName(note.author)}</Text>
							<Text style={styles.noteText}>{note.text.replace(/\r\n|\r/g, '\n')}</Text>
							{metaAttribute(
								'Assignee',
								<View style={styles.metaWithIcon}>
									<Image style={styles.assigneeAvatar} src={getAssigneeAvatar(note)} />
									<Text>{note.assignee?.name || 'Unassigned'}</Text>
								</View>,
							)}
							{metaAttribute(
								'Priority',
								<View style={styles.metaWithIcon}>
									<Image src={formatPriorityIcon(note.priority)} style={styles.priorityIcon} />
									<Text>{formatPriorityName(note.priority)}</Text>
								</View>,
							)}
							{metaAttribute('Browser', note.browser || 'Unknown')}
							{metaAttribute('OS', note.os || 'Unknown')}
							{/*
                              TODO: do not add hyphens when the URL is wrapped, related:
                              https://github.com/diegomura/react-pdf/issues/1380
                            */}
							{metaAttribute('URL', note.source)}
						</View>
					</View>
				))}
				<View fixed style={styles.footer}>
					<Image src={poweredBy} style={styles.logo} />
					<View style={styles.pageCtr}>
						<Text>Page </Text>
						<Text
							style={styles.pageCtrValue}
							render={({ pageNumber, totalPages }) => `${pageNumber} of ${totalPages}`}
						/>
					</View>
				</View>
			</Page>
		</Document>
	);
};

function metaAttribute(label: string, value: ReactNode) {
	const content =
		typeof value === 'string' ? (
			<Text style={styles.metaValue}>{value}</Text>
		) : (
			<View style={styles.metaValue}>{value}</View>
		);
	return (
		<View style={styles.meta}>
			<Text style={styles.metaLabel}>{label}</Text>
			{content}
		</View>
	);
}

export function formatPriorityIcon(opt: TNotePriority) {
	switch (opt) {
		case 'none':
			return priorityNonePng;
		case 'low':
			return priorityLowPng;
		case 'medium':
			return priorityMediumPng;
		case 'high':
			return priorityHighPng;
		case 'urgent':
			return priorityUrgentPng;
	}
}
