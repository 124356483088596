import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, failure } from '@devexperts/remote-data-ts';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { trello } from 'volley-common/dist/services/export/trello.service';
import { TrelloBoardSelector } from './TrelloBoardSelector';
import { of } from 'rxjs';
import { ask } from 'fp-ts/lib/Reader';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { getArraySetoid, setoidNumber } from 'fp-ts/lib/Setoid';

type TrelloBoardSelectorContainerContext = {};

export const TrelloBoardSelectorContainer = combineReader(
	ask<TrelloBoardSelectorContainerContext>(),
	trello,
	(ctx, trello) =>
		withRX(TrelloBoardSelector)(props$ => {
			return {
				defaultProps: {
					boards: initial,
				},
				props: {
					boards: trello.foldL(
						() =>
							of(
								failure(
									new Error('Trello integration is not configured. Please contact the support team.'),
								),
							),
						trello =>
							props$.pipe(
								map(props => [props.productId, props.integrationId]),
								distinctUntilChanged(getArraySetoid(setoidNumber).equals),
								switchMap(([projectId, integrationId]) => trello.getBoards(projectId, integrationId)),
							),
					),
				},
			};
		}),
);
