import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial } from '@devexperts/remote-data-ts';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { JiraProjectSelector } from './JiraProjectSelector';
import { ask } from 'fp-ts/lib/Reader';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { jira } from 'volley-common/dist/services/export/jira.service';
import { getArraySetoid, setoidNumber } from 'fp-ts/lib/Setoid';
import { mapRD } from 'volley-common/dist/utils/object.utils';

type JiraProjectSelectorContainerContext = {};

export const JiraProjectSelectorContainer = combineReader(
	ask<JiraProjectSelectorContainerContext>(),
	jira,
	(ctx, jira) =>
		withRX(JiraProjectSelector)(props$ => {
			return {
				defaultProps: {
					projects: initial,
				},
				props: {
					projects: props$.pipe(
						map(props => [props.productId, props.integrationId]),
						distinctUntilChanged(getArraySetoid(setoidNumber).equals),
						switchMap(([productId, intId]) => jira.getProjects(productId, intId)),
						mapRD(projects => projects.map(p => ({ key: p.project_key, name: p.project_name }))),
					),
				},
			};
		}),
);
