import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, success } from '@devexperts/remote-data-ts';
import Review from './Review';
import { none, Option } from 'fp-ts/lib/Option';
import { TProductImage, defaultNoteFilter } from 'volley-common/dist/services/products.service';
import { ask } from 'fp-ts/lib/Reader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { defaultNotesOrder, ReviewViewModel } from './review.view-model';
import { map } from 'rxjs/operators';

type TReviewContainerContext = {
	viewModel: ReviewViewModel;
};

export const ReviewContainer = combineReader(Review, ask<TReviewContainerContext>(), (Review, { viewModel }) =>
	withRX(Review)(() => {
		const userSelectedNote$ = viewModel.selectedImageData$.pipe(
			map(({ note, userSelected }) => note.map(n => n.filter(note => userSelected === note.id))),
		);
		return {
			defaultProps: {
				selectedImage: success<Error, Option<TProductImage>>(none),
				userSelectedImage: success<Error, Option<TProductImage>>(none),
				onSelectNote: viewModel.onSelectNote,
				onSetNoteStatus: viewModel.onSetNoteStatus,
				saveCommentResult: initial,
				updateCommentResult: initial,
				uploadedImages: initial,
				onPostNote: viewModel.onCreateNote,
				images: success<Error, any[]>([]),
				teamMembers: [],
				isShowResolved: false,
				onSetShowResolved: viewModel.onSetShowResolved,
				noteFilter: defaultNoteFilter,
				onSetNoteFilter: viewModel.onSetNoteFilter,
				notesOrder: defaultNotesOrder,
				onChangeNotesOrder: viewModel.onChangeNotesOrder,
				showNotesLimit: none,
				offerFirstTimeUpgrade: false,
				statuses: [],
			},
			props: {
				teamMembers: viewModel.teamMembers$,
				onDeleteNote: viewModel.onDeleteNote$,
				images: viewModel.images$,
				selectedImage: viewModel.selectedImage$,
				userSelectedImage: userSelectedNote$,
				uploadedImage: viewModel.selectedUploadedImage$,
				uploadedImages: viewModel.uploadedImages$,
				saveCommentResult: viewModel.saveCommentResult$,
				updateCommentResult: viewModel.updateCommentResult$,
				isShowResolved: viewModel.isShowResolved$,
				noteFilter: viewModel.noteFilter$,
				notesOrder: viewModel.notesOrder$,
				showNotesLimit: viewModel.showNotesLimit$,
				offerFirstTimeUpgrade: viewModel.offerFirstTimeUpgrade$,
				statuses: viewModel.statuses$,
			},
			effects$: viewModel.effects$,
		};
	}),
);
