import { ReviewViewModel } from './review.view-model';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { BoardView, Column, NoteMove } from './BoardView';
import { ask } from 'fp-ts/lib/Reader';
import { useObservable } from 'volley-common/dist/utils/react.utils';
import { initial, RemoteData } from '@devexperts/remote-data-ts';
import { defaultNoteFilter, NoteStatus } from 'volley-common/dist/services/products.service';
import { none, Option } from 'fp-ts/lib/Option';
import { ReactElement } from 'react';
import { empty } from 'fp-ts/lib/Array';

interface BoardViewContainerContext {
	viewModel: ReviewViewModel;
}

export const mockColumns: Column[] = [
	{ status_id: 1, label: 'To Do', color: '#f00' },
	{ status_id: 2, label: 'In Progress', color: '#f00' },
	{ status_id: 3, label: 'Needs Review', color: '#f00' },
	{ status_id: 4, label: 'Blocked', color: '#f00' },
	{ status_id: 5, label: 'Cancelled', color: '#f00' },
	{ status_id: 6, label: 'Completed', color: '#f00' },
];

interface ContainerProps {
	productId: number;
	onEditStatuses?: (columns: Option<Column[]>) => void;
	subscriptionOverlay?: ReactElement;
	setSubscriptionOverlay?: (elem: ReactElement | undefined) => void;
	onSaveStatuses: (columns: NoteStatus[]) => void;
	saveStatusesResult: RemoteData<Error, unknown>;
	canDeleteStatus?: (id: number) => Promise<boolean | string>;
}

export const BoardViewContainer = combineReader(
	BoardView,
	ask<BoardViewContainerContext>(),
	(BoardView, { viewModel }) =>
		(props: ContainerProps) => {
			const images = useObservable(viewModel.images$, initial);
			const onNoteMove = ({ id, newStatus }: NoteMove) => viewModel.onSetNoteStatus(id, newStatus);
			const statuses = useObservable(viewModel.statuses$, empty);
			const noteFilter = useObservable(viewModel.noteFilter$, defaultNoteFilter);
			const teamMembers = useObservable(viewModel.teamMembers$);
			const offerFirstTimeUpgrade = useObservable(viewModel.offerFirstTimeUpgrade$, false);
			const showNotesLimit = useObservable(viewModel.showNotesLimit$, none);
			const selectedNoteData = useObservable(viewModel.selectedImageData$, {
				note: initial,
				userSelected: undefined,
			});
			console.log('selectedNoteData', selectedNoteData);
			const selectedNote = selectedNoteData.note.map(n =>
				n.filter(note => selectedNoteData.userSelected === note.id),
			);

			return (
				<BoardView
					{...props}
					images={images}
					onNoteMove={onNoteMove}
					noteFilter={noteFilter}
					onSetNoteFilter={viewModel.onSetNoteFilter}
					teamMembers={teamMembers}
					showNotesLimit={showNotesLimit}
					offerFirstTimeUpgrade={offerFirstTimeUpgrade}
					onSelectNote={viewModel.onSelectNote}
					selectedNote={selectedNote}
					statuses={statuses}
				/>
			);
		},
);
