import React, { Component, Fragment } from 'react';
import { RemoteData, RemoteSuccess } from '@devexperts/remote-data-ts';
import { Card, CardBody, Button, Input, Alert } from 'reactstrap';
import { Form, Field, FormRenderProps } from 'react-final-form';
import { TApiError, getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { validatePassword } from 'volley-common/dist/utils/string.utils';
import { PasswordRequirements } from '../../components/PasswordRequirements';
import { FormState } from 'final-form';

type TFormModel = {
	oldPassword?: string;
	newPassword?: string;
	newPassword2?: string;
};

export type TPasswordChange = {
	oldPassword: string;
	newPassword: string;
};

export type TPasswordProps = {
	onSave: (update: TPasswordChange) => unknown;
	saveResult: RemoteData<TApiError, unknown>;
	resultShown: boolean;
};

type TPasswordState = {
	version: number;
	lastSuccess: RemoteSuccess<unknown, unknown> | null;
};

export class Password extends Component<TPasswordProps, TPasswordState> {
	state: TPasswordState = {
		version: 0,
		lastSuccess: null,
	};

	static getDerivedStateFromProps(nextProps: Readonly<TPasswordProps>, prevState: TPasswordState) {
		if (nextProps.saveResult.isSuccess() && nextProps.saveResult !== prevState.lastSuccess) {
			return {
				lastSuccess: nextProps.saveResult,
				version: prevState.version + 1,
			};
		}
		return null;
	}

	render() {
		return (
			<Fragment>
				{this.props.saveResult.fold(
					null,
					null,
					e => {
						const message = getNonFieldErrors(e);
						return (
							<Alert color="danger" isOpen={this.props.resultShown}>
								Error: {message.join(' ')}
							</Alert>
						);
					},
					() => (
						<Alert color="success" isOpen={this.props.resultShown}>
							<strong>Success:</strong> Your profile has been updated.
						</Alert>
					),
				)}
				<Card>{this.renderSuccess()}</Card>
			</Fragment>
		);
	}

	renderSuccess = () => {
		return (
			<CardBody>
				<Form key={this.state.version} onSubmit={this.handleSubmit} validate={this.handleValidate}>
					{(props: FormRenderProps & FormState<any>) => (
						<form onSubmit={props.handleSubmit}>
							<Field name="oldPassword">
								{({ input, meta }) => (
									<div className="margin-bottom">
										<h4 className="bold-font">Current password</h4>
										<Input
											invalid={meta.error && meta.touched}
											bsSize="lg"
											placeholder="Enter your current password"
											{...input}
											type="password"
										/>
										{meta.error && meta.touched && (
											<div className="invalid-feedback">{meta.error}</div>
										)}
									</div>
								)}
							</Field>
							<Field name="newPassword">
								{({ input, meta }) => (
									<div className="margin-bottom">
										<h4 className="bold-font">New password</h4>
										<Input
											invalid={meta.error && meta.touched}
											bsSize="lg"
											autoComplete="new-password"
											placeholder="Enter your new password"
											{...input}
											type="password"
										/>
										{meta.error && meta.touched && (
											<div className="invalid-feedback">{meta.error}</div>
										)}
									</div>
								)}
							</Field>
							<Field name="newPassword2">
								{({ input, meta }) => (
									<div className="margin-bottom">
										<h4 className="bold-font">Confirm new password</h4>
										<Input
											invalid={meta.error && meta.touched}
											bsSize="lg"
											autoComplete="new-password"
											placeholder="Confirm your new password"
											{...input}
											type="password"
										/>
										{meta.error && meta.touched && (
											<div className="invalid-feedback">{meta.error}</div>
										)}
									</div>
								)}
							</Field>
							<div className="double-margin-bottom">
								<PasswordRequirements password={props.values.newPassword || ''} />
							</div>
							<Button
								type="submit"
								color="primary"
								disabled={this.props.saveResult.isPending()}
								size="lg">
								Change
							</Button>
						</form>
					)}
				</Form>
			</CardBody>
		);
	};

	handleValidate = (values: object): { [P in keyof TFormModel]?: string } => {
		const data = values as TFormModel;
		const errors = {} as any;
		if (!data.oldPassword || data.oldPassword === '') {
			errors.oldPassword = 'This field may not be blank';
		}
		if (!data.newPassword || !validatePassword(data.newPassword).valid) {
			errors.newPassword = `Please check the password requirements`;
		}
		if (data.newPassword !== data.newPassword2) {
			errors.newPassword2 = 'Passwords must match';
		}
		return errors;
	};

	handleSubmit = (values: object) => {
		const { oldPassword, newPassword } = values as TFormModel;
		this.props.onSave && this.props.onSave({ oldPassword: oldPassword || '', newPassword: newPassword || '' });
	};
}
