import * as React from 'react';
import { FC, Fragment, useCallback } from 'react';
import { Container, Row, Col, Media, Button } from 'reactstrap';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { renderIntegrationIcon } from '../export.model';
import { RemoteData } from '@devexperts/remote-data-ts';
import { ordString, contramap } from 'fp-ts/lib/Ord';
import { TrelloList } from './TrelloListSelector';
import { ListSelect, useSingleSelect } from '../ListSelect';

export type TrelloBoard = {
	id: string;
	name: string;
	group: string;
	lists: Array<TrelloList>;
};

type TrelloBoardSelectorProps = {
	onContinue: (board: TrelloBoard) => void;
	onBack: () => void;
	boards: RemoteData<Error, TrelloBoard[]>;
	integrationId: number;
	productId: number;
};

const renderBoard = (board: TrelloBoard) => (
	<Row className="align-items-center double-margin-left">
		<Col>
			<h4 className="quarter-margin-bottom medium-font">{board.name}</h4>
		</Col>
	</Row>
);

const pk = (board: TrelloBoard) => board.id;

const boardGrouping = {
	getGroup: (board: TrelloBoard) => board.group,
	ordGroup: contramap((group: string) => group.toLowerCase(), ordString),
};

export const TrelloBoardSelector: FC<TrelloBoardSelectorProps> = ({ boards, onContinue, onBack }) => {
	const { selected, handleToggleItem, getSelectedItem } = useSingleSelect();
	const selectedItem = getSelectedItem().toUndefined();
	const handleContinue = useCallback(() => {
		boards
			.toOption()
			.mapNullable(boards => boards.find(board => selectedItem === board.id))
			.map(onContinue);
	}, [boards, onContinue, selectedItem]);

	return (
		<Fragment>
			<section className="export-header">
				<Container>
					<Row className="align-items-center">
						<Col md={6}>
							<Media>
								{renderIntegrationIcon(IntegrationType.Trello)}
								<Media body>
									<p className="no-margin-bottom">Export notes to Trello</p>
									<h2 className="medium-font margin-bottom">Choose a board from Trello</h2>
								</Media>
							</Media>
						</Col>
						<Col md={6}>
							<div className="text-right actions margin-top">
								<Button color="secondary" size="lg" className="half-margin-right" onClick={onBack}>
									Back
								</Button>{' '}
								<Button
									color="primary"
									size="lg"
									disabled={selectedItem === undefined}
									onClick={handleContinue}>
									Continue
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<ListSelect
				selected={selected}
				renderItem={renderBoard}
				identifier={pk}
				items={boards}
				grouping={boardGrouping}
				onToggleSelected={handleToggleItem}
			/>
		</Fragment>
	);
};
