import React, { ComponentType, useEffect } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import { routes } from 'volley-common/dist/utils/routes';
import { Redirect } from 'react-router';
import { identity } from 'fp-ts/lib/function';

export const withRouteClass = (type: string) => <P extends {}>(Target: ComponentType<P>) => (props: P) => {
	const className = `route-${type}`;
	useEffect(() => {
		document.getElementsByTagName('body')[0].classList.add(className);
		return () => {
			document.getElementsByTagName('body')[0].classList.remove(className);
		};
	}, [className]);
	return <Target {...props} />;
};

type WithReturnUrlProps = {
	result: RemoteData<unknown, unknown>;
	isLoggedIn: boolean;
	returnUrl?: string;
};

export function withReturnUrl<P extends WithReturnUrlProps>(
	Target: ComponentType<P>,
	rewriteRoute: (val: string) => string = identity,
) {
	return (props: P) => {
		const alreadySignedIn = props.isLoggedIn && props.result.isInitial();
		if (alreadySignedIn || props.result.isSuccess()) {
			const { returnUrl } = props;
			const path = returnUrl && returnUrl.startsWith('/') ? returnUrl : routes.dashboard;
			const actualLink = alreadySignedIn ? path : rewriteRoute(path);
			return <Redirect to={actualLink} />;
		}

		return <Target {...props} />;
	};
}
