import * as React from 'react';
import { Fragment, FC, useCallback, useState, ReactNode } from 'react';
import { Overlay } from 'volley-common/dist/components/Overlay';
import style from './SubscriptionView.module.scss';
import { Row, Container, Col, Button, Alert } from 'reactstrap';
import { Option } from 'fp-ts/lib/Option';
import {
	TPlanType,
	getPlan,
	isPaymentAuthorizationRequired,
	TBillingInfo,
	BillingPeriod,
} from 'volley-common/dist/services/auth.service';
import { FreePlanInfoCard, EnterprisePlanInfoCard, ProPlanInfoCard } from './PlanInfoCard';
import { pluralProjects } from 'volley-common/dist/services/products.service';
import { DowngradeConfirmation, TDowngradeConfirmationProps } from './DowngradeConfirmation';
import { constVoid } from 'fp-ts/lib/function';
import { RemoteData, initial } from '@devexperts/remote-data-ts';
import { pluralTeamMembers, pluralTeams } from 'volley-common/dist/services/teams.service';
import { BillingPeriodSelector } from './BillingPeriodSelector';
import cn from 'classnames';
import iconBrandfolder from 'volley-common/dist/assets/images/logo-brandfolder.png';
import iconGempages from 'volley-common/dist/assets/images/logo-gempages.png';
import iconApprove from 'volley-common/dist/assets/images/logo-approve-me.png';
import iconSilverstripe from 'volley-common/dist/assets/images/logo-silverstripe.png';
import iconLightning from 'volley-common/dist/assets/images/lightning.png';

export type TSubscriptionViewProps = {
	title?: string;
	subtitle?: string;
	currentPlan: Option<TPlanType>;
	currentBillingInfo: Option<TBillingInfo>;
	onClose: () => void;
	onChoosePlan: (plan: TPlanType, billingPeriod: BillingPeriod) => void;
	numNotes?: number;
	numProjects: number;
	numTeamMembers: number;
	numTeams: number;
	downgradeResult?: RemoteData<unknown, unknown>;
	isSuperUser: boolean;
	className?: string;
};

const overlayBaseTheme = {
	container: style.container,
	close: style.closePanel,
	close__link: style.closeIcon,
};

const currentPlanButton = (
	<Button tag="a" block size="lg" color="secondary" disabled>
		Current plan
	</Button>
);

const defaultTitle = 'Simple pricing, better collaboration.';

export const SubscriptionView: FC<TSubscriptionViewProps> = ({
	onClose,
	title = defaultTitle,
	currentBillingInfo,
	subtitle,
	currentPlan: currentPlanProp,
	onChoosePlan,
	numNotes = 0,
	numProjects,
	numTeams,
	numTeamMembers,
	downgradeResult = initial,
	isSuperUser,
	className,
}) => {
	const currentPlan = currentPlanProp.toNullable();
	const currentBillingPeriod = currentBillingInfo.map(billing => billing.interval).toNullable();
	const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>('year');
	const [overLimitAlert, setOverLimitAlert] = useState<ReactNode>();
	const [downgradeConfirmation, setDowngradeConfirmation] = useState<
		Partial<TDowngradeConfirmationProps> | undefined
	>();
	const [handleChooseFree, handleChoosePro] = (['starter', 'pro'] as const).map(newPlan =>
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useCallback(() => {
			const teamLimit = getPlan(newPlan).features.maxTeamMembers;
			// Hard restrictions: cannot downgrade if these conditions are met
			if (teamLimit.isSome()) {
				if (!isSuperUser && numTeams > 0 && teamLimit.toNullable() === 0) {
					setOverLimitAlert(
						<Alert color="primary">
							<b>Oops:</b> The plan you selected does not allow teams. You currently have {numTeams}{' '}
							{pluralTeams(numTeams)}. You'll need to trim down a bit to use the basic plan.
						</Alert>,
					);
					return;
				}
				if (!isSuperUser && teamLimit.exists(limit => numTeamMembers > limit)) {
					setOverLimitAlert(
						<Alert color="primary">
							<b>Oops:</b> The plan you selected only allows for {teamLimit.value}{' '}
							{pluralTeamMembers(teamLimit.value)}. You currently have {numTeamMembers}{' '}
							{pluralTeamMembers(numTeamMembers)}. You'll need to trim down a bit to use the basic plan.
						</Alert>,
					);
					return;
				}
			}
			const projectLimit = getPlan(newPlan).features.maxProjects;
			if (!isSuperUser && projectLimit.isSome() && projectLimit.value < numProjects) {
				setOverLimitAlert(
					<Alert color="primary">
						<b>Oops:</b> The plan you selected only allows for {projectLimit.value}{' '}
						{pluralProjects(projectLimit.value)}. You currently have {numProjects}{' '}
						{pluralProjects(numProjects)}. You'll need to trim down a bit to use the basic plan.
					</Alert>,
				);
				return;
			}
			// Soft restrictions: downgrade is allowed but give the user a change to stay on the current plan
			const notesLimit = getPlan(newPlan).features.maxNotes;
			if (!!currentPlan && notesLimit.exists(limit => numNotes > limit)) {
				setDowngradeConfirmation({
					isOpen: true,
					reason: {
						type: 'notesLimit',
						projectLimit: projectLimit.getOrElse(Infinity),
						noteLimit: notesLimit.getOrElse(Infinity),
					},
					onConfirm: () => onChoosePlan(newPlan, billingPeriod),
				});
				return;
			}
			if (!!currentPlan && !isPaymentAuthorizationRequired(currentPlan, newPlan)) {
				if (projectLimit.isSome()) {
					setDowngradeConfirmation({
						isOpen: true,
						reason: { type: 'projectsLimit', limit: projectLimit.value },
						onConfirm: () => onChoosePlan(newPlan, billingPeriod),
					});
					return;
				}
				// Last chance: show a generic confirmation modal before downgrading
				setDowngradeConfirmation({
					isOpen: true,
					reason: { type: 'justPleaseStay' },
					onConfirm: () => onChoosePlan(newPlan, billingPeriod),
				});
				return;
			}
			onChoosePlan(newPlan, billingPeriod);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			currentPlan,
			numProjects,
			numTeams,
			numNotes,
			numProjects,
			numTeamMembers,
			currentBillingInfo,
			onChoosePlan,
			billingPeriod,
			setDowngradeConfirmation,
			setOverLimitAlert,
		]),
	);

	const overlayTheme = React.useMemo(
		() => ({
			...overlayBaseTheme,
			container: cn(overlayBaseTheme.container, className, 'subscription-view-overlay'),
		}),
		[className],
	);

	return (
		<Overlay theme={overlayTheme} onClose={onClose}>
			<DowngradeConfirmation
				isOpen={false}
				onClose={() => setDowngradeConfirmation(props => ({ ...props, isOpen: false }))}
				onConfirm={constVoid}
				reason={{ type: 'projectsLimit', limit: 0 }}
				isPending={downgradeResult.isPending()}
				{...downgradeConfirmation}
			/>

			<section className="no-padding-top no-padding-bottom">
				<Container>
					<Row className="justify-content-center">
						<Col lg={8}>
							<h2 className="text-center medium-font" style={{ fontSize: 37, lineHeight: '1.1' }}>
								{title}
							</h2>
							{subtitle && <p className="light-text text-center">{subtitle}</p>}
						</Col>
					</Row>
				</Container>
			</section>

			{overLimitAlert && (
				<section className="half-padding-top no-padding-bottom">
					<Container>
						<Row>
							<Col>{overLimitAlert}</Col>
						</Row>
					</Container>
				</section>
			)}

			<section className="no-padding-top">
				<div className="padded">
					<Container fluid style={{ maxWidth: 1190 }}>
						<Row className="text-center">
							<div className="col-12">
								<div className="margin-top triple-margin-bottom">
									<BillingPeriodSelector
										billingPeriod={billingPeriod}
										onBillingPeriodChange={setBillingPeriod}
									/>
								</div>
							</div>
						</Row>
						<Row className="justify-content-center">
							<div className="col-12">
								<Row className="justify-content-center">
									<Col sm={12} md={12} lg={4} className="margin-bottom">
										<FreePlanInfoCard
											billingPeriod={billingPeriod}
											footer={
												<Fragment>
													{currentPlan === 'starter' ? (
														currentPlanButton
													) : (
														<Button
															color="primary"
															size="lg"
															block
															onClick={handleChooseFree}>
															<span className="bold-font">Get Starter</span>
														</Button>
													)}
												</Fragment>
											}
										/>
									</Col>
									<Col sm={12} md={12} lg={4} className="margin-bottom no-padding">
										<ProPlanInfoCard
											billingPeriod={billingPeriod}
											footer={
												currentBillingPeriod === billingPeriod && currentPlan === 'pro' ? (
													currentPlanButton
												) : (
													<Button color="primary" size="lg" block onClick={handleChoosePro}>
														<span className="bold-font">
															Get Pro
															<img
																src={iconLightning}
																style={{ height: 23, marginLeft: 12 }}
															/>
														</span>
													</Button>
												)
											}
										/>
									</Col>
									<Col sm={12} md={12} lg={4} className="margin-bottom">
										<EnterprisePlanInfoCard
											billingPeriod={billingPeriod}
											footer={
												currentBillingPeriod === billingPeriod &&
												currentPlan === 'unlimited' ? (
													currentPlanButton
												) : (
													<Button
														color="secondary"
														tag={'a'}
														href="mailto:hello@meetvolley.com?subject=Enterprise+plan"
														size="lg"
														block>
														<span className="bold-font">Contact sales</span>
													</Button>
												)
											}
										/>
									</Col>
								</Row>
							</div>
						</Row>
					</Container>
				</div>
			</section>
			<section className="no-padding-top">
				<Container>
					<Row className="justify-content-center">
						<Col lg={9}>
							<div className="text-center">
								<h5 className="light-text-color medium-font" style={{ letterSpacing: 0.5 }}>
									HUNDREDS OF HAPPY CUSTOMERS, INCLUDING
								</h5>
								<div className="padding-top">
									<img className="d-inline" src={iconBrandfolder} width={146} />
									<img className="d-inline" src={iconGempages} width={146} />
									<img className="d-inline" src={iconApprove} width={146} />
									{/* <img className="d-inline" src={iconNextiva} width={146} /> */}
									<img className="d-inline" src={iconSilverstripe} width={146} />
								</div>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={7}>
							<div className="text-center">
								<div className="padded double-padding-top">
									<h3 style={{ lineHeight: '1.6em' }}>
										“Volley is without a doubt the biggest timesaver I have come across in a very
										long time. It literally takes seconds to create a note that includes both a
										screenshot of the issue and a written description.”
									</h3>
									<h4 className="bold-font margin-top">Mathias Hoffmann - CTO</h4>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Overlay>
	);
};
