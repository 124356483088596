import React, { memo } from 'react';
import { Alert } from 'reactstrap';
import { MaintenanceInfo, formatMaintenanceText } from 'volley-common/dist/models/maintenance.model';

interface MaintenanceAlertProps {
	info: MaintenanceInfo;
}

export const MaintenanceAlert = memo<MaintenanceAlertProps>(({ info }) => {
	return <Alert isOpen={true} color="warning" children={formatMaintenanceText(info)} />;
});
