import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';

import type * as pdfjs from 'pdfjs-dist';
import { RemoteData, failure, isSuccess, pending, progress, success } from '@devexperts/remote-data-ts';
import { BehaviorSubject, Observable } from 'rxjs';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { some } from 'fp-ts/lib/Option';

let pdfJsPromise: Promise<typeof pdfjs> | null = null;
const loadPdfJS = () => {
	if (!pdfJsPromise) {
		pdfJsPromise = eval("import('https://cdn.jsdelivr.net/npm/pdfjs-dist@4.0.189/build/pdf.mjs')").then(
			(pdfJs: typeof pdfjs) => {
				pdfJs.GlobalWorkerOptions.workerSrc =
					'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.0.189/build/pdf.worker.mjs';
				return pdfJs;
			},
		);
	}
	return pdfJsPromise!;
};

/** @returns array of data URLs, one for each page */
export type PdfProcessingResult = RemoteData<unknown, Array<RemoteData<unknown, string>>>;
export const splitPdf = (file: File): Observable<PdfProcessingResult> => {
	const result$ = new BehaviorSubject<PdfProcessingResult>(pending);
	(async () => {
		let doc: PDFDocumentProxy;
		try {
			const [pdfjs, fileBuffer] = await Promise.all([loadPdfJS(), file.arrayBuffer()]);
			doc = await pdfjs.getDocument(fileBuffer).promise;
		} catch (e) {
			result$.next(failure(e));
			result$.complete();
			return;
		}

		const pages: Array<RemoteData<unknown, string>> = [];
		const total = some(doc.numPages);
		for (var i = 1; i <= doc.numPages; i++) {
			result$.next(progress({ loaded: i - 1, total }));
			try {
				const page = await doc.getPage(i);
				const viewport = await page.getViewport({ scale: 2.0 });
				const canvas = document.createElement('canvas');
				canvas.width = Math.floor(viewport.width);
				canvas.height = Math.floor(viewport.height);
				await page.render({
					canvasContext: canvas.getContext('2d')!,
					viewport,
				}).promise;
				pages.push(success(canvas.toDataURL('image/png')));
			} catch (e) {
				pages.push(failure(e));
			}
		}
		result$.next(pages.some(isSuccess) ? success(pages) : failure(new Error('Failed to process any page')));
		result$.complete();
	})();
	return result$;
};

export const PdfUploader = () => {
	const handleAttach = async (files: File[]) => {
		// https://github.com/mozilla/pdf.js/blob/master/examples/learning/helloworld.html
		console.log('files', files);
		const [pdfjs, fileBuffer] = await Promise.all([loadPdfJS(), files[0].arrayBuffer()]);
		console.log({ pdfjs, fileBuffer });
		const doc = await pdfjs.getDocument(fileBuffer).promise;
		console.log(doc.numPages, 'pages');
		const page = await doc.getPage(1);
		const viewport = await page.getViewport({ scale: 1.0 });
		const canvas = document.createElement('canvas');
		canvas.width = Math.floor(viewport.width);
		canvas.height = Math.floor(viewport.height);
		document.body.appendChild(canvas);
		console.log({ viewport });
		await page.render({
			canvasContext: canvas.getContext('2d')!,
			viewport,
		}).promise;
		const u = canvas.toDataURL();
		const i = document.createElement('img');
		i.src = u;
		document.body.appendChild(i);
	};

	return (
		<div>
			<h1>PDF uploader</h1>
			<Dropzone onDropAccepted={handleAttach}>
				{({ getRootProps, getInputProps, open, isDragActive }) => (
					<div {...getRootProps({})}>
						<input {...getInputProps()} />
						<Button
							color="flat"
							// className={cn('btn-icon', style.commentForm__attachButton)}
							onClick={open}
							// disabled={isPending}
						>
							<i className="material-icons">attachment</i>
						</Button>
					</div>
				)}
			</Dropzone>
		</div>
	);
};
