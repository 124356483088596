import React, { memo, useState, Fragment } from 'react';
import { NoteStatus } from 'volley-common/dist/services/products.service';
import { Dropdown, DropdownToggle, ButtonGroup, Button, DropdownMenu, DropdownItem } from 'reactstrap';
import cn from 'classnames';
import { formatStatusIcon, formatStatusName } from './Review';
import { Tooltip } from 'volley-common/dist/components/Tooltip';
import css from './StatusSelector.module.scss';

interface StatusSelectorProps {
	options: NoteStatus[];
	value: number;
	onChange: (val: number) => void;
}

export const StatusSelector = memo<StatusSelectorProps>(({ options, value, onChange }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(prevState => !prevState);

	const renderOption = (opt: NoteStatus, final: boolean) => (
		<DropdownItem key={opt.id} active={opt.id === value} onClick={() => onChange(opt.id)}>
			<span className={cn(css.menuStatusIcon, final && css.menuStatusIcon_final)}>
				{formatStatusIcon(opt, options)}
			</span>
			&nbsp;
			{formatStatusName(opt)}
		</DropdownItem>
	);

	const completedStatus = options.length > 0 ? options[options.length - 1] : null;
	const currentStatus = options.find(o => o.id === value);

	return (
		<Dropdown group isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle tag="span">
				<ButtonGroup>
					{value === completedStatus?.id ? (
						<div className={cn(/*style.markResolved,*/ 'complete-btn', 'mark-resolved')}>
							<Button active={true} color="secondary" className={css.resolved}>
								<i className={cn('material-icons' /*style.markResolved__tick*/)}>check</i>{' '}
								{completedStatus?.name}
							</Button>
						</div>
					) : (
						<Fragment>
							<Button color="secondary" className="filter-button dropdown-action">
								<div className={cn('d-inline-block', css.statusIcon)}>
									{currentStatus && formatStatusIcon(currentStatus, options)}
								</div>
								<div className="d-inline-block">{currentStatus && formatStatusName(currentStatus)}</div>
								<div className="d-inline-block">
									<i className="material-icons">expand_more</i>
								</div>
							</Button>
							{completedStatus && (
								<Tooltip
									target=""
									trigger={
										<button
											color="secondary"
											className="btn btn-secondary btn-icon filter-button"
											onClick={e => {
												e.stopPropagation();
												completedStatus && onChange(completedStatus.id);
											}}>
											<span className="light-text-color">
												<div className="material-icons">check</div>
											</span>
										</button>
									}>
									Mark complete
								</Tooltip>
							)}
						</Fragment>
					)}
				</ButtonGroup>
			</DropdownToggle>

			<DropdownMenu right>
				{options.map((opt, i, arr) => i < arr.length - 1 && renderOption(opt, false))}
				<DropdownItem divider />
				{completedStatus && renderOption(completedStatus, true)}
			</DropdownMenu>
		</Dropdown>
	);
});
