import * as React from 'react';
import { FC, Fragment } from 'react';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { Container, Navbar, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NoteSelectionViewContainer } from './NoteSelectionViewContainer';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { TrelloExport } from './trello/TrelloExport';
import { RemoteData } from '@devexperts/remote-data-ts';
import { routes } from 'volley-common/dist/utils/routes';
import { JiraExport } from './jira/JiraExport';
import { ExportType } from './export.model';

export type State = { step: 'notes' } | { step: 'integration'; notes: TProductImage[] };

type ExportPageProps = {
	integrationType: ExportType;
	integrationId: number;
	productId: number;
	state: State;
	onContinueToIntegration: (notes: TProductImage[]) => void;
	onBackToNotes: () => void;
	onStartExport: (req: ExportData) => void;
	exportResult: RemoteData<Error, unknown>;
};

export type ExportData = {
	note_ids: number[];
};

export const ExportPage = combineReader(
	NoteSelectionViewContainer,
	TrelloExport,
	JiraExport,
	(NoteSelectionViewContainer, TrelloExport, JiraExport): FC<ExportPageProps> =>
		({
			integrationType,
			integrationId,
			productId,
			state,
			onContinueToIntegration,
			onBackToNotes,
			exportResult,
			onStartExport,
		}) => {
			return (
				<Fragment>
					<div className="main">
						<Navbar light fixed="top" expand="sm">
							<Container fluid>
								<Nav className="mr-auto" />
								<div>
									<Nav>
										<NavItem>
											<Link to={routes.product(productId)}>
												<i className="material-icons">close</i>
											</Link>
										</NavItem>
									</Nav>
								</div>
							</Container>
						</Navbar>
						{state.step === 'notes' && (
							<NoteSelectionViewContainer
								onContinue={onContinueToIntegration}
								integrationType={integrationType}
								productId={productId}
							/>
						)}
						{state.step === 'integration' && integrationType === IntegrationType.Trello && (
							<TrelloExport
								productId={productId}
								integrationId={integrationId}
								notes={state.notes}
								onContinue={onStartExport}
								exportResult={exportResult}
								onBack={onBackToNotes}
							/>
						)}
						{state.step === 'integration' && integrationType === IntegrationType.Jira && (
							<JiraExport
								productId={productId}
								integrationId={integrationId}
								notes={state.notes}
								onContinue={onStartExport}
								exportResult={exportResult}
								onBack={onBackToNotes}
							/>
						)}
					</div>
				</Fragment>
			);
		},
);
