// eslint-disable-next-line no-restricted-imports
import { Modal as ReactstrapModal, ModalProps } from 'reactstrap';
import { ComponentType } from 'react';
import * as ReactstrapUtils from 'reactstrap/es/utils';

const ReactstrapModalClass: any = ReactstrapModal;

export class ModalClass extends ReactstrapModalClass {
	init() {
		if (!this._element) {
			(this as any)._element = document.createElement('div');
			this._element.setAttribute('tabindex', '-1');
			// This will probably break the nested modals, see:
			// https://github.com/reactstrap/reactstrap/pull/227
			// However, this is supposed to fix the mobile Safari z-index bug
			// this._element.style.position = 'relative';
			// this._element.style.zIndex = this.props.zIndex;
			document.body.appendChild(this._element);
			this._mountContainer = (ReactstrapUtils as any).getTarget(this.props.container);
			this._mountContainer.appendChild(this._element);
		}
		super.init();
	}
}

export const Modal: ComponentType<ModalProps> = ModalClass as any;
