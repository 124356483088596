import { initial } from '@devexperts/remote-data-ts';
import { of, Subject } from 'rxjs';
import { switchMap, delay, startWith, share } from 'rxjs/operators';
import { Profile, TUpdateProfile } from './Profile';
import { asks } from 'fp-ts/lib/Reader';
import { ProfileModelType } from '../../models/profile.model';
import { mapRD } from 'volley-common/dist/utils/object.utils';
import { useObservable } from 'volley-common/dist/utils/react.utils';
import { memo, useCallback } from 'react';

export type TProfileContainerContext = {
	profileModel: ProfileModelType;
};

const nullProfile = {
	fullName: '',
	email: '',
	avatar: undefined,
};

export const ProfileContainer = asks(({ profileModel }: TProfileContainerContext) => {
	const userInfo$ = profileModel.profile$.pipe(
		mapRD(profile =>
			profile.fold(nullProfile, profile => ({
				fullName: profile.name,
				email: profile.email,
				avatar: profile.profile.avatar,
			})),
		),
	);

	const save$ = new Subject<TUpdateProfile>();
	const saveResult$ = save$.pipe(
		switchMap(data => profileModel.update(data)),
		share(),
	);

	const resultShown$ = saveResult$.pipe(
		switchMap(result => {
			if (result.isFailure() || result.isSuccess()) {
				return of(false).pipe(delay(3000), startWith(true));
			} else {
				return of(false);
			}
		}),
	);

	return memo(function ProfileContainer() {
		const userInfo = useObservable(userInfo$, initial);
		const saveResult = useObservable(saveResult$, initial);
		const resultShown = useObservable(resultShown$, false);
		const onSave = useCallback(val => save$.next(val), []);

		return <Profile profile={userInfo} saveResult={saveResult} onSave={onSave} resultShown={resultShown} />;
	});
});
