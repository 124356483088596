import React, { useCallback, memo, useEffect, useRef, useState, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { CustomInput, FormGroup, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Modal } from '../../../components/Modal';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { v4 as uuid } from 'uuid';
import { isSuccess, RemoteData } from '@devexperts/remote-data-ts';
import { Selectbox, Option, Header } from 'volley-common/dist/components/Selectbox';
import { FormState } from 'final-form';
import { empty } from 'fp-ts/lib/Array';
import { TrelloBoard } from 'volley-common/dist/services/export/trello.service';
import { validateRequired } from 'volley-common/dist/utils/object.utils';
import { array, nonEmptyArray2v, ord } from 'fp-ts';

export interface TrelloSettingsFormModel {
	board?: string;
	list?: string;
	auto_export?: boolean;
}

export const emptyTrelloSettings: TrelloSettingsFormModel = {};

interface TrelloSettingsProps {
	visible: boolean;
	onClose: () => void;
	onSave: (val: TrelloSettingsFormModel) => void;
	saveResult: RemoteData<Error, unknown>;
	value: TrelloSettingsFormModel;
	boards: RemoteData<Error, TrelloBoard[]>;
}

export const TrelloSettings = memo<TrelloSettingsProps>(({ visible, onClose, onSave, saveResult, value, boards }) => {
	const isSaveSuccess = isSuccess(saveResult);
	const prevSuccess = useRef(isSaveSuccess);
	useEffect(() => {
		if (visible && isSaveSuccess && !prevSuccess.current) {
			onClose();
		}
		prevSuccess.current = isSaveSuccess;
	}, [visible, onClose, isSaveSuccess]);

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader tag="h2" className="no-border-bottom no-padding-bottom double-padding-left" toggle={onClose}>
				Integration Settings
			</ModalHeader>
			<Form
				initialValues={value}
				render={props => <TrelloSettingsForm {...props} boards={boards} saveResult={saveResult} />}
				onSubmit={(val: object) => {
					const data = val as TrelloSettingsFormModel;
					onSave(data);
				}}
			/>
		</Modal>
	);
});

const validateBoardOrList = (val: string, form: TrelloSettingsFormModel) =>
	form.auto_export ? validateRequired(val) : null;

const groupBoards = (boards: TrelloBoard[]) =>
	nonEmptyArray2v
		.groupSort(ord.contramap((b: TrelloBoard) => b.group, ord.ordString))(boards)
		.map(boards => ({
			group: boards[0].group,
			boards,
		}));

const TrelloSettingsForm = memo<FormRenderProps & Pick<TrelloSettingsProps, 'saveResult' | 'boards'>>(
	({ handleSubmit, saveResult, boards: boardsRD, form, ...rest }) => {
		const { values } = rest as unknown as FormState<TrelloSettingsFormModel>;
		const [componentId] = useState(() => uuid());
		const boards = boardsRD.getOrElse(empty);
		const selectedBoard = boards.find(b => b.id === values.board);
		const renderBoardOption = useCallback(
			(val: string | undefined) => (val?.length ? boards.find(b => b.id === val)?.name ?? val : 'not selected'),
			[boards],
		);
		const groupedBoards = useMemo(() => {
			const groups = groupBoards(boards);
			return array.flatten(
				groups.map(({ group, boards }) => [
					<Header key={'group-' + boards[0].id}>{group}</Header>,
					...(boards.map(opt => (
						<Option key={opt.id} value={opt.id}>
							{opt.name}
						</Option>
					)) as any),
				]),
			);
		}, [boards]);
		const renderListOption = useCallback(
			(val: string | undefined) =>
				val?.length ? selectedBoard?.lists?.find(b => b.id === val)?.name ?? val : 'not selected',
			[selectedBoard],
		);

		const { list: selectedList } = values;
		useEffect(() => {
			if (selectedList) {
				const isValidList = selectedBoard?.lists.some(l => l.id === selectedList);
				if (!isValidList) {
					form.change('list', undefined);
				}
			}
		}, [selectedList, selectedBoard, form]);

		return (
			<form onSubmit={handleSubmit}>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<p className="bold-font half-margin-bottom">Trello settings</p>
						<p>
							You can manually export notes to Trello using the "Export" button on your project overview.
						</p>
						<div className="mt-3">
							<div className="form-check custom-checkbox no-padding-left">
								<Field name="auto_export" type="checkbox">
									{({ input }) => (
										<CustomInput
											{...input}
											className="no-padding-left"
											type="checkbox"
											label="Automatically export notes"
											id={`${componentId}-auto-export`}
										/>
									)}
								</Field>
							</div>
						</div>
						<div className="settings-section mb-2" style={{ paddingLeft: 28 }}>
							<Field name="board" validate={validateBoardOrList}>
								{({ input, meta }) => (
									<FormGroup>
										<label className="mb-0 half-margin-top">Select board</label>
										<Selectbox<string>
											disabled={!values.auto_export}
											invalid={meta.error && meta.touched}
											renderOption={renderBoardOption}
											{...input}
											pending={boardsRD.isPending()}
											children={groupedBoards}
										/>
									</FormGroup>
								)}
							</Field>
						</div>
						<div className="settings-section mb-2" style={{ paddingLeft: 28 }}>
							<Field name="list" validate={validateBoardOrList}>
								{({ input, meta }) => (
									<FormGroup>
										<label className="mb-0 half-margin-top">Select list</label>
										<Selectbox<string>
											disabled={!values.auto_export || !selectedBoard}
											invalid={meta.error && meta.touched}
											renderOption={renderListOption}
											{...input}
											pending={boardsRD.isPending()}
											children={(selectedBoard?.lists ?? empty).map(opt => (
												<Option value={opt.id} key={opt.id}>
													{opt.name}
												</Option>
											))}
										/>
									</FormGroup>
								)}
							</Field>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className="no-border-top">
					<LoadingButton type="submit" pending={saveResult.isPending()} color="primary">
						Save changes
					</LoadingButton>
				</ModalFooter>
			</form>
		);
	},
);
