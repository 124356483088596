import { ask } from 'fp-ts/lib/Reader';
import React, { memo, ReactNode, useMemo } from 'react';
import { Badge, Card, CardBody, DropdownMenu, DropdownToggle, Media, UncontrolledDropdown } from 'reactstrap';
import { ChatHQCardContainer } from './IntegrationsChatHQ';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { IntegrationsMap, IntegrationStore } from 'volley-common/dist/services/export/integration.store';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { distinctUntilChanged, pluck, switchMap } from 'rxjs/operators';
import cn from 'classnames';
import { JiraCardContainer } from './IntegrationsJira';
import { useSubscriptionView } from '../../subscribe/SubscriptionViewContainer';
import { TrelloCardContainer } from './IntegrationsTrello';
import { SlackCardContainer } from './IntegrationsSlack';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';

interface IntegrationCardProps {
	name: ReactNode;
	description?: ReactNode;
	logo?: ReactNode;
	active: boolean;
	menu?: ReactNode;
}

export const IntegrationCard = memo<IntegrationCardProps>(({ name, active, description, menu, logo }) => {
	return (
		<Card className={cn('mb-4', active && 'border-success')}>
			<CardBody>
				<div className="d-flex justify-content-between align-items-center" style={{ minHeight: 90 }}>
					<Media className="align-items-center">
						{typeof logo === 'string' ? <img className="max-width mr-4" src={logo} width={90} /> : logo}
						<div className="media-body">
							<h2 className="mb-1 bold-font align-middle">
								{name}{' '}
								{active && (
									<Badge pill tag="div" color="success" className="align-middle ml-1">
										{' '}
										Active{' '}
									</Badge>
								)}
							</h2>
							{description && <h4>{description}</h4>}
						</div>
					</Media>
					{menu && (
						<UncontrolledDropdown>
							<DropdownToggle color="flat" className="btn-icon">
								<i className="material-icons">more_vert</i>
							</DropdownToggle>
							<DropdownMenu right>{menu}</DropdownMenu>
						</UncontrolledDropdown>
					)}
				</div>
			</CardBody>
		</Card>
	);
});

interface IntegrationsTabProps {
	productId: number;
	integrations: IntegrationsMap;
	canUseExport: boolean;
	initialEditing?: IntegrationType;
}

export const IntegrationsTab = combineReader(
	useSubscriptionView,
	ChatHQCardContainer,
	JiraCardContainer,
	TrelloCardContainer,
	SlackCardContainer,
	(useSubscriptionView, ChatHQCardContainer, JiraCardContainer, TrelloCardContainer, SlackCardContainer) =>
		memo<IntegrationsTabProps>(({ productId, integrations, canUseExport, initialEditing }) => {
			const { subscriptionView, showSubscriptionView } = useSubscriptionView();
			const onSubscriptionNeeded = useMemo(
				() => (canUseExport ? undefined : () => showSubscriptionView()),
				[canUseExport, showSubscriptionView],
			);

			return (
				<div className="settings-container double-margin-top">
					{subscriptionView}
					<div className="settings-section double-margin-bottom">
						<ChatHQCardContainer
							integrations={integrations}
							productId={productId}
							onSubscriptionNeeded={onSubscriptionNeeded}
						/>
						<JiraCardContainer
							integrations={integrations}
							productId={productId}
							onSubscriptionNeeded={onSubscriptionNeeded}
						/>
						<TrelloCardContainer
							integrations={integrations}
							productId={productId}
							onSubscriptionNeeded={onSubscriptionNeeded}
						/>
						<SlackCardContainer
							integrations={integrations}
							productId={productId}
							onSubscriptionNeeded={onSubscriptionNeeded}
							initialIsEditing={initialEditing === IntegrationType.Slack}
						/>
					</div>
				</div>
			);
		}),
);

type IntegrationsTabContainerContext = {
	integrationStore: IntegrationStore;
};

export const IntegrationsTabContainer = combineReader(
	IntegrationsTab,
	ask<IntegrationsTabContainerContext>(),
	(IntegrationsTab, ctx) =>
		withRX(IntegrationsTab)(props$ => {
			const integrations$ = props$.pipe(
				pluck('productId'),
				distinctUntilChanged(),
				switchMap(id => ctx.integrationStore.getAllWithUpdates(id)),
			);

			return {
				defaultProps: {
					integrations: {} as IntegrationsMap,
				},
				props: {
					integrations: integrations$,
				},
			};
		}),
);
