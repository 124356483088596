import React, { memo } from 'react';
import { validatePassword } from 'volley-common/dist/utils/string.utils';
import { Alert, Row, Col } from 'reactstrap';
import cn from 'classnames';

interface PasswordRequirementsProps {
	password: string;
}

export const PasswordRequirements = memo<PasswordRequirementsProps>(({ password }) => {
	const result = validatePassword(password);
	return result.valid ? (
		<Alert color="success">Your password is secure and you're all set!</Alert>
	) : (
		<Row>
			<Col>
				<div className={cn('password-requirement', result.lowercase && 'completed')}>
					One lowercase character
				</div>
				<div className={cn('password-requirement', result.uppercase && 'completed')}>
					One uppercase character
				</div>
				<div className={cn('password-requirement', result.number && 'completed')}>One number</div>
			</Col>
			<Col>
				<div className={cn('password-requirement', result.special && 'completed')}>One special character</div>
				<div className={cn('password-requirement', result.validLength && 'completed')}>
					8 characters minimum
				</div>
			</Col>
		</Row>
	);
});
