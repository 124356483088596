import React, { FC, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Modal, Button, ModalBody } from 'reactstrap';
import { AppState } from '../../models/app-state.model';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { constant } from 'fp-ts/lib/function';
import { asks } from 'fp-ts/lib/Reader';
import { map } from 'rxjs/operators';
import logoImg from 'volley-common/dist/assets/images/logo-icon.svg';
import cn from 'classnames';
import css from './YouRock.module.scss';
import { TSubscription } from 'volley-common/dist/services/auth.service';
import confetti from 'canvas-confetti';

type State = boolean | (TSubscription & { couponCode?: string });
export type YouRockAppState = { showYouRock: State };

type TYouRockProps = {
	state: State;
	onClose: () => void;
	isProduction?: boolean;
};

export const YouRock: FC<TYouRockProps> = ({ state, onClose, isProduction }) => {
	useEffect(() => {
		if (state && typeof state === 'object' && isProduction) {
			try {
				console.log('Tracking conversion', state, process.env.NODE_ENV);
				const price = state.plan_amount ? state.plan_amount / 100 : undefined;
				const dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);
				dataLayer.push({
					event: 'eec.purchase',
					ecommerce: {
						purchase: {
							actionField: {
								id: state.subscription_id,
								revenue: price,
								coupon: state.couponCode,
							},
							products: [
								{
									id: state.subscription_id,
									name: state.plan_name,
									price,
									variant: state.interval,
									coupon: state.couponCode,
								},
							],
						},
					},
				});

				const { fbq } = window as any;
				if (fbq) {
					fbq('track', 'Purchase', {
						plan_name: state.plan_name,
						plan_interval: state.interval,
						plan_price: price,
						order_id: state.subscription_id,
						value: price,
						currency: 'USD',
						predicted_ltv: '1.00',
					});
					fbq('track', 'Subscribe', {
						plan_name: state.plan_name,
						plan_interval: state.interval,
						plan_price: price,
						order_id: state.subscription_id,
						value: price,
						currency: 'USD',
						predicted_ltv: '1.00',
					});
				}
			} catch (e) {
				/* nop */
			}
		}
	}, [state, isProduction]);

	return (
		<Modal isOpen={!!state} external={<Confetti />}>
			<ModalBody className="text-center">
				<img src={logoImg} className={css.logo} />
				<h2>Thank you</h2>
				<p className="double-margin-bottom">We hope you enjoy using Volley</p>
				<Button className={cn(css.close, 'margin-bottom')} color="primary" onClick={onClose}>
					Close
				</Button>
			</ModalBody>
		</Modal>
	);
};

type YouRockContainerContext = {
	appState: AppState<YouRockAppState>;
	isProduction: boolean;
};
export const YouRockContainer = asks((ctx: YouRockContainerContext) => {
	return withRX(YouRock)(
		constant({
			defaultProps: {
				onClose: () => ctx.appState.setState({ showYouRock: false }),
				state: false,
				isProduction: ctx.isProduction,
			},
			props: {
				state: ctx.appState.state$.pipe(map(s => s.showYouRock)),
			},
		}),
	);
});

function Confetti() {
	const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
	useEffect(() => {
		if (canvasRef) {
			var myConfetti = confetti.create(canvasRef, {
				resize: true,
				useWorker: true,
				disableForReducedMotion: true,
			});
			myConfetti({
				particleCount: 150,
				spread: 160,
			});
			return () => myConfetti.reset();
		}
	}, [canvasRef]);
	return <canvas style={{ width: '100%', height: '100%', position: 'absolute' }} ref={setCanvasRef}></canvas>;
}
