import * as React from 'react';
import { FC, Fragment, useState, useMemo, useCallback } from 'react';
import { Container, Row, Col, Media, Button, ListGroup, ListGroupItem, CustomInput } from 'reactstrap';
import { ExportType, renderIntegrationIcon, renderIntegrationName } from './export.model';
import {
	TProductImage,
	filterNotesByStatuses,
	defaultNoteFilter,
	TMember,
	TProduct,
	NoteStatus,
} from 'volley-common/dist/services/products.service';
import { RemoteData } from '@devexperts/remote-data-ts';
import { format } from 'date-fns';
import { TextWithMentions } from 'volley-common/dist/components/Mentions';
import css from './NoteSelectionView.module.scss';
import classNames from 'classnames';
import uuid from 'uuid';
import { useMultiSelect, ListSelect } from './ListSelect';
import { NoteFilter } from '../product/review/NoteFilter';
import { PdfDocumentButton } from './pdf/PdfDownloadButton';

type NoteSelectionViewProps = {
	integrationType: ExportType;
	productId: number;
	product: RemoteData<Error, TProduct>;
	onContinue: (selectedNotes: TProductImage[]) => void;
	notes: RemoteData<Error, TProductImage[]>;
	teamMembers: TMember[];
	statuses: NoteStatus[];
};

const pk = (note: TProductImage) => String(note.id);

const renderItem = (note: TProductImage) => {
	return (
		<Row className="align-items-center double-margin-left">
			<Col lg={1} className="d-none d-lg-block">
				<img className="full-width margin-auto" src={note.image || note.video_thumbnail} />
			</Col>
			<Col lg={2}>
				<h4 className="quarter-margin-bottom medium-font">
					{(note.author && note.author.name) || 'Anonymous'}
				</h4>
			</Col>
			<Col lg={8}>
				<h4 className="quarter-margin-bottom">
					<TextWithMentions text={note.text || 'No comment'} />
				</h4>
			</Col>
			<Col lg={1}>
				<h4 className="no-margin-bottom light-text-color" style={{ whiteSpace: 'nowrap' }}>
					{format(note.created_at, 'MMM DD')}
				</h4>
			</Col>
		</Row>
	);
};

export const NoteSelectionView: FC<NoteSelectionViewProps> = ({
	integrationType,
	notes,
	product,
	onContinue,
	teamMembers,
	statuses,
}) => {
	const [prefix] = useState(() => uuid.v4());
	const { selected, handleToggleAll, handleToggleItem } = useMultiSelect(notes, pk);
	const [noteFilter, setNoteFilter] = useState(defaultNoteFilter);
	const [showResolved, setShowResolved] = useState(false);
	const visibleNotes = useMemo(
		() => notes.map(filterNotesByStatuses(statuses, noteFilter, showResolved)),
		[statuses, notes, showResolved, noteFilter],
	);
	const effectiveSelected = useMemo(() => {
		return visibleNotes.getOrElse([]).filter(n => selected.has(pk(n)));
	}, [selected, visibleNotes]);
	const productName = product.map(p => p.name).getOrElse('Volley');
	const handleContinue = useCallback(() => {
		if (effectiveSelected.length && integrationType !== 'pdf') {
			onContinue(effectiveSelected);
		}
	}, [effectiveSelected, onContinue, integrationType]);

	return (
		<Fragment>
			<section className="export-header">
				<Container>
					<Row className="align-items-center">
						<Col md={6}>
							<Media>
								{renderIntegrationIcon(integrationType)}
								<Media body>
									<p className="no-margin-bottom">
										Export notes to {renderIntegrationName(integrationType)}
									</p>
									<h2 className="medium-font margin-bottom">Select notes to export</h2>
								</Media>
							</Media>
						</Col>
						<Col md={6}>
							<div className="text-right actions margin-top">
								{integrationType === 'pdf' ? (
									<PdfDocumentButton notes={effectiveSelected} productName={productName} />
								) : (
									<Button
										color="primary"
										size="lg"
										disabled={effectiveSelected.length === 0}
										onClick={handleContinue}>
										Continue
									</Button>
								)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<ListSelect
				items={visibleNotes}
				onToggleSelected={handleToggleItem}
				renderItem={renderItem}
				identifier={pk}
				selected={selected}>
				<Row>
					<Col>
						<ListGroup>
							<div>
								<input
									id={`${prefix}-all`}
									type="checkbox"
									onChange={handleToggleAll}
									disabled={!notes.isSuccess()}
									checked={visibleNotes
										.map(notes => notes.length === effectiveSelected.length)
										.getOrElse(false)}
								/>
								<ListGroupItem tag="label" htmlFor={`${prefix}-all`} className="select-all">
									<Row className="align-items-center double-margin-left">
										<Col>
											<p className="medium-font no-margin-bottom">
												{effectiveSelected.length} selected
											</p>
										</Col>
									</Row>
								</ListGroupItem>
							</div>
						</ListGroup>
					</Col>
					<Col>
						<div className="float-right">
							<NoteFilter
								value={noteFilter}
								onChange={setNoteFilter}
								teamMembers={teamMembers}
								statuses={statuses}
							/>
						</div>
						<div className={'check-btn'}>
							<div className={classNames(css.showResolved, 'check-btn float-right')}>
								<CustomInput
									type="checkbox"
									label="Show completed"
									checked={showResolved}
									id={`${prefix}-export--showResolved`}
									onChange={e => setShowResolved(e.target.checked)}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</ListSelect>
		</Fragment>
	);
};
