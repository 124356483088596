import React, { memo } from 'react';
import logoImg from 'volley-common/dist/assets/images/logo.svg';
import { MaintenanceInfo, formatMaintenanceText } from 'volley-common/dist/models/maintenance.model';

type MaintenanceInProgressProps = {
	info: MaintenanceInfo;
};

export const MaintenanceInProgress = memo<MaintenanceInProgressProps>(({ info }) => (
	<div className="review-container unavailable">
		<div className="note-container">
			<div className="note-body">
				<div className="note-info">
					<div className="note-zero">
						<div className="margin-auto double-margin-bottom">
							<img src={logoImg} width={150} />
						</div>
						<div className="note-zero-text text-center">
							<h2>{formatMaintenanceText(info)}</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
));
