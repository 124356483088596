import { RemoteData } from '@devexperts/remote-data-ts';
import React, { memo, useEffect, useState } from 'react';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { NoteStatus } from 'volley-common/dist/services/products.service';
import { toColumn, toNoteStatus } from '../review/BoardView';
import { StatusEditor } from './StatusEditor';

interface WorkflowProps {
	statuses: NoteStatus[];
	workflowSaveResult: RemoteData<Error, unknown>;
	onSaveWorkflow: (val: NoteStatus[]) => void;
	onClose: () => void;
	canDelete?: (id: number) => Promise<boolean | string>;
}

export const Workflow = memo<WorkflowProps>(({ statuses, onSaveWorkflow, workflowSaveResult, onClose, canDelete }) => {
	const [value, onChange] = useState(() => statuses.map(toColumn));
	useEffect(() => {
		onChange(statuses.map(toColumn));
	}, [statuses]);

	const [initialResult] = useState(workflowSaveResult);
	useEffect(() => {
		workflowSaveResult !== initialResult && workflowSaveResult.isSuccess() && onClose();
	}, [workflowSaveResult, onClose, initialResult]);

	return (
		<div className="double-margin-top margin-bottom">
			<StatusEditor value={value} onChange={onChange} canDelete={canDelete} />
			<hr className={'double-margin-top'} />
			<div className="text-right">
				<LoadingButton
					color="primary"
					size="lg"
					pending={workflowSaveResult.isPending()}
					onClick={() => onSaveWorkflow(value.map(toNoteStatus))}>
					Save changes
				</LoadingButton>
			</div>
		</div>
	);
});
