import Cookies from 'js-cookie';

const REFERRAL_COOKIE = 'volley_referral_details';

interface Referral {
	url: string;
	count: number;
	earliest_timestamp: string;
	latest_timestamp: string;
}

interface ReferralDetails {
	referrals: Referral[];
}

function validateTimestamp(raw: any) {
	return typeof raw === 'string' && !isNaN(new Date(raw).getTime());
}

function validateReferral(data: any): data is Referral {
	return (
		typeof data === 'object' &&
		typeof data.url === 'string' &&
		typeof data.count === 'number' &&
		validateTimestamp(data.earliest_timestamp) &&
		validateTimestamp(data.latest_timestamp)
	);
}

function validateReferralDetails(data: any): data is ReferralDetails {
	return typeof data === 'object' && Array.isArray(data.referrals) && data.referrals.every(validateReferral);
}

export function loadReferralDetails() {
	const raw = Cookies.get(REFERRAL_COOKIE);
	if (raw) {
		try {
			const data = JSON.parse(raw);
			if (validateReferralDetails(data)) {
				return data;
			}
		} catch (ignored) {}
	}
	return null;
}

export function storeReferralDetails(details: ReferralDetails) {
	const expires = new Date();
	expires.setFullYear(expires.getFullYear() + 2);
	Cookies.set(REFERRAL_COOKIE, JSON.stringify(details), {
		domain: window.location.hostname.toLowerCase().includes('meetvolley.com') ? '.meetvolley.com' : undefined,
		expires,
		path: '/',
		secure: window.location.protocol.toLowerCase() === 'https:',
	});
}

export function pushReferral(url: string) {
	try {
		const hostname = new URL(url).hostname.toLowerCase();
		const now = new Date().toISOString();
		const details = loadReferralDetails() ?? { referrals: [] };
		let referral = details.referrals.find(r => r.url === hostname);
		if (!referral) {
			referral = { url: hostname, count: 0, earliest_timestamp: now, latest_timestamp: now };
			details.referrals.push(referral);
		}
		referral.count++;
		referral.latest_timestamp = now;
		storeReferralDetails(details);
	} catch (ignored) {}
}
