import React, { memo } from 'react';
import { TFileOrFileData } from 'volley-common/dist/utils/axios';
import css from './WidgetPreview.module.scss';
import previewTopSvg from './preview-top.svg';
import previewButtonsSvg from './preview-buttons.svg';
import widgetDefaultLogoSvg from 'volley-common/dist/assets/images/widget-default-logo.svg';
import { AttachmentPreviewContainer, TAttachmentPreviewProps } from 'volley-common/dist/components/AttachmentPreview';
import poweredByPng from 'volley-common/dist/assets/images/powered-by-volley.png';
import { asks } from 'fp-ts/lib/Reader';
import cn from 'classnames';

export const WidgetLogo = memo<TAttachmentPreviewProps>(({ fileData, className }) => {
	return (
		<img
			className={className}
			style={{ maxWidth: 200, maxHeight: 90 }}
			src={fileData ? fileData.data : widgetDefaultLogoSvg}
		/>
	);
});

export const WidgetLogoContainer = AttachmentPreviewContainer(WidgetLogo);

export interface WidgetPreviewProps {
	logo?: TFileOrFileData;
	poweredByVolley: boolean;
}

interface WidgetPreviewContext {
	websiteUrl: string;
}

export const WidgetPreview = asks((ctx: WidgetPreviewContext) =>
	memo<WidgetPreviewProps>(({ logo, poweredByVolley }) => {
		return (
			<div className={css.container}>
				<div className={css.frame}>
					<img src={previewTopSvg} className="max-width" />
					<div className="text-center">
						<WidgetLogoContainer {...logo} />
					</div>
					<img src={previewButtonsSvg} className="max-width" />
					<div className={cn('text-center', css.powered, poweredByVolley && css.powered_visible)}>
						<img src={poweredByPng} />
					</div>
				</div>
			</div>
		);
	}),
);
