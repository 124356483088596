import React, { Component, Fragment, ReactElement, ReactNode, memo, useState, useCallback, MouseEvent } from 'react';
import cn from 'classnames';
import { CustomInput, Spinner, Collapse, Button } from 'reactstrap';
import {
	TProductImage,
	TProduct,
	TMember,
	filterNotesByStatuses,
	TNotePriority,
	NoteFilterValue,
	extractPageFromUrl,
	NoteStatus,
	TProductUploadedImage,
} from 'volley-common/dist/services/products.service';
import { RemoteData, success } from '@devexperts/remote-data-ts';
import { TComment } from 'volley-common/dist/services/notes.service';
import { RenderRemoteData } from '../../../components/RenderRemoteData';
import zeroNotesSvg from '../../../assets/images/comment.svg';
import priorityNoneSvg from 'volley-common/dist/assets/images/priority-none.svg';
import priorityLowSvg from 'volley-common/dist/assets/images/priority-low.svg';
import priorityMediumSvg from 'volley-common/dist/assets/images/priority-medium.svg';
import priorityHighSvg from 'volley-common/dist/assets/images/priority-high.svg';
import priorityUrgentSvg from 'volley-common/dist/assets/images/priority-urgent.svg';
import completedIcon from 'volley-common/dist/assets/images/completed-lane-check.svg';
import { Option, none } from 'fp-ts/lib/Option';
import { constVoid } from 'fp-ts/lib/function';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { Selectbox, Option as SelectboxOption } from 'volley-common/dist/components/Selectbox';
import style from './Review.module.scss';
import { YesNoConfirmation } from '../../../components/Confirmation';
import { SubscriptionViewContainer } from '../../subscribe/SubscriptionViewContainer';
import { NoteFilter } from './NoteFilter';
import { ZeroState } from './ZeroState';
import { NoteCard, NoteCardLoading } from './NoteCard';
import { MaxNotesLimit } from './MaxNotesLimit';
import { NoteDetailsPanel } from './NoteDetailsPanel';
import { ResolveBar } from './ResolveBar';
import { NoteView, TProjectType } from './NoteView';
import { AttachmentOverlay } from './AttachmentOverlay';
import { ThumbnailBarContainer } from './ThumbnailBarContainer';
import { history } from '../../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import { CreateNoteForUploadedImage } from './ImagePanelV2';

export type TCommentEffect = {
	id: number;
	effect: 'scroll' | 'highlight';
};

export type TNotesOrder = 'newest' | 'oldest' | 'status' | 'priority' | 'page';

export type TNoteLimit = {
	mode: 'owner' | 'guest';
	limit: number;
};

type SidebarGroup = { name: string; images: TProductImage[]; active?: boolean };

const thumbImages = success<Error, string[]>([
	'https://loremflickr.com/320/240/kitten?lock=1',
	'https://loremflickr.com/320/240/kitten?lock=2',
	'https://loremflickr.com/320/240/kitten?lock=3',
	'https://loremflickr.com/320/240/kitten?lock=4',
	'https://loremflickr.com/320/240/kitten?lock=5',
]);

export type ProductView = 'board' | 'inbox';

export type TReviewProps = {
	product: RemoteData<Error, Option<TProduct>>;
	images: RemoteData<Error, TProductImage[]>;
	uploadedImages: RemoteData<Error, TProductUploadedImage[]>;
	uploadedImage?: TProductUploadedImage;
	onPostNote?: (note: CreateNoteForUploadedImage) => void;
	onSelectNote: (note: TProductImage | Pick<TProductImage, 'product_id'>) => void;
	onSetNoteStatus: (note: number, status: number) => void;
	saveCommentResult: RemoteData<Error, TComment>;
	updateCommentResult: RemoteData<Error, unknown>;
	onDeleteNote?: (id: number) => unknown;
	// deleteCommentResult: RemoteData<Error, unknown>;
	selectedImage: RemoteData<Error, Option<TProductImage>>;
	userSelectedImage: RemoteData<Error, Option<TProductImage>>;
	isShowResolved: boolean;
	noteFilter: NoteFilterValue;
	onSetShowResolved: (val: boolean) => unknown;
	onSetNoteFilter: (val: NoteFilterValue) => unknown;
	onShare: () => unknown;
	onOpenSettings: () => unknown;
	teamMembers: TMember[];
	focusedCommentId?: number;
	noteId?: number;
	imageId?: number;
	notesOrder: TNotesOrder;
	showNotesLimit: Option<TNoteLimit>;
	onChangeNotesOrder: (order: TNotesOrder) => void;
	offerFirstTimeUpgrade: boolean;
	subscriptionOverlay?: ReactElement;
	setSubscriptionOverlay?: (elem: ReactElement | undefined) => void;
	statuses: NoteStatus[];
	type: RemoteData<Error, TProjectType>;
};

export type TReviewState = {
	scrollToCommentId?: number;
	commentEffect?: TCommentEffect;
	overlayImage?: string;
	notesListOpened: boolean;
	commentsListOpened: boolean;
	noteDeleteConfirmation?: number;
	naturalSize?: {
		noteId: number;
		width: number;
		height: number;
		ratio: number;
	};
};

type NoteDetailsProps = {
	note: TProductImage;
	naturalSize?: TReviewState['naturalSize'];
};

export const NoteDetails = memo<NoteDetailsProps>(({ note, naturalSize }) => {
	const [open, setOpen] = useState(false);
	const handleToggle = useCallback((e: MouseEvent) => {
		e.preventDefault();
		setOpen(x => !x);
	}, []);
	return (
		<div className="note-details">
			<h4 className="medium-font">
				<a className={cn('details-toggle', !open && 'collapsed')} href="#" role="button" onClick={handleToggle}>
					<i className="material-icons align-middle">expand_more</i>
					Details
				</a>
			</h4>
			<Collapse isOpen={open}>
				<div className="note-details-items">
					<dl className="row">
						<dt className="col-sm-3">Browser</dt>
						<dd className="col-sm-9">{note.browser}</dd>
					</dl>
					<dl className="row">
						<dt className="col-sm-3">OS</dt>
						<dd className="col-sm-9">{note.os}</dd>
					</dl>
					{naturalSize && note.id === naturalSize.noteId && (
						<dl className="row">
							<dt className="col-sm-3">Viewport</dt>
							<dd className="col-sm-9">
								{Math.round(naturalSize.width / naturalSize.ratio)}x
								{Math.round(naturalSize.height / naturalSize.ratio)}
							</dd>
						</dl>
					)}
					<dl className="row">
						<dt className="col-sm-3">URL</dt>
						<dd className="col-sm-9">{note.source}</dd>
					</dl>
				</div>
			</Collapse>
		</div>
	);
});

const Review = combineReader(
	SubscriptionViewContainer,
	MaxNotesLimit,
	NoteDetailsPanel,
	ThumbnailBarContainer,
	(SubscriptionViewContainer, MaxNotesLimit, NoteDetailsPanel, ThumbnailBarContainer) => {
		return class extends Component<TReviewProps, TReviewState> {
			state: TReviewState = {
				scrollToCommentId: undefined,
				notesListOpened: false,
				commentsListOpened: false,
			};

			componentDidUpdate(prevProps: TReviewProps) {
				// eslint-disable-next-line array-callback-return
				this.props.saveCommentResult.map(comment => {
					if (!prevProps.saveCommentResult.isSuccess()) {
						this.setState({ commentEffect: { id: comment.id, effect: 'scroll' } });
					}
				});
				// eslint-disable-next-line array-callback-return
				this.props.updateCommentResult.map(() => {
					if (!prevProps.updateCommentResult.isSuccess()) {
						this.setState({ commentEffect: undefined });
					}
				});
				if (this.props.focusedCommentId && this.props.focusedCommentId !== prevProps.focusedCommentId) {
					this.setState({ commentEffect: { id: this.props.focusedCommentId, effect: 'highlight' } });
				}
			}

			componentDidMount() {
				if (this.props.focusedCommentId) {
					this.setState({ commentEffect: { id: this.props.focusedCommentId, effect: 'highlight' } });
				}
			}

			render() {
				const { images, showNotesLimit } = this.props;
				const selectedImages = images.map(
					filterNotesByStatuses(this.props.statuses, this.props.noteFilter, this.props.isShowResolved),
				);
				const productId = this.props.product.toNullable()?.toNullable()?.id;
				const currentImageIndex = this.selectedImage
					.map(img =>
						img.chain(img =>
							selectedImages.toOption().mapNullable(images => images.findIndex(i => i.id === img.id)),
						),
					)
					.getOrElse(none);
				const totalFilteredImages = selectedImages.map(i => i.length).getOrElse(0);

				return (
					<div className="main">
						{this.renderImageOverlay()}
						<YesNoConfirmation
							isOpen={!!this.state.noteDeleteConfirmation}
							title={'Delete note'}
							confirmLabel={'Yes, delete'}
							onConfirm={this.handleDeleteNote}
							onCancel={() => this.setState({ noteDeleteConfirmation: undefined })}
							onClose={() => this.setState({ noteDeleteConfirmation: undefined })}>
							Are you sure you want to delete this note? All comments and attachments will also be
							deleted.
						</YesNoConfirmation>
						<div className={style.reviewContainer}>
							<div
								className={cn(
									style.notesListContainer,
									this.props.type.map(type => style[`notesListContainer_${type}`]).toNullable(),
									this.state.notesListOpened && style.notesBarOpen,
								)}>
								<div className={style.notesListHeader}>
									<div className="inline-block quarter-padding-top">
										<h4 className="bold-font">
											{formatNoteCount(
												selectedImages
													.map<number | undefined>(p => p.length)
													.getOrElse(undefined),
											)}
										</h4>
									</div>
									<div
										className={cn(
											style.showNotesToggle,
											'inline-block align-middle float-right margin-left',
										)}>
										<a
											href="#"
											onClick={e => {
												e.preventDefault();
												this.handleToggleNotesBar();
											}}>
											<i
												className="material-icons text-color"
												style={{ fontSize: 14, position: 'relative', top: 3 }}>
												clear
											</i>
										</a>
									</div>
									<div className="inline-block align-middle float-right">
										<Selectbox
											theme={{
												buttonColor: 'default',
												button: cn(
													'no-padding btn-sm-text light-text-color regular-font',
													style.notesOrderSelector,
												),
											}}
											className="d-inline half-margin-right"
											menuOptions={{ right: true }}
											value={this.props.notesOrder}
											renderOption={formatNotesOrder}
											onChange={order => this.props.onChangeNotesOrder(order)}>
											<SelectboxOption
												active={this.props.notesOrder === 'newest'}
												value={'newest'}>
												Newest
											</SelectboxOption>
											<SelectboxOption
												active={this.props.notesOrder === 'oldest'}
												value={'oldest'}>
												Oldest
											</SelectboxOption>
											<SelectboxOption
												active={this.props.notesOrder === 'status'}
												value={'status'}>
												Status
											</SelectboxOption>
											<SelectboxOption
												active={this.props.notesOrder === 'priority'}
												value={'priority'}>
												Priority
											</SelectboxOption>
											<SelectboxOption active={this.props.notesOrder === 'page'} value={'page'}>
												Page
											</SelectboxOption>
										</Selectbox>
										<NoteFilter
											value={this.props.noteFilter}
											onChange={this.props.onSetNoteFilter}
											teamMembers={this.props.teamMembers}
											statuses={this.props.statuses}
										/>
									</div>
								</div>
								<div
									className={cn(
										style.notesListBody,
										showNotesLimit.isSome() && style.notesListBody_withAlert,
									)}>
									<RenderRemoteData
										data={images}
										success={this.renderSidebarItems}
										DataStatePending={this.renderPendingSidebarItems}
									/>
								</div>
								<MaxNotesLimit
									value={showNotesLimit}
									offerFirstTimeUpgrade={this.props.offerFirstTimeUpgrade}
									onSetSubscriptionOverlay={this.props.setSubscriptionOverlay ?? constVoid}
								/>
								<div className={style.notesListFooter}>
									<div className={cn(style.showResolved, 'check-btn')}>
										<CustomInput
											type="checkbox"
											label={<h4 className="no-padding">Show completed</h4>}
											checked={this.props.isShowResolved}
											id="review--showResolved"
											onChange={e => this.handleToggleResolved(e.target.checked)}
										/>
									</div>
								</div>
							</div>
							{this.props.type.exists(type => type === 'uploaded') &&
								this.selectedImage
									.toOption()
									.chain(x => x)
									.map(note => (
										<div className={style.leftNoteDetails}>
											<div className={cn('padded', style.leftNoteDetails__header)}>
												<Button onClick={this.handleCloseUploadedImageNote}>
													<i className="material-icons light-text-color">
														keyboard_arrow_left
													</i>{' '}
													Back
												</Button>
												<h4 className="medium-font d-inline mb-0 align-middle">
													{currentImageIndex.fold(
														formatNoteCount(totalFilteredImages),
														index => `${index + 1} of ${totalFilteredImages}`,
													)}
												</h4>
											</div>
											{this.renderComments(note)}
										</div>
									))
									.toNullable()}
							<RenderRemoteData data={this.selectedImage} success={this.renderMainArea} />
							{this.props.type.exists(type => type === 'uploaded') && !!productId && (
								<ThumbnailBarContainer projectId={productId} />
							)}
						</div>
						{this.props.subscriptionOverlay}
					</div>
				);
			}

			handleCloseUploadedImageNote = () => {
				const productId = this.props.product.toNullable()?.toNullable()?.id;
				const note = this.selectedImage.toOption().toNullable()?.toNullable();
				productId &&
					history.push(
						note?.product_upload_image_id
							? routes.productImages(productId, note.product_upload_image_id)
							: routes.product(productId),
					);
			};

			handleDeleteNote = () => {
				if (this.state.noteDeleteConfirmation) {
					this.props.onDeleteNote && this.props.onDeleteNote(this.state.noteDeleteConfirmation);
					this.setState({ noteDeleteConfirmation: undefined });
				}
			};

			renderMainArea = (data: Option<TProductImage>) => {
				return (
					<div className={cn(style.noteContainer, 'note-container')}>
						<div className={cn(style.noteBody, 'note-body')}>
							{this.renderResolveBar(data, true)}
							<div className={style.noteInfo}>
								{this.renderImage(data)}
								{this.props.type.exists(t => t === 'extension') &&
									data.map(note => this.renderComments(note)).toNullable()}
							</div>
						</div>
					</div>
				);
			};

			renderResolveBar = (note: Option<TProductImage>, tablet: boolean) => {
				const canDelete = !!this.props.onDeleteNote;
				const onDelete = canDelete
					? () => note.fold(null, note => this.setState({ noteDeleteConfirmation: note.id }))
					: this.handleUpgradeForNoteDeletion;

				return (
					<ResolveBar
						statuses={this.props.statuses}
						note={note}
						tablet={tablet}
						onToggleNotesBar={this.handleToggleNotesBar}
						onDeleteNote={onDelete}
						onSetNoteStatus={this.props.onSetNoteStatus}
					/>
				);
			};

			handleToggleNotesBar = () => {
				this.setState(state => ({
					notesListOpened: !state.notesListOpened,
				}));
			};

			handleToggleCommentsBar = () => {
				this.setState(state => ({
					commentsListOpened: !state.commentsListOpened,
				}));
			};

			handleUpgradeForNoteDeletion = () => {
				this.props.setSubscriptionOverlay?.(
					<SubscriptionViewContainer
						subtitle={'Upgrade your plan to delete notes.'}
						onClose={() => this.props.setSubscriptionOverlay?.(undefined)}
					/>,
				);
			};

			renderImageOverlay = () => {
				const { overlayImage } = this.state;
				if (overlayImage) {
					return (
						<AttachmentOverlay
							src={overlayImage}
							onClose={() => this.setState({ overlayImage: undefined })}
						/>
					);
				}
			};

			handleToggleResolved = (value: boolean) => {
				this.props.onSetShowResolved(value);
			};

			acceptImgNaturalSize = (naturalSize: TReviewState['naturalSize']) => {
				this.setState({ naturalSize });
			};

			renderImage = (image: Option<TProductImage>) => {
				const waitingForType = this.props.type.isPending() || this.props.type.isInitial();
				const waitingForUploadedImages =
					this.props.type.exists(t => t === 'uploaded') &&
					(this.props.uploadedImages.isPending() || this.props.uploadedImages.isInitial());
				if (
					waitingForType ||
					this.props.images.isPending() ||
					this.props.images.isInitial() ||
					waitingForUploadedImages
				) {
					return this.renderLoadingNotes();
				}
				const type = this.props.type.getOrElse('extension');
				if (type === 'extension' && image.isNone()) return this.renderZeroNotes();
				const notes =
					type === 'extension'
						? image.map(i => [i])
						: this.props.images
								.toOption()
								.filter(() => !!this.props.uploadedImage)
								.map(images =>
									images.filter(i => i.product_upload_image_id === this.props.uploadedImage?.id),
								);

				return notes.foldL(this.renderZeroNotes, notes => (
					<NoteView
						type={type}
						notes={notes}
						uploadedImage={this.props.uploadedImage}
						currentNoteId={image.toNullable()?.id}
						onReceivedNaturalSize={this.acceptImgNaturalSize}
						onToggleCommentsBar={this.handleToggleCommentsBar}
						onClickNote={this.handleSelectNote}
						onPostNote={note =>
							this.props.onPostNote?.({
								...note,
								productId: this.props.product.toNullable()?.toNullable()?.id!,
							})
						}
					/>
				));
			};

			renderZeroNotes = () => {
				return <ZeroState onShare={this.props.onShare} onOpenSettings={this.props.onOpenSettings} />;
			};

			renderLoadingNotes() {
				return (
					<div className={cn('note-zero', style.notesLoading)}>
						<div className={style.loading__spinnerArea}>
							<Spinner color="primary" className={style.loading__spinner} />
						</div>
						Loading notes
					</div>
				);
			}

			renderComments = (note: TProductImage) => {
				const canDelete = !!this.props.onDeleteNote;
				const onDelete = canDelete
					? () => this.setState({ noteDeleteConfirmation: note.id })
					: this.handleUpgradeForNoteDeletion;
				const handleToggleComments = this.props.type
					.toOption()
					.fold(constVoid, type =>
						type === 'uploaded' ? this.handleCloseUploadedImageNote : this.handleToggleCommentsBar,
					);
				return (
					<NoteDetailsPanel
						commentEffect={this.state.commentEffect}
						commentListOpened={this.state.commentsListOpened}
						note={note}
						onClickAttachment={this.handleClickAttachment}
						onToggleCommentListOpened={handleToggleComments}
						handleCommentRef={this.handleCommentRef}
						naturalSize={this.state.naturalSize}
						onDeleteNote={onDelete}
						onToggleNotesBar={this.handleToggleNotesBar}
						className={style.noteDetails_left}
					/>
				);
			};

			handleClickAttachment = (source: string) => {
				this.setState({ overlayImage: source });
			};

			renderEmptySidebar = () => {
				return (
					<div className={style.notesZero}>
						<div className={cn(style.notesZero__container, 'text-center')}>
							<img width={70} src={zeroNotesSvg} />
							<h4 className="light-text-color margin-top">
								There are no open notes to display for this project.
							</h4>
						</div>
					</div>
				);
			};

			renderSidebarItems = (images: TProductImage[]) => {
				const notes = filterNotesByStatuses(
					this.props.statuses,
					this.props.noteFilter,
					this.props.isShowResolved,
				)(images);
				if (!notes.length) {
					return this.renderEmptySidebar();
				} else if (this.props.notesOrder === 'page') {
					return this.renderGroupedSidebarItems(notes);
				} else {
					return <Fragment>{notes.map(this.renderNote)}</Fragment>;
				}
			};

			renderGroupedSidebarItems = (images: TProductImage[]) => {
				return this.props.type.toOption().foldL(this.renderPendingSidebarItems, type => {
					if (type === 'uploaded') {
						return this.props.uploadedImages.foldL(
							this.renderPendingSidebarItems,
							this.renderPendingSidebarItems,
							() => <Fragment>{images.map(this.renderNote)}</Fragment>,
							uploadedImages => {
								// console.log('grouping', this.props.uploadedImage);
								const groups = new Map<string, SidebarGroup>(
									uploadedImages.map((i, index) => [
										String(i.id),
										{
											name: i.name,
											images: [],
											active: this.props.uploadedImage?.id === i.id,
										},
									]),
								);
								const uncat: SidebarGroup = { name: 'Uncategorized', images: [] };
								groups.set('Uncategorized', uncat);
								images.forEach(i => {
									const group = groups.get(String(i.product_upload_image_id)) ?? uncat;
									group.images.push(i);
								});
								return this.renderSidebarGroups(Array.from(groups.values()));
							},
						);
					} else {
						const groups = new Map<string, SidebarGroup>();
						images.forEach(i => {
							const g = extractPageFromUrl(i.source);
							let group = groups.get(g);
							if (!group) {
								group = { name: g, images: [] };
								groups.set(g, group);
							}
							group.images.push(i);
						});
						return this.renderSidebarGroups(Array.from(groups.values()));
					}
				});
			};

			renderSidebarGroups = (groups: Array<SidebarGroup>) => {
				const items: ReactNode[] = groups.flatMap(({ name, images, active }, index) =>
					images.length === 0
						? []
						: [
								<h5
									className={cn(!active && 'light-text-color', style.pageSeparator)}
									key={`group-${index}`}>
									<span className={style.pageSeparatorContent}>{name}</span>
									<span className={style.pageSeparatorLine} />
								</h5>,
								...images.map(i => this.renderNote(i)),
						  ],
				);
				return <Fragment>{items}</Fragment>;
			};

			renderPendingSidebarItems = () => {
				return (
					<Fragment>
						<NoteCardLoading />
						<NoteCardLoading />
					</Fragment>
				);
			};

			handleCommentRef = (e: HTMLElement | null) => {
				const { commentEffect } = this.state;
				if (commentEffect) {
					e &&
						e.scrollIntoView({
							behavior: 'smooth',
						});
					setTimeout(
						() => {
							this.setState(currState => {
								return commentEffect === currState.commentEffect ? { commentEffect: undefined } : null;
							});
						},
						commentEffect.effect === 'highlight' ? 5000 : 0,
					);
				}
			};

			renderNote = (note: TProductImage) => {
				const isSelected = this.selectedImage.exists(n => n.exists(n => n.id === note.id));
				return (
					<NoteCard
						key={note.id}
						statuses={this.props.statuses}
						isSelected={isSelected}
						onSelect={this.handleSelectNote}
						note={note}
					/>
				);
			};

			private get selectedImage() {
				return this.props.type.chain(type =>
					type === 'uploaded' ? this.props.userSelectedImage : this.props.selectedImage,
				);
			}

			private handleSelectNote = (note: TProductImage) => {
				if (this.state.notesListOpened) {
					this.handleToggleNotesBar();
				}
				this.props.onSelectNote(note);
			};
		};
	},
);

export function formatNoteCount(n: number | undefined): string {
	switch (n) {
		case 1:
			return '1 Note';
		case undefined:
			return 'Notes';
		default:
			return `${n} Notes`;
	}
}

export function formatStatusName(status: NoteStatus): string;
export function formatStatusName(status: number, statuses: NoteStatus[]): string;
export function formatStatusName(status: NoteStatus | number, statuses?: NoteStatus[]): string {
	if (typeof status === 'object') {
		return status.name;
	}
	return statuses?.find(s => s.id === status)?.name ?? String(status);
}

export function formatStatusIcon(opt: NoteStatus, statuses: NoteStatus[]): ReactNode;
export function formatStatusIcon(opt: number, statuses: NoteStatus[]): ReactNode;
export function formatStatusIcon(opt: NoteStatus | number, statuses?: NoteStatus[]): ReactNode {
	const found = typeof opt === 'object' ? opt : statuses?.find(s => s.id === opt);
	const isFinal = found && statuses?.[statuses.length - 1].id === found.id;
	const color = found?.color ?? '#E6EAEE';
	if (isFinal) {
		return <img src={completedIcon} />;
	} else {
		return <i style={{ display: 'inline-block', width: 6, height: 6, borderRadius: 6, background: color }} />;
	}
}

export function formatPriorityIcon(opt: TNotePriority) {
	switch (opt) {
		case 'none':
			return priorityNoneSvg;
		case 'low':
			return priorityLowSvg;
		case 'medium':
			return priorityMediumSvg;
		case 'high':
			return priorityHighSvg;
		case 'urgent':
			return priorityUrgentSvg;
	}
}

function formatNotesOrder(order: TNotesOrder | undefined) {
	return order ? order.charAt(0).toUpperCase() + order.slice(1) : '';
}

export default Review;
