import * as React from 'react';
import { Fragment, useMemo, useCallback, ChangeEventHandler, ChangeEvent, PropsWithChildren } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { RemoteData } from '@devexperts/remote-data-ts';
import { RenderRemoteData } from '../../components/RenderRemoteData';
import { groupSort, fromArray } from 'fp-ts/lib/NonEmptyArray2v';
import { contramap, Ord, ordString } from 'fp-ts/lib/Ord';
import uuid from 'uuid';
import { singleton, toArray } from 'fp-ts/lib/Set';
import { head } from 'fp-ts/lib/Array';

type Item = unknown;

type ListSelectProps<T extends Item> = {
	items: RemoteData<Error, T[]>;
	renderItem: (item: T) => React.ReactNode;
	grouping?: Grouping<T>;
	identifier: (item: T) => string;
	selected: Set<string>;
	onToggleSelected: (id: string, selected: boolean) => void;
};

export const ListSelect = <T extends Item>({
	items,
	renderItem,
	grouping,
	onToggleSelected,
	selected,
	children,
	identifier,
}: PropsWithChildren<ListSelectProps<T>>) => {
	const prefix = useMemo(() => uuid.v4(), []);
	const handleToggleItem = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value, checked } = e.target;
			onToggleSelected(value, checked);
		},
		[onToggleSelected],
	);
	const renderItems = useCallback(
		renderSuccess(prefix, handleToggleItem, selected, renderItem, identifier, grouping),
		[prefix, handleToggleItem, selected, renderItem, grouping],
	);

	return (
		<section className="no-padding-top">
			<Container>
				<Row>
					<Col>
						<div className="list-of-options">
							{children}
							<RenderRemoteData data={items} success={renderItems} />
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

type Grouping<T> = {
	getGroup: (item: T) => string;
	ordGroup: Ord<string>;
};

const renderSuccess = <T extends Item>(
	prefix: string,
	onToggle: ChangeEventHandler<HTMLInputElement>,
	selected: Set<string>,
	renderItem: (item: T) => React.ReactNode,
	identifier: (item: T) => string,
	grouping?: Grouping<T>,
) => {
	function renderItems(items: T[]) {
		return items.map(item => {
			const id = identifier(item);
			const key = `${prefix}-${id}`;
			return (
				<ListGroup key={key}>
					<div>
						<input id={key} type="checkbox" onChange={onToggle} value={id} checked={selected.has(id)} />
						<ListGroupItem tag="label" htmlFor={key}>
							{renderItem(item)}
						</ListGroupItem>
					</div>
				</ListGroup>
			);
		});
	}

	return (items: T[]) => {
		if (grouping) {
			const groups = fromArray(items)
				.map(groupSort(contramap(grouping.getGroup, grouping.ordGroup)))
				.getOrElse([]);
			return (
				<Fragment>
					{groups.map(items => (
						<div key={`group-${grouping.getGroup(items[0])}`} className="margin-bottom">
							<p className="bold-font">{grouping.getGroup(items[0])}</p>
							{renderItems(items)}
						</div>
					))}
				</Fragment>
			);
		} else {
			return <Fragment>{renderItems(items)}</Fragment>;
		}
	};
};

export function useMultiSelect<T extends Item>(items: RemoteData<Error, T[]>, identifier: (item: T) => string) {
	const [selected, setSelected] = React.useState(new Set<string>());
	const handleToggleItem = useCallback(
		(id: string, selected: boolean) => {
			setSelected(prev => {
				if (selected) {
					if (!prev.has(id)) {
						return new Set(prev).add(id);
					}
				} else {
					if (prev.has(id)) {
						const state = new Set(prev);
						state.delete(id);
						return state;
					}
				}
				return prev;
			});
		},
		[setSelected],
	);
	const handleToggleAll = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const state = new Set(e.target.checked ? items.getOrElse([]).map(identifier) : []);
			setSelected(state);
		},
		[setSelected, items, identifier],
	);
	return {
		selected,
		handleToggleItem,
		handleToggleAll,
	};
}

export function useSingleSelect<T extends Item>() {
	const [selected, setSelected] = React.useState(new Set<string>());
	const handleToggleItem = useCallback(
		(id: string, selected: boolean) => {
			setSelected(singleton(id));
		},
		[setSelected],
	);
	const getSelectedItem = () => head(toArray(ordString)(selected));
	return {
		selected,
		handleToggleItem,
		getSelectedItem,
	};
}
