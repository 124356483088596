import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { PageNotFound } from './PageNotFound';
import { constant } from 'fp-ts/lib/function';
import { asks } from 'fp-ts/lib/Reader';
import { SessionServiceClass } from '../../services/token.service';
import { isSome } from 'fp-ts/lib/Option';
import { map } from 'rxjs/operators';

export type TPageNotFoundContainerContext = {
	sessionService: SessionServiceClass;
};

export const PageNotFoundContainer = asks((ctx: TPageNotFoundContainerContext) =>
	withRX(PageNotFound)(
		constant({
			props: {
				isLoggedIn: ctx.sessionService.token$.pipe(map(isSome)),
			},
		}),
	),
);
