import React, { Component, SFC } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import logoSvg from '../../assets/images/logo.svg';
import { Input, Button } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { WelcomeLayout } from '../welcome/WelcomeLayout';
import { FormField } from '../welcome/FormField';
import { TApiError, hasFieldOrGlobalErrors, getErrors, getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { routes } from 'volley-common/dist/utils/routes';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ask } from 'fp-ts/lib/Reader';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { withReturnUrl } from '../../utils/route';
import qs from 'qs';
import { capterraHandler } from '../../utils/capterra';
import { PasswordRequirements } from '../../components/PasswordRequirements';
import { validatePassword } from 'volley-common/dist/utils/string.utils';
import { empty } from 'fp-ts/lib/Array';
import { MIN_PASSWORD_LENGTH } from 'volley-common/dist/services/auth.service';

export type TRegistrationData = {
	email: string;
	fullName: string;
	password: string;
};

type TFormData = Partial<TRegistrationData>;

export type TSignUpProps = {
	onTryRegister: (data: TRegistrationData) => void;
	result: RemoteData<TApiError, boolean>;
	isLoggedIn: boolean;
	returnUrl?: string;
};

interface SignUpContext {
	websiteUrl: string;
}

interface TSignUpState {
	passwordVisible: boolean;
}

const SignUp = combineReader(WelcomeLayout, ask<SignUpContext>(), (WelcomeLayout, ctx) =>
	withReturnUrl(
		class extends Component<TSignUpProps, TSignUpState> {
			state = {
				passwordVisible: false,
			};

			render() {
				const { returnUrl } = this.props;
				const loginUrl = routes.login + qs.stringify({ returnUrl }, { addQueryPrefix: true });
				return (
					<WelcomeLayout>
						<div className="form-content">
							<img className="double-margin-top double-margin-bottom" src={logoSvg} width="120" />
							<h2>Get started now</h2>
							<p className="light-text-color">Filling the gap between design and development</p>
							<Form onSubmit={this.handleSubmit} render={this.renderForm} />

							<div className="double-margin-top">
								<p>
									Have an account? <Link to={loginUrl}>Log in</Link>
								</p>
							</div>
						</div>
					</WelcomeLayout>
				);
			}

			private renderForm: SFC<FormRenderProps> = ({ handleSubmit }) => {
				const { result } = this.props;
				result.isFailure() && console.log(result.error);
				return (
					<form onSubmit={handleSubmit}>
						<Field
							name="fullName"
							render={({ input, meta }) => (
								<FormField name="Name">
									<Input
										autoComplete="name"
										bsSize="lg"
										placeholder="John Doe"
										disabled={result.isPending()}
										invalid={result.isFailure() && hasFieldOrGlobalErrors(result.error, 'name')}
										{...input}
										type="text"
									/>
									{result.isFailure() &&
										getErrors(result.error, 'name').map((e, index) => (
											<div className="invalid-feedback" key={index}>
												{e}
											</div>
										))}
								</FormField>
							)}
						/>
						<Field
							name="email"
							render={({ input, meta }) => (
								<FormField name="Email">
									<Input
										bsSize="lg"
										placeholder="john@doe.com"
										disabled={result.isPending()}
										invalid={result.isFailure() && hasFieldOrGlobalErrors(result.error, 'email')}
										{...input}
										type="email"
									/>
									{result.isFailure() &&
										getErrors(result.error, 'email').map((e, index) => (
											<div className="invalid-feedback" key={index}>
												{e}
											</div>
										))}
								</FormField>
							)}
						/>
						<Field
							name="password"
							validate={(pwd: string | undefined) =>
								validatePassword(pwd || '').valid ? null : 'Please check the password requirements'
							}
							render={({ input, meta }) => {
								const err =
									meta.submitFailed && meta.error
										? [meta.error]
										: result.isFailure()
										? getErrors(result.error, 'password')
										: empty;
								return (
									<div className="margin-bottom">
										<h4 className="d-inline align-baseline bold-font">Password</h4>
										<h4 className="float-right d-inline">
											<Button
												onClick={() =>
													this.setState({ passwordVisible: !this.state.passwordVisible })
												}
												color="default"
												className="link-color"
												style={{ fontSize: 15, fontWeight: 600, padding: 0 }}>
												<i className="material-icons-outlined">visibility</i>{' '}
												{this.state.passwordVisible ? 'Hide' : 'Show'}
											</Button>
										</h4>
										<Input
											bsSize="lg"
											placeholder={`Min ${MIN_PASSWORD_LENGTH} chars.`}
											autoComplete="new-password"
											disabled={result.isPending()}
											invalid={
												err.length > 0 ||
												(result.isFailure() && hasFieldOrGlobalErrors(result.error, 'password'))
											}
											{...input}
											type={this.state.passwordVisible ? 'text' : 'password'}
										/>
										{err.map((e, index) => (
											<div className="invalid-feedback" key={index}>
												{e}
											</div>
										))}
										{result.isFailure() &&
											getNonFieldErrors(result.error).map((e, index) => (
												<div className="invalid-feedback" key={index}>
													{e}
												</div>
											))}
									</div>
								);
							}}
						/>

						<Field
							name={'password'}
							render={({ input: { value } }) => (
								<div className="double-margin-bottom">
									<PasswordRequirements password={value} />
								</div>
							)}
						/>

						<h5 className="light-text-color">
							I agree to the{' '}
							<a className="light-text-color" href={`${ctx.websiteUrl}terms`}>
								<u>Terms and privacy</u>
							</a>
						</h5>
						<div className="double-margin-top">
							<LoadingButton color="primary" size="lg" block pending={result.isPending()}>
								Create account
							</LoadingButton>
						</div>
					</form>
				);
			};

			handleSubmit = (values: TFormData) => {
				capterraHandler();
				this.props.onTryRegister &&
					this.props.onTryRegister({
						email: values.email || '',
						fullName: values.fullName || '',
						password: values.password || '',
					});
			};
		},
		route => (route === routes.dashboard ? routes.dashboardWelcome : route),
	),
);

export default SignUp;
