import * as React from 'react';
import { MouseEvent, useCallback, Fragment } from 'react';
import { Alert } from 'reactstrap';
import { identity } from 'fp-ts/lib/function';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { dismissable } from '../alerts.utils';
import { ProfileModelType } from '../../../models/profile.model';
import { ask } from 'fp-ts/lib/Reader';
import { map, tap, filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { isDefined } from 'volley-common/dist/utils/object.utils';
import { useSubscriptionView } from '../../subscribe/SubscriptionViewContainer';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';

interface SuspendedAlertProps {
	isOpen: boolean;
	onClose: () => void;
}

export const SuspendedAlert = combineReader(
	useSubscriptionView,
	useSubscriptionView => ({ isOpen, onClose }: SuspendedAlertProps) => {
		const { showSubscriptionView, subscriptionView } = useSubscriptionView();

		const handleSubscribe = useCallback(
			(e: MouseEvent<unknown>) => {
				e.preventDefault();
				showSubscriptionView();
			},
			[showSubscriptionView],
		);

		return (
			<Fragment>
				{subscriptionView}
				<Alert toggle={onClose} isOpen={isOpen} color="primary">
					<strong>Your plan has been canceled</strong>
					<br />
					We've moved you to the Starter plan and archived all but your first product. Please{' '}
					<a href="#" onClick={handleSubscribe} className="alert-link">
						select a new plan
					</a>{' '}
					and enter a valid credit card to re-activate your subscription.
				</Alert>
			</Fragment>
		);
	},
);

interface SuspendedContainerContext {
	profileModel: ProfileModelType;
}

export const SuspendedContainer = combineReader(
	SuspendedAlert,
	ask<SuspendedContainerContext>(),
	(SuspendedAlert, { profileModel }) =>
		withRX(SuspendedAlert)(() => {
			const { isOpen$: isNotDismissed$, dismiss } = dismissable('noSuspendedAlert');

			const isSuspended$ = profileModel.profile$.pipe(
				map(p =>
					p
						.toOption()
						.chain(identity)
						.toNullable(),
				),
				filter(isDefined),
				map(profile => !!profile.billing.cancellation_reason),
				tap(suspended => {
					// remove the 'dismissed' flag from the local storage
					// so that the alert is not hidden the next time the account is suspended
					if (!suspended) {
						dismiss(false);
					}
				}),
			);

			const isVisible$ = combineLatest(isSuspended$, isNotDismissed$).pipe(
				map(([suspended, notDismissed]) => suspended && notDismissed),
			);

			return {
				defaultProps: {
					isOpen: false,
					onClose: () => dismiss(true),
				},
				props: {
					isOpen: isVisible$,
				},
			};
		}),
);
