import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import React, { Fragment, memo, useState } from 'react';
import { DropdownItem } from 'reactstrap';
import { IntegrationCard } from './Integrations';
import jiraSvg from 'volley-common/dist/assets/images/jira-software-logo.svg';
import { IntegrationsMap, IntegrationStore } from 'volley-common/dist/services/export/integration.store';
import { distinctUntilChanged, map, pluck, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ask } from 'fp-ts/lib/Reader';
import { Subject, of, EMPTY, merge } from 'rxjs';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { RemoteData, initial } from '@devexperts/remote-data-ts';
import { JiraConnectionModal } from '../../export/jira/JiraConnectionModal';
import { jira, JiraConnectionDetails, JiraProject } from 'volley-common/dist/services/export/jira.service';
import { JiraSettings, JiraSettingsFormModel } from '../integrations/JiraSettings';
import { tuple } from 'fp-ts/lib/function';
import { PaidOption } from 'volley-common/dist/components/PaidOption';

interface JiraCardProps {
	onConnect: (data: JiraConnectionDetails) => void;
	onSaveSettings: (data: JiraSettingsFormModel) => void;
	onDisconnect: () => void;
	hasCredentials: boolean;
	integrations: IntegrationsMap;
	productId: number;
	saveResult: RemoteData<Error, unknown>;
	projects: RemoteData<Error, JiraProject[]>;
	onSubscriptionNeeded?: () => void;
}

const JiraCard = memo<JiraCardProps>(
	({
		onConnect,
		onSaveSettings,
		onDisconnect,
		hasCredentials,
		integrations,
		saveResult,
		projects,
		onSubscriptionNeeded,
	}) => {
		const [isEditing, setIsEditing] = useState(false);
		const onStartEditing = () => setIsEditing(true);
		const integration = integrations.jira;

		const menu = hasCredentials ? (
			<Fragment>
				<PaidOption
					isUnlocked={!onSubscriptionNeeded}
					onAllowed={onStartEditing}
					onLocked={onSubscriptionNeeded}>
					Settings
				</PaidOption>
				<DropdownItem divider />
				<DropdownItem onClick={onDisconnect}>Disconnect</DropdownItem>
			</Fragment>
		) : (
			<PaidOption isUnlocked={!onSubscriptionNeeded} onAllowed={onStartEditing} onLocked={onSubscriptionNeeded}>
				Connect
			</PaidOption>
		);

		return (
			<Fragment>
				<JiraConnectionModal
					onBack={() => setIsEditing(false)}
					visible={!hasCredentials && isEditing}
					onVerifyAndComplete={hasCredentials ? onSaveSettings : onConnect}
					verifyAndSaveResult={saveResult}
				/>
				<JiraSettings
					onClose={() => setIsEditing(false)}
					visible={hasCredentials && isEditing}
					onSave={onSaveSettings}
					projects={projects}
					saveResult={saveResult}
					value={
						integration
							? {
									...(integration.config_details as any),
							  }
							: undefined
					}
				/>
				<IntegrationCard active={hasCredentials} logo={jiraSvg} name={'Jira'} menu={menu} />
			</Fragment>
		);
	},
);

type JiraCardContainerContext = {
	integrationStore: IntegrationStore;
};

export const JiraCardContainer = combineReader(jira, ask<JiraCardContainerContext>(), (jira, ctx) =>
	withRX(JiraCard)(props$ => {
		const hasCredentials$ = props$.pipe(
			map(p => !!p.integrations.jira),
			distinctUntilChanged(),
		);

		const productId$ = props$.pipe(pluck('productId'), distinctUntilChanged());
		const connect$ = new Subject<JiraConnectionDetails>();
		const connectEffect$ = connect$.pipe(
			withLatestFrom(productId$),
			switchMap(([{ host, ...data }, productId]) =>
				ctx.integrationStore.set(productId, IntegrationType.Jira, data, { host }, {}),
			),
		);

		const projects$ = props$.pipe(
			map(p => tuple(p.productId, p.integrations.jira?.id)),
			distinctUntilChanged((a, b) => a.every((val, i) => val === b[i])),
			switchMap(([productId, configId]) => (configId ? jira.getProjects(productId, configId) : of(initial))),
		);

		const saveSettings$ = new Subject<JiraSettingsFormModel>();
		const saveSettingsEffect$ = saveSettings$.pipe(
			withLatestFrom(props$, projects$),
			switchMap(([settings, { productId, integrations }, projects]) => {
				const project = projects.getOrElse([]).find(p => p.project_key === settings.project_key);
				return integrations.jira
					? ctx.integrationStore.update(
							productId,
							integrations.jira.id,
							undefined,
							{
								...settings,
								project_name: project?.project_name,
							},
							{},
					  )
					: EMPTY;
			}),
		);

		const disconnect$ = new Subject<void>();
		const disconnectEffect$ = disconnect$.pipe(
			withLatestFrom(props$),
			switchMap(([, props]) =>
				props.integrations.jira
					? ctx.integrationStore.remove(props.productId, props.integrations.jira.id)
					: EMPTY,
			),
		);

		return {
			defaultProps: {
				onConnect: val => connect$.next(val),
				onSaveSettings: val => saveSettings$.next(val),
				onDisconnect: () => disconnect$.next(),
				hasCredentials: false,
				saveResult: initial,
				projects: initial,
			},
			props: {
				hasCredentials: hasCredentials$,
				saveResult: merge(saveSettingsEffect$, connectEffect$),
				projects: projects$,
			},
			effects$: disconnectEffect$,
		};
	}),
);
