import React, { Fragment, memo, useState, useCallback, useMemo, useEffect } from 'react';
import BrowseModeHeader from './NavBar';
import { RemoteData, failure, success } from '@devexperts/remote-data-ts';
import { NoteStatus, TProduct } from 'volley-common/dist/services/products.service';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ShareFormContainer } from './share/ShareFormContainer';
import { Option, isNone, some } from 'fp-ts/lib/Option';
import { ProductUnavailable } from './ProductUnavailable';
import { IntegrationSelectorContainer } from './export/IntegrationSelectorContainer';
import qs from 'qs';
import { identity, tuple } from 'fp-ts/lib/function';
import { ProductSettingsContainer } from 'volley-ui/src/modules/product/settings/ProductSettingsContainer';
import { ProductSettingsState } from './settings/ProductSettings';
import { type, string } from 'io-ts';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { isEnum } from 'volley-common/dist/utils/object.utils';
import { history } from '../../utils/history';
import { ProductView } from './review/Review';
import { ReviewOuterContainer } from './review/ReviewOuterContainer';
import { routes } from 'volley-common/dist/utils/routes';
import { DragLayer } from './review/BoardView';
import { TProjectType } from './review/NoteView';

export type TBrowseModeProps = {
	userId: Option<number>;
	productId: number;
	noteId?: number;
	imageId?: number;
	commentId?: number;
	product: RemoteData<Error, Option<TProduct>>;
	teamId: Option<number>;
	onReloadProduct?: () => void;
	onProjectLeft?: () => void;
	state?: unknown;
	onSaveStatuses: (columns: NoteStatus[]) => void;
	saveStatusesResult: RemoteData<Error, unknown>;
	canDeleteStatus?: (id: number) => Promise<boolean | string>;
	type: RemoteData<Error, TProjectType>;
};

function isExportUrl() {
	const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	return query && query.hasOwnProperty('export');
}

const integrationStateCodec = type({ integrationType: string });
function getIntegrationFromState(state: unknown): ProductSettingsState | undefined {
	if (integrationStateCodec.is(state) && isEnum(IntegrationType)(state.integrationType)) {
		return { tab: 'integrations', integration: some(state.integrationType) };
	}
	return undefined;
}

export const BrowseMode = combineReader(
	ReviewOuterContainer,
	ShareFormContainer,
	IntegrationSelectorContainer,
	BrowseModeHeader,
	ProductSettingsContainer,
	(
		ReviewOuterContainer,
		ShareFormContainer,
		IntegrationSelectorContainer,
		BrowseModeHeader,
		ProductSettingsContainer,
	) =>
		memo<TBrowseModeProps>(
			({
				product,
				productId,
				commentId,
				noteId,
				imageId,
				userId,
				teamId,
				onReloadProduct,
				onProjectLeft,
				state,
				onSaveStatuses,
				saveStatusesResult,
				canDeleteStatus,
				type,
			}) => {
				const [view, setView] = useViewState(productId);
				const [isShareModalShown, setShareModalShown] = useState(false);
				const [isExporting, setExporting] = useState(isExportUrl);
				// eslint-disable-next-line react-hooks/exhaustive-deps -- no need to react to further state changes
				const productSettingsState = useMemo(() => getIntegrationFromState(state), []);
				const [isSettingsOpen, setSettingsOpen] = useState(!!productSettingsState?.tab);

				useEffect(() => {
					if (integrationStateCodec.is(state)) {
						const { integrationType, ...newState } = state;
						history.replace({ state: newState });
					}
				}, [state]);

				const handleCloseShare = useCallback(() => setShareModalShown(false), []);
				const handleCloseExport = useCallback(() => setExporting(false), []);
				const handleShare = useCallback(() => setShareModalShown(true), []);
				const handleExport = useCallback(() => setExporting(true), []);
				const handleOpenSettings = useCallback(() => setSettingsOpen(true), []);
				const handleCloseSettings = useCallback(() => setSettingsOpen(false), []);

				if (product.exists(isNone)) {
					return <ProductUnavailable />;
				}

				const productForNavbar = product.chain(product =>
					product.fold(failure<Error, TProduct>(new Error('Product not found - not a member?')), product =>
						success<Error, TProduct>(product),
					),
				);

				const actualProduct = product.toOption().chain(identity);

				const isExtension = type.exists(t => t === 'extension');

				return (
					<Fragment>
						<DragLayer />
						<BrowseModeHeader
							onShare={handleShare}
							onExport={isExtension ? handleExport : undefined}
							onOpenSettings={handleOpenSettings}
							product={productForNavbar}
							userId={userId}
							teamId={teamId}
							onChangeView={isExtension ? setView : undefined}
							view={view}
						/>
						{isShareModalShown && actualProduct.isSome() && (
							<ShareFormContainer
								onClose={handleCloseShare}
								product={actualProduct.value}
								onReloadProduct={onReloadProduct}
								onProjectLeft={onProjectLeft}
							/>
						)}
						{isSettingsOpen && actualProduct.isSome() && (
							<ProductSettingsContainer
								product={actualProduct.value}
								onReloadProduct={onReloadProduct}
								onClose={handleCloseSettings}
								initialState={productSettingsState}
								onSaveWorkflow={onSaveStatuses}
								workflowSaveResult={saveStatusesResult}
								canDelete={canDeleteStatus}
								tabs={isExtension ? undefined /* all tabs */ : ['workflow']}
							/>
						)}
						{product
							.toOption()
							.chain(identity)
							.map(product => (
								<IntegrationSelectorContainer
									isOpen={isExporting}
									onClose={handleCloseExport}
									product={product}
								/>
							))
							.toNullable()}
						<ReviewOuterContainer
							productId={productId}
							product={product}
							onShare={handleShare}
							onOpenSettings={handleOpenSettings}
							focusedCommentId={commentId}
							noteId={noteId}
							imageId={imageId}
							view={view}
							onSaveStatuses={onSaveStatuses}
							saveStatusesResult={saveStatusesResult}
							canDeleteStatus={canDeleteStatus}
							type={type}
						/>
					</Fragment>
				);
			},
		),
);

const storageKey = 'Volley.productViews';
function useViewState(productId: number) {
	const [view, setView] = useState<ProductView>(() => {
		const viewByProductStr = localStorage.getItem(storageKey);
		try {
			const viewByProduct = viewByProductStr && JSON.parse(viewByProductStr);
			if (typeof viewByProduct === 'object') {
				const view = viewByProduct[String(productId)];
				if (view === 'inbox' || view === 'board') {
					return view;
				}
			}
		} catch (e) {}
		return 'inbox';
	});
	const setAndSaveView = useCallback(
		(view: ProductView) => {
			const viewByProductStr = localStorage.getItem(storageKey);
			let viewByProduct: Record<string, ProductView> = {};
			try {
				const parsed = viewByProductStr && JSON.parse(viewByProductStr);
				if (parsed && typeof parsed === 'object') {
					viewByProduct = parsed;
				}
			} catch (e) {}
			viewByProduct[String(productId)] = view;
			localStorage.setItem(storageKey, JSON.stringify(viewByProduct));
			setView(view);
			history.replace(routes.product(productId));
		},
		[productId],
	);
	return tuple(view, setAndSaveView);
}

export default BrowseMode;
