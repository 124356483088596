import React, { Component, SFC } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import logoSvg from '../../assets/images/logo.svg';
import { Input, Button, Alert } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { WelcomeLayout } from '../welcome/WelcomeLayout';
import { FormField } from '../welcome/FormField';
import { constNull } from 'fp-ts/lib/function';
import { history } from '../../utils/history';
import { getErrors, hasErrors, hasNonFieldErrors, getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';

export type TForgotPasswordProps = {
	onSendRequest: (email: string) => unknown;
	result: RemoteData<Error, unknown>;
	showResult: boolean;
};

export const ForgotPassword = combineReader(
	WelcomeLayout,
	WelcomeLayout =>
		class extends Component<TForgotPasswordProps> {
			render() {
				return (
					<WelcomeLayout>
						<div className="form-content">
							<img className="double-margin-top double-margin-bottom" src={logoSvg} width="120" />
							<h2>Forgot your password?</h2>
							<p className="light-text-color">
								Enter your email address to reset your password. You may need to check your spam folder
								or unblock no-reply@meetvolley.com.
							</p>

							{this.props.result.foldL(
								constNull,
								constNull,
								error =>
									hasNonFieldErrors(error) && (
										<Alert color="danger" isOpen={this.props.showResult}>
											Error: {getNonFieldErrors(error).join(' ')}
										</Alert>
									),
								success => (
									<Alert color="success" isOpen={this.props.showResult}>
										If you have an account with this email, we'll email you a reset link.
									</Alert>
								),
							)}

							<Form onSubmit={this.handleSubmit} render={this.renderForm} />
						</div>
					</WelcomeLayout>
				);
			}

			private renderForm: SFC<FormRenderProps> = ({ handleSubmit }) => {
				const { result } = this.props;
				return (
					<form onSubmit={handleSubmit}>
						<Field
							name="email"
							render={({ input, meta }) => (
								<FormField name="Email">
									<Input
										bsSize="lg"
										placeholder="john@doe.com"
										disabled={result.isPending()}
										invalid={result.isFailure() && hasErrors(result.error, 'email')}
										{...input}
										type="email"
									/>
									{result.isFailure() &&
										getErrors(result.error, 'email').map((e, index) => (
											<div className="invalid-feedback" key={index}>
												{e}
											</div>
										))}
								</FormField>
							)}
						/>

						<div className="double-margin-top">
							<Button color="primary" size="lg" block type="submit">
								Submit
							</Button>
							<Button color="link" block onClick={() => history.goBack()}>
								Back
							</Button>
						</div>
					</form>
				);
			};

			handleSubmit = (values: object) => {
				this.props.onSendRequest && this.props.onSendRequest(((values as any)['email'] as string) || '');
			};
		},
);
