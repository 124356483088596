import React, { SFC, FC, useCallback, useState, useEffect } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import logoSvg from '../../assets/images/logo.svg';
import { Input, Button, Alert } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { WelcomeLayout } from '../welcome/WelcomeLayout';
import { FormField } from '../welcome/FormField';
import { constNull } from 'fp-ts/lib/function';
import {
	getErrors,
	hasErrors,
	hasNonFieldErrors,
	getNonFieldErrors,
	TApiError,
} from 'volley-common/dist/models/api.model';
import { Link } from 'react-router-dom';
import { routes } from 'volley-common/dist/utils/routes';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { validatePassword } from 'volley-common/dist/utils/string.utils';
import { PasswordRequirements } from '../../components/PasswordRequirements';
import { FormState } from 'final-form';

export type TResetPasswordProps = {
	token: string;
	userId: number;
	onSendRequest: (newPassword: string) => unknown;
	result: RemoteData<Error, unknown>;
	showResult: boolean;
};

const renderForm = (result: RemoteData<TApiError, unknown>): SFC<FormRenderProps> => props => {
	const { values, handleSubmit } = props as FormRenderProps & FormState<any>;
	return (
		<form onSubmit={handleSubmit}>
			<Field
				name="password"
				render={({ input, meta }) => (
					<FormField name="New password">
						<Input
							autoComplete="new-password"
							bsSize="lg"
							disabled={result.isPending()}
							invalid={
								(meta.error && meta.touched) ||
								(result.isFailure() && hasErrors(result.error, 'password'))
							}
							{...input}
							type="password"
						/>
						{result.isFailure() &&
							getErrors(result.error, 'password').map((e, index) => (
								<div className="invalid-feedback" key={index}>
									{e}
								</div>
							))}
						{meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
					</FormField>
				)}
			/>
			<Field
				name="password2"
				render={({ input, meta }) => (
					<FormField name="Confirm password">
						<Input
							autoComplete="new-password"
							bsSize="lg"
							disabled={result.isPending()}
							invalid={
								(meta.error && meta.touched) ||
								(result.isFailure() && hasErrors(result.error, 'password2'))
							}
							{...input}
							type="password"
						/>
						{result.isFailure() &&
							getErrors(result.error, 'password2').map((e, index) => (
								<div className="invalid-feedback" key={index}>
									{e}
								</div>
							))}
						{meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
					</FormField>
				)}
			/>
			<PasswordRequirements password={values.password || ''} />

			<div className="double-margin-top">
				<Button color="primary" size="lg" block type="submit">
					Submit
				</Button>
			</div>
		</form>
	);
};

const handleValidate = (values: any) => {
	const errors = {} as any;
	if (!values.password || !validatePassword(values.password).valid) {
		errors.password = `Please check the password requirements`;
	}
	if (values.password2 !== values.password) {
		errors.password2 = 'Passwords must match';
	}
	return errors;
};

export const ResetPassword = combineReader(
	WelcomeLayout,
	(WelcomeLayout): FC<TResetPasswordProps> => props => {
		const { result, onSendRequest } = props;
		const renderFormFn = useCallback(renderForm(result), [result]);
		const handleSubmit = useCallback(
			(values: object) => {
				onSendRequest && onSendRequest((values as any)['password'] as string);
			},
			[onSendRequest],
		);
		const [lastSuccess, setLastSuccess] = useState<object | null>(null);
		const [version, setVersion] = useState(0);
		useEffect(() => {
			if (result.isSuccess() && lastSuccess !== result) {
				setLastSuccess(result);
				setVersion(version + 1);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [result, version]);

		return (
			<WelcomeLayout>
				<div className="form-content">
					<img className="double-margin-top double-margin-bottom" src={logoSvg} width="120" />
					<h2>Forgot your password?</h2>
					<p className="light-text-color">Enter a new password for your account.</p>

					{props.result.foldL(
						constNull,
						constNull,
						error =>
							hasNonFieldErrors(error) && (
								<Alert color="danger" isOpen={props.showResult}>
									Error: {getNonFieldErrors(error).join(' ')}
								</Alert>
							),
						success => (
							<Alert color="success" isOpen={props.showResult}>
								<b>Success.</b> You can now <Link to={routes.login}>log in</Link> using your new
								password.
							</Alert>
						),
					)}

					<Form key={version} onSubmit={handleSubmit} render={renderFormFn} validate={handleValidate} />
				</div>
			</WelcomeLayout>
		);
	},
);
