import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import React, { Fragment, memo, useState } from 'react';
import { DropdownItem } from 'reactstrap';
import { ChatHQCredentials, ChatHQCredentialsFormModel } from '../integrations/ChatHQCredentials';
import { IntegrationCard } from './Integrations';
import chatHqIcon from 'volley-common/dist/assets/images/integration-icon-chathq.png';
import { IntegrationsMap, IntegrationStore } from 'volley-common/dist/services/export/integration.store';
import { PaidOption } from 'volley-common/dist/components/PaidOption';
import { distinctUntilChanged, map, pluck, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { ask } from 'fp-ts/lib/Reader';
import { Subject, of, EMPTY, merge } from 'rxjs';
import { IntegrationType } from 'volley-common/dist/services/export/integration.utils';
import { chatHQ } from 'volley-common/dist/services/export/chathq.service';
import { RemoteData, initial, success } from '@devexperts/remote-data-ts';
import { ChatHQSettings, ChatHQSettingsFormModel, emptyChatHQSettings } from '../integrations/ChatHQSettings';
import { getArraySetoid, setoidNumber } from 'fp-ts/lib/Setoid';
import { isDefined, mapRD } from 'volley-common/dist/utils/object.utils';

interface ChatHQCardProps {
	onConnect: (data: ChatHQCredentialsFormModel) => void;
	onSaveSettings: (data: ChatHQSettingsFormModel) => void;
	onDisconnect: () => void;
	hasCredentials: boolean;
	integrations: IntegrationsMap;
	productId: number;
	channels: RemoteData<Error, string[]>;
	saveResult: RemoteData<Error, unknown>;
	onSubscriptionNeeded?: () => void;
}

const ChatHQCard = memo<ChatHQCardProps>(
	({
		onConnect,
		onSaveSettings,
		onDisconnect,
		channels,
		hasCredentials,
		integrations,
		saveResult,
		onSubscriptionNeeded,
	}) => {
		const [isEditing, setIsEditing] = useState(false);
		const integration = integrations.chathq;
		const configDetails = integration?.config_details;

		const onStartEditing = () => setIsEditing(true);

		const menu = hasCredentials ? (
			<Fragment>
				<PaidOption
					isUnlocked={!onSubscriptionNeeded}
					onAllowed={onStartEditing}
					onLocked={onSubscriptionNeeded}>
					Settings
				</PaidOption>
				<DropdownItem divider />
				<DropdownItem onClick={onDisconnect}>Disconnect</DropdownItem>
			</Fragment>
		) : (
			<PaidOption isUnlocked={!onSubscriptionNeeded} onAllowed={onStartEditing} onLocked={onSubscriptionNeeded}>
				Connect
			</PaidOption>
		);

		const settings = configDetails
			? {
					...(configDetails as ChatHQSettingsFormModel),
					...(integration?.notification_details as ChatHQSettingsFormModel),
			  }
			: emptyChatHQSettings;

		return (
			<Fragment>
				<ChatHQCredentials
					onConnect={onConnect}
					onClose={() => setIsEditing(false)}
					visible={!hasCredentials && isEditing}
					saveResult={saveResult}
				/>
				<ChatHQSettings
					channels={channels}
					onClose={() => setIsEditing(false)}
					onSave={onSaveSettings}
					saveResult={saveResult}
					value={settings}
					visible={hasCredentials && isEditing}
				/>
				<IntegrationCard
					active={hasCredentials}
					logo={chatHqIcon}
					name={'ChatHQ'}
					description={'Send notifications to ChatHQ.'}
					menu={menu}
				/>
			</Fragment>
		);
	},
);

type ChatHQCardContainerContext = {
	integrationStore: IntegrationStore;
};

export const ChatHQCardContainer = combineReader(ask<ChatHQCardContainerContext>(), chatHQ, (ctx, chatHQ) =>
	withRX(ChatHQCard)(props$ => {
		const hasCredentials$ = props$.pipe(
			map(p => !!p.integrations.chathq),
			distinctUntilChanged(),
		);

		const productId$ = props$.pipe(pluck('productId'), distinctUntilChanged());
		const connect$ = new Subject<ChatHQCredentialsFormModel>();
		const connectEffect$ = connect$.pipe(
			withLatestFrom(productId$),
			switchMap(([{ auth_token, user_id, host }, productId]) =>
				ctx.integrationStore.set(productId, IntegrationType.ChatHQ, { auth_token, user_id }, { host }, {}),
			),
		);

		const saveSettings$ = new Subject<ChatHQSettingsFormModel>();
		const saveSettingsEffect$ = saveSettings$.pipe(
			withLatestFrom(props$),
			switchMap(([{ host, channel, note_create }, { productId, integrations }]) =>
				integrations.chathq
					? ctx.integrationStore.update(
							productId,
							integrations.chathq.id,
							undefined,
							{ host, channel },
							{ note_create },
					  )
					: EMPTY,
			),
		);

		const emptyChannels = success<Error, string[]>([]);
		const channels$ = props$.pipe(
			map(props => (props.integrations.chathq ? [props.productId, props.integrations.chathq.id] : [])),
			distinctUntilChanged(getArraySetoid(setoidNumber).equals),
			switchMap(ids =>
				ids.length
					? chatHQ
							.getChannels(ids[0], ids[1])
							.pipe(mapRD(result => result.map(r => r.channel).filter(isDefined)))
					: of(emptyChannels),
			),
		);

		const disconnect$ = new Subject<void>();
		const disconnectEffect$ = disconnect$.pipe(
			withLatestFrom(props$),
			switchMap(([, props]) =>
				props.integrations.chathq
					? ctx.integrationStore.remove(props.productId, props.integrations.chathq.id)
					: EMPTY,
			),
		);

		return {
			defaultProps: {
				onConnect: val => connect$.next(val),
				onSaveSettings: val => saveSettings$.next(val),
				onDisconnect: () => disconnect$.next(),
				hasCredentials: false,
				saveResult: initial,
				channels: emptyChannels,
			},
			props: {
				hasCredentials: hasCredentials$,
				saveResult: merge(saveSettingsEffect$, connectEffect$),
				channels: channels$,
			},
			effects$: disconnectEffect$,
		};
	}),
);
