import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { Button, CustomInput, DropdownItem, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Modal } from '../../../components/Modal';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { v4 as uuid } from 'uuid';
import { isSuccess, RemoteData } from '@devexperts/remote-data-ts';
import { Selectbox } from 'volley-common/dist/components/Selectbox';
import { SlackChannel } from 'volley-common/dist/services/export/slack.service';
import { tuple } from 'fp-ts/lib/function';

export interface SlackSettingsFormModel {
	channel_id?: string;
	note_create?: boolean;
}

export const emptySlackSettings: SlackSettingsFormModel = {};

interface SlackSettingsProps {
	visible: boolean;
	onClose: () => void;
	onSave: (val: SlackSettingsFormModel) => void;
	saveResult: RemoteData<Error, unknown>;
	value: SlackSettingsFormModel;
	channels: RemoteData<Error, SlackChannel[]>;
}

export const SlackSettings = memo<SlackSettingsProps>(({ visible, onClose, onSave, saveResult, value, channels }) => {
	const isSaveSuccess = isSuccess(saveResult);
	const prevSuccess = useRef(isSaveSuccess);
	useEffect(() => {
		if (visible && isSaveSuccess && !prevSuccess.current) {
			onClose();
		}
		prevSuccess.current = isSaveSuccess;
	}, [visible, onClose, isSaveSuccess]);

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader className="no-border-bottom no-padding-bottom" toggle={onClose} />
			<Form
				initialValues={value}
				render={props => (
					<SlackSettingsForm {...props} channels={channels} saveResult={saveResult} onClose={onClose} />
				)}
				onSubmit={(val: object) => {
					const data = val as SlackSettingsFormModel;
					onSave(data);
				}}
			/>
		</Modal>
	);
});

const SlackSettingsForm = memo<FormRenderProps & Pick<SlackSettingsProps, 'saveResult' | 'channels' | 'onClose'>>(
	({ handleSubmit, channels, saveResult, onClose }) => {
		const [componentId] = useState(() => uuid());
		const channelsMap = useMemo(() => new Map(channels.getOrElse([]).map(c => tuple(c.id, c.name))), [channels]);
		return (
			<form onSubmit={handleSubmit}>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<div className="settings-section mb-5">
							<p className="bold-font">Slack settings</p>
							<label className="mb-0">Select channel</label>
							<Field name="channel_id">
								{({ input }) => (
									<Selectbox<string>
										renderOption={val => (val && channelsMap.get(val)) || 'not selected'}
										{...input}
										pending={channels.isPending()}
										children={channels.getOrElse([]).map(channel => (
											<DropdownItem key={channel.id} value={channel.id}>
												{channel.name}
											</DropdownItem>
										))}
									/>
								)}
							</Field>
						</div>
						<div className="settings-section">
							<p className="bold-font">Notification settings</p>
							<div className="margin-bottom">
								<label className="mb-0">Send notifications to Slack when</label>
								<div className="mt-3">
									<div className="form-check custom-checkbox no-padding-left">
										<Field name="note_create" type="checkbox">
											{({ input }) => (
												<CustomInput
													{...input}
													className="no-padding-left"
													type="checkbox"
													label="Note is created"
													id={`${componentId}-note-created`}
												/>
											)}
										</Field>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className="no-border-top">
					<Button type="button" color="link" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton type="submit" pending={saveResult.isPending()} color="primary">
						Save changes
					</LoadingButton>
				</ModalFooter>
			</form>
		);
	},
);
