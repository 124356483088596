import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { TeamMembers } from './TeamMembers';
import { initial, failure, success, RemoteData } from '@devexperts/remote-data-ts';
import { constVoid } from 'fp-ts/lib/function';
import { requireTeam } from '../team-settings/team.utils';
import { ask } from 'fp-ts/lib/Reader';
import { TeamsService } from 'volley-common/dist/services/teams.service';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { map, withLatestFrom, switchMap, tap, shareReplay } from 'rxjs/operators';
import { Subject, merge, of } from 'rxjs';
import { genericErrorMessage, isDisplayedError } from 'volley-common/dist/utils/error.utils';
import { ProfileModelType } from '../../models/profile.model';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { switchMapRD } from 'volley-common/dist/utils/object.utils';

type TeamMembersContainerContext = {
	teamsService: TeamsService;
	toastService: ToastService;
	profileModel: ProfileModelType;
	authService: AuthService;
};

export const TeamMembersContainer = combineReader(TeamMembers, ask<TeamMembersContainerContext>(), (TeamMembers, ctx) =>
	withRX(TeamMembers)(props$ => {
		const teamId$ = props$.pipe(map(props => props.teamId));
		const { realTeamId$, effects$, team$, isOwner$ } = requireTeam(teamId$, ctx.teamsService, ctx.authService);

		const userId$ = ctx.authService.userId$.pipe(
			map(userId => userId.fold<RemoteData<Error, number>>(failure(new Error('Not authorized')), success)),
		);

		const removeMember$ = new Subject<['member' | 'prospect', number]>();
		const removeMemberResult$ = removeMember$.pipe(
			withLatestFrom(realTeamId$, userId$),
			switchMap(([[type, userId], teamId, selfUserId]) => {
				if (type === 'member') {
					return of(selfUserId).pipe(
						switchMapRD(selfUserId =>
							selfUserId === userId
								? ctx.teamsService.leaveTeam(teamId, userId)
								: ctx.teamsService.removeMember(teamId, userId),
						),
					);
				} else {
					return ctx.teamsService.removeProspect(teamId, userId);
				}
			}),
			tap(result => {
				result.foldL(
					constVoid,
					constVoid,
					err => ctx.toastService.push({ text: genericErrorMessage }),
					success => ctx.toastService.push({ text: 'User was removed from the team' }),
				);
			}),
			shareReplay(1),
		);

		const resendInvite$ = new Subject<number>();
		const resendInviteResult$ = resendInvite$.pipe(
			withLatestFrom(realTeamId$),
			switchMap(([userId, teamId]) => ctx.teamsService.resendInvite(teamId, userId)),
			tap(result => {
				result.foldL(
					constVoid,
					constVoid,
					err => ctx.toastService.push({ text: genericErrorMessage }),
					success => ctx.toastService.push({ text: 'Invite has been sent' }),
				);
			}),
			shareReplay(1),
		);

		const inviteMembers$ = new Subject<string[]>();
		const inviteMembersResult$ = inviteMembers$.pipe(
			withLatestFrom(realTeamId$),
			switchMap(([emails, teamId]) =>
				ctx.teamsService.inviteMembers(teamId, emails).pipe(
					tap(result => {
						result.foldL(
							constVoid,
							constVoid,
							err => !isDisplayedError(err) && ctx.toastService.push({ text: genericErrorMessage }),
							team => {
								const allEmails = new Set(
									[
										team.owner.email,
										...team.members.map(m => m.email),
										...team.prospects.map(m => m.email),
									].map(s => s.toLowerCase()),
								);
								const allSent = emails.every(e => allEmails.has(e.toLowerCase()));
								if (allSent) {
									ctx.toastService.push({ text: 'Invites have been sent' });
								} else {
									ctx.toastService.push({
										text: 'Some invites have not been sent because you reached the team members limit. Consider upgrading your plan',
									});
								}
							},
						);
					}),
				),
			),
			shareReplay(1),
		);

		return {
			defaultProps: {
				team: initial,
				onInviteMembers: (emails: string[]) => inviteMembers$.next(emails),
				inviteResult: initial,
				onRemoveMember: (type: 'prospect' | 'member', id: number) => removeMember$.next([type, id]),
				removeMemberResult: initial,
				onResendInvite: (id: number) => resendInvite$.next(id),
				resendInviteResult: initial,
				canEdit: false,
				canAddMembers: true, // any member can invite new members
				userId: initial,
			},
			props: {
				team: team$,
				inviteResult: inviteMembersResult$,
				removeMemberResult: removeMemberResult$,
				resendInviteResult: resendInviteResult$,
				canEdit: isOwner$,
				userId: userId$,
			},
			effects$: merge(effects$, removeMemberResult$),
		};
	}),
);
