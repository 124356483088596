import { NotificationsView } from './Notifications';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { Notifications } from 'volley-common/dist/services/auth.service';
import { asks } from 'fp-ts/lib/Reader';
import { Subject, of } from 'rxjs';
import { switchMap, share, startWith, delay } from 'rxjs/operators';
import { ProfileModelType } from '../../models/profile.model';
import { mapRD } from 'volley-common/dist/utils/object.utils';
import { initial } from '@devexperts/remote-data-ts';

interface NotificationsContainerContext {
	profileModel: ProfileModelType;
}

export const NotificationsContainer = asks((ctx: NotificationsContainerContext) =>
	withRX(NotificationsView)(() => {
		const save$ = new Subject<Notifications>();
		const saveEffect$ = save$.pipe(
			switchMap(val => ctx.profileModel.updateNotifications(val)),
			share(),
		);
		const showResult$ = saveEffect$.pipe(
			switchMap(rd => {
				if (rd.isFailure() || rd.isSuccess()) {
					return of(false).pipe(
						delay(3000),
						startWith(true),
					);
				}
				return of(false);
			}),
		);

		return {
			defaultProps: {
				onSave: (val: Notifications) => save$.next(val),
				value: initial,
				saveResult: initial,
				resultShown: false,
			},
			props: {
				saveResult: saveEffect$,
				value: ctx.profileModel.profile$.pipe(
					mapRD(profile => profile.mapNullable(p => p.notification).getOrElse({})),
				),
				resultShown: showResult$,
			},
		};
	}),
);
