import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial } from '@devexperts/remote-data-ts/dist/remote-data';
import { Subject } from 'rxjs';
import SignUp, { TRegistrationData } from './SignUp';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { ask } from 'fp-ts/lib/Reader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { SessionServiceClass } from '../../services/token.service';
import { isSome } from 'fp-ts/lib/Option';
import { loadReferralDetails } from '../../referral/utils';

type TSignUpContainerContext = {
	authService: AuthService;
	sessionService: SessionServiceClass;
};

export const SignUpContainer = combineReader(SignUp, ask<TSignUpContainerContext>(), (SignUp, ctx) => {
	return withRX(SignUp)(props$ => {
		const registrationRequest$ = new Subject<TRegistrationData>();
		const registrationResult$ = registrationRequest$.pipe(
			switchMap(data =>
				ctx.authService.register({ ...data, signupType: 'direct', referral_details: loadReferralDetails() }),
			),
			map(result => result.map(_ => true)),
		);

		return {
			defaultProps: {
				onTryRegister: (data: TRegistrationData) => registrationRequest$.next(data),
				result: initial,
				isLoggedIn: false,
			},
			props: {
				result: registrationResult$,
				isLoggedIn: ctx.sessionService.token$.pipe(map(isSome)),
			},
		};
	});
});
