import * as React from 'react';
import { FC, useCallback } from 'react';
import { Col } from 'reactstrap';
import {
	Elements,
	injectStripe,
	ReactStripeElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from 'react-stripe-elements';
import cardLogos from 'volley-common/dist/assets/images/cards.png';
import poweredByStripeImg from 'volley-common/dist/assets/images/powered-by-stripe.svg';
import style from './PaymentForm.module.scss';
import cn from 'classnames';
import { constVoid, identity } from 'fp-ts/lib/function';
import { TPaymentMethodData } from 'volley-common/dist/services/auth.service';
import { TApiError } from 'volley-common/dist/models/api.model';
import { RemoteData } from '@devexperts/remote-data-ts';
import { fromNullable } from 'fp-ts/lib/Option';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';

export type PaymentMethod = {
	id: string;
	card?: {
		brand: string;
		exp_month: number;
		exp_year: number;
		last4: string;
	};
};

type TPaymentFormProps = {
	onWaitingForToken?: (waiting: boolean) => void;
	onTokenCreated?: (token: PaymentMethod) => void;
	paymentAmount: number;
	footer?: React.ReactNode;
	result: RemoteData<TApiError, unknown>;
	showIsSecure?: boolean;
};

type TRawPaymentFormProps = ReactStripeElements.InjectedStripeProps & TPaymentFormProps;

const inputStyle: ReactStripeElements.ElementsOptions['style'] = {
	base: {
		fontSize: '16px',
		fontFamily: '"Source Sans Pro", sans-serif',
		lineHeight: '1.5',
		color: '#495057',
	},
};

const inputClasses = {
	base: style.input,
	focus: style.input_focus,
	invalid: style.input_invalid,
};

export type PaymentConfirmationButton = 'upgrade' | 'switchToMonthly' | 'switchToYearly' | 'currentPlan';
export const renderDefaultFooter = (isPending?: boolean, mode: PaymentConfirmationButton = 'upgrade') => (
	<div className="form-row">
		<Col xs={12} className="form-group">
			<LoadingButton
				color={mode === 'currentPlan' ? 'secondary' : 'primary'}
				size="lg"
				block
				type="submit"
				disabled={mode === 'currentPlan'}
				pending={isPending}>
				{formatButtonText(mode)}
			</LoadingButton>
		</Col>
	</div>
);

function formatButtonText(mode: PaymentConfirmationButton): string {
	switch (mode) {
		case 'currentPlan':
			return 'Current plan';
		case 'switchToMonthly':
			return 'Change to monthly billing';
		case 'switchToYearly':
			return 'Change to annual billing';
		default:
			return 'Upgrade my plan';
	}
}

export const SecurePayment = () => (
	<div className="text-center">
		<h4 className="align-middle">
			<i className="material-icons align-middle" style={{ fontSize: 23 }}>
				lock
			</i>{' '}
			Guaranteed <span className="medium-font">safe and secure </span>
			checkout <img className="align-middle" src={poweredByStripeImg} width={100} />
		</h4>
	</div>
);

const RawPaymentForm: FC<TRawPaymentFormProps> = ({
	stripe,
	onTokenCreated = constVoid,
	onWaitingForToken = constVoid,
	paymentAmount,
	footer,
	result,
	showIsSecure = false,
}) => {
	const handleSubmit = useCallback(
		evt => {
			evt.preventDefault();
			if (stripe) {
				onWaitingForToken(true);
				stripe
					.createPaymentMethod('card', { billing_details: {} })
					.then(response =>
						response.error || !response.paymentMethod
							? onWaitingForToken(false)
							: onTokenCreated(response.paymentMethod),
					);
			} else {
				// Stripe.js hasn't loaded yet
			}
		},
		[stripe, onTokenCreated, onWaitingForToken],
	);

	return (
		<form onSubmit={handleSubmit}>
			<div className="form-row">
				<Col xs={12} className="form-group">
					<label className={style.cardLabel}>Credit card number</label>
					<CardNumberElement classes={inputClasses} style={inputStyle} />
				</Col>
			</div>
			<div className="form-row margin-bottom">
				<Col xs={6} className="form-group">
					<label className={style.cardLabel}>Expiration</label>
					<CardExpiryElement classes={inputClasses} style={inputStyle} />
				</Col>
				<Col xs={6} className="form-group">
					<label className={style.cardLabel}>Security code</label>
					<CardCvcElement classes={inputClasses} style={inputStyle} />
				</Col>
				{result.isFailure() &&
					extractError(result.error).map((error, index) => (
						<div key={index} className={cn('invalid-feedback', style.error)}>
							{error}
						</div>
					))}
			</div>
			<div className="form-row margin-bottom">
				<Col xs={12} className="form-group">
					<img src={cardLogos} width={330} className="max-width" />
				</Col>
			</div>
			{footer || renderDefaultFooter(result.isPending())}
			{showIsSecure && <SecurePayment />}
		</form>
	);
};

const PaymentFormWithStripe = injectStripe(RawPaymentForm);

const stripeFonts = [{ cssSrc: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900' }];
export const PaymentForm: FC<TPaymentFormProps> = props => {
	return (
		<Elements fonts={stripeFonts} locale="en-US">
			<PaymentFormWithStripe {...props} />
		</Elements>
	);
};

export function toPaymentMethodData(data: PaymentMethod): TPaymentMethodData {
	return {
		source: data.id,
		card_brand: data.card ? data.card.brand : '',
		card_last4: data.card ? data.card.last4 : '',
		card_exp_year: data.card ? data.card.exp_year : 0,
		card_exp_month: data.card ? data.card.exp_month : 0,
	};
}

export function extractError(error: TApiError): string[] {
	return fromNullable(error.data)
		.mapNullable(data => {
			if (Array.isArray(data)) {
				return data.map(x => (typeof x === 'string' ? x.replace(/^Request \S+?:\s*/g, '') : x));
			}
		})
		.fold([], identity);
}
