import React, { Ref, memo, forwardRef } from 'react';
import cn from 'classnames';
import { Media, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TComment, createAnonymousUser, isImageAttachment } from 'volley-common/dist/services/notes.service';
import { TimeAgo } from '../../../components/TimeAgo';
import { TextWithMentions } from 'volley-common/dist/components/Mentions';
import style from './Comment.module.scss';
import Linkify from 'react-linkify';
import { Avatar } from 'volley-common/dist/components/Avatar';
import { formatAuthorName } from '../../../models/profile.utils';
import { CommentForm, TNewComment } from './CommentForm';
import { TMember } from 'volley-common/dist/services/products.service';
import { RemoteData } from '@devexperts/remote-data-ts';

const linkifyProps = {
	target: '_blank',
};

export type TCommentProps = {
	containerRef?: Ref<HTMLDivElement>;
	comment: TComment;
	isNoteComment?: boolean;
	highlighted?: boolean;

	onAttachmentClick?: (url: string) => unknown;
	onStartEdit?: () => unknown;
	onDelete?: () => unknown;
};

export const Comment = memo<TCommentProps>(
	({
		containerRef,
		comment,
		isNoteComment = false,
		highlighted = false,
		onStartEdit,
		onDelete,
		onAttachmentClick,
	}) => {
		const author =
			comment.author ||
			(comment.fingerprint ? createAnonymousUser(comment.fingerprint, comment.external_author) : undefined);

		const handleClickAttachment = (e: React.MouseEvent, source: string) => {
			e.preventDefault();
			onAttachmentClick && onAttachmentClick(source);
		};

		const renderAttachment = (comment: TComment) => {
			if (comment.attachment) {
				const source = comment.attachment;
				return isImageAttachment(source) ? (
					<a
						href={source}
						rel="noopener noreferrer"
						target="_blank"
						onClick={e => handleClickAttachment(e, source)}>
						<img
							src={source}
							className={cn(style.commentAttachment__preview, style.comment__img)}
							width="85px"
						/>
					</a>
				) : (
					<a href={source} rel="noopener noreferrer" target="_blank">
						<i className="material-icons">attachment</i>
					</a>
				);
			}
		};

		const container = document.querySelector<HTMLElement>('[class*="Review_commentsWrapper__"]');

		return (
			<div
				className={cn(style.comment, isNoteComment && 'note-comment', highlighted && style.comment_highlight)}
				ref={containerRef}>
				<Media>
					<div className={cn(style.comment__avatar, style.comment__avatar_xs)} />
					<Avatar user={author} size="xs" className="half-margin-right" />
					<Media body className={style.comment__body}>
						{(onStartEdit || onDelete) && (
							<div className={cn('actions', style.actions)}>
								<UncontrolledDropdown>
									<DropdownToggle
										color="flat"
										className={cn('btn-icon btn-action', style.actions__toggle)}>
										<i className="material-icons">more_horiz</i>
									</DropdownToggle>
									<DropdownMenu
										right
										modifiers={{
											preventOverflow: {
												enabled: true,
												boundariesElement: container || 'scrollParent',
											},
										}}>
										{onStartEdit && (
											<DropdownItem onClick={onStartEdit}>
												{isNoteComment ? 'Edit note' : 'Edit'}
											</DropdownItem>
										)}
										{onDelete && <DropdownItem onClick={onDelete}>Delete</DropdownItem>}
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						)}
						<h4 className="bold-font no-margin-bottom">{formatAuthorName(author)}</h4>
						<h5 className="light-text-color">
							<TimeAgo date={comment.updated_at || comment.created_at} />
						</h5>
						<h4 className={style.text}>
							<TextWithMentions
								text={comment.text}
								renderText={text => <Linkify properties={linkifyProps}>{text}</Linkify>}
							/>
						</h4>
						{renderAttachment(comment)}
					</Media>
				</Media>
			</div>
		);
	},
);

interface EditedCommentProps {
	comment: Pick<TComment, 'text' | 'attachment' | 'author' | 'id'>;
	isNoteComment?: boolean;

	canUseMentions?: boolean;
	teamMembers: TMember[];
	updateCommentResult: RemoteData<Error, unknown>;
	onUpdateComment: (id: number, comment: TNewComment) => unknown;
	onUpdateNote: (id: number, comment: TNewComment) => unknown;
	onCancelEdit: () => void;
}

export const EditedComment = forwardRef<HTMLDivElement, EditedCommentProps>(
	(
		{
			comment,
			isNoteComment,
			canUseMentions,
			onCancelEdit,
			updateCommentResult,
			teamMembers,
			onUpdateComment,
			onUpdateNote,
		},
		ref,
	) => {
		const handleSave = (update: TNewComment) =>
			isNoteComment ? onUpdateNote(comment.id, update) : onUpdateComment(comment.id, update);
		return (
			<div className={style.comment} key={comment.id} ref={ref}>
				<Media>
					<div className={cn(style.comment__avatar, style.comment__avatar_xs)} />
					<Avatar
						user={comment.author || createAnonymousUser(String(comment.id))}
						size="xs"
						className="half-margin-right"
					/>
					<Media body className={style.comment__body}>
						<h4 className="bold-font quarter-margin-bottom">{formatAuthorName(comment.author)}</h4>
						<CommentForm
							isNoteComment={isNoteComment}
							canUseMentions={canUseMentions}
							onSave={handleSave}
							onCancel={onCancelEdit}
							saveStatus={updateCommentResult}
							editedComment={comment}
							teamMembers={teamMembers}
							isEditMode={true}
						/>
					</Media>
				</Media>
			</div>
		);
	},
);
