import React, { Fragment, memo, useState, useMemo, useEffect } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import { CardBody, Alert, Row, ListGroup, ListGroupItem, Col } from 'reactstrap';
import { TApiError, getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { Notifications } from 'volley-common/dist/services/auth.service';
import { v4 as uuid } from 'uuid';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';

export interface NotificationsViewProps {
	value: RemoteData<Error, Notifications>;
	onSave: (update: Notifications) => unknown;
	saveResult: RemoteData<TApiError, unknown>;
	resultShown: boolean;
}

const options = [
	{ key: 'note_reply_any', label: 'Replies to any note' },
	{ key: 'note_reply_owned', label: "Replies to a note I've made" },
	{ key: 'note_reply_commented', label: "Replies to a note I've replied to" },
	{ key: 'note_status_any', label: 'Changes the status of any note' },
	{ key: 'note_status_owned', label: "Changes the status of a note I've made" },
	{ key: 'note_status_commented', label: "Changes the status of a note I've replied to" },
	{ key: 'note_create', label: 'Creates a note' },
	{ key: 'note_priority_any', label: 'Changes the priority of any note' },
	{ key: 'note_priority_owned', label: "Changes the priority of a note I've made" },
	{ key: 'note_priority_commented', label: "Changes the priority of a note I've replied to" },
	{ key: 'note_assign', label: 'Assigns a note to me' },
];

export const NotificationsView = memo<NotificationsViewProps>(props => {
	const id = useMemo(() => uuid(), []);
	const [value, setValue] = useState<Notifications>(props.value.getOrElse({}));
	const toggleFlag = (key: keyof Notifications) => {
		setValue(val => {
			const newValue = !val[key];
			switch (key) {
				case 'note_reply_any':
					if (newValue) {
						return {
							...val,
							note_reply_any: true,
							note_reply_commented: true,
							note_reply_owned: true,
						};
					}
					break;
				case 'note_reply_owned':
				case 'note_reply_commented':
					if (!newValue) {
						return {
							...val,
							[key]: false,
							note_reply_any: false,
						};
					}
					break;
				case 'note_status_any':
					if (newValue) {
						return {
							...val,
							note_status_any: true,
							note_status_commented: true,
							note_status_owned: true,
						};
					}
					break;
				case 'note_status_owned':
				case 'note_status_commented':
					if (!newValue) {
						return {
							...val,
							[key]: false,
							note_status_any: false,
						};
					}
					break;
				case 'note_priority_any':
					if (newValue) {
						return {
							...val,
							note_priority_any: true,
							note_priority_commented: true,
							note_priority_owned: true,
						};
					}
					break;
				case 'note_priority_owned':
				case 'note_priority_commented':
					if (!newValue) {
						return {
							...val,
							[key]: false,
							note_priority_any: false,
						};
					}
					break;
			}
			return { ...val, [key]: newValue };
		});
	};

	useEffect(() => {
		props.value.map(setValue);
	}, [props.value]);

	const renderFlag = (key: keyof Notifications) => {
		const text = options.find(o => o.key === key)!.label;
		const flagId = `flag-${id}-${key}`;
		return (
			<ListGroup tag="div">
				<div className="list-group-inner">
					<div>
						<input
							type="checkbox"
							id={flagId}
							onChange={() => toggleFlag(key)}
							value={key}
							checked={!!value[key]}
						/>
						<ListGroupItem tag="label" htmlFor={flagId}>
							<Row className="align-items-center margin-left">
								<Col>
									<h4 className="quarter-margin-bottom">{text}</h4>
								</Col>
							</Row>
						</ListGroupItem>
					</div>
				</div>
			</ListGroup>
		);
	};

	return (
		<Fragment>
			{props.saveResult.fold(
				null,
				null,
				e => {
					const message = getNonFieldErrors(e);
					return (
						<Alert color="danger" isOpen={props.resultShown}>
							Error: {message.join(' ')}
						</Alert>
					);
				},
				() => (
					<Alert color="success" isOpen={props.resultShown}>
						<strong>Success:</strong> Your settings have been updated.
					</Alert>
				),
			)}
			<div className="card-checkbox-fix margin-bottom">
				<CardBody>
					<Row className="align-items-center">
						<div className="col-12">
							<h3 className="medium-font">Send me an email when someone...</h3>
							<div className="list-of-options simple style-checkbox double-margin-top">
								<div className="margin-bottom">
									<p className="medium-font">Note creation</p>
									<div className="margin-left">{renderFlag('note_create')}</div>
								</div>
								<div className="margin-bottom">
									<p className="medium-font">Replies</p>
									<div className="margin-left">
										{renderFlag('note_reply_any')}
										{renderFlag('note_reply_owned')}
										{renderFlag('note_reply_commented')}
									</div>
								</div>
								<div className="margin-bottom">
									<p className="medium-font">Statuses</p>
									<div className="margin-left">
										{renderFlag('note_status_any')}
										{renderFlag('note_status_owned')}
										{renderFlag('note_status_commented')}
									</div>
								</div>
								<div className="margin-bottom">
									<p className="medium-font">Priority</p>
									<div className="margin-left">
										{renderFlag('note_priority_any')}
										{renderFlag('note_priority_owned')}
										{renderFlag('note_priority_commented')}
									</div>
								</div>
								<div className="margin-bottom">
									<p className="medium-font">Assignments</p>
									<div className="margin-left">{renderFlag('note_assign')}</div>
								</div>
							</div>
							<hr />
							<LoadingButton
								onClick={() => props.onSave(value)}
								color="primary"
								pending={props.saveResult.isPending()}
								size="lg"
								className="margin-top">
								Save changes
							</LoadingButton>
						</div>
					</Row>
				</CardBody>
			</div>
		</Fragment>
	);
});
