import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { ProductService } from 'volley-common/dist/services/products.service';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { asks } from 'fp-ts/lib/Reader';
import { Subject } from 'rxjs';
import React, { memo, ComponentType } from 'react';
import { map, share, withLatestFrom, switchMap, tap } from 'rxjs/operators';

type TRemoveButtonProps = {
	loading?: boolean;
	onClick: () => void;
};

const RemoveButton = memo<TRemoveButtonProps>(({ loading, onClick }) => (
	<LoadingButton block color="secondary" size="sm" pending={loading} disabled={loading} onClick={onClick}>
		Remove
	</LoadingButton>
));

type TRemoveButtonContext = {
	productService: ProductService;
	toastService: ToastService;
};

type TRemoveButtonContainerProps = TRemoveButtonProps & {
	userId: number;
	projectId: number;
	onReloadProduct?: () => void;
};

export const RemoveButtonContainer = asks((ctx: TRemoveButtonContext) =>
	withRX(RemoveButton as ComponentType<TRemoveButtonContainerProps>)(props$ => {
		const remove$ = new Subject<void>();

		const isLoading$ = remove$.pipe(
			withLatestFrom(props$),
			switchMap(([, props]) =>
				ctx.productService.removeCollaborator(props.projectId, props.userId).pipe(
					tap(result =>
						result.fold(
							null,
							null,
							() =>
								ctx.toastService.push({
									text: 'Failed to remove a collaborator. Please try again later',
								}),
							() => {
								ctx.toastService.push({ text: 'Collaborator has been removed' });
								props.onReloadProduct && props.onReloadProduct();
							},
						),
					),
				),
			),
			map(rd => rd.isPending()),
			share(),
		);

		return {
			defaultProps: {
				onClick: () => remove$.next(),
			},
			props: {
				loading: isLoading$,
			},
		};
	}),
);
