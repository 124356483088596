import React, { FC, Fragment, useCallback, ReactNode, CSSProperties, useEffect, useState } from 'react';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Input, Container, Row, Col, Form as BootstrapForm, FormGroup } from 'reactstrap';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { Form, FormRenderProps, Field } from 'react-final-form';
import appSumoLogoSvg from 'volley-common/dist/assets/images/volley-appsumo.svg';
import { TRegistrationData } from 'volley-common/dist/services/auth.service';
import { getErrors, hasFieldOrGlobalErrors, getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { asks } from 'fp-ts/lib/Reader';
import { fromArray } from 'fp-ts/lib/NonEmptyArray2v';
import { capterraHandler } from '../../utils/capterra';

export type TAppSumoSignUpProps = {
	registrationResult: RemoteData<Error, unknown>;
	applyCodeResult: RemoteData<Error, unknown>;
	onRegister: (data: TRegistrationData) => void;
	onApplyCode: (code: string) => void;
	appliedCodes: string[];
};

type TFormType = {
	email?: string;
	fullName?: string;
	password?: string;
};

interface SignUpContext {
	websiteUrl: string;
}

const formStyle: CSSProperties = {
	flexWrap: 'nowrap',
};

export const AppSumoSignUp = asks(
	(ctx: SignUpContext): FC<TAppSumoSignUpProps> => ({
		onApplyCode,
		appliedCodes,
		applyCodeResult,
		registrationResult,
		onRegister,
	}) => {
		const [formKey, setFormKey] = useState(0);
		const handleSubmit = (values: TFormType) => {
			capterraHandler();
			onRegister({
				email: values.email || '',
				fullName: values.fullName || '',
				password: values.password || '',
				signupType: 'direct',
			});
		};

		useEffect(() => {
			if (applyCodeResult.isSuccess()) {
				setFormKey(i => i + 1);
			}
		}, [applyCodeResult]);

		const renderForm: FC<FormRenderProps> = ({ handleSubmit }) => {
			return (
				<form onSubmit={handleSubmit}>
					<Field
						name="fullName"
						render={({ input, meta }) => (
							<div className="margin-bottom">
								<h4 className="bold-font">Name</h4>
								<Input
									bsSize="lg"
									placeholder="John Doe"
									invalid={
										registrationResult.isFailure() &&
										hasFieldOrGlobalErrors(registrationResult.error, 'name')
									}
									disabled={registrationResult.isPending()}
									{...input}
									type="text"
								/>
								{registrationResult.isFailure() &&
									getErrors(registrationResult.error, 'name').map((e, index) => (
										<div className="invalid-feedback" key={index}>
											{e}
										</div>
									))}
							</div>
						)}
					/>
					<Field
						name="email"
						render={({ input, meta }) => (
							<div className="margin-bottom">
								<h4 className="bold-font">Email</h4>
								<Input
									bsSize="lg"
									placeholder="john@doe.com"
									invalid={
										registrationResult.isFailure() &&
										hasFieldOrGlobalErrors(registrationResult.error, 'email')
									}
									disabled={registrationResult.isPending()}
									{...input}
									type="text"
								/>
								{registrationResult.isFailure() &&
									getErrors(registrationResult.error, 'email').map((e, index) => (
										<div className="invalid-feedback" key={index}>
											{e}
										</div>
									))}
							</div>
						)}
					/>
					<Field
						name="password"
						render={({ input, meta }) => (
							<div className="margin-bottom">
								<h4 className="bold-font">Password</h4>
								<Input
									bsSize="lg"
									placeholder="Min 6 chars."
									invalid={
										registrationResult.isFailure() &&
										hasFieldOrGlobalErrors(registrationResult.error, 'password')
									}
									disabled={registrationResult.isPending()}
									{...input}
									type="password"
								/>
								{registrationResult.isFailure() &&
									getErrors(registrationResult.error, 'password').map((e, index) => (
										<div className="invalid-feedback" key={index}>
											{e}
										</div>
									))}
								{registrationResult.isFailure() &&
									getNonFieldErrors(registrationResult.error).map((e, index) => (
										<div className="invalid-feedback" key={index}>
											{e}
										</div>
									))}
							</div>
						)}
					/>

					<div className="double-margin-top">
						<LoadingButton color="primary" size="lg" block pending={registrationResult.isPending()}>
							Create my LIFETIME account
						</LoadingButton>
					</div>
					<h5 className="light-text-color text-center margin-top">
						I agree to the{' '}
						<a className="light-text-color" href={`${ctx.websiteUrl}terms`}>
							<u>Terms and privacy</u>
						</a>
					</h5>
				</form>
			);
		};

		const handleSubmitCode = useCallback(
			(values: any) => {
				onApplyCode(values.code);
			},
			[onApplyCode],
		);

		const renderRedeemForm: FC<FormRenderProps> = ({ handleSubmit }) => {
			return (
				<Fragment>
					<BootstrapForm inline className="no-margin-bottom" style={formStyle} onSubmit={handleSubmit}>
						<FormGroup className="half-margin-right no-margin-bottom">
							<Field
								name="code"
								render={({ input }) => (
									<Input
										disabled={applyCodeResult.isPending()}
										placeholder="AppSumo Code"
										{...input}
										type="text"
									/>
								)}
							/>
						</FormGroup>
						<FormGroup className="no-margin-bottom">
							<LoadingButton
								pending={applyCodeResult.isPending()}
								color="primary"
								style={{ padding: '8px 10px' }}>
								Verify code
							</LoadingButton>
						</FormGroup>
					</BootstrapForm>
					{applyCodeResult.isFailure() && (
						<div className="invalid-feedback d-block">{applyCodeResult.error.message}</div>
					)}
				</Fragment>
			);
		};

		return (
			<section>
				<Container>
					<Row className="justify-content-center">
						<Col lg={10} xl={8}>
							<div className="header text-center double-margin-bottom">
								<img className="double-margin-bottom" src={appSumoLogoSvg} width={320} />
								<h2 className="bold-font">Get started with your LIFETIME account!</h2>
								<p>
									Here you can register your new Volley account and apply your AppSumo codes. Once
									registered you can apply more codes (up to 5 total) to adjust account limits.
								</p>
							</div>
							<Row>
								<Col md={{ order: 2, size: 6 }}>
									<div className="panel half-margin-top margin-bottom">
										<div className="panel-body lifetime-redeem-panel">
											<div className="margin-bottom">
												<h4 className="bold-font">Stack your codes</h4>
												<h4>Apply your AppSumo codes here:</h4>
												<Form
													key={formKey}
													onSubmit={handleSubmitCode}
													render={renderRedeemForm}
												/>
											</div>
											<div className="margin-bottom">
												<h4 className="bold-font">Your applied codes:</h4>
												{fromArray(appliedCodes).fold<ReactNode>(<h4>No codes</h4>, codes =>
													codes.map(code => <h4 key={code}>• {code}</h4>),
												)}
											</div>
											<div className="margin-bottom">
												<h4 className="bold-font">Your lifetime plan:</h4>
												<h4>
													{appliedCodes.length > 0
														? `AppSumo ${appliedCodes.length} code plan`
														: 'Apply a code to see plan'}
												</h4>
											</div>
										</div>
									</div>
								</Col>
								<Col md={{ order: 1, size: 6 }}>
									<div className="form-container">
										<div className="form-content-wrapper">
											<div className="form-content">
												<Form onSubmit={handleSubmit} render={renderForm} />
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
		);
	},
);
