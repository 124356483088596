import React from 'react';
import { Overlay } from 'volley-common/dist/components/Overlay';
import style from './Review.module.scss'; // TODO: extract styles
import cn from 'classnames';

interface AttachmentOverlayProps {
	src: string;
	onClose: () => void;
}

export const AttachmentOverlay = ({ src, onClose }: AttachmentOverlayProps) => {
	return (
		<Overlay className={style.imageOverlay} onClose={onClose}>
			<div className={style.imageOverlay__body}>
				<img src={src} className={style.imageOverlay__img} />
			</div>
			<div className={style.imageOverlay__footer}>
				<a href={src} className={style.imageOverlay__footer__link} rel="noopener noreferrer" target="_blank">
					<i className={cn('material-icons', style.imageOverlay__footer__linkIcon)}>open_in_new</i>
					Open in new tab
				</a>
			</div>
		</Overlay>
	);
};
