import React, { memo, useCallback, FC, Fragment } from 'react';
import { Input, Button, ModalHeader, ModalBody, ModalFooter, Media, Row, Col } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { Modal } from '../../components/Modal';
import logoImg from 'volley-common/dist/assets/images/logo.svg';
import { Selectbox } from 'volley-common/dist/components/Selectbox';
import css from './Survey.module.scss';
import { RemoteData } from '@devexperts/remote-data-ts';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { validateRequired } from 'volley-common/dist/utils/object.utils';

export interface SurveyFormModel {
	role: string;
	lead: string;
}

export interface SurveyFormProps {
	onSubmit: (value: SurveyFormModel) => void;
	onCancel: () => void;
	mode?: 'optional' | 'required';
	saveResult: RemoteData<unknown, unknown>;
	skipResult: RemoteData<unknown, unknown>;
}

const roleOptions = [
	'Project / Product Manager',
	'Account Manager',
	'Designer',
	'Engineer / Developer',
	'QA Tester',
	'Operations & IT',
	'Customer Support',
	'Marketing & Sales',
	'Entrepreneur',
	'Student',
	'Other',
];
const leadOptions = [
	'Colleague',
	'Digital advertising',
	'Google Search',
	'G2/Capterra',
	'Social media',
	'Email outreach',
	'Article',
	'Other',
];
const initialValue = {} as SurveyFormModel;

type SurvayFormInnerProps = Pick<FormRenderProps, 'handleSubmit'> &
	Pick<SurveyFormProps, 'mode' | 'onCancel' | 'saveResult' | 'skipResult'>;

const SurveyFormInner: FC<SurvayFormInnerProps> = ({ handleSubmit, mode, onCancel, saveResult, skipResult }) => {
	return (
		<form onSubmit={handleSubmit}>
			<label>What best describes your role?</label>
			<Field
				name="role"
				validate={validateRequired}
				render={({ input, meta }) => (
					<div className="double-margin-bottom">
						<Selectbox<string | undefined>
							invalid={meta.invalid && meta.submitFailed}
							renderOption={val => val || 'Select...'}
							{...input}
							options={roleOptions}
						/>
					</div>
				)}
			/>
			<label>How did you hear about Volley?</label>
			<Field
				name="lead"
				validate={validateRequired}
				render={({ input, meta }) => (
					<div className="double-margin-bottom">
						<Selectbox<string | undefined>
							invalid={meta.invalid && meta.submitFailed}
							renderOption={val => val || 'Select...'}
							{...input}
							options={leadOptions}
						/>
					</div>
				)}
			/>
			<div className="text-center">
				<LoadingButton block color="primary" size="lg" pending={saveResult.isPending()}>
					{mode === 'required' ? 'Go to dashboard ➔' : 'Submit'}
				</LoadingButton>
			</div>
			{mode === 'optional' && (
				<div className="text-center">
					<LoadingButton
						color="link"
						size="lg"
						onClick={onCancel}
						disabled={saveResult.isPending()}
						pending={skipResult.isPending()}>
						Skip, for now
					</LoadingButton>
				</div>
			)}
		</form>
	);
};

export const SurveyForm = memo<SurveyFormProps>(({ mode, onSubmit, onCancel, saveResult, skipResult }) => {
	const renderForm = useCallback(
		(props: FormRenderProps) => (
			<SurveyFormInner
				{...props}
				mode={mode}
				onCancel={onCancel}
				saveResult={saveResult}
				skipResult={skipResult}
			/>
		),
		[mode, onCancel, saveResult, skipResult],
	);

	return (
		<Modal isOpen={!!mode} className={css.survey}>
			<ModalBody className={'double-padded'}>
				<div className="text-center">
					<img src={logoImg} />
					{mode === 'optional' && (
						<h2 className="margin-top margin-bottom">Mind answering a couple quick questions?</h2>
					)}
					{mode === 'required' && (
						<Fragment>
							<h2 className="margin-top margin-bottom">
								You're in! Welcome to Volley
								<br />
								Let's finalize your profile.
							</h2>
						</Fragment>
					)}
				</div>
				<Form onSubmit={onSubmit as (val: object) => void} render={renderForm} initialValues={initialValue} />
			</ModalBody>
		</Modal>
	);
});
