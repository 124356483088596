import { combineLatest, Subject } from 'rxjs';
import { switchMap, startWith, share, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { asks } from 'fp-ts/lib/Reader';
import { ProfileModelType } from '../../models/profile.model';
import { useObservable } from 'volley-common/dist/utils/react.utils';
import { memo, useCallback } from 'react';
import { identity } from 'fp-ts/lib/function';
import { SurveyForm, SurveyFormModel } from './Survey';
import { initial } from '@devexperts/remote-data-ts';
import { isAfter, parse } from 'date-fns';

const SURVEY_REQUIRED_SINCE = new Date('2023-08-15T00:00:00Z');

export interface SurveyContainerContext {
	profileModel: ProfileModelType;
}

export const SurveyContainer = asks(({ profileModel }: SurveyContainerContext) => {
	const account$ = profileModel.profile$.pipe(
		filter(rd => !rd.isPending()),
		map(rd => rd.toOption().chain(identity)),
		share(),
	);

	const skip$ = new Subject<void>();
	const skipResult$ = skip$.pipe(
		switchMap(() => profileModel.update({ signup_survey_displayed: true })),
		share(),
	);

	const formMode$ = combineLatest([account$, skipResult$.pipe(startWith(undefined))]).pipe(
		map(([account]) =>
			account
				.map(({ profile, id }) => {
					if (profile.signup_type === 'share') {
						return undefined; // we wont have it for share signups
					} else if (profile.lead?.length && profile.role?.length) {
						return undefined; // already filled
					} else {
						const dismissed = !!profile.signup_survey_displayed;
						console.log(profile.created_at, isAfter(parse(profile.created_at!), SURVEY_REQUIRED_SINCE));
						const isRequired =
							!!profile.created_at && isAfter(parse(profile.created_at), SURVEY_REQUIRED_SINCE);
						return dismissed ? undefined : isRequired ? 'required' : 'optional';
					}
				})
				.toUndefined(),
		),
		distinctUntilChanged(),
	);

	const save$ = new Subject<SurveyFormModel>();
	const saveResult$ = save$.pipe(
		switchMap(data => profileModel.update({ ...data, signup_survey_displayed: true })),
		share(),
	);

	return memo(function SurveyContainer() {
		const formMode = useObservable(formMode$, undefined);
		const saveResult = useObservable(saveResult$, initial);
		const skipResult = useObservable(skipResult$, initial);
		const onSave = useCallback(val => save$.next(val), []);
		const onCancel = useCallback(() => skip$.next(), []);

		return (
			<SurveyForm
				onSubmit={onSave}
				mode={formMode}
				onCancel={onCancel}
				saveResult={saveResult}
				skipResult={skipResult}
			/>
		);
	});
});
