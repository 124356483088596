import React, { memo, useEffect, useState } from 'react';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import filenamify from 'filenamify/filenamify';
// eslint-disable-next-line import/no-webpack-loader-syntax
import PdfWorker from 'worker-loader?!../../../worker/pdf.worker';
import * as Comlink from 'comlink';
import { PdfWorkerRemote } from '../../../worker/pdf.worker';
import { failure, initial, pending, RemoteData, success } from '@devexperts/remote-data-ts';
import { saveAs } from 'file-saver';
import { TProductImage } from 'volley-common/dist/services/products.service';
import { PdfDocument } from './PdfDocument';
import { usePDF } from '@react-pdf/renderer';

interface PdfDocumentButtonProps {
	notes: TProductImage[];
	productName: string;
}

const PdfDocumentButtonInner = memo<PdfDocumentButtonProps>(({ notes, productName }) => {
	const [downloadLinkRef, setDownloadLinkRef] = useState<HTMLElement | null>();
	const [document] = useState(() => <PdfDocument productName={productName} notes={notes} />);
	const [pdf] = usePDF({ document });

	const ready = !pdf.loading && !!pdf.blob;
	useEffect(() => {
		if (ready) {
			console.log('downloading', downloadLinkRef);
			downloadLinkRef?.click();
		}
	}, [ready, downloadLinkRef]);

	return (
		<LoadingButton
			ref={setDownloadLinkRef}
			pending={!ready}
			color="primary"
			size="lg"
			tag="a"
			href={pdf.url}
			download={makePdfName(productName)}>
			Download PDF
		</LoadingButton>
	);
});

export const PdfDocumentButtonSync = memo<PdfDocumentButtonProps>(({ notes, productName }) => {
	const [document, setDocument] = useState<TProductImage[]>([]);
	const key = notes.map(n => n.id).join(',');

	const startGenerating = () => {
		if (notes.length) {
			setDocument(notes);
		}
	};

	useEffect(() => {
		setDocument([]);
	}, [key]);

	return document.length ? (
		<PdfDocumentButtonInner notes={document} productName={productName} key={key} />
	) : (
		<LoadingButton disabled={notes.length === 0} color="primary" size="lg" onClick={startGenerating}>
			Generate PDF
		</LoadingButton>
	);
});

export const PdfDocumentButton = memo<PdfDocumentButtonProps>(({ notes, productName }) => {
	const [result, setResult] = useState<RemoteData<unknown, unknown>>(initial);
	const startGenerating = () => {
		setResult(pending);
		const worker = new PdfWorker();
		const pdfWorker = Comlink.wrap<PdfWorkerRemote>(worker);
		pdfWorker.generateSingle(notes, productName).then(
			pdfBlob => {
				saveAs(pdfBlob, makePdfName(productName));
				setResult(success(null));
			},
			err => {
				console.warn('error', err);
				setResult(failure(err));
			},
		);
	};

	return (
		<LoadingButton
			pending={result.isPending()}
			disabled={notes.length === 0}
			color="primary"
			size="lg"
			onClick={startGenerating}>
			Generate PDF
		</LoadingButton>
	);
});

function makePdfName(productName: string) {
	return filenamify(productName, { replacement: '_' }) + '.pdf';
}
