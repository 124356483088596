import { TrelloOptions } from 'volley-common/dist/services/export/trello.service';
import { MaintenanceInfoIO } from 'volley-common/dist/models/maintenance.model';

export type TEnv = {
	baseUrl: string;
	websiteUrl: string;
	widgetUrl?: string;
	hashidsSalt: string;
	heapId?: string;
	hotJarId?: number;
	stripeApiKey?: string;
	slackClientId?: string;
	trelloOptions?: TrelloOptions;
	maintenanceInfo?: unknown;
	capterraVkey?: string;
	capterraVid?: string;
};

const dynamicEnv: TEnv | undefined = (window as any).ENV;
export const env: TEnv = dynamicEnv || {
	baseUrl: 'http://localhost:8000',
	websiteUrl: 'https://meetvolley.com',
	widgetUrl: 'https://widget.meetvolley.com',
	hashidsSalt: 'xxxxxxxxxxxx',
	trelloOptions: {
		clientKey: 'c2d1a00baa4c077abdea074c724b46cb',
	},
	slackClientId: '345951250628.3666003958213',
	// Volley LOCAL:
	// slackClientId: '345951250628.3836299271506',
};

export const maintenanceInfo = MaintenanceInfoIO.decode(env.maintenanceInfo);
