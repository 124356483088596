import { initial, success, RemoteData, failure, pending } from '@devexperts/remote-data-ts';
import { Observable, of, Subject, merge, combineLatest, BehaviorSubject, from } from 'rxjs';
import {
	map,
	switchMap,
	pluck,
	withLatestFrom,
	scan,
	share,
	filter,
	distinctUntilChanged,
	mapTo,
	tap,
	shareReplay,
	startWith,
} from 'rxjs/operators';
import { TNotesOrder } from './Review';
import {
	mapRD,
	isDefined,
	switchMapRD,
	filterSuccess,
	getRightSetoid,
	tapRD,
	shareReplayRefCount,
} from 'volley-common/dist/utils/object.utils';
import { none, some, Option, getSetoid } from 'fp-ts/lib/Option';
import {
	TProductImage,
	ProductService,
	getMembersOrEmpty,
	filterNotesByStatuses,
	NoteFilterValue,
	defaultNoteFilter,
	noteByStatusOrd,
	noteByPriorityOrd,
	TNotePriority,
	TAttachedFile,
	extractPageFromUrl,
	TProduct,
	NoteStatus,
	TProductUploadedImage,
	parseProductImage,
	TSerializedProductImage,
} from 'volley-common/dist/services/products.service';
import { TNewComment } from './CommentForm';
import {
	noteDateOrd,
	TWithDate,
	NotesService,
	TComment,
	noteCreatedDateOrd,
	TCommentAuthor,
} from 'volley-common/dist/services/notes.service';
import { sort, head, lookup } from 'fp-ts/lib/Array';
import { asks } from 'fp-ts/lib/Reader';
import { array } from 'fp-ts';
import { SessionServiceClass } from '../../../services/token.service';
import { contramap, setoidNumber } from 'fp-ts/lib/Setoid';
import { history } from '../../../utils/history';
import { ProfileModelType } from '../../../models/profile.model';
import { constNull, constVoid, identity } from 'fp-ts/lib/function';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { genericErrorMessage } from 'volley-common/dist/utils/error.utils';
import { routes } from 'volley-common/dist/utils/routes';
import { planNameToType, getPlan } from 'volley-common/dist/services/auth.service';
import { getDualOrd } from 'fp-ts/lib/Ord';
import { shouldOfferFirstTimeCoupon } from '../../../models/profile.utils';
import { TFileData, readFiles } from 'volley-common/dist/utils/axios';
import { CreateNoteForUploadedImage } from './ImagePanelV2';

interface Ctx {
	productService: ProductService;
	notesService: NotesService;
	sessionService: SessionServiceClass;
	profileModel: ProfileModelType;
	toastService: ToastService;
}

type TSetStatusAction = { type: 'setStatus'; id: number; status: number };
type TAttachmentDeletedAction = { type: 'attachmentDeleted'; attachmentId: number };
type TAttachmentsUpdatedAction = { type: 'attachmentsUpdated'; attachments: TAttachedFile[]; noteId: number };
type TSetPriorityAction = { type: 'setPriority'; id: number; priority: TNotePriority };
type TSetAssigneeAction = { type: 'setAssignee'; id: number; assignee: TCommentAuthor | undefined };
type TUpdateNoteAction = { type: 'update'; id: number; data: TNewComment };
type TCreateNoteAction = { type: 'create'; data: TProductImage };
type TDataAction = { type: 'data'; data: RemoteData<Error, TProductImage[]> };
type TDeleteNodeAction = { delete: number };
type TAction =
	| TSetStatusAction
	| TDataAction
	| TCreateNoteAction
	| TUpdateNoteAction
	| TDeleteNodeAction
	| TSetPriorityAction
	| TAttachmentDeletedAction
	| TAttachmentsUpdatedAction
	| TSetAssigneeAction;

const sortFn = sort(noteDateOrd);
const sortByDate = function <T extends TWithDate>(items: T[]): T[] {
	return sortFn(items) as T[];
};
const sortCreatedDateFn = sort(noteCreatedDateOrd);
const sortByCreatedDate = function <T extends TWithDate>(items: T[]): T[] {
	return sortCreatedDateFn(items) as T[];
};

export function sortImages(
	statuses: NoteStatus[],
	images: TProductImage[],
	order: TNotesOrder,
	uploadedImages: number[] | null,
): TProductImage[] {
	switch (order) {
		case 'oldest':
			return sortByDate(images);
		case 'newest':
			return sortByDate(images).reverse();
		case 'status':
			return array.sortBy([noteByStatusOrd(statuses), noteDateOrd]).fold(images, sorter => sorter(images));
		case 'priority':
			return array.sortBy([getDualOrd(noteByPriorityOrd), noteDateOrd]).fold(images, sorter => sorter(images));
		case 'page':
			const groups = new Map<string, TProductImage[]>();
			sortByDate(images)
				.reverse()
				.forEach(img => {
					const page = img.product_upload_image_id
						? String(img.product_upload_image_id)
						: extractPageFromUrl(img.source);
					let group = groups.get(page);
					if (!group) {
						groups.set(page, (group = []));
					}
					group.push(img);
				});
			if (uploadedImages !== null) {
				const getIndex = (key: string) => {
					const index = uploadedImages.indexOf(Number(key));
					return index === -1 ? Number.MAX_SAFE_INTEGER : index;
				};
				const arr = Array.from(groups.entries()).sort((a, b) => getIndex(a[0]) - getIndex(b[0]));
				return arr.flatMap(e => e[1]);
			}
			return Array.from(groups.values()).flat();
	}
}

export const defaultNotesOrder: TNotesOrder = 'page';

export interface ReviewViewModelProps {
	productId: number;
	product: RemoteData<Error, Option<TProduct>>;
	noteId?: number;
	imageId?: number;
}

export const createReviewViewModel = asks((ctx: Ctx) => (props$: Observable<ReviewViewModelProps>) => {
	const productId$ = props$.pipe(pluck('productId'), distinctUntilChanged());
	const product$ = props$.pipe(pluck('product'), distinctUntilChanged());
	const statuses$ = product$.pipe(
		map(p =>
			p
				.toOption()
				.chain(identity)
				.mapNullable(p => p.statuses)
				.getOrElse([]),
		),
	);

	const onSelectNote = (note: TProductImage | Pick<TProductImage, 'product_id' | 'product_upload_image_id'>) => {
		console.log('push', note);
		if (note.product_id && note.product_upload_image_id) {
			// Create an additional history entry so that clicking "Back" leaves the user on the same page
			history.push(routes.productImages(note.product_id, note.product_upload_image_id));
		}
		history.push('id' in note ? routes.productNotes(note.product_id, note.id) : routes.product(note.product_id));
	};
	const isShowResolved$ = new BehaviorSubject(false);
	const noteFilter$ = new BehaviorSubject<NoteFilterValue>(defaultNoteFilter);
	const filterNotes$ = combineLatest([statuses$, isShowResolved$, noteFilter$]).pipe(
		map(([statuses, showResolved, filter]) => filterNotesByStatuses(statuses, filter, showResolved)),
		shareReplayRefCount(1),
	);

	const refreshUploadedImages$ = new Subject<void>(); // TODO: refresh is triggered twice

	const uploadedImages$: Observable<RemoteData<Error, TProductUploadedImage[]>> = product$.pipe(
		map(product =>
			product
				.toOption()
				.chain(identity)
				.mapNullable(p => (p.type === 'upload_image' ? p.id : null))
				.toNullable(),
		),
		switchMap(projectId =>
			projectId
				? refreshUploadedImages$.pipe(
						startWith(null),
						switchMap(() => ctx.productService.getProductUploadedImages(projectId)),
				  )
				: of(initial),
		),
		shareReplayRefCount(1),
	);

	/**
	 * List of uploaded image IDs as they appear in the API response
	 * For use in sorting by page
	 */
	const uploadedImagesOrder$ = uploadedImages$.pipe(
		map(products => (products === initial ? null : products.toNullable()?.map(i => i.id) ?? [])),
		distinctUntilChanged((x, y) =>
			x === null ? y === null : !!y && x.length === y.length && x.every((v, i) => v === y[i]),
		),
		shareReplayRefCount(1),
	);

	const pushAction$ = new Subject<TAction>();

	const setPriority$ = new Subject<[number, TNotePriority]>();
	const setPriorityResult$ = setPriority$.pipe(
		switchMap(([id, priority]) =>
			ctx.productService
				.setPriority(id, priority)
				.pipe(mapRD(() => ({ type: 'setPriority' as const, id, priority }))),
		),
		shareReplayRefCount(1),
	);

	const setAssignee$ = new Subject<[number, number | null]>();
	const setAssigneeResult$ = setAssignee$.pipe(
		tap(c => console.log('setting assignee', c)),
		switchMap(([id, assignee]) =>
			ctx.productService
				.setAssignee(id, assignee)
				.pipe(mapRD(note => ({ type: 'setAssignee' as const, id, assignee: note.assignee || undefined }))),
		),
		shareReplayRefCount(1),
	);

	const setStatus$ = new Subject<[noteId: number, status: number]>();
	const setStatusResult$ = setStatus$.pipe(
		switchMap(([id, status]) =>
			ctx.productService.setStatus(id, status).pipe(
				mapRD(() => ({ type: 'setStatus' as const, id, status })),
				startWith(success<Error, TSetStatusAction>({ type: 'setStatus' as const, id, status })),
				tap(rd => rd.isFailure() && ctx.toastService.push({ text: 'Failed to update the note status' })),
				tap(c => console.log(c)),
			),
		),
	);

	const deleteNoteAction$ = new Subject<TDeleteNodeAction>();

	const imagesFromApi$ = productId$.pipe(
		switchMap(productId => ctx.productService.getNotes(productId)),
		map(rd => ({ type: 'data' as const, data: rd })),
		share(),
	);

	const notesOrder$ = new BehaviorSubject<TNotesOrder>(defaultNotesOrder);

	const ownerPlan$ = product$.pipe(
		map(product =>
			product
				.toOption()
				.chain(identity)
				.mapNullable(p => p.owner)
				.mapNullable(o => o.billing)
				.mapNullable(b => b.plan_name)
				.chain(planNameToType)
				.map(getPlan),
		),
	);

	/**
	 * If the project is a team project, this is `none` meaning no note limit is applied;
	 * If the current user is a superuser, this will be `none`;
	 * Otherwise, returns the note count limit as defined by the product owner's plan.
	 */
	const maxNotes$ = combineLatest([ctx.profileModel.isSuperUser$, product$, ownerPlan$]).pipe(
		map(([isSuperUser, product, ownerPlan]) =>
			isSuperUser || product.exists(p => p.exists(p => !!p.team))
				? none
				: ownerPlan.chain(plan => plan.features.maxNotes),
		),
		share(),
	);

	const updateNoteRequest$ = new Subject<[number, TNewComment]>();
	const updateNoteResult$ = updateNoteRequest$.pipe(
		switchMap(([id, data]) =>
			ctx.notesService
				.updateNote(id, data.text, data.attachments, data.mentions)
				.pipe(mapRD(() => ({ type: 'update' as const, id, data }))),
		),
		share(),
	);

	const createNoteRequest$ = new Subject<CreateNoteForUploadedImage>();
	const createNoteResult$ = createNoteRequest$.pipe(
		switchMap(data => {
			const files$ = data.attachments ? from(readFiles(data.attachments)) : of([]);
			return files$.pipe(
				switchMap(attachments =>
					ctx.productService.postProductImage({
						productId: data.productId,
						product_upload_image_id: data.uploadedImageId,
						source: '',
						x: data.x,
						y: data.y,
						pixel_ratio: 1,
						comment: data.note,
						browser: '',
						os: '',
						mentions: data.mentions,
						attachments,
					}),
				),
				mapRD(img => parseProductImage(img as unknown as TSerializedProductImage)),
				mapRD((data): TCreateNoteAction => ({ type: 'create' as const, data })),
			);
		}),
		share(),
	);

	const unsortedImages$ = merge(
		imagesFromApi$,
		setStatusResult$.pipe(filterSuccess),
		setPriorityResult$.pipe(filterSuccess),
		setAssigneeResult$.pipe(filterSuccess),
		updateNoteResult$.pipe(filterSuccess),
		createNoteResult$.pipe(filterSuccess),
		deleteNoteAction$,
		pushAction$,
	).pipe(
		scan<TAction, RemoteData<Error, TProductImage[]>>((images, action) => {
			if ('type' in action && action.type === 'update') {
				return images.map(images => {
					return images.map(image =>
						image.id === action.id
							? {
									...image,
									text: action.data.text,
							  }
							: image,
					);
				});
			} else if ('type' in action && action.type === 'create') {
				return images.map(images => [...images, action.data]);
			} else if ('type' in action && action.type === 'data') {
				return action.data;
			} else if ('type' in action && action.type === 'setStatus') {
				return images.map(images => {
					return images.map(image => ({
						...image,
						status_id: image.id === action.id ? action.status : image.status_id,
					}));
				});
			} else if ('type' in action && action.type === 'setPriority') {
				return images.map(images => {
					return images.map(image => ({
						...image,
						priority: image.id === action.id ? action.priority : image.priority,
					}));
				});
			} else if ('type' in action && action.type === 'setAssignee') {
				return images.map(images => {
					return images.map(image => ({
						...image,
						assignee: image.id === action.id ? action.assignee : image.assignee,
					}));
				});
			} else if ('type' in action && action.type === 'attachmentDeleted') {
				return images.map(images => {
					return images.map(image => ({
						...image,
						attachments:
							image.attachments && image.attachments.filter(att => att.id !== action.attachmentId),
					}));
				});
			} else if ('type' in action && action.type === 'attachmentsUpdated') {
				return images.map(images =>
					images.map(image =>
						image.id === action.noteId
							? {
									...image,
									attachments: action.attachments,
							  }
							: image,
					),
				);
			} else if (action.delete) {
				return images.map(images => images.filter(note => note.id !== action.delete));
			} else return images;
		}, initial),
		share(),
	);

	const images$ = combineLatest(statuses$, unsortedImages$, notesOrder$, maxNotes$, uploadedImagesOrder$).pipe(
		map(([statuses, images, order, maxNotes, uploadedImageOrder]) =>
			images.map(img => {
				const limited = maxNotes.fold(img, limit => sortByCreatedDate(img).slice(0, limit));
				return sortImages(statuses, limited, order, uploadedImageOrder);
			}),
		),
		shareReplay(),
	);

	const deleteNote$ = new Subject<number>();
	const deleteNoteEffect$ = deleteNote$.pipe(
		withLatestFrom(images$.pipe(filterSuccess), filterNotes$),
		switchMap(([id, images, filterNotes]) => {
			const origNotes = filterNotes(images);
			const deletedIndex = origNotes.findIndex(i => i.id === id);
			const nextNote = lookup(deletedIndex + 1, origNotes).orElse(() => lookup(deletedIndex - 1, origNotes));
			return ctx.productService.deleteNote(id).pipe(mapRD(() => ({ delete: id, nextNote })));
		}),
		tapRD(({ nextNote, ...action }) => {
			nextNote.foldL(constVoid, onSelectNote);
			deleteNoteAction$.next(action);
		}),
		tap(result => result.mapLeft(() => ctx.toastService.push({ text: genericErrorMessage }))),
		share(),
	);

	type SelectedImageData = {
		userSelected: number | null | undefined;
		note: RemoteData<Error, Option<TProductImage>>;
	};
	const selectedImageData$ = combineLatest(props$.pipe(pluck('noteId')), images$, filterNotes$).pipe(
		scan<any, SelectedImageData>(
			(acc, [userSelected, images, filterNotes]) => ({
				userSelected,
				note: success<Error, Option<TProductImage>>(
					images
						.map((images: TProductImage[]) => {
							const visibleImages = filterNotes(images);
							const lastSelected = userSelected || acc.note.toNullable()?.toNullable()?.id;
							if (lastSelected) {
								const found = images.find(i => i.id === lastSelected);
								if (found) {
									return some(found);
								}
							}
							return head(visibleImages);
						})
						.getOrElse(none),
				),
			}),
			{ userSelected: undefined, note: initial },
		),
		distinctUntilChanged(),
	);

	const selectedImage$ = selectedImageData$.pipe(map(d => d.note));

	const saveCommentRequest$ = new Subject<TNewComment>();
	const saveCommentEffect$ = saveCommentRequest$.pipe(
		withLatestFrom(selectedImage$),
		switchMap(([comment, image]) => {
			const result: Observable<RemoteData<Error, TCommentCreateAction>> = image.fold(
				of(initial),
				of(pending),
				e => of(failure<Error, any>(e)),
				noteOpt =>
					noteOpt
						.map(image =>
							ctx.notesService
								.postComment(image.id, comment.text, comment.attachments, comment.mentions)
								.pipe(mapRD(comment => ({ type: 'create', comment } as TCommentAction))),
						)
						.getOrElse(of(failure<Error, any>(new Error()))),
			);
			return result;
		}),
		share(),
	);

	const updateCommentRequest$ = new Subject<[number, TNewComment]>();
	const updateCommentEffect$ = updateCommentRequest$.pipe(
		withLatestFrom(selectedImage$),
		switchMap(([[id, comment], image]) => {
			const result: Observable<RemoteData<Error, TCommentUpdateAction>> = image.fold(
				of(initial),
				of(pending),
				e => of(failure<Error, any>(e)),
				noteOpt =>
					noteOpt
						.map(image =>
							ctx.notesService
								.updateComment(id, comment.text, comment.attachments, comment.mentions)
								.pipe(mapRD(comment => ({ type: 'update', comment } as TCommentAction))),
						)
						.getOrElse(of(failure<Error, any>(new Error()))),
			);
			return result;
		}),
		share(),
	);

	const setEditedCommentId$ = new Subject<number | 'note' | undefined>();
	const editedCommentId$ = merge(
		setEditedCommentId$,
		merge(updateCommentEffect$, updateNoteResult$).pipe(
			tap((result: RemoteData<Error, unknown>) =>
				result.fold(null, null, () => ctx.toastService.push({ text: genericErrorMessage }), constNull),
			),
			filterSuccess,
			mapTo(undefined),
		),
	);

	const deleteComment$ = new Subject<TComment>();
	const deleteCommentEffect$ = deleteComment$.pipe(
		switchMap(comment =>
			ctx.notesService.deleteComment(comment.id).pipe(
				map(rd => rd.map(() => ({ type: 'delete', id: comment.id } as TCommentAction)).toNullable()),
				filter(isDefined),
			),
		),
		share(),
	);

	// reloading only when selected note ID changes
	const reloadedComments$ = selectedImage$.pipe(
		distinctUntilChanged(
			getRightSetoid(getSetoid(contramap<number, TProductImage>(note => note.id, setoidNumber))).equals,
		),
		switchMapRD(image => image.map(image => ctx.productService.getComments(image.id)).getOrElse(of(success([])))),
		share(),
	);

	type TCommentCreateAction = { type: 'create'; comment: TComment };
	type TCommentUpdateAction = { type: 'update'; comment: TComment };
	type TCommentAction =
		| TCommentCreateAction
		| TCommentUpdateAction
		| { type: 'load'; data: RemoteData<Error, TComment[]> }
		| { type: 'delete'; id: number };

	const comments$ = merge(
		reloadedComments$.pipe(map(data => ({ type: 'load', data } as TCommentAction))),
		saveCommentEffect$.pipe(filterSuccess),
		updateCommentEffect$.pipe(filterSuccess),
		deleteCommentEffect$,
	).pipe(
		scan((acc: RemoteData<Error, TComment[]>, action: TCommentAction) => {
			switch (action.type) {
				case 'create':
					return acc.map(comments => [...comments, action.comment]);
				case 'update':
					return acc.map(comments => comments.map(c => (c.id === action.comment.id ? action.comment : c)));
				case 'load':
					return action.data;
				case 'delete':
					return acc.map(comments => comments.filter(c => c.id !== action.id));
			}
		}, initial),
		mapRD(comments => sortByCreatedDate(comments)),
	);

	const loadingCommentsCount$ = comments$.pipe(
		map(data => data.map(comments => comments.length).toNullable()),
		filter(isDefined),
		distinctUntilChanged(),
	);

	const canUseMentions$ = combineLatest([
		ctx.profileModel.isSuperUser$,
		ctx.profileModel.currentPlan$.pipe(map(plan => plan.features.useMentions)),
	]).pipe(map(([isSuperUser, canUseMentions]) => isSuperUser || canUseMentions));

	const userId$ = ctx.sessionService.decoded$.pipe(map(token => token.map(t => t.user_id)));
	const reachedNotesLimit$ = combineLatest([maxNotes$, unsortedImages$, product$, userId$]).pipe(
		map(([maxNotes, notes, product, userId]) =>
			maxNotes
				.filter(limit => notes.exists(notes => notes.length > limit))
				.map(limit => {
					const prod = product.toOption().chain(identity);
					const isOwn = prod.exists(product => !!product.owner && product.owner.id === userId.toNullable());
					return { mode: isOwn ? ('owner' as const) : ('guest' as const), limit };
				}),
		),
		shareReplay(1),
	);

	const onDeleteNote$ = maxNotes$.pipe(
		map(maxNotes => (maxNotes.isNone() ? (id: number) => deleteNote$.next(id) : undefined)),
	);

	const offerFirstTimeUpgrade$ = ctx.profileModel.profile$.pipe(
		map(p => p.exists(p => p.exists(p => shouldOfferFirstTimeCoupon(p.billing)))),
		distinctUntilChanged(),
		shareReplay(1),
	);

	const uploadedImageFromUrl$ = props$.pipe(map(p => p.imageId));
	const setUploadedImage$ = new Subject<number>();
	const selectedUploadedImageId$ = setUploadedImage$.pipe(startWith(null));
	const selectedUploadedImage$ = combineLatest([
		uploadedImageFromUrl$,
		selectedImageData$,
		selectedUploadedImageId$,
		uploadedImages$,
	]).pipe(
		map(([idFromUrl, selectedImage, id, images]): TProductUploadedImage | undefined => {
			const selectedNote = selectedImage.userSelected ? selectedImage.note : success(none);
			return (
				selectedNote
					.map(selectedImage => {
						const effectiveId = selectedImage.toNullable()?.product_upload_image_id ?? id ?? idFromUrl;
						return effectiveId
							? images.toNullable()?.find(i => i.id === effectiveId)
							: images.toNullable()?.[0];
					})
					.toNullable() ?? undefined
			);
		}),
		shareReplay(1),
	);

	const selectUploadedImage$ = new Subject<number>();
	const selectUploadedImageEffect$ = selectUploadedImage$.pipe(
		withLatestFrom(selectedUploadedImage$),
		tap(([clickedId, currentId]) => {
			setUploadedImage$.next(clickedId);
		}),
	);

	const uploadMoreImages$ = new Subject<{ projectId: number; files: TFileData[] }>();
	const uploadMoreImagesResult$ = uploadMoreImages$.pipe(
		switchMap(c => ctx.productService.addMoreUploadedImages(c.projectId, c.files)),
		shareReplayRefCount(1),
	);

	return {
		onSelectNote,
		onSetNoteStatus: (note: number, status: number) => setStatus$.next([note, status]),
		onSetNotePriority: (note: number, priority: TNotePriority) => setPriority$.next([note, priority]),
		onSetNoteAssignee: (note: number, assignee: number | null) => setAssignee$.next([note, assignee]),
		onSaveComment: (comment: TNewComment) => saveCommentRequest$.next(comment),
		onSetShowResolved: (val: boolean) => isShowResolved$.next(val),
		onSetNoteFilter: (val: NoteFilterValue) => noteFilter$.next(val),
		onDeleteComment: (comment: TComment) => deleteComment$.next(comment),
		onUpdateComment: (id: number, comment: TNewComment) => updateCommentRequest$.next([id, comment]),
		onUpdateNote: (id: number, comment: TNewComment) => updateNoteRequest$.next([id, comment]),
		onCreateNote: (data: CreateNoteForUploadedImage) => createNoteRequest$.next(data),
		onChangeNotesOrder: (order: TNotesOrder) => notesOrder$.next(order),
		onChangeEditedCommentId: (id: number | 'note' | undefined) => setEditedCommentId$.next(id),
		onAttachmentsUpdated: (noteId: number, attachments: TAttachedFile[]) =>
			pushAction$.next({ type: 'attachmentsUpdated', attachments, noteId }),
		onAttachmentDeleted: (attachmentId: number) => pushAction$.next({ type: 'attachmentDeleted', attachmentId }),
		teamMembers$: product$.pipe(
			mapRD(getMembersOrEmpty),
			map(r => r.getOrElse([])),
		),
		onDeleteNote$,
		images$,
		comments$,
		selectedImage$,
		selectedImageData$,
		saveCommentResult$: saveCommentEffect$.pipe(mapRD(action => action.comment)),
		updateCommentResult$: merge(updateCommentEffect$, updateNoteResult$),
		isShowResolved$,
		noteFilter$,
		userId$,
		loadingCommentsCount$,
		notesOrder$,
		canUseMentions$,
		editedCommentId$,
		showNotesLimit$: reachedNotesLimit$,
		setPriorityResult$,
		setAssigneeResult$,
		offerFirstTimeUpgrade$,
		statuses$,
		uploadedImages$,
		selectedUploadedImage$,
		selectUploadedImage: (id: number) => selectUploadedImage$.next(id),
		refreshUploadedImages$,
		addMoreUploadedImages: (projectId: number, files: TFileData[]) => uploadMoreImages$.next({ projectId, files }),
		addMoreUploadedImagesResult$: uploadMoreImagesResult$,
		effects$: merge(deleteCommentEffect$, deleteNoteEffect$, selectUploadedImageEffect$),
	};
});

export type ReviewViewModel = ReturnType<ReturnType<typeof createReviewViewModel['run']>>;
