import React, { memo, Fragment, useState, useCallback } from 'react';
import cn from 'classnames';
import css from './NoteAttachment.module.scss';
import { Button, Spinner } from 'reactstrap';
import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { RemoteData, initial } from '@devexperts/remote-data-ts';
import { TAttachedFile } from 'volley-common/dist/services/products.service';
import { constVoid } from 'fp-ts/lib/function';
import { Subject, of } from 'rxjs';
import { switchMap, withLatestFrom, map } from 'rxjs/operators';
import { asks } from 'fp-ts/lib/Reader';
import { isImageAttachment, NotesService } from 'volley-common/dist/services/notes.service';
import { YesNoConfirmation } from '../../../components/Confirmation';

interface NoteAttachmentProps {
	noteId: number;
	attachment: TAttachedFile;
	onPreview?: (src: string) => void;
	onDelete?: (id: number) => void;
	deleteStatus: RemoteData<Error, unknown>;
}

const NoteAttachment = memo<NoteAttachmentProps>(({ attachment, onPreview, onDelete = constVoid, deleteStatus }) => {
	const isImage = isImageAttachment(attachment.file);

	const ext = /\.([^.]+)$/.exec(attachment.name);

	const body = isImage ? (
		<img className="max-width" src={attachment.file} />
	) : (
		<Fragment>
			<h5 className={cn('title medium-font', css.attTitle)}>{attachment.name}</h5>
			{ext && (
				<h5 className="type">
					<span className="badge bg-light text-color">{ext[1].toUpperCase()}</span>
				</h5>
			)}
		</Fragment>
	);

	const isDeleting = deleteStatus.isPending();

	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const closeDeleteConfirmation = useCallback(() => setDeleteConfirmation(false), []);

	return (
		<Fragment>
			<YesNoConfirmation
				isOpen={deleteConfirmation}
				title={'Delete file'}
				confirmLabel={'Yes, delete'}
				onConfirm={() => {
					onDelete(attachment.id);
					closeDeleteConfirmation();
				}}
				onCancel={closeDeleteConfirmation}
				onClose={closeDeleteConfirmation}>
				Are you sure you want to remove this file?
			</YesNoConfirmation>
			<a
				className={cn(css.preview, isImage && css.preview_image, 'attachment-preview d-inline-block')}
				rel="noopener noreferrer"
				target="_blank"
				onClick={e => {
					if (isImage && onPreview) {
						e.preventDefault();
						if (!isDeleting) {
							onPreview(attachment.file);
						}
					}
				}}
				href={attachment.file}>
				<Button
					color="secondary"
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
						!isDeleting && setDeleteConfirmation(true);
					}}
					className={cn('btn-circle remove', css.delete, isDeleting && css.delete_progress)}>
					{isDeleting ? (
						<Spinner style={{ width: 20, height: 20 }} />
					) : (
						<i className="material-icons" style={{ fontSize: 12 }}>
							clear
						</i>
					)}
				</Button>
				{body}
			</a>
		</Fragment>
	);
});

export const NoteAttachmentContainer = asks((ctx: { notesService: NotesService }) =>
	withRX(NoteAttachment)(props$ => {
		const noteId$ = props$.pipe(map(props => props.noteId));

		const delete$ = new Subject<number>();
		const deleteResult$ = delete$.pipe(
			withLatestFrom(noteId$),
			switchMap(([attId, noteId]) =>
				ctx.notesService.deleteNoteAttachment(noteId, attId).pipe(
					withLatestFrom(props$),
					map(([result, props]) => {
						if (result.isSuccess() && props.onDelete) {
							props.onDelete(attId);
						}
						return result;
					}),
				),
			),
		);

		return {
			defaultProps: {
				deleteStatus: initial,
			},
			props: {
				onDelete: of((id: number) => delete$.next(id)),
				deleteStatus: deleteResult$,
			},
		};
	}),
);
