import {
	AuthService,
	TAccountInfo,
	TPlan,
	getPlanInfo,
	isSuperUser,
	Notifications,
} from 'volley-common/dist/services/auth.service';
import { asks } from 'fp-ts/lib/Reader';
import { of, Subject, Observable, merge, empty, BehaviorSubject, combineLatest } from 'rxjs';
import { fromNullable, isSome, none, Option } from 'fp-ts/lib/Option';
import { success, initial, RemoteData } from '@devexperts/remote-data-ts';
import {
	map,
	share,
	filter,
	mergeMap,
	withLatestFrom,
	switchMap,
	shareReplay,
	delay,
	startWith,
	tap,
} from 'rxjs/operators';
import { mapRD, filterSuccess } from 'volley-common/dist/utils/object.utils';
import { identity } from 'fp-ts/lib/function';

export type TUpdateProfile = {
	avatar?: File;
	name?: string;
	email?: string;
	newPassword?: string;
	role?: string;
	lead?: string;
	signup_survey_displayed?: boolean;
};

export type TProfileModelContext = {
	authService: AuthService;
};

export const ProfileModel = asks((ctx: TProfileModelContext) => {
	const { authService } = ctx;

	const updateResults$ = new Subject<Observable<TAccountInfo>>();
	const refreshResults$ = new Subject<Observable<TAccountInfo>>();
	const successRefreshResults$ = refreshResults$.pipe(
		switchMap(identity),
		map(x => success<Error, TAccountInfo>(x)),
	);

	const userId$ = authService.userId$;

	const profile$ = userId$.pipe(
		switchMap(userId => {
			const initialLoad$ = userId.foldL(
				() => of(success<Error, TAccountInfo | undefined>(undefined)),
				user => authService.getProfile(),
			);

			const updatedValues$ = userId.foldL(
				() => empty(),
				id =>
					updateResults$.pipe(
						mergeMap(identity),
						filter(data => data.id === id),
						map(data => success<Error, TAccountInfo>(data)),
					),
			);

			return merge(initialLoad$, updatedValues$, successRefreshResults$).pipe(
				mapRD((data: TAccountInfo | undefined) => fromNullable(data)),
			);
		}),
		shareReplay(1),
	);

	const isSuperUser$ = profile$.pipe(map(isSuperUser));

	const lastSuccessfulUser$ = userId$.pipe(filter(isSome), shareReplay(1));
	const activationRequest$ = new Subject<string>();
	const activationResult$ = activationRequest$.pipe(
		withLatestFrom(lastSuccessfulUser$),
		switchMap(([token, userId]) => authService.confirmEmail(userId.value, token)),
		switchMap(
			result =>
				(result.isFailure() || result.isSuccess()
					? of(initial).pipe(delay(5000), startWith(result))
					: of(result)) as Observable<RemoteData<unknown, TAccountInfo>>,
		),
		withLatestFrom(profile$.pipe(filterSuccess)),
		tap(([result, profile]) =>
			result.map(updated =>
				profile.map(profile =>
					updateResults$.next(
						of({
							...profile,
							profile: {
								...profile.profile,
								email_confirmed: true,
							},
						}),
					),
				),
			),
		),
		map(([a, b]) => a),
		shareReplay(1),
	);

	activationResult$.subscribe();

	const update = (data: TUpdateProfile) => {
		const updateResult$ = authService.saveProfile(data).pipe(shareReplay(1));
		updateResults$.next(updateResult$.pipe(filterSuccess));
		return updateResult$;
	};

	const updateNotifications = (data: Notifications) => {
		const updateResult$ = authService.saveNotifications(data).pipe(share());
		updateResults$.next(updateResult$.pipe(filterSuccess));
		return updateResult$;
	};

	const currentPlanFromAPI$ = profile$.pipe(getPlanInfo);

	const currentPlanDebug$ = new BehaviorSubject<Option<TPlan>>(none);

	const currentPlan$ = combineLatest(currentPlanFromAPI$, currentPlanDebug$).pipe(
		map(([real, debug]) => debug.getOrElse(real)),
		shareReplay(1),
	);

	return {
		profile$,
		isSuperUser$,
		update,
		updateNotifications,
		activate: (token: string) => activationRequest$.next(token),
		activationResult$,
		currentPlan$,
		refresh: () => {
			const result$ = authService.getProfile();
			refreshResults$.next(result$.pipe(filterSuccess, share()));
			return result$;
		},
	};
});

export type ProfileModelType = ReturnType<typeof ProfileModel.run>;
