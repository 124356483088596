import React, { Fragment, memo } from 'react';
import { TMember } from 'volley-common/dist/services/products.service';
import { Avatar } from 'volley-common/dist/components/Avatar';
import cn from 'classnames';
import css from './MembersIndicator.module.scss';
import { UncontrolledTooltip } from 'reactstrap';

type TMembersIndicatorProps = {
	members: TMember[];
	currentUserId?: number;
	ownerUserId?: number;
	maxIcons?: number;
	onShowMore?: () => unknown;
};

type TMemberIcon = TMember | { plus: number };

const MembersIndicator = memo<TMembersIndicatorProps>(props => {
	const { onShowMore, ownerUserId } = props;
	const icons = prepareIcons(props);
	return (
		<div onClick={onShowMore} className="avatars-list half-margin-right hidden-sm d-none d-md-inline-block">
			{icons.map(icon => {
				if ('plus' in icon) {
					return (
						<Avatar
							key="plus"
							size="xs"
							initials={`+${icon.plus}`}
							className={cn(css.avatar, 'd-inline-block primary-bg')}
						/>
					);
				} else {
					return (
						<Fragment key={icon.id}>
							<Avatar
								size="xs"
								user={icon}
								id={`MembersIndicator-${icon.id}`}
								className={cn(css.avatar, 'd-inline-block')}
							/>
							<UncontrolledTooltip placement="top" target={`MembersIndicator-${icon.id}`}>
								{icon.name}
								{ownerUserId === icon.id ? ' (owner)' : undefined}
							</UncontrolledTooltip>
						</Fragment>
					);
				}
			})}
		</div>
	);
});

function prepareIcons(props: TMembersIndicatorProps): TMemberIcon[] {
	const { members, currentUserId, ownerUserId, maxIcons = 4 } = props;

	let result: TMemberIcon[] = [];
	if (ownerUserId) {
		const owner = members.find(m => m.id === ownerUserId);
		if (owner) {
			result.push(owner);
		}
	}

	if (currentUserId) {
		const current = members.find(m => m.id === currentUserId);
		if (current) {
			result.push(current);
		}
	}

	result = [...result, ...members.filter(m => !result.includes(m))];

	if (members.length > maxIcons) {
		return [...members.slice(0, maxIcons - 1), { plus: members.length - maxIcons + 1 }];
	} else {
		return members;
	}
}

export default MembersIndicator;
