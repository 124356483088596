import React, { memo, useMemo, useState } from 'react';
import cn from 'classnames';
import smileSvg from 'volley-common/dist/assets/images/smile.svg';
import yourWebsiteSvg from 'volley-common/dist/assets/images/your-website.svg';
import yourWidgetSvg from 'volley-common/dist/assets/images/your-widget.svg';
import chromeIconSvg from 'volley-common/dist/assets/images/chrome-icon.svg';
import chromeColorIconPng from 'volley-common/dist/assets/images/chrome-icon-color.png';
import firefoxIconSvg from 'volley-common/dist/assets/images/firefox-icon.svg';
import firefoxColorIconSvg from 'volley-common/dist/assets/images/firefox-icon-color.svg';
import codeIconSvg from 'volley-common/dist/assets/images/code-icon.svg';
import {
	CardDeck,
	Card,
	CardBody,
	CardTitle,
	CardText,
	CardFooter,
	Button,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import * as Bowser from 'bowser';
import style from './ZeroState.module.scss';

const InstallExtensionCard = memo(() => {
	const browser = useMemo(
		() =>
			Bowser.getParser(window.navigator.userAgent)
				.getBrowserName()
				.toLowerCase(),
		[],
	);
	// For the list of possible browser names, see here:
	// https://github.com/lancedikson/bowser/blob/master/src/constants.js
	switch (browser) {
		case 'chrome':
			return (
				<Card>
					<CardBody>
						<CardTitle className="medium-font">Install the browser extension</CardTitle>
						<CardText>Install the Chrome extension to capture notes on any website.</CardText>
					</CardBody>
					<CardFooter>
						<Button
							block
							color="primary"
							tag="a"
							target="_blank"
							rel="noopener noreferrer"
							href="https://chrome.google.com/webstore/detail/volley-extension/gipdjalmbiackhnmpjlpafmiaciejfdf">
							<img
								src={chromeIconSvg}
								style={{ position: 'relative', top: -1, marginRight: 3 }}
								width={16}
							/>{' '}
							Show Chrome extension
						</Button>
					</CardFooter>
				</Card>
			);
		case 'firefox':
			return (
				<Card>
					<CardBody>
						<CardTitle className="medium-font">Install the browser extension</CardTitle>
						<CardText>Install the Firefox addon to capture notes on any website.</CardText>
					</CardBody>
					<CardFooter>
						<Button
							block
							color="primary"
							tag="a"
							target="_blank"
							rel="noopener noreferrer"
							href="https://addons.mozilla.org/en-US/firefox/addon/volley-extension/">
							<img
								src={firefoxIconSvg}
								style={{ position: 'relative', top: -1, marginRight: 3 }}
								width={16}
							/>{' '}
							Show Firefox addon
						</Button>
					</CardFooter>
				</Card>
			);
		default:
			return (
				<Card>
					<CardBody>
						<CardTitle className="medium-font">Install the browser extension</CardTitle>
						<CardText>Install the Chrome or Firefox extension to capture notes on any website.</CardText>
					</CardBody>
					<CardFooter className="text-center">
						<img src={chromeColorIconPng} className={style.browserIcon} />
						<img src={firefoxColorIconSvg} className={style.browserIcon} />
					</CardFooter>
				</Card>
			);
	}
});

interface ZeroStateProps {
	onShare?: () => void;
	onOpenSettings?: () => void;
}

type CurrentTab = 'extension' | 'widget';

export const ZeroState = memo<ZeroStateProps>(({ onOpenSettings, onShare }) => {
	const [tab, setTab] = useState<CurrentTab>('extension');

	const widgetSettingsCard = (
		<Card>
			<CardBody>
				<CardTitle className="medium-font">Install the widget</CardTitle>
				<CardText>Install the embedable widget on your website.</CardText>
			</CardBody>
			<CardFooter>
				<Button color="primary" block onClick={onOpenSettings}>
					<img src={codeIconSvg} style={{ position: 'relative', top: -1, marginRight: 3 }} width="26px" />
					widget settings
				</Button>
			</CardFooter>
		</Card>
	);

	const renderTabContent = (tab: CurrentTab) => (
		<TabPane tabId={tab}>
			<CardDeck className="double-margin-top get-started-deck margin-auto">
				{tab === 'extension' ? <InstallExtensionCard /> : widgetSettingsCard}
				<Card>
					<CardBody>
						<CardTitle className="medium-font">Invite your colleagues</CardTitle>
						<CardText>Invite anyone that will be reviewing your feedback.</CardText>
					</CardBody>
					<CardFooter>
						<Button block color="primary" onClick={onShare}>
							Invite now
						</Button>
					</CardFooter>
				</Card>
				<Card>
					<CardBody>
						<CardTitle className="medium-font">Visit your website</CardTitle>
						{tab === 'extension' ? (
							<CardText>
								Open the extension and select this project. Your feedback will show up here.
							</CardText>
						) : (
							<CardText>Capture feedback from your widget, it will show up here.</CardText>
						)}
					</CardBody>
					<CardFooter className="text-center">
						{tab === 'extension' ? (
							<img
								className="max-width margin-auto"
								src={yourWebsiteSvg}
								style={{ position: 'relative', top: 6 }}
								width={201}
							/>
						) : (
							<img
								className="max-width margin-auto margin-bottom"
								src={yourWidgetSvg}
								style={{ position: 'relative', top: 6 }}
								width={120}
							/>
						)}
					</CardFooter>
				</Card>
			</CardDeck>
		</TabPane>
	);

	return (
		<div className={cn('note-zero', style.noteZero)}>
			<img
				className={cn('margin-auto d-none d-sm-block double-margin-bottom', style.noteZero__image)}
				src={smileSvg}
				width={90}
			/>
			<div className="note-zero-text">
				<h2 className="text-center medium-font">Start your project. Here’s how.</h2>
			</div>
			<Nav tabs className={'justify-content-center mt-4'}>
				<NavItem>
					<NavLink
						href="#"
						active={tab === 'extension'}
						onClick={e => {
							e.preventDefault();
							setTab('extension');
						}}>
						Browser extension
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						href="#"
						active={tab === 'widget'}
						onClick={e => {
							e.preventDefault();
							setTab('widget');
						}}>
						Widget code
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={tab}>
				{renderTabContent('extension')}
				{renderTabContent('widget')}
			</TabContent>
		</div>
	);
});
