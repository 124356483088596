import React, { FC, Fragment, useCallback } from 'react';
import { initial, RemoteData } from '@devexperts/remote-data-ts';
// eslint-disable-next-line no-restricted-imports
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, FormRenderProps, Field } from 'react-final-form';
import { TProduct } from 'volley-common/dist/services/products.service';
import { constVoid } from 'fp-ts/lib/function';
import { none, Option, some } from 'fp-ts/lib/Option';
import { Selectbox } from 'volley-common/dist/components/Selectbox';
import { Team } from 'volley-common/dist/services/teams.service';
import cn from 'classnames';
import css from './MoveProjectPopup.module.scss';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';

export interface MoveProjectRequest {
	productId: number;
	moveToTeamId: Option<number>;
}

export type MoveProjectPopupProps = {
	result?: RemoteData<Error, unknown>;
	onSave: (data: MoveProjectRequest) => void;
	onClose: () => void;
	teams: RemoteData<Error, Team[]>;
	product: TProduct;
	userId: number;
};

type TFormType = { moveTo: Option<Team> };
const initialValue: TFormType = { moveTo: none };

function renderOption(val: Option<Team> | undefined) {
	return (
		val?.foldL(
			() => (
				<Fragment>
					<i className="material-icons-outlined mr-2">person_outline</i> Personal projects
				</Fragment>
			),
			team => (
				<Fragment>
					<i className="material-icons-outlined mr-2">group</i> {team.name}
				</Fragment>
			),
		) ?? 'not selected'
	);
}

const selectboxTheme = {
	button: cn('btn-lg', css.select),
	menu: 'full-width',
};

export const MoveProjectPopup: FC<MoveProjectPopupProps> = ({
	product,
	teams,
	onClose,
	onSave = constVoid,
	result = initial,
	userId,
}) => {
	const handleSubmit = useCallback(
		(valuesObj: object) => {
			const values = valuesObj as TFormType;
			onSave({
				productId: product.id,
				moveToTeamId: values.moveTo.map(team => team.id),
			});
		},
		[product.id, onSave],
	);

	const teamOptions = teams.map(teams => [none, ...teams.filter(t => t.owner.id === userId).map(some)]).getOrElse([]);

	const renderForm: FC<FormRenderProps> = ({ handleSubmit }) => {
		return (
			<form onSubmit={handleSubmit}>
				<ModalBody>
					<div className="padded no-padding-top no-padding-bottom">
						<div className="margin-bottom">
							<label>Move to:</label>
							<Field name="moveTo">
								{({ input }) => (
									<Selectbox<Option<Team>>
										theme={selectboxTheme}
										renderOption={renderOption}
										{...input}
										pending={teams.isPending()}
										options={teamOptions}
									/>
								)}
							</Field>
						</div>
					</div>
				</ModalBody>

				<ModalFooter className="no-border-top">
					<Button color="link" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton
						color="primary"
						type="submit"
						disabled={result.isPending()}
						pending={result.isPending()}>
						Move
					</LoadingButton>
				</ModalFooter>
			</form>
		);
	};

	return (
		<Modal isOpen={true}>
			<ModalHeader toggle={onClose}>
				<h2 className="padding-left">Move {product.name}</h2>
			</ModalHeader>
			<Form initialValues={initialValue} onSubmit={handleSubmit} render={renderForm} />
		</Modal>
	);
};
