import { TeamsService } from 'volley-common/dist/services/teams.service';
import { mapRD, filterRD, isDefined, filterSuccess } from 'volley-common/dist/utils/object.utils';
import { history } from '../../utils/history';
import { routes } from 'volley-common/dist/utils/routes';
import { constVoid } from 'fp-ts/lib/function';
import { tap, skip, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable, merge, combineLatest } from 'rxjs';
import { some } from 'fp-ts/lib/Option';
import { AuthService } from 'volley-common/dist/services/auth.service';

export const requireTeam = (propsTeamId$: Observable<number>, teamsService: TeamsService, authService: AuthService) => {
	const switchTeamEffect$ = propsTeamId$.pipe(tap(id => teamsService.selectTeam(some(id))));

	const team$ = teamsService.selectedTeam$.pipe(
		mapRD(team => team.toNullable()),
		filterRD(isDefined),
	);

	const redirectEffect$ = teamsService.selectedTeam$.pipe(
		filterSuccess,
		skip(1), // skip share-replayed value
		tap(team => team.foldL(() => history.push(routes.dashboard), constVoid)),
	);

	const teamId$ = team$.pipe(mapRD(team => team.id));
	const realTeamId$ = teamId$.pipe(filterSuccess);

	const isOwner$ = combineLatest([team$, authService.userId$]).pipe(
		map(([team, userId]) => team.exists(team => team.owner.id === userId.getOrElse(-1))),
		distinctUntilChanged(),
	);

	return { team$, effects$: merge(redirectEffect$, switchTeamEffect$), teamId$, realTeamId$, isOwner$ };
};
