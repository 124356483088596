import * as React from 'react';
import { FC, Fragment, useCallback, useState, FormEvent, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Input } from 'reactstrap';
import { StripeProvider } from 'react-stripe-elements';
import { ask } from 'fp-ts/lib/Reader';
import {
	PaymentForm,
	PaymentMethod,
	renderDefaultFooter,
	SecurePayment,
	PaymentConfirmationButton,
	extractError,
} from './PaymentForm';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { DisplayPlanInfoCard } from '../PlanInfoCard';
import { TPlanType, CardInfo, BillingPeriod, CouponInfo } from 'volley-common/dist/services/auth.service';
import { RemoteData, success, initial, pending } from '@devexperts/remote-data-ts';
import { Option, isSome } from 'fp-ts/lib/Option';
import { identity } from 'fp-ts/lib/function';
import { RenderRemoteData } from '../../../components/RenderRemoteData';
import css from './PaymentPage.module.scss';
import { TApiError } from 'volley-common/dist/models/api.model';
import { routes } from 'volley-common/dist/utils/routes';
import { Link } from 'react-router-dom';
import { BillingPeriodSelector } from '../BillingPeriodSelector';
import { Form, Field } from 'react-final-form';
import { ErrorBoundary } from 'volley-common/dist/components/ErrorBoundary';

export type TPaymentPageResult = {
	paymentMethod?: PaymentMethod;
	prospectivePlan: TPlanType;
	billingPeriod: BillingPeriod;
};

type TProspectivePlanInfo = {
	annualCost: number;
	monthlyCost: number;
};

type TPaymentPageContext = {
	stripeApiKey: string;
};

type BillingInfo = CardInfo & {
	name: string;
	billingPeriod: BillingPeriod;
	planType: TPlanType;
};

type TPaymentPageProps = {
	onSubmit: (data: TPaymentPageResult) => void;
	currentPaymentMethod: RemoteData<unknown, Option<BillingInfo>>;
	prospectivePlan: TPlanType;
	prospectivePlanInfo: TProspectivePlanInfo;
	result: RemoteData<TApiError, unknown>;
	coupon?: string;
	appliedCoupon: RemoteData<string, Option<CouponInfo>>;
	onApplyCoupon: (code: string | null) => void;
	initialBillingPeriod?: BillingPeriod;
};

type ApplyCouponForm = {
	code: string;
};

export const PaymentPage = combineReader(
	ask<TPaymentPageContext>(),
	({ stripeApiKey }): FC<TPaymentPageProps> =>
		({
			onSubmit,
			prospectivePlan,
			prospectivePlanInfo,
			currentPaymentMethod,
			result,
			coupon,
			appliedCoupon,
			onApplyCoupon,
			initialBillingPeriod = 'year',
		}) => {
			const [isWaitingForStripeToken, setWaitingForStripeToken] = useState(false);
			useEffect(() => setWaitingForStripeToken(false), [result]);

			const [isAddingNewCard, setAddingNewCard] = useState(false);
			const handleChangeCard = useCallback(() => setAddingNewCard(true), [setAddingNewCard]);
			const handleUseExistingCard = useCallback(() => setAddingNewCard(false), [setAddingNewCard]);
			const existingPaymentMethod = currentPaymentMethod.chain(p => p.fold(initial, success));
			const hasPaymentMethod = currentPaymentMethod.exists(isSome);

			const appliedCouponOption = appliedCoupon.toOption().chain(identity);

			const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(initialBillingPeriod);
			const forcedBillingPeriod = appliedCouponOption.chain(coupon => coupon.forceBlllingCycle).toNullable();
			useEffect(() => {
				if (forcedBillingPeriod) {
					setBillingPeriod(forcedBillingPeriod);
				}
			}, [forcedBillingPeriod]);

			const [applyFormKey, setApplyFormKey] = useState(1);
			const appliedCode = appliedCouponOption.map(c => c.code).toNullable();
			useEffect(() => {
				if (appliedCode) {
					setApplyFormKey(x => x + 1);
				}
			}, [appliedCode]);

			const handleToken = useCallback(
				(paymentMethod: PaymentMethod) => {
					onSubmit({
						paymentMethod,
						prospectivePlan,
						billingPeriod,
					});
				},
				[onSubmit, prospectivePlan, billingPeriod],
			);
			const handlePayWithExistingCard = useCallback(
				(e: FormEvent) => {
					e.preventDefault();
					onSubmit({
						prospectivePlan,
						billingPeriod,
					});
				},
				[prospectivePlan, onSubmit, billingPeriod],
			);

			const paymentButton = currentPaymentMethod
				.toOption()
				.chain(identity)
				.map<PaymentConfirmationButton>(billing =>
					billing.planType !== prospectivePlan
						? 'upgrade'
						: prospectivePlan === 'starter'
						? 'currentPlan'
						: billing.billingPeriod === billingPeriod
						? 'currentPlan'
						: billingPeriod === 'month'
						? 'switchToMonthly'
						: 'switchToYearly',
				)
				.getOrElse('upgrade');

			return (
				<Fragment>
					<div className="main">
						<Link className="close-x menu-close" to={routes.dashboard}>
							<div className="material-icons">clear</div>
						</Link>
						<section>
							<Container>
								<Row className="justify-content-center">
									<Col lg={5}>
										<ErrorBoundary>
											<StripeProvider apiKey={stripeApiKey}>
												<Fragment>
													<h1>Confirm your subscription</h1>
													<p>Enter payment details to subscribe</p>
													{appliedCode === null && (
														<Form
															key={applyFormKey}
															onSubmit={values =>
																onApplyCoupon((values as ApplyCouponForm).code)
															}
															initialValues={{ code: '' }}>
															{({ handleSubmit }) => (
																<form onSubmit={handleSubmit}>
																	<div className="form-row">
																		<label>Promo code</label>
																	</div>
																	<Field name="code">
																		{({ input }) => (
																			<div className="form-row">
																				<div className="col-9">
																					<Input {...input} type="text" />
																				</div>
																				<div className="col-3">
																					<Button
																						type="submit"
																						color="secondary"
																						block
																						style={{ padding: 8 }}>
																						Apply
																					</Button>
																				</div>
																				{appliedCoupon.isFailure() && (
																					<div className="col-12 invalid-feedback d-block">
																						{appliedCoupon.error}
																					</div>
																				)}
																			</div>
																		)}
																	</Field>
																</form>
															)}
														</Form>
													)}
													{appliedCoupon
														.toOption()
														.chain(identity)
														.fold(null, coupon => (
															<div className="double-margin-top double-margin-bottom">
																<div className="chip full-width promo-code-chip">
																	Applied:{' '}
																	<span className="bold-font">
																		{coupon.code}
																		{coupon.name ? ' - ' + coupon.name : ''}
																	</span>
																	<Button
																		color="link"
																		className="close"
																		onClick={() => onApplyCoupon(null)}>
																		<div className="material-icons align-middle">
																			clear
																		</div>
																	</Button>
																</div>
															</div>
														))}
													{!forcedBillingPeriod && (
														<div className="double-margin-top double-margin-bottom">
															<BillingPeriodSelector
																billingPeriod={billingPeriod}
																onBillingPeriodChange={setBillingPeriod}
															/>
														</div>
													)}
													{isAddingNewCard || !hasPaymentMethod ? (
														<PaymentForm
															showIsSecure
															result={isWaitingForStripeToken ? pending : result}
															onTokenCreated={handleToken}
															onWaitingForToken={setWaitingForStripeToken}
															paymentAmount={prospectivePlanInfo.annualCost / 12}
															footer={
																<Fragment>
																	{hasPaymentMethod && (
																		<div
																			className="form-row text-center"
																			style={{ marginTop: -16 }}>
																			<Col xs={12} className="form-group">
																				<Button
																					className={
																						css.useExistingPaymentMethod
																					}
																					color="link"
																					size="sm"
																					onClick={handleUseExistingCard}>
																					Cancel, use existing card
																				</Button>
																			</Col>
																		</div>
																	)}
																	{renderDefaultFooter(
																		isWaitingForStripeToken || result.isPending(),
																		paymentButton,
																	)}
																</Fragment>
															}
														/>
													) : (
														<RenderRemoteData
															data={existingPaymentMethod}
															success={method => {
																return (
																	<form onSubmit={handlePayWithExistingCard}>
																		<Card className="double-margin-bottom">
																			<Container>
																				<Row className="align-items-center">
																					<Col>
																						<h4
																							className={
																								css.existingPaymentMethod__header
																							}>
																							Payment method
																						</h4>
																						<p className="half-margin-bottom">
																							{method.name}
																						</p>
																						<p>
																							•••• •••• ••••{' '}
																							{method.last4} Exp.{' '}
																							{method.expMonth}/
																							{method.expYear}
																						</p>
																					</Col>
																					<Col className={css.changeCard}>
																						<Button
																							color="secondary"
																							onClick={handleChangeCard}>
																							Change card
																						</Button>
																					</Col>
																				</Row>
																			</Container>
																		</Card>
																		{result.isFailure() &&
																			extractError(result.error).map(
																				(error, index) => (
																					<div
																						key={index}
																						className={
																							'invalid-feedback d-block no-padding-top padding-bottom'
																						}>
																						{error}
																					</div>
																				),
																			)}
																		{renderDefaultFooter(
																			result.isPending(),
																			paymentButton,
																		)}
																		<SecurePayment />
																	</form>
																);
															}}
														/>
													)}
												</Fragment>
											</StripeProvider>
										</ErrorBoundary>
									</Col>
									<Col lg={5} className={css.planCol}>
										<div className="padded">
											<DisplayPlanInfoCard
												className={css.planCard}
												plan={prospectivePlan}
												coupon={appliedCouponOption.toUndefined()}
												billingPeriod={billingPeriod}
												isCheckout={true}
											/>
											<div className="double-margin-top padded no-padding-top">
												<h4 className="light-text-color">
													Your new subscription will begin immediately. Any previous payments
													you made will be credited towards your new plan. The amount you owe
													today is the difference in price between your old plan and your new
													plan, for the remainder of the billing period. Each{' '}
													{billingPeriod === 'month' ? 'month' : 'year'} you will be charged
													at a rate of{' '}
													{billingPeriod === 'month'
														? `$${prospectivePlanInfo.monthlyCost}`
														: `$${prospectivePlanInfo.annualCost}`}
													/{billingPeriod === 'month' ? 'month' : 'yr'} until you cancel your
													subscription.
												</h4>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					</div>
				</Fragment>
			);
		},
);
