import React from 'react';
import {
	RenderRemoteData as RenderRemoteDataBase,
	TRenderRemoteDataStates,
	TRenderRemoteDataMainProps,
} from '@devexperts/react-kit/dist/components/render-remote-data/render-remote-data.component';

export const DataStatePending = () => <div>Loading...</div>;
export const DataStateFailure = () => <div>Failed to load the data</div>;
export const DataStateNoData = () => <div>No data</div>;

const dataStates: TRenderRemoteDataStates<any> = {
	DataStatePending,
	DataStateFailure,
	DataStateNoData,
};

export type TRenderRemoteDataProps<L, A> = Partial<TRenderRemoteDataStates<L>> & TRenderRemoteDataMainProps<L, A>;
export const RenderRemoteData = <L, A>(props: TRenderRemoteDataProps<L, A>) => (
	<RenderRemoteDataBase {...dataStates} {...props} />
);
