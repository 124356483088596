import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, success, RemoteData } from '@devexperts/remote-data-ts';
import App from './App';
import { ask } from 'fp-ts/lib/Reader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { SessionServiceClass } from '../../services/token.service';
import { isSome, none } from 'fp-ts/lib/Option';
import { map, filter, tap, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TAxiosInstance } from 'volley-common/dist/utils/axios';
import { history } from '../../utils/history';
import { getLoginUrl } from '../../components/RedirectToLogin';
import { routes } from 'volley-common/dist/utils/routes';

type TAppContainerContext = {
	sessionService: SessionServiceClass;
	axios: TAxiosInstance;
};

export const AppContainer = combineReader(App, ask<TAppContainerContext>(), (App, ctx) =>
	withRX(App)(() => ({
		defaultProps: {
			isLoggedIn: initial,
		},
		props: {
			isLoggedIn: ctx.sessionService.token$.pipe(
				map(isSome),
				distinctUntilChanged(),
				map(success),
			) as Observable<RemoteData<Error, boolean>>,
		},
		effects$: ctx.axios.errors$.pipe(
			filter(e => !!(e && e.response && e.response.status === 401)),
			tap(() => {
				ctx.sessionService.acceptTokens(none, none);
				if (!history.location.pathname.startsWith(routes.login)) {
					history.push(getLoginUrl());
				}
			}),
		),
	})),
);
