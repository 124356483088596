import React, {
	Dispatch,
	Fragment,
	memo,
	MouseEvent,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { Column } from '../review/BoardView';
import cn from 'classnames';
import css from './StatusEditor.module.scss';
import { Button, Input, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { CirclePicker } from 'react-color';
import { colors } from './WidgetSettingsTab';
import { LoadingButton } from 'volley-common/dist/components/LoadingButton';
import { Tooltip } from 'volley-common/dist/components/Tooltip';

interface StatusEditorProps {
	value: Column[];
	onChange: Dispatch<SetStateAction<Column[]>>;
	canDelete?: (id: number) => Promise<boolean | string>;
}

const componentId = 'StatusEditor-' + uuid();

export const StatusEditor = memo<StatusEditorProps>(function StatusEditor({
	value: state,
	onChange: setState,
	canDelete: canDeleteChecker,
}) {
	const visibleState = state; //.filter(s => !s.deleted);
	if (visibleState.length < 2) {
		return null; // TODO: better fix?
	}

	const onChangeStatus = (id: number) => (update: SetStateAction<Column>) =>
		setState(statuses =>
			statuses.map(status =>
				status.status_id === id ? (typeof update === 'function' ? update(status) : update) : status,
			),
		);

	const handleMoveBefore = (pos: number) => (status: number) => {
		setState(states => {
			const movedStatus = states.find(s => s.status_id === status);
			if (movedStatus) {
				const newState = [...states];
				newState.splice(pos, 0, { ...movedStatus });
				return newState.filter(s => s !== movedStatus);
			}
			return states;
		});
	};

	const handleAddStatus = (e: MouseEvent) => {
		e.preventDefault();
		setState(state => {
			const newState = [...state];
			const position = visibleState.length - 1;
			newState.splice(position, 0, {
				color: findFreeColor(visibleState),
				label: 'New Status',
				status_id: -Date.now(),
			});
			return newState;
		});
	};

	const handleDeleteStatus = (status: number) => {
		setState(state => {
			const deletedEntry = state.find(s => s.status_id === status);
			if (!deletedEntry) {
				return state;
			} else if (deletedEntry.status_id < 0) {
				// added during this editing session, just remove it
				return state.filter(s => s.status_id !== status);
			} else {
				// pre-existing status - mark as deleted but
				return state.map(s => (s.status_id === status ? { ...deletedEntry, deleted: true } : s));
			}
		});
	};

	const handleUndeleteStatus = (id: number) => onChangeStatus(id)(s => ({ ...s, deleted: false }));

	const canDelete = (status: Column, index: number) =>
		visibleState.length > 2 && index !== 0 && index !== visibleState.length - 1;

	const renderStatus = (index: number) => {
		const s = visibleState[index];
		return (
			<DraggableStatus
				index={index}
				status={s}
				onChange={onChangeStatus(s.status_id)}
				onDelete={handleDeleteStatus}
				onUndelete={handleUndeleteStatus}
				canDelete={canDelete(s, index)}
				canDeleteChecker={canDeleteChecker}
			/>
		);
	};

	return (
		<div className={componentId}>
			<div className="settings-section workflow-editor" style={{ marginBottom: '2rem' }}>
				<div className="d-flex align-items-center mb-3">
					<p className="bold-font mb-0 mr-2">Default status</p>
					<h4 className="light-text-color mb-0">New feedback is added to this status</h4>
				</div>
				<div className="draggable-container">
					{/* <DropTarget onStatusDropped={handleMoveBefore(0)} /> */}
					{renderStatus(0)}
				</div>
			</div>
			<div className="settings-section mb-4">
				<div className="draggable-container">
					{visibleState.map((s, i, all) => {
						return i === 0 || i === all.length - 1 ? null : (
							<Fragment key={s.status_id}>
								<DropTarget onStatusDropped={handleMoveBefore(i)} index={i} />
								{renderStatus(i)}
							</Fragment>
						);
					})}
				</div>
				<DropTarget
					onStatusDropped={handleMoveBefore(visibleState.length - 1)}
					index={visibleState.length - 1}
				/>
				<a href="#" onClick={handleAddStatus}>
					+ Add status
				</a>
			</div>
			<div className="settings-section mb-2">
				<div className="d-flex align-items-center">
					<p className="bold-font mb-0 mr-2">Resolved status</p>
					<h4 className="light-text-color mb-0">Resolved feedback is moved to this status</h4>
				</div>
				<div className="draggable-container mt-3 mb-3">{renderStatus(visibleState.length - 1)}</div>
				{/* <DropTarget onStatusDropped={handleMoveBefore(visibleState.length)} /> */}
			</div>
		</div>
	);
});

export const DRAGGABLE_STATUS = 'draggable-status';

export interface DraggableStatusItem {
	status: number;
	originalWidth?: number;
	index: number;
	previewData: Column;
}

interface DraggableStatusProps {
	status: Column;
	index: number;
	canDelete?: boolean;
	onChange?: Dispatch<SetStateAction<Column>>;
	onDelete: (status: number) => void;
	onUndelete: (status: number) => void;
	canDeleteChecker?: (id: number) => Promise<boolean | string>;
}

export function DraggableStatus({
	canDelete = false,
	index,
	status,
	onChange,
	onDelete,
	canDeleteChecker,
	onUndelete,
}: DraggableStatusProps) {
	const id = useMemo(() => 'status_' + uuid().replace(/-/g, ''), []);
	const ref = useRef<HTMLDivElement | null>();

	const [{ isDragging }, dragRef] = useDrag({
		type: DRAGGABLE_STATUS,
		item: { status: status.status_id, index, originalWidth: ref.current?.clientWidth, previewData: status },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const composedRef = useCallback(
		(el: HTMLDivElement | null) => {
			ref.current = el;
			dragRef(el);
		},
		[ref, dragRef],
	);

	return (
		<div
			className={cn('draggable-item d-flex align-items-center', isDragging && css.dragged)}
			ref={canDelete ? composedRef : undefined}>
			<span className={cn(css.dragHandle, canDelete && css.dragHandle_active)}>
				<i className="material-icons">drag_indicator</i>
			</span>
			<div className={css.dragContent}>
				<Input
					className={cn(css.statusName, status.deleted && css.statusName_deleted)}
					value={status.label}
					onChange={e => {
						const label = e.target.value;
						return onChange?.(c => ({ ...c, label }));
					}}
				/>
				{status.deleted && (
					<Button
						className={cn('btn-flat btn-icon', css.remove)}
						onClick={() => onUndelete(status.status_id)}>
						<i className="material-icons">replay</i>
					</Button>
				)}
				{!status.deleted && canDelete && (
					<DeleteStatusButton
						onDelete={onDelete}
						status={status}
						canDelete={status.status_id < 0 ? alwaysCanDelete : canDeleteChecker}
					/>
				)}
				<div id={id} className={css.color} style={{ backgroundColor: status.color }}></div>
				<UncontrolledPopover
					hideArrow
					className={css.colorPopover}
					delay={{ show: 0, hide: 300 }}
					container={`.${componentId}`}
					placement="bottom-end"
					trigger="hover"
					target={`#${id}`}>
					<PopoverBody>
						<CirclePicker
							className={css.colorBox}
							colors={colors}
							onChangeComplete={e => onChange?.(c => ({ ...c, color: e.hex }))}
							color={status.color}
						/>
					</PopoverBody>
				</UncontrolledPopover>
			</div>
		</div>
	);
}

interface DropTargetProps {
	onStatusDropped: (status: number) => void;
	index: number;
}

function DropTarget({ onStatusDropped, index }: DropTargetProps) {
	const [drop, ref] = useDrop({
		accept: DRAGGABLE_STATUS,
		collect: monitor => ({
			item: monitor.getItem(),
			itemType: monitor.getItemType(),
			isOver: monitor.isOver(),
		}),
		drop: (item: DraggableStatusItem) => {
			onStatusDropped(item.status);
		},
	});
	const isDragging =
		drop.itemType === DRAGGABLE_STATUS &&
		(drop.item as DraggableStatusItem).index !== index &&
		(drop.item as DraggableStatusItem).index !== index - 1;
	const isOver = isDragging && drop.isOver;
	return (
		<div ref={ref} className={cn(css.drop, isDragging && css.drop_dragging, isOver && css.drop_active)}>
			{/* <div className={cn(css.dropLine, isDragging && css.dropLine_dragging, isOver && css.dropLine_active)} /> */}
		</div>
	);
}

function findFreeColor(state: Column[]) {
	const taken = new Set(state.map(s => s.color));
	const free = colors.find(c => !taken.has(c));
	return free ?? colors[state.length % colors.length];
}

interface DeleteStatusButtonProps {
	status: Column;
	onDelete: (id: number) => void;
	/** Return false if cannot delete, or a string for a custom error message */
	canDelete?: (id: number) => Promise<boolean | string>;
}

function DeleteStatusButton({ status, onDelete, canDelete }: DeleteStatusButtonProps) {
	const [state, setState] = useState<Promise<void> | boolean | string | null>(null);
	const isPending = typeof state !== 'boolean' && state !== null && typeof state !== 'string';
	const error = typeof state === 'string' ? state : state === false ? 'Cannot delete this status' : null;
	const onClick = () => {
		if (isPending || error) {
			return;
		} else if (state === null) {
			if (canDelete) {
				const promise = canDelete(status.status_id)
					.catch(e => true)
					.then(result => {
						setState(result);
						if (result === true) {
							onDelete(status.status_id);
						}
					});
				setState(promise);
				return;
			}
		}
		onDelete(status.status_id);
	};
	const button = (
		<LoadingButton pending={isPending} className={cn('btn-flat btn-icon', css.remove)} onClick={onClick}>
			<i className="material-icons">delete_outline</i>
		</LoadingButton>
	);
	return error ? (
		<Tooltip target="" trigger={button} parent={'.workflow-editor'}>
			{error}
		</Tooltip>
	) : (
		button
	);
}

const alwaysCanDelete = () => Promise.resolve(true);
