import { withRX } from '@devexperts/react-kit/dist/utils/with-rx2';
import { initial, isSuccess, RemoteData } from '@devexperts/remote-data-ts/dist/remote-data';
import { Products, TArchiveRequest } from './Products';
import { of, Subject, merge, concat, combineLatest, EMPTY } from 'rxjs';
import {
	switchMap,
	scan,
	filter,
	withLatestFrom,
	map,
	mapTo,
	share,
	tap,
	delay,
	distinctUntilChanged,
} from 'rxjs/operators';
import { mapRD, filterSuccess, shareReplayRefCount } from 'volley-common/dist/utils/object.utils';
import { history } from '../../utils/history';
import {
	ProductService,
	TProduct,
	countLimitedProjects,
	parseProductCreationError,
} from 'volley-common/dist/services/products.service';
import { ask } from 'fp-ts/lib/Reader';
import { combineReader } from '@devexperts/utils/dist/adt/reader.utils';
import { SessionServiceClass } from '../../services/token.service';
import { none, Option, getSetoid, fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { TUpdateProductRequest } from './EditProjectPopup';
import { ProfileModelType } from '../../models/profile.model';
import { AuthService } from 'volley-common/dist/services/auth.service';
import { constVoid, not, pipe, constTrue, constFalse } from 'fp-ts/lib/function';
import { ToastService } from 'volley-common/dist/services/toasts.service';
import { genericErrorMessage, isFormError } from 'volley-common/dist/utils/error.utils';
import { routes } from 'volley-common/dist/utils/routes';
import { TeamsService, getProductTeam } from 'volley-common/dist/services/teams.service';
import { setoidNumber } from 'fp-ts/lib/Setoid';
import { ProductsViewModel } from 'volley-common/dist/services/products.view-model';
import React, { ComponentType } from 'react';
import { RouteComponentProps } from 'react-router';
import { MoveProjectRequest } from './MoveProjectPopup';
import { TransferOwnershipRequest } from './TransferOwnership';
import { getNonFieldErrors } from 'volley-common/dist/models/api.model';
import { shouldOfferFirstTimeCoupon } from '../../models/profile.utils';
import { TFileData } from 'volley-common/dist/utils/axios';

type TAddProductAction = { type: 'add'; product: TProduct };
type TDeleteProductAction = { type: 'delete'; id: number };
type TUpdateProductAction = { type: 'update'; product: TProduct };
type TArchiveProductAction = { type: 'archive'; id: number; archived_at?: Date };
type TLoadAction = { type: 'load'; data: RemoteData<Error, TProduct[]> };
type TAction = TAddProductAction | TDeleteProductAction | TLoadAction | TUpdateProductAction | TArchiveProductAction;

type TProductsContainerContext = {
	productService: ProductService;
	sessionService: SessionServiceClass;
	profileModel: ProfileModelType;
	authService: AuthService;
	toastService: ToastService;
	teamsService: TeamsService;
	productsViewModel: ProductsViewModel;
};

/**
 * Recreates the ProductsContainer component from scratch each time when team selector is changed
 */
function withTeamIdKey<P extends RouteComponentProps>(Target: ComponentType<P>) {
	return (props: P) => {
		const teamId = fromNullable(props.match)
			.mapNullable(m => m.params)
			.mapNullable(p => (p as any).id)
			.getOrElse('personal');
		return <Target key={teamId} {...props} />;
	};
}

export const ProductsContainer = combineReader(Products, ask<TProductsContainerContext>(), (Products, ctx) => {
	const { profileModel, productService, teamsService, productsViewModel } = ctx;

	const notifyProductUpdateError = pipe(
		map((result: RemoteData<Error, TProduct>) => result.mapLeft(parseProductCreationError)),
		tap(result => {
			if (result.isFailure()) {
				ctx.toastService.push({
					text: isFormError(result.error)
						? result.error.message
						: getNonFieldErrors(result.error)[0] || genericErrorMessage,
				});
			}
		}),
	);

	return withTeamIdKey(
		withRX(Products)(props$ => {
			const userId$ = ctx.sessionService.decoded$.pipe(map(token => token.map(t => t.user_id)));

			const teamId$ = props$.pipe(
				map(props =>
					fromNullable(props.match)
						.mapNullable(m => m.params)
						.mapNullable(p => (p as any).id)
						.map(Number),
				),
				distinctUntilChanged(getSetoid(setoidNumber).equals),
				shareReplayRefCount(),
			);

			const rememberTeamIdEffect$ = teamId$.pipe(tap(id => teamsService.selectTeam(id)));

			const team$ = teamsService.selectedTeam$.pipe(filterSuccess);

			const addProductRequest$ = new Subject<{ name: string; files?: TFileData[] }>();
			const addProductApiResult$ = addProductRequest$.pipe(
				withLatestFrom(teamId$),
				switchMap(([{ name, files }, teamId]) =>
					files
						? productService.createProductFromUploadedImages(teamId, name, files)
						: productService.createProduct(teamId, name),
				),
				notifyProductUpdateError,
				share(),
			);
			const addProductResult$ = addProductApiResult$.pipe(
				filter(isSuccess),
				map(product => ({ type: 'add', product: product.value } as TAddProductAction)),
			);

			function loadAllProducts(teamId: Option<number>) {
				return teamId.foldL(
					() => productService.getAll(true),
					teamId => productService.getTeamProducts(teamId, true),
				);
			}

			const allProducts$ = teamId$.pipe(switchMap(loadAllProducts));

			const deleteProductRequest$ = new Subject<number>();
			const deleteProductResult$ = deleteProductRequest$.pipe(
				switchMap(id => productService.deleteProduct(id).pipe(mapRD(_ => id))),
				filter(isSuccess),
				map(deletedId => ({ type: 'delete', id: deletedId.value } as TDeleteProductAction)),
			);

			const moveProductRequest$ = new Subject<[productId: number, teamId: number | null]>();
			const moveProductResult$ = moveProductRequest$.pipe(
				switchMap(([productId, teamId]) => productService.moveProduct(productId, teamId)),
				tap(result =>
					result.foldL(
						constVoid,
						constVoid,
						error => {
							ctx.toastService.push({
								text: isFormError(error)
									? error.message
									: getNonFieldErrors(error)[0] || genericErrorMessage,
							});
						},
						() => {
							reloadProduct$.next();
							movedProduct$.next(none);
							ctx.toastService.push({ text: 'Project moved' });
						},
					),
				),
			);

			const transferProductRequest$ = new Subject<[productId: number, newOwner: string]>();
			const transferProductResult$ = transferProductRequest$.pipe(
				switchMap(([productId, newOwner]) => productService.transferProduct(productId, newOwner)),
				tap(result =>
					result.foldL(
						constVoid,
						constVoid,
						error => {
							ctx.toastService.push({
								text: isFormError(error)
									? error.message
									: getNonFieldErrors(error)[0] || genericErrorMessage,
							});
						},
						() => {
							reloadProduct$.next();
							transferredProduct$.next(none);
							ctx.toastService.push({ text: 'Project transferred' });
						},
					),
				),
			);

			const leaveProductRequest$ = new Subject<number>();
			const leaveProductResult$ = leaveProductRequest$.pipe(
				withLatestFrom(userId$),
				switchMap(([id, userId]) =>
					userId.fold(EMPTY, userId => productService.removeCollaborator(id, userId)).pipe(mapRD(_ => id)),
				),
				filter(isSuccess),
				map(deletedId => ({ type: 'delete', id: deletedId.value } as TDeleteProductAction)),
			);

			const updateProductRequest$ = new Subject<TUpdateProductRequest>();
			const updateProductApiResult$ = updateProductRequest$.pipe(
				switchMap(product =>
					productService.update(product.productId, { name: product.title, thumbnail: product.thumbnail }),
				),
				notifyProductUpdateError,
				share(),
			);
			const updateProductResult$ = updateProductApiResult$.pipe(
				filter(isSuccess),
				map(product => ({ type: 'update', product: product.value } as TUpdateProductAction)),
			);

			const archiveProductRequest$ = new Subject<TArchiveRequest>();
			const archiveProductApiResult$ = archiveProductRequest$.pipe(
				switchMap(product => productService.setArchiveProduct(product.productId, product.archived)),
				share(),
			);
			const archiveProductResult$ = archiveProductApiResult$.pipe(
				filterSuccess,
				map(
					product =>
						({
							type: 'archive',
							id: product.id,
							archived_at: product.archived_at,
						} as TArchiveProductAction),
				),
			);

			const editProductIntent$ = new Subject<Option<TProduct>>();
			const editingProduct$ = merge(editProductIntent$, updateProductResult$.pipe(mapTo(none)));

			const movedProduct$ = new Subject<Option<TProduct>>();
			const transferredProduct$ = new Subject<Option<TProduct>>();

			const reloadProduct$ = new Subject<void>();
			const reloadedProducts$ = reloadProduct$.pipe(
				withLatestFrom(teamId$),
				switchMap(([, teamId]) => loadAllProducts(teamId)),
				filter(rd => rd.isSuccess()),
				map(data => ({ type: 'load', data } as TLoadAction)),
			);

			const products$ = merge(
				allProducts$.pipe(map(data => ({ type: 'load', data } as TLoadAction))),
				reloadedProducts$,
				addProductResult$,
				updateProductResult$,
				merge(leaveProductResult$, deleteProductResult$, archiveProductResult$),
			).pipe(
				scan<TAction, RemoteData<Error, TProduct[]>>((products, action) => {
					switch (action.type) {
						case 'add':
							return products.map(products => [action.product, ...products]);
						case 'update':
							return products.map(products =>
								products.map(product => (product.id === action.product.id ? action.product : product)),
							);
						case 'load':
							return action.data;
						case 'delete':
							return products.map(products => products.filter(p => p.id !== action.id));
						case 'archive':
							return products.map(products =>
								products.map(product =>
									product.id === action.id
										? {
												...product,
												archived_at: action.archived_at,
										  }
										: product,
								),
							);
					}
				}, initial),
				mapRD(products =>
					products
						.map(product => ({
							...product,
							created_at: new Date(product.created_at),
							updated_at: product.updated_at ? new Date(product.updated_at) : undefined,
						}))
						.sort((a, b) => b.created_at.getTime() - a.created_at.getTime()),
				),
				shareReplayRefCount(),
			);

			const openCreatedProjectEffect$ = addProductResult$.pipe(
				tap(action => history.push(routes.product(action.product.id))),
			);

			const needActivation$ = profileModel.profile$.pipe(
				filterSuccess,
				map(account => account.fold(false, account => !account.profile.email_confirmed)),
			);

			const activationResult$ = profileModel.activationResult$.pipe(tap(c => console.log('act', c)));

			const activationResultShown$ = activationResult$.pipe(
				switchMap(() => concat(of(true), of(false).pipe(delay(5000)))),
				tap(c => console.log('act result', c)),
			);

			const passwordResultShown$ = of(false);

			const resendConfirmationEmail$ = new Subject();
			const resendConfirmationResult$ = resendConfirmationEmail$.pipe(
				switchMap(() => ctx.authService.resendConfirmationEmail()),
				tap(result =>
					result.foldL(
						constVoid,
						constVoid,
						error =>
							ctx.toastService.push({
								text: genericErrorMessage,
							}),
						success =>
							ctx.toastService.push({
								text: 'Confirmation email has been resent.',
							}),
					),
				),
			);

			const maxProjects$ = combineLatest(
				ctx.profileModel.isSuperUser$,
				ctx.profileModel.currentPlan$.pipe(map(plan => plan.features.maxProjects)),
			).pipe(map(([isSuperUser, maxProjects]) => (isSuperUser ? none : maxProjects)));
			const canCreateMoreProjects$ = combineLatest(products$, maxProjects$, userId$, team$).pipe(
				map(([products, maxProjects, userId, team]) => {
					return team.fold(
						// personal project: check the plan limits
						maxProjects.fold(true, limit =>
							userId.fold(false, userId =>
								products
									.map(products => countLimitedProjects(products, userId) < limit)
									.getOrElse(false),
							),
						),
						// team project: unlimited projects
						() => true,
					);
				}),
			);

			const canEditProject$ = combineLatest([
				userId$,
				ctx.profileModel.isSuperUser$,
				productsViewModel.isPersonalOrSharedProduct$,
			]).pipe(
				map(([userId, isSuperUser, isPersonalOrSharedProduct]) => {
					if (isSuperUser) {
						return constTrue;
					} else {
						return (product: TProduct) =>
							fromPredicate(not(isPersonalOrSharedProduct))(product)
								.chain(getProductTeam)
								.fold(
									userId.exists(userId => !!product.owner && product.owner.id === userId),
									constTrue,
								);
					}
				}),
				shareReplayRefCount(),
			);

			const eligibleForFreeTrial$ = ctx.profileModel.profile$.pipe(
				map(p => p.exists(p => p.exists(p => shouldOfferFirstTimeCoupon(p.billing)))),
				distinctUntilChanged(),
			);

			const teamIdEq = getSetoid(setoidNumber);

			return {
				defaultProps: {
					onAddProduct: (data: { name: string; files?: TFileData[] }) => addProductRequest$.next(data),
					onDeleteProduct: (id: number) => deleteProductRequest$.next(id),
					onLeaveProduct: (id: number) => leaveProductRequest$.next(id),
					onUpdateProduct: (request: TUpdateProductRequest) => updateProductRequest$.next(request),
					onArchiveProduct: (request: TArchiveRequest) => archiveProductRequest$.next(request),
					onGoToProduct: (product: TProduct) => history.push(routes.product(product.id)),
					onSetEditingProduct: (product: Option<TProduct>) => editProductIntent$.next(product),
					onSetMovedProduct: (product: Option<TProduct>) => movedProduct$.next(product),
					onSetTransferredProduct: (product: Option<TProduct>) => transferredProduct$.next(product),
					onResendConfirmationEmail: () => resendConfirmationEmail$.next(),
					onReloadProducts: () => reloadProduct$.next(),
					products: initial,
					userId: none,
					updateProductResult: initial,
					addProductResult: initial,
					editingProduct: none,
					movedProduct: none,
					transferredProduct: none,
					activationResult: initial,
					activationResultShown: false,
					passwordResultShown: false,
					needActivation: false,
					resendConfirmationResult: initial,
					canCreateMoreProjects: false,
					maxProjects: none,
					canEditProject: constFalse,
					teamId: none,
					teams: initial,
					moveProductResult: initial,
					transferProductResult: initial,
					onMoveProduct: (req: MoveProjectRequest) =>
						moveProductRequest$.next([req.productId, req.moveToTeamId.toNullable()]),
					onTransferProduct: (req: TransferOwnershipRequest) =>
						transferProductRequest$.next([req.productId, req.newOwner]),
				},
				props: {
					products: combineLatest([products$, teamId$, productsViewModel.isPersonalOrSharedProduct$]).pipe(
						map(([products, teamId, isPersonalOrSharedProduct]) =>
							products.map(products =>
								products.filter(p => {
									const actualProductTeam = fromPredicate(not(isPersonalOrSharedProduct))(p).chain(
										getProductTeam,
									);
									return teamIdEq.equals(teamId, actualProductTeam);
								}),
							),
						),
					),
					userId: userId$,
					updateProductResult: updateProductApiResult$,
					addProductResult: addProductApiResult$,
					editingProduct: editingProduct$,
					movedProduct: movedProduct$,
					transferredProduct: transferredProduct$,
					passwordResultShown: passwordResultShown$,
					needActivation: needActivation$,
					activationResultShown: activationResultShown$,
					activationResult: activationResult$,
					resendConfirmationResult: resendConfirmationResult$,
					canCreateMoreProjects: canCreateMoreProjects$,
					maxProjects: maxProjects$,
					canEditProject: canEditProject$,
					teamId: teamId$,
					teams: teamsService.teams$,
					moveProductResult: moveProductResult$,
					transferProductResult: transferProductResult$,
					eligibleForFreeTrial: eligibleForFreeTrial$,
				},
				effects$: merge(openCreatedProjectEffect$, rememberTeamIdEffect$),
			};
		}),
	);
});
